import React, { useState, TouchEvent } from 'react';
import styles from './ViewImageComponent.module.scss';
import {Button} from "@src/components/shared/form/Button/Button";

interface ViewImageComponentProps {
    src: string;
    alt: string;
    maxZoom?: number;
    minZoom?: number;
}

export const ViewImageComponent: React.FC<ViewImageComponentProps> = ({
                                                                          src,
                                                                          alt,
                                                                          maxZoom = 3,
                                                                          minZoom = 1,
                                                                      }) => {
    const [zoomLevel, setZoomLevel] = useState(1);
    const [touchDistance, setTouchDistance] = useState(0);

    // Calculate the distance between two touch points
    const calculateDistance = (touches: TouchList): number => {
        const [touch1, touch2] = Array.from(touches);
        return Math.sqrt(
            (touch2.pageX - touch1.pageX) ** 2 + (touch2.pageY - touch1.pageY) ** 2
        );
    };

    const handleTouchStart = (e: TouchEvent<HTMLDivElement>): void => {
        if (e.touches.length === 2) {
            const distance = calculateDistance(e.touches as unknown as TouchList);
            setTouchDistance(distance);
        }
    };

    const handleTouchMove = (e: TouchEvent<HTMLDivElement>): void => {
        if (e.touches.length === 2) {
            const newDistance = calculateDistance(e.touches as unknown as TouchList);
            const zoomChange = newDistance / touchDistance;
            setZoomLevel((prevZoom) =>
                Math.min(maxZoom, Math.max(minZoom, prevZoom * zoomChange))
            );
            setTouchDistance(newDistance);
        }
    };

    const handleTouchEnd = (): void => {
        setTouchDistance(0); // Reset touch distance after gesture ends
    };

    const zoomIn = () => {
        setZoomLevel((prevZoom) => Math.min(maxZoom, prevZoom + 0.4));
    };

    const zoomOut = () => {
        setZoomLevel((prevZoom) => Math.max(minZoom, prevZoom - 0.4));
    };

    const resetZoom = () => {
        setZoomLevel(1);
    };

    return (
        <div className={styles.imageZoomViewer}>
            <div
                className={styles.imageContainer}
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
                style={{
                    transform: `scale(${zoomLevel})`,
                    transition: 'transform 0.1s ease',
                    transformOrigin: 'center',
                }}
            >
                <img src={src} alt={alt} className={styles.image} />
            </div>
            <div className={styles.controls}>
                <Button
                    onClick={zoomIn}
                    size={"small"}
                    icon={(
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_6_12228)">
                                <path
                                    d="M15.5 14H14.71L14.43 13.73C15.41 12.59 16 11.11 16 9.5C16 5.91 13.09 3 9.5 3C5.91 3 3 5.91 3 9.5C3 13.09 5.91 16 9.5 16C11.11 16 12.59 15.41 13.73 14.43L14 14.71V15.5L19 20.49L20.49 19L15.5 14ZM9.5 14C7.01 14 5 11.99 5 9.5C5 7.01 7.01 5 9.5 5C11.99 5 14 7.01 14 9.5C14 11.99 11.99 14 9.5 14Z"
                                    fill="#323232"/>
                                <path d="M12 10H10V12H9V10H7V9H9V7H10V9H12V10Z" fill="#323232"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_6_12228">
                                    <rect width="24" height="24" fill="white"/>
                                </clipPath>
                            </defs>
                        </svg>
                    )}
                />
                <Button
                    onClick={zoomOut}
                    size={"small"}
                    icon={(
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_6_12072)">
                                <path
                                    d="M15.5 14H14.71L14.43 13.73C15.41 12.59 16 11.11 16 9.5C16 5.91 13.09 3 9.5 3C5.91 3 3 5.91 3 9.5C3 13.09 5.91 16 9.5 16C11.11 16 12.59 15.41 13.73 14.43L14 14.71V15.5L19 20.49L20.49 19L15.5 14ZM9.5 14C7.01 14 5 11.99 5 9.5C5 7.01 7.01 5 9.5 5C11.99 5 14 7.01 14 9.5C14 11.99 11.99 14 9.5 14ZM7 9H12V10H7V9Z"
                                    fill="#323232"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_6_12072">
                                    <rect width="24" height="24" fill="white"/>
                                </clipPath>
                            </defs>
                        </svg>
                    )}
                />
                <Button
                    onClick={resetZoom}
                    size={"small"}
                    icon={(
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_6_13405)">
                                <path
                                    d="M17.6498 6.35C16.1998 4.9 14.2098 4 11.9998 4C7.57977 4 4.00977 7.58 4.00977 12C4.00977 16.42 7.57977 20 11.9998 20C15.7298 20 18.8398 17.45 19.7298 14H17.6498C16.8298 16.33 14.6098 18 11.9998 18C8.68977 18 5.99977 15.31 5.99977 12C5.99977 8.69 8.68977 6 11.9998 6C13.6598 6 15.1398 6.69 16.2198 7.78L12.9998 11H19.9998V4L17.6498 6.35Z"
                                    fill="#323232"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_6_13405">
                                    <rect width="24" height="24" fill="white"/>
                                </clipPath>
                            </defs>
                        </svg>
                    )}
                />
            </div>
        </div>
    );
};
