import React, {ReactNode, useContext} from "react";

import {Typography} from "@mui/material";
import {UseMutationResult} from "@tanstack/react-query";

import {Button} from "@src/components/shared/form/Button/Button";
import {ActionDrawerBody} from "@src/components/shared/ActionDrawerBody/ActionDrawerBody";
import {ApplicationMap} from "@src/core/applicationMap";
import {DialogWindowContextHolderContext} from "@src/components/context/DialogWindowContextHolder/DialogWindowContextHolder.context";


interface IConfirmSubmit {
    mutation:  UseMutationResult<any, Error, any, unknown>,
    title: ReactNode,
    message: ReactNode,
    submitContent: ReactNode,
    icon: ReactNode
}

export const ConfirmSubmit = ({ mutation, title, message, submitContent, icon}: IConfirmSubmit) => {
    const { drawerApi} = useContext(DialogWindowContextHolderContext);

    const onSubmit = async () => {
        await mutation.mutateAsync({});

        drawerApi.close(ApplicationMap.REPORT_BOX_CONFIRM)
    }

    return (
        <ActionDrawerBody
            title={
                <Typography variant="h6" gutterBottom sx={{color: 'white', textAlign: 'left'}}>
                    {title}
                </Typography>
            }
            description={message}
            action={
                <Button onClick={onSubmit} icon={icon} loading={mutation.isPending}>
                    {submitContent}
                </Button>
            }
        />
    )
}
