import React from 'react';
import styles from './CirclesCount.module.scss';

interface CirclesCountProps {
    progress: number; // Progress in percentage (0 to 100)
    radius?: number; // Radius of the circle
    strokeWidth?: number; // Width of the progress bar stroke
    color?: string; // Color of the progress bar
    trailColor?: string; // Color of the background circle
}

export const CirclesCount: React.FC<CirclesCountProps> = ({
                                                              progress,
                                                              radius = 60,
                                                              strokeWidth = 20,
                                                              color = '#99C75A',
                                                              trailColor = '#1e1e1e',
                                                          }) => {
    progress = !progress ? 0.1 : progress;

    const circumference = 2 * Math.PI * radius;
    const offset = circumference - (progress / 100) * circumference;

    const center = radius + strokeWidth / 2;

    return (
        <div className={styles.circleProgressContainer}>
            <svg
                className={styles.circleSvg}
                width={radius * 2 + strokeWidth}
                height={radius * 2 + strokeWidth}
            >
                {/* Progress Circle */}
                <circle
                    className={styles.circleProgress}
                    cx={center}
                    cy={center}
                    r={radius}
                    stroke={color}
                    strokeWidth={strokeWidth}
                    fill="none"
                    strokeDasharray={circumference}
                    strokeDashoffset={offset}
                    strokeLinecap="round"
                />
                {/* Rotating Moving Head */}
                <g
                    className={styles.movingHeadGroup}
                    style={{
                        transform: `rotate(${(progress / 100) * 360}deg)`,
                        transformOrigin: `${center}px ${center}px`,
                    }}
                >
                    <path
                        className={styles.movingHead}
                        d={`
                            M ${center + radius - strokeWidth / 2} ${center}
                            a ${strokeWidth / 2} ${strokeWidth / 2} 0 1 0 ${strokeWidth} 0
                        `}
                        fill={color}
                    />
                </g>
            </svg>
        </div>
    );
};
