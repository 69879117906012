import {Button} from "@src/components/shared/form/Button/Button";
import {useLogout} from "@src/services/auth/useLogout";
import {useTranslationCascade} from "@src/utils/hooks/i18n/useTranslationCascade";
import {LogoutIcon} from "@src/components/icons/core/Logout";

export const LogoutButton = () => {
    const { mutateAsync } = useLogout();
    const {t} = useTranslationCascade();

    const logout = async () => {
        await mutateAsync();
    }

    return (
        <Button onClick={logout} icon={<LogoutIcon />}>
            { t('translations:personalAccountDrawer.logout') }
        </Button>
    )
}
