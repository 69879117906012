import {useIsLoggedIn} from "@src/services/auth/useIsLoggedIn";
import {ApplicationMap} from "@src/core/applicationMap";
import BoxInfo from "@src/components/core/pages/BoxInfo/BoxInfo";
import {useContext} from "react";
import {
    DialogWindowContextHolderContext
} from "@src/components/context/DialogWindowContextHolder/DialogWindowContextHolder.context";
import {BoxInfoSuperAdmin} from "@src/components/core/pages/BoxInfoSuperAdmin/BoxInfoSuperAdmin";

export const useOpenBox = () => {
    const {data} = useIsLoggedIn()
    const {drawerApi} = useContext(DialogWindowContextHolderContext);

    const openBox = ({code}: {code: string}) => {
        if (data.role === "staff") {
            drawerApi.open({
                key: ApplicationMap.BOX_INFO_ADMIN,
                anchor: "right",
                children: (
                    <BoxInfoSuperAdmin code={code} />
                )
            })
        }

        if (data.role === "user") {
            drawerApi.open({
                key: ApplicationMap.BOX_INFO,
                anchor: "right",
                children: (
                    <BoxInfo code={code} />
                )
            })
        }
    }

    return {openBox}
}
