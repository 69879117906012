import {WelcomeGBO} from "@src/components/icons/core/WelcomeGBO";
import {WelcomeGPI} from "@src/components/icons/core/WelcomeGPI";
import {WelcomeNEVEON} from "@src/components/icons/core/WelcomeNEVEON";
import {ReactNode} from "react";

export const UserRoleIconBase: Record<string, ReactNode> = {
    GPI: <WelcomeGPI />,
    GBO: <WelcomeGBO />,
    NEVEON: <WelcomeNEVEON />
}
