import {useTranslation} from "react-i18next";
import type {i18n, TFunction} from "i18next";

export const useTranslationCascade = (): {
    t: any;
    i18n: any;
    // ready: boolean;
} => {
    const translation = useTranslation();


    return {
        t: translation.t,
        i18n: translation.i18n
    }
}
