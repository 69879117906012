import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { XhrBase } from "@src/core/xhr";
import { useHttpClient } from "@src/utils/hooks/xhr/useHttpClient";
import { useContext } from "react";
import { QueryDependencyContext } from "@src/components/context/QueryDeppendencyContext/QueryDeppendencyContext";
import {Alert} from "@mui/material";
import {
    DialogWindowContextHolderContext
} from "@src/components/context/DialogWindowContextHolder/DialogWindowContextHolder.context";

const isAxiosError = (error: unknown): error is AxiosError => {
    return (error as AxiosError).isAxiosError === true;
};

export const useLoginMutation = () => {
    const { xhr } = useHttpClient();
    const { updateDepp } = useContext(QueryDependencyContext);
    const {snackbarApi} = useContext(DialogWindowContextHolderContext);

    return useMutation({
        mutationFn: async ({ login, password }: { login: string; password: string }) => {
            try {
                const result = await xhr.post({
                    relativeUrl: XhrBase.AUTH_LOGIN,
                    payload: { login, password },
                });
                return result;
            } catch (error) {
                if (isAxiosError(error) && error.response?.status === 401) {
                    // Handle unauthorized specifically
                    console.error("Unauthorized: Invalid login or password");
                    throw new Error("Unauthorized: Invalid login or password");
                }
                // Re-throw other errors for global handling
                throw error;
            }
        },
        onSuccess: () => {
            updateDepp("useIsLoggedIn");
        },
        onError: (error) => {
            // Global error handling here

            snackbarApi.open({
                key: "box_check",
                anchorOrigin: { vertical: "top", horizontal: "center" },
                children: (
                    <Alert severity="error" sx={{ width: '100%' }}>
                        Invalid login or password.
                    </Alert>
                )
            });
            console.error("Login failed:", error instanceof Error ? error.message : error);
        },
    });
};
