import {FieldValues, SubmitHandler, useForm} from "react-hook-form";

import {Typography} from "@mui/material";

import {ManualInputFullIcon} from "@src/components/icons/scanner/ManualInputFullIcon";
import {FormCore} from "@src/components/shared/form/Form/Form";
import {TextInputControl} from "@src/components/shared/form/TextInput/TextInput";
import {Button} from "@src/components/shared/form/Button/Button";
import {useGetBoxInfo} from "@src/services/box/useGetBoxInfo";
import {ApplicationMap} from "@src/core/applicationMap";
import {useOpenBox} from "@src/components/core/pages/Scanner/utils/useOpenBox";
import {useDialogWindow} from "@src/utils/hooks/contextMenu/useDialogWindow";
import {useTranslationCascade} from "@src/utils/hooks/i18n/useTranslationCascade";

import styles from "./ManualEnterDrawerBody.module.scss";


export const ManualEnterDrawerBody = () => {
    const {mutateAsync} = useGetBoxInfo();
    const methods = useForm();
    const {openBox} = useOpenBox();
    const {close} = useDialogWindow();
    const {t} = useTranslationCascade();

    const onSubmit: SubmitHandler<FieldValues> = async ({code}) => {
        const scanned = await mutateAsync({code: code});
        openBox({code: scanned.code});

        close(ApplicationMap.MANUAL_ENTER);
    }

    return (
        <div className={styles.ManualEnterDrawerBody}>
            <FormCore methods={methods} onSubmit={onSubmit}>
                <div>
                    <ManualInputFullIcon/>
                </div>
                <div>
                    <Typography variant="h5" gutterBottom sx={{color: 'white', textAlign: 'center'}}>
                        { t('translations:scaner.manualEnter.title') }
                    </Typography>
                    <Typography variant="subtitle2" gutterBottom sx={{color: 'white', textAlign: 'center'}}>
                        { t('translations:scaner.manualEnter.message') }
                    </Typography>
                </div>
                <div className={styles.ManualEnterInput}>
                    <TextInputControl path={"code"} label={"Box Code"} />
                </div>
                <div className={styles.ManualEnterActino}>
                    <Button type={"submit"} loading={methods.formState.isSubmitting}>
                        {t("translations:scaner.manualEnter.submit")}
                    </Button>
                </div>
            </FormCore>
        </div>
    )
}
