import {ReactNode} from "react";
import classNames from "classnames";
import {BoxHistoryHasNoIssues} from "@src/components/icons/boxHistory/BoxHistoryHasNoIssues";
import {Button, Typography} from "@mui/material";

import styles from "./BoxActionBase.module.scss";
import {BoxEvent, EventType, useBoxHistoryLastEvent} from "@src/services/box/useBoxHistory";
import dayjs from "dayjs";


export const BoxAction = ({done, icon, label, event, code, onClick, requested}: {code: string; requested?: boolean; onClick?: ((event: any) => void); done?: BoxEvent; event: EventType; label: string; icon?: ReactNode}) => {
    const {data} = useBoxHistoryLastEvent({code});

    const doneAt = (() => {
        if (!done) {
            return null;
        }

        return dayjs(done.date).format("YYYY-MM-DD HH:mm");
    })();

    return (
        <div className={classNames(styles.BoxAction, done && styles.done, requested && styles.requested)}>
            <Button disabled={!!done} onClick={onClick}>
                {
                    requested &&
                    <div className={styles.requested}>
                        requested
                    </div>
                }
                {
                    done &&
                    <div className={styles.actionChecked}>
                        <div className={styles.textCompensator}>
                            <Typography variant="h5" gutterBottom sx={{fontSize: '12px', color: 'white', textAlign: 'center'}}>
                                Commited at <br/>
                                {doneAt}
                            </Typography>
                        </div>

                        <BoxHistoryHasNoIssues/>

                        <Typography variant="h5" gutterBottom sx={{fontSize: '12px', color: 'white', textAlign: 'center'}}>
                            Commited at <br/>
                            {doneAt}
                        </Typography>
                    </div>
                }
                <div className={styles.icon}>
                    <div className={styles.iconWrapper}>
                        {icon}
                    </div>

                </div>

                <div className={styles.actionLabel}>
                    {label}
                </div>
            </Button>
        </div>
    )
}
