import {useMutation} from "@tanstack/react-query";
import {useHttpClient} from "@src/utils/hooks/xhr/useHttpClient";
import {TAction, XhrBase} from "@src/core/xhr";
import {useBoxHistory} from "@src/services/box/useBoxHistory";

interface IUseCommitActionForBox {
    action: TAction;
    code: string;
}

export const useCommitActionForBox = ({action, code}: IUseCommitActionForBox) => {
    const {xhr} = useHttpClient();
    const {refetch} = useBoxHistory({code});

    return useMutation({
        mutationFn: async () => {
            const response = await xhr.post({
                relativeUrl: XhrBase.BOX_ACTION({code, action}),
            });

            await refetch();
        }
    })
}
