import React, {useState, useCallback, ReactNode, ReactElement} from 'react';
import { SnackbarProps } from '@mui/material';
import {DisposableSnackbar} from "@src/utils/hooks/contextMenu/components/DisposableSnackbar/DisposableSnackbar";

interface OpenSnackbarConfig extends Omit<SnackbarProps, 'open' | 'onClose'> {
    key?: string;
    children?: ReactElement; // Make children optional
    onOpen?: () => void;
    onClose?: () => void;
}

interface SnackbarInstance {
    key: string;
    config: OpenSnackbarConfig;
}

export interface SnackbarHookAPI {
    open: (config: OpenSnackbarConfig) => void;
    close: (key: string) => void;
    closeAll: () => void;
}

export const useSnackbar = (): readonly [SnackbarHookAPI, React.ReactElement] => {
    const [snackbarInstances, setSnackbarInstances] = useState<SnackbarInstance[]>([]);

    const open = useCallback((config: OpenSnackbarConfig) => {
        // Trigger the `onOpen` event first
        if (config.onOpen) {
            config.onOpen();
        }

        const key = config.key || `snackbar_${new Date().getTime()}`;

        // Add the snackbar to `snackbarInstances`
        const newInstance: SnackbarInstance = { key, config };
        setSnackbarInstances((prev) => [...prev, newInstance]);
    }, []);

    const close = useCallback((key: string) => {
        setSnackbarInstances((prev) =>
            prev.filter((instance) => instance.key !== key)
        );

        const closingInstance = snackbarInstances.find((instance) => instance.key === key);
        if (closingInstance?.config.onClose) {
            closingInstance.config.onClose();
        }
    }, [snackbarInstances]);

    const closeAll = useCallback(() => {
        snackbarInstances.forEach((instance) => {
            if (instance.config.onClose) {
                instance.config.onClose();
            }
        });

        // Remove all snackbars
        setSnackbarInstances([]);
    }, [snackbarInstances]);

    const contextHolder = (
        <>
            {snackbarInstances.map(({ key, config }) => (
                <DisposableSnackbar
                    key={key}
                    {...config}
                    onClose={() => close(key)}
                />
            ))}
        </>
    );

    const snackbarApi: SnackbarHookAPI = {
        open,
        close,
        closeAll,
    };

    return [snackbarApi, contextHolder] as const;
};
