import React, { PropsWithChildren, useEffect, useCallback, useRef } from "react";
import { FormProvider, useFormContext, useController } from "react-hook-form";
import classNames from "classnames";
import styles from "./Form.module.scss";

import {FieldValues, UseFormReturn} from "react-hook-form";
import {getFirstFailedFieldRef} from "@src/utils/functions/form/form";

export type TForm<T> = UseFormReturn<FieldValues, T, undefined>

export function FormCore({
                             children,
                             onSubmit,
                             methods,
                             title,
                             id,
                             plain,
                             withInFormSubmit,
                             shrink
                         }: PropsWithChildren<{
    classNames?: string[];
    onSubmit?: (data: any, e: any) => any;
    methods: TForm<any>;
    title?: string;
    id?: string;
    plain?: boolean;
    withInFormSubmit?: boolean;
    shrink?: boolean;
}>) {
    const formRef = useRef<HTMLFormElement | null>(null);

    useEffect(() => {
        methods && void methods.setValue("touched", false);
    }, []);

    const errors = methods?.formState?.errors;

    const firstError = methods && Object.keys(errors).reduce((field: any, a) => {
        return !!errors[field] ? field : a;
    }, null);

    useEffect(() => {
        if (!plain) {
            getFirstFailedFieldRef(methods);
        }
    }, [methods?.formState?.submitCount, firstError]);

    if (!methods && !plain) {
        throw new Error(`Form with id(${id}) does not have methods passed in`);
    }

    const handleSubmitWrapper = async (data: any, e: any) => {
        e?.preventDefault();  // Prevent page reload
        methods.setValue("submitting", true);

        if (onSubmit) {
            await onSubmit(data, e);
        }

        methods.setValue("submitting", false);
    };

    const handleKeyDown = useCallback(
        (event: KeyboardEvent) => {
            if (event.key === "Enter" && !event.shiftKey) {
                event.preventDefault(); // Prevent default Enter behavior
                event.stopPropagation(); // Prevent bubbling up to parent forms

                methods.handleSubmit(handleSubmitWrapper)();
            }
        },
        [methods, handleSubmitWrapper]
    );

    useEffect(() => {
        const currentForm = formRef.current;

        if (!plain && currentForm && withInFormSubmit) {
            currentForm.addEventListener("keydown", handleKeyDown);
        }

        return () => {
            if (!plain && currentForm && withInFormSubmit) {
                currentForm.removeEventListener("keydown", handleKeyDown);
            }
        };
    }, [plain]);

    const FormProviderWrapper = FormProvider as any;

    if (plain) {
        return <>
            {title && <label htmlFor={id}>{title}</label>}
            {children}
        </>;
    }

    const submitMethod = methods.handleSubmit(handleSubmitWrapper);

    return (
        <FormProviderWrapper {...methods} submitMethod={submitMethod}>
            {title && <label htmlFor={id}>{title}</label>}
            <form
                ref={formRef}
                onSubmit={submitMethod}
                id={id}
                className={classNames(shrink && styles.shrink)}
            >
                {children}
            </form>
        </FormProviderWrapper>
    );
}

export { useFormContext, useController };
