import { useEffect, useRef } from "react";

type OnScanCallback = (scannedData: string) => void;

// Map physical `code` values to English characters
const CODE_TO_ENGLISH_MAP: Record<string, string> = {
    KeyA: "A", KeyB: "B", KeyC: "C", KeyD: "D", KeyE: "E",
    KeyF: "F", KeyG: "G", KeyH: "H", KeyI: "I", KeyJ: "J",
    KeyK: "K", KeyL: "L", KeyM: "M", KeyN: "N", KeyO: "O",
    KeyP: "P", KeyQ: "Q", KeyR: "R", KeyS: "S", KeyT: "T",
    KeyU: "U", KeyV: "V", KeyW: "W", KeyX: "X", KeyY: "Y",
    KeyZ: "Z", Digit0: "0", Digit1: "1", Digit2: "2", Digit3: "3",
    Digit4: "4", Digit5: "5", Digit6: "6", Digit7: "7", Digit8: "8",
    Digit9: "9",
};

export const useExternalScanner = (onScan: OnScanCallback, disabled: boolean): void => {
    const lastTimeRef = useRef<number>(Date.now()); // To track the last keypress time
    const scannerSpeedThreshold = 30; // Max interval (ms) for scanner input detection
    const normalTypingThreshold = 300; // Threshold to identify human typing speed

    useEffect(() => {
        let buffer = ""; // Local variable to accumulate scanned characters
        let isScannerInput = false; // Flag to track scanner input

        const handleKeyDown = (e: KeyboardEvent): void => {
            if (disabled) {
                return;
            }

            const currentTime = Date.now();
            const timeElapsed = currentTime - lastTimeRef.current;

            // Reset on slow input
            if (timeElapsed > normalTypingThreshold) {
                buffer = "";
                isScannerInput = false; // Treat as human input
            }

            lastTimeRef.current = currentTime;

            // If Enter is pressed, process the buffer and reset
            if (e.code === "Enter") {
                if (isScannerInput || buffer.length > 3) {
                    onScan(buffer); // Trigger the onScan callback with the scanned data
                }
                buffer = ""; // Reset the buffer
                isScannerInput = false; // Reset scanner input flag
                return; // Stop further processing
            }

            // Map physical key `code` to English characters
            const englishKey = CODE_TO_ENGLISH_MAP[e.code];
            if (!englishKey) {
                return; // Ignore unmapped keys
            }

            // Append the current key to the buffer
            buffer += englishKey;

            // Determine input type based on speed and length
            if (!isScannerInput && buffer.length > 2 && timeElapsed < scannerSpeedThreshold) {
                isScannerInput = true; // Mark as scanner input
            }
        };

        if (!disabled) {
            // Attach the event listener
            window.addEventListener("keydown", handleKeyDown);
        }

        // Cleanup listener on unmount
        return () => {
            if (!disabled) {
                window.removeEventListener("keydown", handleKeyDown);
            }
        };
    }, [onScan, disabled]);
};
