import MuiButton, {ButtonProps} from '@mui/material/Button';

import styles from "./Button.module.scss";
import classNames from "classnames";
import {CircularProgress} from "@mui/material";
import React, {ReactNode} from "react";
import {Space} from "@src/components/shared/Space/Space";

interface AdditionalButtonProps extends ButtonProps {
    align?: 'center' | 'right';
    loading?: boolean;
    icon?: ReactNode;
}

export const Button = ({children, loading, icon, ...props}: AdditionalButtonProps) => {

    return (
        <div className={classNames(styles.Button, props.align && styles[`align_${props.align}`])}>
            <MuiButton
                // startIcon={<SaveIcon />}
                variant="contained" size="large" {...props}
            >
                <Space padding={0} align={"center"} justify={"center"}>
                    {
                        loading &&
                        <CircularProgress sx={{color: "white"}} size="24px" />
                    }
                    {!loading && icon}
                    {children}
                </Space>
            </MuiButton>
        </div>
    )
}
