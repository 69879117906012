import React, {ReactNode} from 'react';
import styles from './Space.module.scss';

type TDirection = 'horizontal' | 'vertical';
type TGap = 0 | 4 | 8 | 16 | 24 | 32 | 40;
type TJustify = 'center' | 'start' | 'end' | 'space-between' | 'space-around' | 'space-evenly';
type TAlign = 'center' | 'start' | 'end' | 'baseline' | 'space-between' | 'space-around' | 'space-evenly';
type TPadding = number | [number, number] | [number, number, number, number];

interface MapSpaceProps {
    direction?: TDirection;
    gap?: TGap;
    justify?: TJustify;
    align?: TAlign;
    stretch?: boolean;
    overflow?: boolean;
    wrap?: boolean;
    padding?: TPadding;
    children: ReactNode;
}

export const Space: React.FC<MapSpaceProps> = ({
                                                   direction = 'horizontal',
                                                   gap = 16,
                                                   justify = 'start',
                                                   align = 'start',
                                                   stretch = false,
                                                   wrap = false,
                                                   padding = 16,
                                                   children,
                                                   overflow
                                               }) => {
    // Generate padding classes based on padding prop
    const paddingClasses = Array.isArray(padding)
        ? padding.length === 4
            ? `${styles[`paddingTop_${padding[0]}`]} ${styles[`paddingRight_${padding[1]}`]} ${styles[`paddingBottom_${padding[2]}`]} ${styles[`paddingLeft_${padding[3]}`]}`
            : `${styles[`paddingTop_${padding[0]}`]} ${styles[`paddingBottom_${padding[0]}`]} ${styles[`paddingRight_${padding[1]}`]} ${styles[`paddingLeft_${padding[1]}`]}`
        : `${styles[`paddingTop_${padding}`]} ${styles[`paddingRight_${padding}`]} ${styles[`paddingBottom_${padding}`]} ${styles[`paddingLeft_${padding}`]}`;

    const classNames = [
        styles.mapSpace,
        styles[`direction_${direction}`],
        styles[`gap_${gap}`],
        styles[`justify_${justify}`],
        styles[`align_${align}`],
        stretch && styles.stretch,
        wrap && styles.wrap,
        paddingClasses,
        overflow && styles.overflow
    ]
        .filter(Boolean)
        .join(' ');

    return <div className={classNames}>{children}</div>;
};
