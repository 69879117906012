import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {Alert, Typography} from "@mui/material";
import React, {useContext} from "react";
import {
    DialogWindowContextHolderContext
} from "@src/components/context/DialogWindowContextHolder/DialogWindowContextHolder.context";
import {useHttpClient} from "@src/utils/hooks/xhr/useHttpClient";
import {XhrBase} from "@src/core/xhr";
import {useCheckInBox} from "@src/services/box/useDispatchBox";
import {useIsLoggedIn} from "@src/services/auth/useIsLoggedIn";
import {BoxRecycledHistory} from "@src/components/core/pages/BoxRecycledHistory/BoxRecycledHistory";
import {ApplicationMap} from "@src/core/applicationMap";
import {useDialogWindow} from "@src/utils/hooks/contextMenu/useDialogWindow";

export const useGetBoxInfo = () => {
    const {xhr} = useHttpClient();
    const {data} = useIsLoggedIn();
    const { snackbarApi} = useContext(DialogWindowContextHolderContext);
    const {openDialog} = useDialogWindow();

    return useMutation({
        mutationFn: async ({code}: {code: string}) => {
            try {
                await xhr.post({
                    relativeUrl: XhrBase.CHECK_IN_BOX({code}),
                    payload: XhrBase.CHECK_IN_BOX_body({locationId: data.location})
                });
            } catch (e: any) {
                if (e.status === 410) {

                    snackbarApi.close("box_check");

                    openDialog({
                        key: ApplicationMap.BOX_HISTORY,
                        children: (
                            <BoxRecycledHistory code={code}/>
                        )
                    })
                }
            }

            const result = await xhr.get({
                relativeUrl: XhrBase.BOX_GET_BY_CODE({code})
            });


            return result.data;
        }
    })
}

export const useGetBoxInfoResult = ({code, refetchOnMount = true}: {code: string; refetchOnMount?: boolean}) => {
    const {xhr} = useHttpClient();

    return useQuery({
        queryKey: ['FOUND_BOX', code],
        queryFn: async () => {
            const result = await xhr.get({
                relativeUrl: XhrBase.BOX_GET_BY_CODE({code: code!})
            });

            result.data.issuesObj = result.data?.issues?.reduce((acc: Record<string, boolean>, el: string) => {
                return { ...acc, [el]: true }
            }, {});

            return result.data
        },
        refetchOnMount,
        enabled: !!code
    })
}
