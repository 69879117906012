import {IconProps} from "@mui/material";

export const RepairBoxIIcon = (props: IconProps) => (
    <svg width="300" height="250" viewBox="0 0 300 250" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M161.092 80.9867H135.709V72.2395C135.709 69.9824 137.537 68.1548 139.794 68.1548H157.007C159.264 68.1548 161.092 69.9824 161.092 72.2395V80.9867Z"
            fill="black"/>
        <path
            d="M223.337 76.5974H73.4634C68.5548 76.5974 64.5756 80.5766 64.5756 85.4852V110.469C64.5756 115.378 68.5548 119.357 73.4634 119.357H223.337C228.246 119.357 232.225 115.378 232.225 110.469V85.4852C232.225 80.5766 228.246 76.5974 223.337 76.5974Z"
            fill="#86BD3D"/>
        <path
            d="M73.4635 79.4637H223.33C226.657 79.4637 229.359 82.166 229.359 85.4931V110.462C229.359 113.789 226.657 116.491 223.33 116.491H73.4635C70.1365 116.491 67.4342 113.789 67.4342 110.462V85.4853C67.4342 82.1582 70.1365 79.4559 73.4635 79.4559V79.4637Z"
            fill="#608929"/>
        <path
            d="M178.664 55.1824L186.31 146.388C186.326 146.552 186.326 146.708 186.31 146.864C186.24 148.738 184.772 150.308 182.858 150.472L179.805 150.73C177.883 150.894 176.189 149.582 175.806 147.747C175.767 147.59 175.743 147.442 175.728 147.278L168.09 56.0727L178.664 55.1902V55.1824Z"
            fill="#83522F"/>
        <mask id="mask0_9728_6354" style={{maskType:"luminance"}} maskUnits="userSpaceOnUse" x="168" y="55" width="19"
              height="96">
            <path
                d="M178.828 55.1667L186.474 146.372C186.49 146.536 186.49 146.692 186.474 146.848C186.404 148.723 184.936 150.293 183.022 150.457L179.968 150.714C178.047 150.878 176.352 149.566 175.97 147.731C175.931 147.575 175.907 147.426 175.892 147.262L168.253 56.0571L178.828 55.1746V55.1667Z"
                fill="white"/>
        </mask>
        <g mask="url(#mask0_9728_6354)">
            <path
                d="M178.234 140.382C177.5 144.256 176.68 148.122 176.258 152.042C176.188 152.675 177.125 152.94 177.25 152.276C177.984 148.379 178.305 144.435 178.719 140.499C178.75 140.187 178.289 140.054 178.227 140.382H178.234Z"
                fill="#5B2907"/>
            <path
                d="M175.095 117.998C173.463 110.93 172.252 103.073 169.41 96.3958C169.207 95.9272 168.386 95.9428 168.449 96.552C169.183 103.425 171.674 110.36 173.291 117.077C175.033 124.317 176.962 131.502 178.789 138.718C178.875 139.062 179.375 138.906 179.313 138.562C178.016 131.674 176.681 124.832 175.103 117.998H175.095Z"
                fill="#5B2907"/>
            <path
                d="M173.392 129.518C173.127 129.065 172.463 129.479 172.705 129.932C175.735 135.579 176.141 140.757 176.61 146.973C176.641 147.379 177.258 147.473 177.344 147.044C178.5 141.217 176.298 134.509 173.392 129.51V129.518Z"
                fill="#5B2907"/>
        </g>
        <path d="M180.757 49.0905L165.005 50.4104L166.449 59.1342L180.789 57.9315L180.757 49.0905Z" fill="black"/>
        <path
            d="M186.037 47.1146L180.968 49.075L163.122 50.5667L159.874 49.4733C158.116 48.7469 155.984 49.3327 154.406 50.3714L152.79 51.4258L147.206 51.8944C147.065 51.91 146.925 51.9022 146.792 51.8788C145.901 51.7694 145.183 51.0509 145.105 50.1137L144.308 40.5933C144.23 39.6639 144.824 38.8282 145.683 38.5783C145.808 38.5314 145.948 38.508 146.089 38.4924L151.673 38.0238L153.454 38.797C155.187 39.5546 157.468 39.7889 159.077 38.7892L162.013 37.1569H162.138L163.271 37.071L176.032 36.0557C177.782 35.9151 179.531 36.0401 181.234 36.4384C181.656 36.5243 182.062 36.6414 182.468 36.7586L184.584 37.4068L186.029 47.1146H186.037Z"
            fill="black"/>
        <path
            d="M207.726 52.4176C208.21 53.144 207.312 53.9796 206.617 53.4564C206.593 53.4407 206.57 53.4251 206.546 53.4095C204.32 51.7069 201.173 49.6997 197.901 48.3642C197.08 48.0362 196.268 47.7394 195.456 47.4973C194.652 47.2552 193.855 47.0522 193.097 46.9272C192.715 46.8491 192.348 46.7944 191.981 46.7554C191.621 46.7007 191.27 46.6773 190.926 46.6538C190.59 46.6226 190.262 46.6148 189.95 46.6148C189.638 46.6148 189.341 46.6148 189.052 46.6304C188.482 46.646 187.974 46.7241 187.537 46.7788C187.099 46.8491 186.724 46.9428 186.42 46.9975C185.826 47.1693 185.483 47.263 185.483 47.263L184.577 37.399C184.577 37.399 185.17 37.4458 186.217 37.5161C186.732 37.5864 187.357 37.6645 188.052 37.7973C188.747 37.9457 189.528 38.1097 190.348 38.3674C190.762 38.4768 191.184 38.6252 191.606 38.7814C192.035 38.9297 192.473 39.1016 192.91 39.2968C193.347 39.4843 193.792 39.6795 194.23 39.906C194.675 40.1247 195.12 40.3512 195.55 40.6089C196.432 41.1009 197.291 41.6633 198.127 42.2568C198.963 42.8504 199.767 43.4986 200.54 44.1625C203.617 46.8413 206.046 49.934 207.663 52.3317C207.679 52.3552 207.694 52.3786 207.71 52.4098L207.726 52.4176Z"
            fill="black"/>
        <path
            d="M151.657 38.0316L152.782 51.4335L147.198 51.9021C147.057 51.9178 146.917 51.9099 146.784 51.8865L145.667 38.5861C145.792 38.5392 145.933 38.5158 146.073 38.5002L151.657 38.0316Z"
            fill="#333333"/>
        <path
            d="M182.765 46.482L180.953 48.8328C180.82 48.989 180.632 49.0984 180.422 49.1218L164.036 50.4964C163.677 50.5276 163.349 50.3011 163.255 49.9575L162.646 47.7941C161.818 44.8341 161.576 41.7492 161.943 38.7033L162.045 37.8442C162.092 37.4693 162.412 37.1881 162.794 37.1881L163.263 37.0944L176.032 36.0557C177.782 35.9151 179.531 36.0401 181.234 36.4384C181.234 36.4384 184.405 44.3421 182.765 46.482Z"
            fill="#333333"/>
        <path
            d="M186.311 146.864C186.24 148.738 184.772 150.308 182.859 150.472L179.805 150.73C177.884 150.894 176.189 149.582 175.806 147.747L186.311 146.864Z"
            fill="#A0663F"/>
        <path
            d="M226.095 137.828L227.274 139.187L229.851 136.953C230.796 136.141 231.085 134.821 230.578 133.689L218.48 106.604C218.16 105.885 218.339 105.049 218.941 104.534L225.743 98.6373C226.298 98.1531 227.141 98.2156 227.618 98.7701L230.632 102.253L231.991 101.074L228.976 97.5986C227.844 96.2943 225.868 96.1538 224.564 97.2784L217.761 103.175C216.559 104.214 216.191 105.885 216.84 107.338L228.937 134.423C229.117 134.837 229.015 135.305 228.68 135.594L226.102 137.828H226.095Z"
            fill="#999999"/>
        <path
            d="M231.257 140.796L203.367 164.96C202.11 166.053 200.197 165.913 199.111 164.655L196.885 162.086C195.792 160.828 195.932 158.923 197.19 157.829L225.079 133.665L231.257 140.804V140.796ZM200.704 161.016C201.438 161.867 202.727 161.961 203.578 161.219C204.43 160.485 204.523 159.196 203.781 158.345C203.047 157.494 201.759 157.4 200.907 158.142C200.056 158.876 199.962 160.165 200.704 161.016Z"
            fill="#FCCB02"/>
        <path d="M236.645 93.7255L223.481 105.129L264.926 152.974L278.091 141.571L236.645 93.7255Z" fill="#FCCB02"/>
        <mask id="mask1_9728_6354" style={{maskType:"luminance"}} maskUnits="userSpaceOnUse" x="223" y="93" width="56"
              height="60">
            <path d="M236.645 93.7255L223.481 105.129L264.926 152.974L278.091 141.571L236.645 93.7255Z" fill="white"/>
        </mask>
        <g mask="url(#mask1_9728_6354)">
            <path
                d="M275.165 141.249C275.899 142.092 274.65 143.31 273.915 142.459C273.181 141.616 274.431 140.397 275.165 141.249Z"
                fill="#F9BA06"/>
            <path
                d="M272.54 139.327C272.829 139.179 273.173 139.218 273.407 139.452C273.618 139.671 273.689 140.062 273.501 140.319C273.4 140.46 273.306 140.577 273.15 140.647C272.853 140.78 272.525 140.764 272.291 140.522C272.072 140.296 272.025 139.929 272.197 139.663C272.291 139.515 272.392 139.406 272.548 139.327H272.54Z"
                fill="#F9BA06"/>
            <path
                d="M272.783 136.906C273.142 136.703 273.635 136.766 273.923 137.07C274.064 137.226 274.173 137.398 274.22 137.601C274.298 137.922 274.22 138.195 274.095 138.492C273.869 139.015 273.252 139.202 272.736 139.077C272.385 138.992 272.088 138.687 271.994 138.343C271.885 137.922 272.073 137.5 272.432 137.266C272.51 137.117 272.627 136.992 272.783 136.906Z"
                fill="#F9BA06"/>
            <path
                d="M269.862 140.468C270.19 140.608 270.416 140.772 270.518 141.139C270.682 141.733 270.205 142.326 269.596 142.319C269.081 142.319 268.651 141.881 268.659 141.374C268.659 141.124 268.768 140.874 268.948 140.702C269.167 140.491 269.565 140.351 269.862 140.476V140.468Z"
                fill="#F9BA06"/>
            <path
                d="M272.096 141.288C272.369 141.288 272.642 141.405 272.83 141.6C273.064 141.842 273.212 142.272 273.08 142.6C273.08 142.615 273.064 142.639 273.056 142.654C272.884 143.076 272.564 143.396 272.08 143.396C271.635 143.396 271.213 143.076 271.104 142.639C271.104 142.623 271.096 142.6 271.088 142.576C270.932 141.928 271.416 141.28 272.096 141.288Z"
                fill="#F9BA06"/>
            <path
                d="M273.009 142.592C273.353 142.522 273.775 142.584 274.009 142.873C274.251 143.17 274.345 143.428 274.274 143.803C274.274 143.826 274.267 143.849 274.259 143.865C274.188 144.224 273.853 144.482 273.525 144.584C273.119 144.709 272.626 144.615 272.338 144.302C272.009 144.138 271.9 143.701 272.111 143.404C272.22 142.998 272.595 142.67 273.009 142.592Z"
                fill="#F9BA06"/>
            <path
                d="M270.198 143.404C270.557 143.779 270.62 144.529 270.174 144.88C270.159 144.896 270.143 144.904 270.127 144.919C269.901 145.091 269.69 145.216 269.401 145.208C269.12 145.208 268.854 145.091 268.659 144.888C268.269 144.482 268.277 143.81 268.683 143.42C268.698 143.404 268.714 143.389 268.722 143.381C269.128 142.99 269.799 142.998 270.198 143.404Z"
                fill="#F9BA06"/>
            <path
                d="M267.503 141.186C267.808 141.108 268.144 141.202 268.362 141.428C268.761 141.842 268.651 142.529 268.159 142.818C268.128 142.834 268.089 142.858 268.058 142.881C267.722 143.076 267.277 143.006 267.011 142.725C266.769 142.475 266.652 141.975 266.894 141.678C266.917 141.647 266.941 141.616 266.964 141.592C267.113 141.405 267.253 141.256 267.495 141.194L267.503 141.186Z"
                fill="#F9BA06"/>
            <path
                d="M269.081 137.484C269.299 137.734 269.424 137.984 269.409 138.328C269.393 138.718 269.065 139.148 268.628 139.101C268.292 139.07 268.026 138.984 267.8 138.726C267.48 138.359 267.456 137.82 267.823 137.469C268.159 137.148 268.768 137.125 269.081 137.492V137.484Z"
                fill="#F9BA06"/>
            <path
                d="M271.432 136.078C271.432 136.078 271.463 136.102 271.479 136.117C271.854 136.438 271.791 137.078 271.455 137.398C271.018 137.82 270.354 137.679 270.042 137.187C270.034 137.172 270.018 137.156 270.01 137.141C269.792 136.805 269.893 136.32 270.167 136.055C270.541 135.696 271.065 135.758 271.432 136.078Z"
                fill="#F9BA06"/>
            <path
                d="M269.503 135.212C270.252 136.078 268.972 137.336 268.214 136.461C267.464 135.594 268.745 134.337 269.503 135.212Z"
                fill="#F9BA06"/>
            <path
                d="M263.747 138.773C264.106 138.82 264.418 138.992 264.512 139.366C264.567 139.57 264.528 139.765 264.434 139.929C264.395 140.046 264.34 140.155 264.239 140.257C264.028 140.46 263.715 140.538 263.426 140.46C262.888 140.311 262.669 139.694 262.934 139.218C263.083 138.953 263.426 138.726 263.747 138.765V138.773Z"
                fill="#F9BA06"/>
            <path
                d="M263.231 140.491C263.38 140.491 263.52 140.514 263.661 140.538C263.919 140.577 264.114 140.811 264.247 141.014C264.403 141.256 264.372 141.592 264.231 141.827C264.005 142.225 263.637 142.319 263.216 142.35C262.708 142.381 262.286 141.897 262.286 141.413C262.286 140.913 262.716 140.483 263.224 140.483L263.231 140.491Z"
                fill="#F9BA06"/>
            <path
                d="M265.847 143.6C265.847 143.6 265.91 143.553 265.941 143.529C266.292 143.287 266.831 143.412 267.105 143.701C267.487 144.092 267.44 144.677 267.081 145.068C267.05 145.099 267.027 145.122 267.003 145.154C266.644 145.544 265.972 145.497 265.621 145.13C265.449 144.951 265.347 144.701 265.347 144.443C265.347 144.068 265.55 143.803 265.847 143.592V143.6Z"
                fill="#F9BA06"/>
            <path
                d="M266.16 144.498C266.644 144.326 267.183 144.701 267.3 145.169C267.339 145.31 267.331 145.45 267.3 145.583C267.3 145.638 267.292 145.693 267.277 145.747C267.285 145.677 267.292 145.622 267.3 145.583C267.3 145.622 267.285 145.677 267.277 145.755C267.152 146.192 266.597 146.575 266.136 146.38C265.957 146.302 265.816 146.263 265.683 146.106C265.566 145.974 265.48 145.841 265.441 145.661C265.316 145.107 265.637 144.662 266.152 144.49L266.16 144.498Z"
                fill="#F9BA06"/>
            <path
                d="M269.058 145.974C269.292 145.81 269.479 145.677 269.753 145.575C269.901 145.521 270.003 145.474 270.174 145.474C270.487 145.474 270.807 145.653 270.963 145.927C271.408 146.723 270.557 147.278 269.963 147.59C269.518 147.825 268.956 147.684 268.706 147.239C268.589 147.028 268.558 146.77 268.62 146.536C268.69 146.278 268.847 146.13 269.058 145.981V145.974Z"
                fill="#F9BA06"/>
            <path
                d="M272.431 145.286L272.65 145.474C273.017 145.778 272.947 146.419 272.626 146.723C272.205 147.13 271.541 147.005 271.244 146.52C271.197 146.442 271.143 146.364 271.096 146.278C270.9 145.958 270.979 145.521 271.244 145.263C271.596 144.919 272.08 144.99 272.423 145.286H272.431Z"
                fill="#F9BA06"/>
            <path
                d="M268.956 148.239C269.245 148.418 269.44 148.824 269.323 149.16C269.276 149.293 269.229 149.418 269.206 149.558C269.175 149.785 268.964 150.004 268.776 150.113C268.488 150.269 268.136 150.269 267.855 150.097C267.386 149.816 267.222 149.027 267.691 148.653C267.8 148.567 267.886 148.465 267.972 148.356C268.199 148.075 268.675 148.075 268.956 148.246V148.239Z"
                fill="#F9BA06"/>
            <path
                d="M265.699 150.558C265.918 150.402 266.144 150.285 266.426 150.308C266.925 150.355 267.41 150.722 267.378 151.277C267.371 151.48 267.339 151.659 267.23 151.839C267.152 151.972 267.027 152.089 266.918 152.198C266.527 152.604 265.84 152.565 265.457 152.175C265.27 151.98 265.168 151.722 265.168 151.456C265.168 151.058 265.387 150.785 265.691 150.558H265.699Z"
                fill="#F9BA06"/>
            <path
                d="M265.332 148.465C265.824 148.606 266.113 149.035 266.136 149.535C266.152 149.793 266.152 150.043 266.066 150.293C265.886 150.793 265.48 151.16 264.926 151.152C264.613 151.152 264.301 151.019 264.082 150.793C263.895 150.597 263.715 150.254 263.754 149.965C263.793 149.683 263.801 149.48 263.942 149.231L264.098 148.965C264.34 148.543 264.879 148.34 265.34 148.473L265.332 148.465Z"
                fill="#F9BA06"/>
            <path
                d="M266.058 146.7C266.293 146.841 266.496 146.989 266.621 147.239C266.917 147.84 266.527 148.496 265.863 148.528C265.574 148.543 265.34 148.434 265.098 148.293C264.66 148.036 264.52 147.45 264.785 147.012C265.051 146.575 265.629 146.442 266.066 146.692L266.058 146.7Z"
                fill="#F9BA06"/>
            <path
                d="M265.269 145.247C265.691 145.497 265.808 146.044 265.566 146.458C265.457 146.653 265.34 146.848 265.207 147.028C265.058 147.223 264.918 147.364 264.676 147.426C264.449 147.489 264.199 147.45 263.996 147.325C263.567 147.067 263.473 146.536 263.7 146.114C263.801 145.911 263.934 145.732 264.067 145.544C264.34 145.154 264.848 144.982 265.277 145.247H265.269Z"
                fill="#F9BA06"/>
            <path
                d="M261.435 145.271C262.13 145.091 262.723 145.622 262.762 146.302C262.778 146.497 262.794 146.692 262.762 146.895C262.762 147.075 262.715 147.239 262.622 147.387C262.497 147.598 262.278 147.762 262.036 147.825C261.63 147.934 261.146 147.747 260.95 147.364C260.818 147.106 260.747 146.841 260.669 146.567C260.513 146.013 260.872 145.419 261.427 145.279L261.435 145.271Z"
                fill="#F9BA06"/>
            <path
                d="M260.716 146.973C260.849 147.028 260.99 147.067 261.122 147.13C261.224 147.176 261.325 147.278 261.411 147.348C261.427 147.364 261.443 147.379 261.458 147.395C261.763 147.294 262.099 147.34 262.356 147.606C262.684 147.942 262.684 148.52 262.333 148.848C262.192 148.973 262.06 149.098 261.919 149.23C261.591 149.527 260.958 149.605 260.654 149.207C260.443 148.934 260.216 148.66 260.107 148.332C260.068 148.215 260.029 148.098 259.998 147.981C259.982 147.887 259.974 147.801 259.966 147.707C260.021 147.52 260.021 147.465 259.966 147.551C259.943 147.2 260.373 146.841 260.716 146.989V146.973Z"
                fill="#F9BA06"/>
            <path
                d="M259.756 144.857C260.138 144.763 260.545 144.872 260.818 145.154C261.107 145.458 261.146 145.818 261.084 146.208C261.021 146.614 260.716 146.887 260.451 147.169C260.021 147.629 259.233 147.575 258.811 147.137C258.35 146.653 258.42 145.974 258.842 145.497C258.92 145.411 258.998 145.318 259.076 145.232C259.256 145.029 259.498 144.919 259.756 144.849V144.857Z"
                fill="#F9BA06"/>
            <path
                d="M260.662 143.42C260.896 143.365 261.107 143.334 261.341 143.404C261.911 143.576 262.114 144.31 261.677 144.732C261.505 144.896 261.302 144.974 261.076 145.029C260.638 145.138 260.178 144.88 260.068 144.435C259.951 144.006 260.224 143.529 260.662 143.42Z"
                fill="#F9BA06"/>
            <path
                d="M263.067 142.17C263.419 142.084 263.731 142.241 263.918 142.529C264.137 142.865 264.075 143.334 263.84 143.639C263.614 143.935 263.294 144.115 262.911 144.006C262.583 143.912 262.278 143.568 262.31 143.209C262.333 142.982 262.356 142.787 262.481 142.592C262.614 142.381 262.817 142.225 263.059 142.17H263.067Z"
                fill="#F9BA06"/>
            <path
                d="M264.856 139.218C265.051 139.093 265.223 139.007 265.465 139.007C265.66 139.007 265.84 139.085 265.98 139.226C266.175 139.421 266.191 139.655 266.152 139.913C266.121 140.148 265.941 140.312 265.801 140.483C265.519 140.811 264.949 140.749 264.676 140.468C264.309 140.085 264.426 139.491 264.863 139.218H264.856Z"
                fill="#F9BA06"/>
            <path
                d="M265.426 137.078C265.426 137.078 265.465 137.055 265.488 137.039C265.856 136.82 266.348 136.906 266.637 137.211C266.808 137.391 266.902 137.633 266.902 137.875C266.902 138.156 266.777 138.343 266.613 138.554C266.597 138.57 266.582 138.593 266.566 138.609C266.238 139.015 265.535 138.929 265.199 138.585C264.762 138.132 264.887 137.391 265.418 137.078H265.426Z"
                fill="#F9BA06"/>
            <path
                d="M268.089 139.031C268.222 138.999 268.347 138.976 268.479 138.976C268.854 138.992 269.284 139.32 269.237 139.741C269.221 139.874 269.206 139.991 269.159 140.116C269.042 140.413 268.854 140.64 268.534 140.725C268.23 140.804 267.902 140.71 267.683 140.483C267.238 140.023 267.441 139.171 268.089 139.023V139.031Z"
                fill="#F9BA06"/>
            <path
                d="M274.79 138.265C275.298 138.265 275.719 138.695 275.719 139.202C275.719 139.429 275.657 139.601 275.555 139.804C275.368 140.163 274.891 140.319 274.517 140.218C274.087 140.093 273.884 139.741 273.845 139.32C273.829 139.187 273.845 139.062 273.9 138.945C274.048 138.554 274.36 138.265 274.798 138.265H274.79Z"
                fill="#F9BA06"/>
            <path
                d="M277.196 137.508C277.258 137.523 277.321 137.531 277.375 137.547C277.735 137.625 277.985 138 277.985 138.351C277.985 138.828 277.602 139.148 277.149 139.179L276.961 139.195C276.493 139.226 276.11 138.773 276.11 138.336C276.11 137.75 276.657 137.398 277.196 137.515V137.508Z"
                fill="#F9BA06"/>
            <path
                d="M277.539 138.703C277.898 138.703 278.18 139.062 278.078 139.413C278.062 139.476 278.047 139.53 278.023 139.593C277.961 139.819 277.75 139.976 277.524 139.976C277.313 139.976 277.055 139.812 277.024 139.585C277.016 139.523 277.008 139.468 277 139.405C276.953 139.054 277.149 138.703 277.547 138.703H277.539Z"
                fill="#F9BA06"/>
            <path
                d="M266.269 133.415C266.308 133.431 266.347 133.446 266.386 133.454C266.73 133.571 266.988 133.86 266.98 134.243C266.98 134.595 266.722 134.962 266.371 135.032C266.332 135.032 266.293 135.048 266.254 135.055C265.723 135.157 265.184 134.805 265.191 134.227C265.191 133.704 265.746 133.228 266.269 133.415Z"
                fill="#F9BA06"/>
            <path
                d="M269.269 133.267C269.534 133.407 269.815 133.525 269.948 133.813C270.143 134.266 269.979 134.766 269.479 134.915C269.159 135.008 268.901 134.891 268.612 134.774C268.191 134.602 267.902 134.227 268.035 133.751C268.183 133.212 268.792 133.017 269.261 133.267H269.269Z"
                fill="#F9BA06"/>
            <path
                d="M268.253 131.83C268.652 131.97 268.878 132.322 268.784 132.751C268.706 133.095 268.363 133.345 268.019 133.345C267.519 133.345 267.175 132.814 267.363 132.369C267.363 132.353 267.363 132.345 267.363 132.33C267.464 131.97 267.878 131.705 268.245 131.838L268.253 131.83Z"
                fill="#F9BA06"/>
            <path
                d="M265.332 131.533C265.723 131.431 266.215 131.674 266.285 132.095C266.34 132.392 266.394 132.689 266.441 132.993C266.519 133.415 266.316 133.837 265.879 133.954C265.488 134.056 264.988 133.813 264.918 133.392C264.871 133.095 264.824 132.798 264.77 132.494C264.699 132.072 264.887 131.658 265.332 131.541V131.533Z"
                fill="#F9BA06"/>
            <path
                d="M264.606 135.899C264.848 136.149 264.988 136.43 264.926 136.781C264.871 137.086 264.504 137.266 264.223 137.195C264.09 137.164 263.996 137.031 263.996 136.899C263.996 136.852 264.004 136.805 264.028 136.766C263.739 136.625 263.567 136.305 263.754 135.993C263.934 135.696 264.364 135.649 264.606 135.899Z"
                fill="#F9BA06"/>
            <path
                d="M262.161 136.805C262.224 136.883 262.279 136.953 262.341 137.031C262.661 137.437 262.716 137.992 262.318 138.375C261.951 138.734 261.326 138.726 260.974 138.351C260.904 138.281 260.842 138.211 260.779 138.14C260.42 137.758 260.42 137.141 260.802 136.774C261.154 136.438 261.841 136.383 262.169 136.797L262.161 136.805Z"
                fill="#F9BA06"/>
            <path
                d="M262.177 139.78C262.224 139.905 262.263 140.03 262.317 140.155C262.442 140.444 262.169 140.788 261.857 140.6C261.708 140.514 261.56 140.429 261.404 140.35C261.099 140.194 261.083 139.749 261.31 139.53C261.607 139.241 262.052 139.413 262.177 139.773V139.78Z"
                fill="#F9BA06"/>
            <path
                d="M261.693 141.335C262.122 141.225 262.536 141.491 262.653 141.897C262.809 142.412 262.466 142.873 261.997 143.037C261.521 143.201 260.873 142.811 260.912 142.264C260.927 142.037 261.021 141.858 261.185 141.741C261.294 141.545 261.49 141.381 261.693 141.327V141.335Z"
                fill="#F9BA06"/>
            <path
                d="M259.029 142.35C259.107 142.334 259.201 142.311 259.279 142.311C259.357 142.311 259.435 142.326 259.506 142.35C259.568 142.35 259.631 142.35 259.709 142.373C259.81 142.405 259.912 142.467 260.013 142.506C260.372 142.678 260.443 143.186 260.255 143.498C260.068 143.803 259.576 143.99 259.263 143.74C259.099 143.615 258.974 143.506 258.889 143.342C258.795 143.225 258.725 143.107 258.678 142.943C258.607 142.701 258.771 142.389 259.029 142.342V142.35Z"
                fill="#F9BA06"/>
            <path
                d="M258.272 139.468C258.615 139.858 258.639 140.444 258.248 140.819C257.889 141.163 257.233 141.194 256.897 140.796C256.553 140.405 256.53 139.819 256.921 139.445C257.28 139.101 257.936 139.077 258.272 139.468Z"
                fill="#F9BA06"/>
            <path
                d="M258.834 137.804C258.936 137.922 259.045 138.031 259.107 138.171C259.225 138.445 259.225 138.742 258.99 138.96C258.779 139.163 258.451 139.202 258.201 139.054C258.061 138.968 257.967 138.867 257.866 138.749C257.624 138.468 257.608 138.062 257.881 137.797C258.131 137.554 258.592 137.539 258.834 137.812V137.804Z"
                fill="#F9BA06"/>
            <path
                d="M258.623 136.063C258.764 135.75 259.053 135.524 259.443 135.524C259.686 135.524 259.928 135.625 260.092 135.805C260.334 136.063 260.365 136.352 260.31 136.688C260.256 137.055 259.935 137.437 259.592 137.578C259.303 137.695 258.967 137.765 258.686 137.562C258.631 137.523 258.662 137.531 258.78 137.586C258.701 137.555 258.631 137.523 258.553 137.492C258.389 137.422 258.233 137.234 258.147 137.078C258.03 136.867 258.03 136.602 258.155 136.399C258.256 136.235 258.413 136.133 258.584 136.094C258.592 136.086 258.608 136.078 258.616 136.071L258.623 136.063Z"
                fill="#F9BA06"/>
            <path
                d="M257.178 136.461C257.647 136.75 257.881 137.461 257.546 137.937L257.296 138.289C257.038 138.648 256.585 138.906 256.132 138.749C255.921 138.679 255.827 138.617 255.671 138.476C255.304 138.148 255.343 137.625 255.531 137.219C255.593 137.086 255.655 136.961 255.71 136.828C255.96 136.297 256.718 136.188 257.186 136.461H257.178Z"
                fill="#F9BA06"/>
            <path
                d="M255.999 139.249C256.046 139.359 256.093 139.468 256.14 139.577C256.273 139.89 255.968 140.265 255.64 140.062C255.546 139.999 255.445 139.944 255.351 139.882C255.093 139.726 255.046 139.359 255.265 139.148C255.46 138.953 255.874 138.976 255.991 139.257L255.999 139.249Z"
                fill="#F9BA06"/>
            <path
                d="M257.405 142.123C258.155 142.99 256.874 144.248 256.117 143.373C255.367 142.506 256.648 141.249 257.405 142.123Z"
                fill="#F9BA06"/>
            <path
                d="M258.17 142.615C258.428 142.545 258.717 142.709 258.78 142.975C258.858 143.287 258.733 143.678 258.42 143.818C258.147 143.943 257.819 143.927 257.608 143.701C257.397 143.474 257.546 143.17 257.788 143.107C257.788 142.889 257.967 142.67 258.17 142.623V142.615Z"
                fill="#F9BA06"/>
            <path
                d="M255.39 141.592C255.491 141.803 255.507 142.014 255.522 142.24C255.554 142.615 255.179 142.936 254.827 142.928C254.46 142.928 254.117 142.608 254.14 142.233C254.14 142.17 254.14 142.1 254.148 142.037C254.156 141.866 254.203 141.733 254.273 141.577C254.476 141.131 255.187 141.194 255.397 141.592H255.39Z"
                fill="#F9BA06"/>
            <path
                d="M252.743 138.382C252.743 138.382 252.821 138.328 252.86 138.304C253.18 138.07 253.688 138.195 253.945 138.46C254.297 138.82 254.258 139.374 253.922 139.726C253.891 139.757 253.859 139.796 253.82 139.827C253.477 140.186 252.867 140.155 252.532 139.804C252.368 139.632 252.274 139.405 252.274 139.171C252.274 138.82 252.469 138.578 252.743 138.382Z"
                fill="#F9BA06"/>
            <path
                d="M254.695 135.196C254.968 135.383 255.078 135.586 255.109 135.914C255.148 136.399 254.625 136.688 254.211 136.594C253.977 136.539 253.797 136.391 253.688 136.18C253.547 135.883 253.57 135.54 253.813 135.297C254.039 135.079 254.43 135.016 254.695 135.196Z"
                fill="#F9BA06"/>
            <path
                d="M259.17 138.664C259.365 138.562 259.607 138.507 259.818 138.578C259.92 138.609 259.771 138.5 260.06 138.679C260.123 138.726 260.177 138.773 260.24 138.82C260.263 138.851 260.287 138.882 260.302 138.906C260.513 139.116 260.615 139.413 260.521 139.741C260.404 140.171 259.881 140.53 259.435 140.35C259.185 140.249 258.951 140.124 258.834 139.866C258.631 139.437 258.725 138.898 259.17 138.664Z"
                fill="#F9BA06"/>
            <path
                d="M259.475 139.499C259.537 139.406 259.623 139.335 259.748 139.304C260.013 139.234 260.302 139.398 260.365 139.663C260.404 139.819 260.404 139.96 260.373 140.108C260.373 140.148 260.357 140.28 260.373 140.124C260.373 140.202 260.357 140.288 260.326 140.358C260.302 140.413 260.279 140.46 260.256 140.515C260.224 140.577 260.232 140.561 260.271 140.483C260.248 140.616 260.154 140.765 260.06 140.858C259.928 140.983 259.732 141.077 259.553 141.116C259.037 141.217 258.522 140.866 258.522 140.319C258.522 139.843 258.99 139.421 259.467 139.507L259.475 139.499Z"
                fill="#F9BA06"/>
            <path
                d="M262.638 132.837L262.716 132.931C263.021 133.29 263.044 133.814 262.693 134.149C262.365 134.462 261.779 134.485 261.474 134.126L261.396 134.032C261.092 133.681 261.068 133.15 261.42 132.814C261.748 132.502 262.333 132.478 262.638 132.837Z"
                fill="#F9BA06"/>
            <path
                d="M259.732 133.173C260.115 133.4 260.248 133.923 260.014 134.306C259.826 134.602 259.662 134.977 259.334 135.141C258.819 135.399 258.131 135.071 258.147 134.446C258.155 134.056 258.397 133.767 258.6 133.454C258.842 133.079 259.334 132.939 259.732 133.173Z"
                fill="#F9BA06"/>
            <path
                d="M260.974 133.673C261.107 133.712 261.24 133.751 261.372 133.79C261.536 133.837 261.661 133.939 261.818 134.001C262.013 134.079 262.161 134.235 262.161 134.462C262.161 134.712 261.942 134.946 261.685 134.93C261.529 134.93 261.419 134.923 261.279 134.93C261.122 134.946 260.958 134.899 260.802 134.884C260.474 134.837 260.185 134.634 260.193 134.267C260.193 133.876 260.591 133.564 260.974 133.681V133.673Z"
                fill="#F9BA06"/>
            <path
                d="M263.317 133.649C263.653 133.462 264.098 133.579 264.293 133.923C264.496 134.282 264.34 134.68 264.02 134.899C263.676 135.133 263.044 135.227 262.794 134.79C262.536 134.337 262.95 133.853 263.317 133.649Z"
                fill="#F9BA06"/>
            <path
                d="M260.841 130.69C261.029 130.549 261.201 130.447 261.451 130.447C261.685 130.447 261.911 130.541 262.068 130.713C262.396 131.057 262.38 131.603 262.044 131.939C262.029 131.955 262.013 131.97 262.005 131.978C261.669 132.314 261.099 132.298 260.771 131.955C260.474 131.642 260.412 131.01 260.795 130.721C260.81 130.705 260.826 130.697 260.841 130.682V130.69Z"
                fill="#F9BA06"/>
            <path
                d="M262.645 128.721C262.888 128.784 263.098 128.878 263.317 128.995C263.981 129.362 263.669 130.432 262.919 130.416C262.669 130.416 262.435 130.369 262.2 130.307C261.771 130.19 261.513 129.721 261.63 129.292C261.755 128.862 262.216 128.604 262.645 128.721Z"
                fill="#F9BA06"/>
            <path
                d="M265.543 128.987C265.863 128.807 266.363 128.901 266.527 129.26C266.621 129.463 266.73 129.674 266.785 129.893C266.855 130.205 266.73 130.533 266.449 130.69C266.09 130.893 265.699 130.736 265.48 130.416C265.387 130.283 265.332 130.119 265.27 129.963C265.113 129.604 265.176 129.19 265.543 128.979V128.987Z"
                fill="#F9BA06"/>
            <path
                d="M267.004 130.978C267.175 131.08 267.238 131.213 267.308 131.392C267.496 131.861 266.918 132.392 266.465 132.22C266.246 132.134 266.051 132.009 265.996 131.767C265.996 131.759 265.996 131.752 265.996 131.744C265.863 131.166 266.48 130.674 267.004 130.994V130.978Z"
                fill="#F9BA06"/>
            <path
                d="M263.958 129.182C264.27 129.01 264.629 129.073 264.903 129.276C265.145 129.456 265.285 129.705 265.45 129.955C265.707 130.354 265.512 130.916 265.114 131.143C264.669 131.392 264.2 131.197 263.927 130.807C263.802 130.635 263.653 130.455 263.599 130.244C263.489 129.846 263.559 129.401 263.95 129.182H263.958Z"
                fill="#F9BA06"/>
            <path
                d="M265.871 126.464C266.566 127.269 265.379 128.425 264.676 127.62C263.981 126.816 265.168 125.66 265.871 126.464Z"
                fill="#F9BA06"/>
            <path
                d="M259.811 124.246C260.545 125.09 259.295 126.308 258.561 125.457C257.827 124.613 259.076 123.395 259.811 124.246Z"
                fill="#F9BA06"/>
            <path
                d="M257.186 122.325C257.475 122.177 257.819 122.216 258.053 122.45C258.264 122.669 258.335 123.059 258.155 123.317C258.053 123.457 257.96 123.575 257.803 123.645C257.507 123.778 257.179 123.762 256.944 123.52C256.726 123.293 256.679 122.926 256.851 122.661C256.944 122.512 257.046 122.411 257.202 122.325H257.186Z"
                fill="#F9BA06"/>
            <path
                d="M257.429 119.904C257.788 119.701 258.28 119.763 258.569 120.068C258.709 120.224 258.819 120.396 258.866 120.599C258.944 120.927 258.866 121.193 258.741 121.489C258.514 122.013 257.897 122.2 257.382 122.075C257.03 121.989 256.733 121.685 256.64 121.341C256.53 120.919 256.718 120.497 257.077 120.263C257.155 120.115 257.272 119.99 257.429 119.904Z"
                fill="#F9BA06"/>
            <path
                d="M254.5 123.465C254.828 123.606 255.062 123.778 255.156 124.137C255.32 124.723 254.843 125.324 254.234 125.316C253.719 125.316 253.289 124.879 253.297 124.371C253.297 124.121 253.406 123.871 253.586 123.7C253.805 123.489 254.203 123.348 254.5 123.473V123.465Z"
                fill="#F9BA06"/>
            <path
                d="M256.733 124.285C257.007 124.285 257.28 124.402 257.468 124.598C257.702 124.84 257.85 125.269 257.718 125.59C257.718 125.605 257.702 125.629 257.694 125.644C257.522 126.066 257.202 126.386 256.718 126.386C256.273 126.386 255.851 126.066 255.742 125.629C255.742 125.613 255.734 125.59 255.726 125.574C255.57 124.926 256.054 124.277 256.733 124.277V124.285Z"
                fill="#F9BA06"/>
            <path
                d="M257.655 125.59C257.999 125.519 258.421 125.582 258.655 125.871C258.897 126.168 258.991 126.418 258.92 126.8C258.92 126.824 258.913 126.847 258.905 126.871C258.834 127.23 258.499 127.488 258.171 127.589C257.772 127.714 257.272 127.612 256.984 127.308C256.655 127.144 256.546 126.707 256.757 126.41C256.866 126.004 257.241 125.676 257.655 125.597V125.59Z"
                fill="#F9BA06"/>
            <path
                d="M254.843 126.402C255.203 126.777 255.265 127.527 254.82 127.878C254.804 127.894 254.789 127.901 254.773 127.917C254.546 128.089 254.336 128.214 254.047 128.206C253.765 128.206 253.5 128.089 253.305 127.886C252.914 127.48 252.922 126.808 253.328 126.418C253.344 126.402 253.359 126.394 253.375 126.379C253.781 125.98 254.453 125.996 254.851 126.402H254.843Z"
                fill="#F9BA06"/>
            <path
                d="M252.149 124.192C252.454 124.113 252.789 124.207 253.008 124.434C253.406 124.848 253.297 125.535 252.805 125.824C252.774 125.847 252.735 125.863 252.703 125.886C252.368 126.082 251.922 126.011 251.657 125.73C251.415 125.48 251.305 124.98 251.54 124.684C251.563 124.652 251.587 124.621 251.61 124.59C251.758 124.402 251.899 124.254 252.149 124.192Z"
                fill="#F9BA06"/>
            <path
                d="M253.719 120.482C253.938 120.732 254.063 120.982 254.047 121.325C254.031 121.716 253.696 122.145 253.266 122.099C252.93 122.067 252.665 121.981 252.438 121.716C252.118 121.349 252.095 120.81 252.462 120.458C252.797 120.138 253.399 120.115 253.719 120.482Z"
                fill="#F9BA06"/>
            <path
                d="M256.077 119.076C256.077 119.076 256.109 119.1 256.124 119.115C256.499 119.435 256.437 120.076 256.101 120.396C255.663 120.818 255 120.677 254.687 120.185C254.679 120.17 254.664 120.154 254.656 120.138C254.437 119.802 254.539 119.326 254.812 119.053C255.187 118.693 255.71 118.756 256.077 119.076Z"
                fill="#F9BA06"/>
            <path
                d="M254.14 118.217C254.89 119.084 253.609 120.341 252.852 119.467C252.102 118.6 253.383 117.342 254.14 118.217Z"
                fill="#F9BA06"/>
            <path
                d="M248.392 121.771C248.751 121.817 249.056 121.989 249.158 122.364C249.212 122.567 249.173 122.762 249.079 122.926C249.04 123.044 248.986 123.153 248.876 123.254C248.665 123.457 248.353 123.536 248.072 123.457C247.533 123.309 247.314 122.692 247.58 122.216C247.728 121.95 248.072 121.724 248.392 121.763V121.771Z"
                fill="#F9BA06"/>
            <path
                d="M247.877 123.489C248.026 123.489 248.166 123.512 248.307 123.536C248.564 123.575 248.76 123.809 248.892 124.02C249.049 124.262 249.017 124.59 248.877 124.832C248.65 125.23 248.283 125.324 247.862 125.348C247.354 125.379 246.932 124.895 246.932 124.41C246.932 123.903 247.362 123.473 247.869 123.481L247.877 123.489Z"
                fill="#F9BA06"/>
            <path
                d="M250.485 126.597C250.485 126.597 250.548 126.55 250.579 126.527C250.931 126.285 251.469 126.41 251.743 126.699C252.126 127.089 252.079 127.675 251.719 128.065C251.696 128.097 251.665 128.12 251.641 128.151C251.282 128.542 250.61 128.495 250.259 128.128C250.079 127.948 249.986 127.698 249.986 127.441C249.986 127.066 250.189 126.792 250.485 126.589V126.597Z"
                fill="#F9BA06"/>
            <path
                d="M250.798 127.495C251.282 127.324 251.821 127.698 251.938 128.167C251.977 128.308 251.969 128.448 251.938 128.581C251.938 128.636 251.93 128.69 251.915 128.745C251.922 128.675 251.93 128.62 251.938 128.581C251.938 128.62 251.922 128.682 251.915 128.753C251.79 129.19 251.235 129.573 250.782 129.378C250.603 129.299 250.462 129.26 250.329 129.104C250.212 128.971 250.126 128.839 250.087 128.659C249.962 128.105 250.282 127.667 250.798 127.488V127.495Z"
                fill="#F9BA06"/>
            <path
                d="M253.703 128.971C253.93 128.807 254.125 128.675 254.398 128.573C254.547 128.518 254.648 128.464 254.82 128.472C255.132 128.472 255.452 128.651 255.609 128.925C256.054 129.721 255.203 130.276 254.609 130.588C254.164 130.822 253.602 130.682 253.352 130.237C253.234 130.026 253.203 129.768 253.266 129.534C253.336 129.276 253.492 129.128 253.703 128.979V128.971Z"
                fill="#F9BA06"/>
            <path
                d="M257.077 128.284C257.148 128.347 257.226 128.409 257.304 128.472C257.671 128.776 257.6 129.417 257.28 129.721C256.859 130.127 256.195 130.002 255.898 129.518C255.851 129.44 255.804 129.354 255.75 129.276C255.554 128.956 255.632 128.518 255.898 128.268C256.249 127.925 256.726 127.995 257.077 128.292V128.284Z"
                fill="#F9BA06"/>
            <path
                d="M253.601 131.236C253.89 131.416 254.086 131.822 253.968 132.158C253.922 132.291 253.875 132.416 253.851 132.556C253.82 132.783 253.609 133.001 253.422 133.111C253.133 133.267 252.781 133.267 252.5 133.095C252.032 132.814 251.868 132.025 252.336 131.65C252.445 131.564 252.531 131.463 252.617 131.353C252.844 131.072 253.32 131.072 253.609 131.244L253.601 131.236Z"
                fill="#F9BA06"/>
            <path
                d="M250.345 133.556C250.563 133.4 250.79 133.283 251.071 133.306C251.571 133.353 252.055 133.712 252.024 134.274C252.016 134.485 251.985 134.657 251.876 134.837C251.797 134.969 251.672 135.087 251.563 135.196C251.173 135.602 250.485 135.563 250.103 135.173C249.915 134.977 249.814 134.72 249.814 134.454C249.814 134.056 250.032 133.782 250.337 133.556H250.345Z"
                fill="#F9BA06"/>
            <path
                d="M249.97 131.463C250.462 131.603 250.751 132.033 250.774 132.525C250.782 132.783 250.79 133.025 250.704 133.282C250.524 133.782 250.118 134.149 249.564 134.142C249.251 134.142 248.939 134.009 248.72 133.782C248.533 133.587 248.353 133.243 248.392 132.954C248.431 132.673 248.439 132.47 248.58 132.22L248.736 131.955C248.978 131.533 249.517 131.33 249.978 131.463H249.97Z"
                fill="#F9BA06"/>
            <path
                d="M250.704 129.698C250.939 129.83 251.142 129.987 251.267 130.237C251.563 130.838 251.173 131.494 250.509 131.525C250.22 131.541 249.986 131.431 249.744 131.291C249.306 131.033 249.166 130.447 249.431 130.01C249.697 129.573 250.275 129.44 250.712 129.698H250.704Z"
                fill="#F9BA06"/>
            <path
                d="M249.908 128.245C250.321 128.495 250.446 129.042 250.204 129.456C250.095 129.651 249.978 129.846 249.845 130.026C249.697 130.213 249.556 130.362 249.314 130.424C249.087 130.487 248.838 130.448 248.635 130.323C248.205 130.065 248.111 129.534 248.338 129.112C248.439 128.909 248.572 128.729 248.697 128.542C248.978 128.151 249.478 127.98 249.908 128.245Z"
                fill="#F9BA06"/>
            <path
                d="M246.073 128.268C246.768 128.089 247.361 128.62 247.401 129.299C247.408 129.495 247.432 129.69 247.401 129.893C247.401 130.073 247.354 130.237 247.26 130.385C247.127 130.596 246.916 130.76 246.674 130.822C246.268 130.932 245.784 130.744 245.589 130.361C245.456 130.104 245.386 129.838 245.307 129.565C245.151 129.01 245.518 128.417 246.065 128.276L246.073 128.268Z"
                fill="#F9BA06"/>
            <path
                d="M245.354 129.971C245.487 130.026 245.619 130.065 245.76 130.127C245.862 130.174 245.963 130.276 246.049 130.346C246.065 130.362 246.08 130.377 246.096 130.393C246.4 130.291 246.736 130.338 246.994 130.604C247.322 130.94 247.322 131.518 246.971 131.846C246.83 131.97 246.697 132.103 246.557 132.228C246.229 132.525 245.596 132.603 245.291 132.205C245.081 131.931 244.854 131.658 244.745 131.33C244.706 131.213 244.667 131.096 244.635 130.979C244.62 130.885 244.612 130.799 244.604 130.705C244.659 130.518 244.659 130.463 244.604 130.549C244.581 130.198 245.01 129.838 245.346 129.987L245.354 129.971Z"
                fill="#F9BA06"/>
            <path
                d="M244.394 127.854C244.776 127.761 245.182 127.87 245.456 128.151C245.745 128.456 245.784 128.815 245.721 129.206C245.659 129.612 245.354 129.885 245.089 130.166C244.659 130.627 243.87 130.572 243.448 130.135C242.988 129.651 243.058 128.971 243.48 128.495C243.558 128.409 243.636 128.315 243.714 128.229C243.894 128.026 244.136 127.917 244.394 127.847V127.854Z"
                fill="#F9BA06"/>
            <path
                d="M245.3 126.417C245.534 126.363 245.745 126.332 245.979 126.394C246.55 126.566 246.753 127.3 246.315 127.722C246.143 127.886 245.94 127.964 245.714 128.019C245.277 128.128 244.816 127.87 244.706 127.425C244.589 126.995 244.863 126.519 245.3 126.41V126.417Z"
                fill="#F9BA06"/>
            <path
                d="M247.705 125.168C248.056 125.082 248.369 125.238 248.556 125.535C248.775 125.871 248.712 126.339 248.478 126.644C248.252 126.941 247.931 127.12 247.549 127.011C247.221 126.917 246.916 126.574 246.947 126.215C246.971 125.988 246.994 125.793 247.119 125.598C247.252 125.387 247.455 125.23 247.697 125.176L247.705 125.168Z"
                fill="#F9BA06"/>
            <path
                d="M249.501 122.216C249.696 122.091 249.868 122.005 250.11 122.005C250.305 122.005 250.485 122.083 250.618 122.223C250.813 122.427 250.829 122.653 250.79 122.911C250.758 123.145 250.579 123.309 250.438 123.481C250.157 123.809 249.595 123.746 249.314 123.457C248.947 123.075 249.064 122.481 249.493 122.208L249.501 122.216Z"
                fill="#F9BA06"/>
            <path
                d="M250.071 120.076C250.071 120.076 250.111 120.052 250.134 120.037C250.501 119.818 250.993 119.904 251.282 120.209C251.454 120.388 251.548 120.63 251.548 120.872C251.548 121.154 251.423 121.341 251.259 121.552C251.243 121.568 251.227 121.591 251.212 121.607C250.884 122.013 250.181 121.927 249.845 121.583C249.408 121.122 249.533 120.388 250.064 120.076H250.071Z"
                fill="#F9BA06"/>
            <path
                d="M252.735 122.028C252.86 121.997 252.992 121.973 253.125 121.973C253.508 121.989 253.929 122.317 253.883 122.739C253.867 122.872 253.851 122.989 253.805 123.114C253.687 123.411 253.5 123.637 253.18 123.723C252.875 123.801 252.547 123.707 252.328 123.481C251.883 123.02 252.094 122.169 252.742 122.02L252.735 122.028Z"
                fill="#F9BA06"/>
            <path
                d="M259.436 121.263C259.943 121.263 260.365 121.692 260.365 122.2C260.365 122.427 260.303 122.598 260.201 122.801C260.014 123.161 259.537 123.317 259.162 123.215C258.733 123.09 258.537 122.739 258.491 122.317C258.475 122.192 258.491 122.06 258.545 121.942C258.694 121.552 259.006 121.263 259.443 121.263H259.436Z"
                fill="#F9BA06"/>
            <path
                d="M261.833 120.505C261.896 120.521 261.958 120.529 262.013 120.544C262.372 120.622 262.622 120.997 262.614 121.349C262.614 121.825 262.232 122.145 261.779 122.177L261.591 122.192C261.123 122.223 260.74 121.77 260.74 121.333C260.74 120.747 261.287 120.396 261.826 120.513L261.833 120.505Z"
                fill="#F9BA06"/>
            <path
                d="M262.177 121.7C262.536 121.7 262.817 122.06 262.715 122.411C262.7 122.473 262.684 122.528 262.661 122.591C262.598 122.809 262.387 122.973 262.161 122.973C261.95 122.973 261.692 122.809 261.661 122.583C261.653 122.52 261.646 122.466 261.638 122.403C261.591 122.052 261.786 121.7 262.184 121.7H262.177Z"
                fill="#F9BA06"/>
            <path
                d="M250.907 116.413C250.907 116.413 250.985 116.436 251.024 116.452C251.368 116.569 251.625 116.858 251.618 117.241C251.618 117.592 251.36 117.959 251.009 118.03C250.969 118.03 250.93 118.045 250.891 118.053C250.36 118.154 249.821 117.803 249.829 117.225C249.829 116.694 250.384 116.225 250.907 116.413Z"
                fill="#F9BA06"/>
            <path
                d="M253.914 116.264C254.18 116.405 254.461 116.522 254.594 116.811C254.797 117.264 254.625 117.764 254.125 117.912C253.805 118.006 253.547 117.889 253.258 117.772C252.836 117.6 252.547 117.225 252.68 116.749C252.836 116.21 253.438 116.014 253.906 116.264H253.914Z"
                fill="#F9BA06"/>
            <path
                d="M252.899 114.827C253.305 114.968 253.531 115.319 253.43 115.749C253.352 116.093 253.008 116.343 252.664 116.343C252.165 116.343 251.821 115.812 252.008 115.366C252.008 115.351 252.008 115.343 252.008 115.327C252.11 114.968 252.524 114.702 252.891 114.835L252.899 114.827Z"
                fill="#F9BA06"/>
            <path
                d="M249.978 114.531C250.368 114.429 250.853 114.671 250.931 115.093C250.985 115.39 251.04 115.686 251.095 115.983C251.173 116.405 250.97 116.835 250.532 116.944C250.15 117.045 249.642 116.803 249.572 116.382C249.525 116.085 249.478 115.78 249.423 115.483C249.353 115.062 249.54 114.648 249.986 114.531H249.978Z"
                fill="#F9BA06"/>
            <path
                d="M249.244 118.896C249.486 119.146 249.627 119.435 249.564 119.787C249.509 120.091 249.142 120.271 248.861 120.201C248.728 120.169 248.635 120.037 248.635 119.904C248.635 119.857 248.642 119.81 248.666 119.771C248.377 119.623 248.205 119.31 248.393 118.998C248.572 118.701 249.002 118.654 249.244 118.904V118.896Z"
                fill="#F9BA06"/>
            <path
                d="M246.807 119.802C246.869 119.881 246.924 119.951 246.987 120.029C247.307 120.435 247.361 120.99 246.963 121.372C246.596 121.731 245.971 121.724 245.62 121.349C245.557 121.279 245.487 121.208 245.425 121.138C245.065 120.755 245.065 120.138 245.448 119.771C245.799 119.435 246.487 119.381 246.815 119.795L246.807 119.802Z"
                fill="#F9BA06"/>
            <path
                d="M246.822 122.778C246.861 122.903 246.908 123.028 246.963 123.153C247.088 123.442 246.815 123.778 246.502 123.598C246.354 123.512 246.205 123.426 246.049 123.348C245.745 123.192 245.729 122.747 245.955 122.528C246.252 122.239 246.697 122.411 246.822 122.77V122.778Z"
                fill="#F9BA06"/>
            <path
                d="M246.338 124.34C246.768 124.231 247.182 124.496 247.299 124.902C247.455 125.418 247.111 125.879 246.643 126.043C246.166 126.207 245.518 125.816 245.557 125.269C245.573 125.043 245.674 124.863 245.838 124.746C245.948 124.551 246.143 124.387 246.346 124.34H246.338Z"
                fill="#F9BA06"/>
            <path
                d="M243.675 125.355C243.753 125.34 243.847 125.316 243.925 125.316C244.003 125.316 244.081 125.332 244.152 125.355C244.214 125.355 244.284 125.355 244.355 125.379C244.456 125.41 244.558 125.472 244.659 125.511C245.011 125.683 245.089 126.191 244.901 126.503C244.714 126.808 244.222 126.995 243.909 126.745C243.745 126.62 243.62 126.511 243.535 126.347C243.441 126.23 243.371 126.113 243.324 125.949C243.253 125.707 243.417 125.394 243.675 125.347V125.355Z"
                fill="#F9BA06"/>
            <path
                d="M242.918 122.466C243.261 122.856 243.285 123.442 242.894 123.817C242.535 124.16 241.887 124.192 241.543 123.793C241.199 123.403 241.176 122.817 241.567 122.442C241.926 122.091 242.574 122.067 242.918 122.466Z"
                fill="#F9BA06"/>
            <path
                d="M243.48 120.802C243.581 120.919 243.69 121.029 243.753 121.169C243.87 121.443 243.87 121.739 243.636 121.958C243.425 122.161 243.097 122.2 242.847 122.044C242.706 121.958 242.613 121.856 242.511 121.739C242.269 121.458 242.253 121.052 242.527 120.786C242.785 120.544 243.237 120.521 243.48 120.802Z"
                fill="#F9BA06"/>
            <path
                d="M243.261 119.06C243.401 118.748 243.69 118.522 244.081 118.522C244.323 118.522 244.565 118.623 244.729 118.803C244.971 119.053 245.003 119.349 244.948 119.677C244.893 120.045 244.573 120.427 244.229 120.568C243.94 120.685 243.605 120.755 243.323 120.552C243.269 120.513 243.3 120.521 243.417 120.576C243.339 120.544 243.269 120.513 243.191 120.482C243.027 120.404 242.87 120.224 242.785 120.068C242.667 119.857 242.675 119.592 242.792 119.388C242.894 119.224 243.05 119.123 243.222 119.084C243.23 119.076 243.245 119.068 243.253 119.06H243.261Z"
                fill="#F9BA06"/>
            <path
                d="M241.816 119.459C242.285 119.74 242.519 120.451 242.183 120.935L241.934 121.286C241.676 121.646 241.223 121.903 240.77 121.747C240.559 121.677 240.473 121.614 240.309 121.474C239.942 121.146 239.981 120.622 240.168 120.224C240.231 120.091 240.286 119.966 240.348 119.834C240.598 119.303 241.356 119.193 241.824 119.467L241.816 119.459Z"
                fill="#F9BA06"/>
            <path
                d="M240.637 122.247C240.684 122.356 240.731 122.466 240.778 122.575C240.91 122.887 240.606 123.254 240.278 123.059C240.184 122.997 240.083 122.942 239.989 122.88C239.731 122.723 239.692 122.356 239.903 122.145C240.106 121.95 240.512 121.974 240.629 122.255L240.637 122.247Z"
                fill="#F9BA06"/>
            <path
                d="M242.043 125.121C242.793 125.988 241.512 127.245 240.754 126.371C240.005 125.504 241.286 124.246 242.043 125.121Z"
                fill="#F9BA06"/>
            <path
                d="M242.808 125.613C243.066 125.543 243.355 125.707 243.418 125.972C243.496 126.285 243.371 126.675 243.058 126.816C242.785 126.941 242.457 126.925 242.246 126.699C242.035 126.472 242.176 126.168 242.426 126.105C242.426 125.886 242.605 125.668 242.808 125.621V125.613Z"
                fill="#F9BA06"/>
            <path
                d="M240.035 124.59C240.145 124.801 240.152 125.012 240.168 125.238C240.199 125.613 239.824 125.933 239.473 125.933C239.106 125.933 238.762 125.613 238.786 125.238C238.786 125.176 238.786 125.105 238.793 125.043C238.801 124.871 238.848 124.738 238.918 124.582C239.121 124.137 239.84 124.199 240.043 124.598L240.035 124.59Z"
                fill="#F9BA06"/>
            <path
                d="M237.388 121.38C237.388 121.38 237.466 121.325 237.505 121.294C237.825 121.06 238.333 121.185 238.591 121.45C238.942 121.81 238.911 122.364 238.567 122.716C238.536 122.747 238.505 122.786 238.466 122.817C238.122 123.176 237.513 123.145 237.177 122.794C237.013 122.622 236.919 122.395 236.919 122.153C236.919 121.802 237.115 121.56 237.388 121.364V121.38Z"
                fill="#F9BA06"/>
            <path
                d="M239.34 118.194C239.614 118.381 239.723 118.584 239.754 118.912C239.793 119.396 239.27 119.685 238.856 119.592C238.622 119.537 238.434 119.389 238.333 119.178C238.192 118.881 238.216 118.537 238.458 118.295C238.684 118.076 239.075 118.014 239.34 118.194Z"
                fill="#F9BA06"/>
            <path
                d="M243.808 121.661C244.003 121.56 244.245 121.505 244.456 121.575C244.558 121.606 244.409 121.497 244.698 121.677C244.761 121.724 244.815 121.77 244.878 121.817C244.901 121.849 244.925 121.88 244.94 121.903C245.151 122.114 245.253 122.411 245.159 122.739C245.042 123.168 244.519 123.528 244.073 123.348C243.823 123.254 243.589 123.122 243.472 122.864C243.269 122.434 243.363 121.895 243.808 121.661Z"
                fill="#F9BA06"/>
            <path
                d="M244.12 122.497C244.183 122.403 244.269 122.333 244.393 122.302C244.659 122.231 244.94 122.395 245.01 122.661C245.05 122.817 245.05 122.958 245.018 123.106C245.018 123.145 245.003 123.278 245.01 123.122C245.01 123.2 244.995 123.286 244.971 123.356C244.948 123.411 244.925 123.458 244.901 123.512C244.87 123.575 244.87 123.559 244.917 123.481C244.893 123.614 244.8 123.762 244.706 123.856C244.573 123.981 244.378 124.075 244.198 124.114C243.683 124.215 243.16 123.864 243.167 123.317C243.167 122.841 243.636 122.419 244.112 122.505L244.12 122.497Z"
                fill="#F9BA06"/>
            <path
                d="M247.275 115.835L247.354 115.929C247.658 116.28 247.682 116.811 247.33 117.147C247.002 117.459 246.416 117.483 246.112 117.124L246.034 117.03C245.729 116.678 245.706 116.147 246.057 115.812C246.385 115.499 246.971 115.476 247.275 115.835Z"
                fill="#F9BA06"/>
            <path
                d="M244.37 116.171C244.753 116.397 244.886 116.92 244.651 117.303C244.464 117.6 244.3 117.975 243.972 118.139C243.456 118.397 242.769 118.069 242.785 117.444C242.792 117.053 243.035 116.764 243.238 116.452C243.48 116.077 243.972 115.936 244.37 116.171Z"
                fill="#F9BA06"/>
            <path
                d="M245.612 116.671C245.745 116.71 245.878 116.749 246.011 116.788C246.175 116.835 246.299 116.936 246.456 116.999C246.651 117.077 246.799 117.233 246.799 117.452C246.799 117.702 246.581 117.936 246.323 117.92C246.167 117.92 246.057 117.912 245.917 117.92C245.761 117.936 245.597 117.889 245.44 117.873C245.112 117.826 244.823 117.623 244.831 117.256C244.831 116.866 245.23 116.553 245.612 116.671Z"
                fill="#F9BA06"/>
            <path
                d="M247.963 116.647C248.299 116.46 248.744 116.577 248.939 116.921C249.142 117.28 248.986 117.678 248.666 117.897C248.322 118.131 247.69 118.225 247.44 117.787C247.19 117.335 247.596 116.85 247.963 116.647Z"
                fill="#F9BA06"/>
            <path
                d="M245.487 113.687C245.674 113.547 245.846 113.445 246.096 113.445C246.33 113.445 246.557 113.539 246.713 113.711C247.041 114.054 247.026 114.601 246.69 114.937C246.674 114.952 246.658 114.968 246.651 114.976C246.315 115.312 245.745 115.296 245.417 114.952C245.12 114.64 245.057 114.007 245.44 113.718C245.456 113.703 245.471 113.695 245.487 113.679V113.687Z"
                fill="#F9BA06"/>
            <path
                d="M247.291 111.719C247.525 111.782 247.744 111.875 247.963 111.992C248.626 112.367 248.314 113.429 247.564 113.414C247.314 113.414 247.08 113.367 246.846 113.305C246.416 113.187 246.158 112.719 246.276 112.289C246.401 111.86 246.861 111.602 247.291 111.719Z"
                fill="#F9BA06"/>
            <path
                d="M250.181 111.985C250.501 111.805 251.008 111.899 251.165 112.258C251.258 112.461 251.368 112.672 251.422 112.891C251.493 113.203 251.368 113.531 251.087 113.687C250.727 113.89 250.337 113.734 250.118 113.414C250.024 113.281 249.97 113.117 249.907 112.969C249.751 112.609 249.814 112.195 250.181 111.985Z"
                fill="#F9BA06"/>
            <path
                d="M251.649 113.976C251.821 114.078 251.883 114.21 251.954 114.39C252.141 114.859 251.563 115.39 251.11 115.218C250.891 115.132 250.696 115.007 250.642 114.765C250.642 114.757 250.642 114.749 250.642 114.742C250.509 114.164 251.126 113.672 251.649 113.984V113.976Z"
                fill="#F9BA06"/>
            <path
                d="M248.603 112.18C248.915 112.008 249.275 112.07 249.548 112.274C249.79 112.453 249.931 112.703 250.095 112.953C250.352 113.351 250.157 113.914 249.759 114.14C249.314 114.39 248.845 114.195 248.572 113.804C248.447 113.632 248.298 113.461 248.244 113.242C248.134 112.844 248.205 112.399 248.595 112.18H248.603Z"
                fill="#F9BA06"/>
            <path
                d="M250.517 109.462C251.212 110.266 250.025 111.422 249.322 110.618C248.627 109.813 249.814 108.658 250.517 109.462Z"
                fill="#F9BA06"/>
            <path
                d="M245.854 108.072C246.588 108.915 245.338 110.134 244.604 109.282C243.87 108.439 245.12 107.22 245.854 108.072Z"
                fill="#F9BA06"/>
            <path
                d="M243.23 106.151C243.519 106.002 243.863 106.041 244.097 106.276C244.308 106.494 244.378 106.885 244.191 107.142C244.089 107.283 243.995 107.4 243.839 107.47C243.542 107.603 243.214 107.588 242.98 107.345C242.761 107.119 242.714 106.752 242.886 106.486C242.98 106.338 243.082 106.229 243.238 106.151H243.23Z"
                fill="#F9BA06"/>
            <path
                d="M243.472 103.729C243.839 103.526 244.323 103.589 244.612 103.893C244.753 104.05 244.862 104.221 244.909 104.424C244.987 104.752 244.909 105.018 244.784 105.315C244.558 105.846 243.941 106.025 243.425 105.901C243.074 105.815 242.777 105.51 242.683 105.166C242.574 104.745 242.761 104.323 243.121 104.089C243.199 103.94 243.316 103.815 243.472 103.729Z"
                fill="#F9BA06"/>
            <path
                d="M240.544 107.291C240.872 107.431 241.106 107.603 241.2 107.962C241.364 108.548 240.887 109.15 240.278 109.142C239.763 109.142 239.333 108.704 239.341 108.197C239.341 107.947 239.45 107.697 239.63 107.525C239.849 107.314 240.247 107.174 240.544 107.299V107.291Z"
                fill="#F9BA06"/>
            <path
                d="M242.777 108.111C243.05 108.111 243.323 108.228 243.511 108.423C243.745 108.665 243.894 109.095 243.761 109.423C243.761 109.446 243.745 109.462 243.737 109.478C243.565 109.899 243.245 110.22 242.761 110.22C242.316 110.22 241.894 109.899 241.785 109.462C241.785 109.446 241.777 109.423 241.769 109.407C241.613 108.759 242.097 108.111 242.777 108.111Z"
                fill="#F9BA06"/>
            <path
                d="M243.698 109.415C244.042 109.345 244.464 109.407 244.706 109.696C244.948 109.993 245.041 110.243 244.971 110.626C244.971 110.649 244.963 110.672 244.956 110.688C244.885 111.047 244.549 111.305 244.221 111.407C243.823 111.532 243.323 111.43 243.034 111.125C242.706 110.961 242.597 110.524 242.808 110.227C242.917 109.821 243.292 109.493 243.706 109.415H243.698Z"
                fill="#F9BA06"/>
            <path
                d="M240.887 110.227C241.246 110.602 241.308 111.352 240.863 111.703C240.848 111.719 240.832 111.727 240.816 111.743C240.59 111.914 240.387 112.039 240.09 112.032C239.809 112.032 239.543 111.914 239.348 111.711C238.958 111.305 238.965 110.634 239.372 110.243C239.387 110.227 239.403 110.212 239.418 110.204C239.825 109.806 240.496 109.821 240.895 110.227H240.887Z"
                fill="#F9BA06"/>
            <path
                d="M238.192 108.009C238.497 107.931 238.833 108.025 239.052 108.251C239.45 108.665 239.34 109.353 238.848 109.642C238.817 109.657 238.778 109.681 238.747 109.704C238.411 109.899 237.966 109.829 237.7 109.548C237.458 109.298 237.349 108.798 237.583 108.501C237.607 108.47 237.63 108.439 237.654 108.408C237.802 108.22 237.942 108.072 238.185 108.009H238.192Z"
                fill="#F9BA06"/>
            <path
                d="M239.762 104.307C239.98 104.557 240.105 104.807 240.09 105.151C240.074 105.541 239.746 105.971 239.309 105.924C238.973 105.893 238.707 105.807 238.481 105.541C238.161 105.174 238.137 104.635 238.504 104.284C238.84 103.964 239.449 103.94 239.762 104.307Z"
                fill="#F9BA06"/>
            <path
                d="M242.121 102.902C242.121 102.902 242.152 102.925 242.168 102.941C242.542 103.261 242.48 103.901 242.144 104.221C241.707 104.643 241.043 104.495 240.731 104.011C240.723 103.995 240.707 103.979 240.699 103.964C240.481 103.628 240.582 103.151 240.856 102.886C241.23 102.527 241.754 102.589 242.121 102.909V102.902Z"
                fill="#F9BA06"/>
            <path
                d="M240.184 102.035C240.933 102.902 239.653 104.159 238.895 103.284C238.137 102.417 239.426 101.16 240.184 102.035Z"
                fill="#F9BA06"/>
            <path
                d="M234.436 105.596C234.795 105.643 235.107 105.815 235.201 106.189C235.256 106.393 235.217 106.588 235.123 106.752C235.084 106.869 235.029 106.978 234.92 107.08C234.709 107.283 234.397 107.361 234.108 107.283C233.569 107.134 233.35 106.518 233.616 106.041C233.764 105.776 234.108 105.549 234.428 105.588L234.436 105.596Z"
                fill="#F9BA06"/>
            <path
                d="M233.92 107.314C234.069 107.314 234.209 107.338 234.35 107.361C234.607 107.4 234.803 107.634 234.935 107.845C235.092 108.087 235.06 108.415 234.92 108.658C234.693 109.056 234.326 109.15 233.904 109.181C233.397 109.212 232.975 108.728 232.975 108.244C232.975 107.744 233.405 107.314 233.912 107.314H233.92Z"
                fill="#F9BA06"/>
            <path
                d="M236.529 110.423C236.529 110.423 236.591 110.376 236.622 110.352C236.974 110.11 237.505 110.235 237.786 110.524C238.169 110.915 238.122 111.5 237.763 111.891C237.731 111.922 237.708 111.953 237.677 111.977C237.317 112.367 236.646 112.32 236.294 111.953C236.123 111.774 236.021 111.524 236.021 111.266C236.021 110.891 236.224 110.626 236.521 110.415L236.529 110.423Z"
                fill="#F9BA06"/>
            <path
                d="M236.841 111.321C237.325 111.149 237.864 111.524 237.989 111.992C238.02 112.133 238.02 112.274 237.989 112.406C237.989 112.461 237.981 112.516 237.966 112.57C237.974 112.5 237.981 112.445 237.989 112.406C237.989 112.445 237.974 112.5 237.966 112.578C237.841 113.016 237.286 113.398 236.825 113.203C236.646 113.125 236.505 113.086 236.372 112.93C236.255 112.797 236.169 112.664 236.13 112.484C236.005 111.93 236.326 111.493 236.833 111.313L236.841 111.321Z"
                fill="#F9BA06"/>
            <path
                d="M239.746 112.797C239.973 112.633 240.168 112.5 240.442 112.398C240.59 112.344 240.691 112.297 240.863 112.297C241.176 112.297 241.496 112.477 241.652 112.75C242.097 113.547 241.246 114.101 240.652 114.413C240.207 114.648 239.645 114.507 239.395 114.062C239.278 113.851 239.247 113.593 239.309 113.359C239.379 113.101 239.536 112.953 239.746 112.805V112.797Z"
                fill="#F9BA06"/>
            <path
                d="M243.12 112.11C243.19 112.172 243.269 112.235 243.339 112.297C243.706 112.602 243.636 113.242 243.315 113.547C242.894 113.953 242.23 113.828 241.933 113.344C241.886 113.265 241.832 113.18 241.785 113.101C241.589 112.781 241.668 112.344 241.933 112.094C242.284 111.75 242.761 111.821 243.112 112.117L243.12 112.11Z"
                fill="#F9BA06"/>
            <path
                d="M239.645 115.062C239.934 115.241 240.129 115.647 240.004 115.983C239.957 116.116 239.911 116.241 239.887 116.382C239.856 116.608 239.645 116.827 239.458 116.928C239.176 117.092 238.817 117.085 238.536 116.913C238.06 116.632 237.903 115.843 238.372 115.468C238.481 115.382 238.567 115.28 238.653 115.171C238.88 114.89 239.356 114.89 239.645 115.062Z"
                fill="#F9BA06"/>
            <path
                d="M236.388 117.381C236.607 117.225 236.833 117.108 237.115 117.131C237.614 117.178 238.099 117.545 238.067 118.1C238.06 118.311 238.028 118.482 237.919 118.67C237.841 118.803 237.716 118.92 237.607 119.029C237.216 119.435 236.529 119.396 236.146 119.006C235.959 118.811 235.857 118.553 235.857 118.287C235.857 117.889 236.076 117.616 236.38 117.389L236.388 117.381Z"
                fill="#F9BA06"/>
            <path
                d="M236.013 115.288C236.505 115.429 236.794 115.858 236.818 116.35C236.833 116.608 236.833 116.858 236.747 117.108C236.568 117.608 236.162 117.975 235.607 117.967C235.295 117.967 234.982 117.834 234.764 117.608C234.568 117.413 234.396 117.069 234.436 116.78C234.475 116.499 234.482 116.296 234.623 116.046L234.779 115.78C235.021 115.358 235.56 115.155 236.021 115.288H236.013Z"
                fill="#F9BA06"/>
            <path
                d="M236.747 113.523C236.982 113.664 237.185 113.812 237.31 114.062C237.607 114.663 237.216 115.319 236.552 115.351C236.263 115.366 236.029 115.257 235.787 115.116C235.349 114.859 235.209 114.273 235.467 113.836C235.732 113.398 236.31 113.265 236.747 113.523Z"
                fill="#F9BA06"/>
            <path
                d="M235.951 112.071C236.373 112.32 236.49 112.867 236.248 113.281C236.131 113.476 236.022 113.672 235.889 113.851C235.74 114.039 235.6 114.187 235.358 114.25C235.131 114.312 234.881 114.273 234.678 114.148C234.249 113.89 234.155 113.359 234.381 112.937C234.483 112.734 234.616 112.555 234.741 112.367C235.014 111.977 235.522 111.805 235.951 112.071Z"
                fill="#F9BA06"/>
            <path
                d="M232.116 112.094C232.811 111.914 233.405 112.445 233.444 113.125C233.459 113.328 233.475 113.515 233.444 113.718C233.444 113.898 233.397 114.062 233.303 114.21C233.178 114.421 232.96 114.585 232.717 114.648C232.311 114.749 231.827 114.57 231.632 114.187C231.499 113.929 231.429 113.664 231.351 113.39C231.194 112.836 231.562 112.242 232.108 112.094H232.116Z"
                fill="#F9BA06"/>
            <path
                d="M231.398 113.797C231.53 113.851 231.671 113.89 231.804 113.953C231.905 113.992 232.007 114.101 232.093 114.171C232.108 114.187 232.124 114.203 232.14 114.218C232.444 114.117 232.78 114.164 233.038 114.429C233.366 114.765 233.366 115.343 233.014 115.671C232.874 115.796 232.741 115.929 232.6 116.054C232.272 116.35 231.64 116.429 231.335 116.03C231.124 115.757 230.898 115.484 230.788 115.156C230.749 115.038 230.71 114.921 230.679 114.804C230.664 114.71 230.656 114.624 230.648 114.531C230.703 114.343 230.703 114.289 230.648 114.375C230.624 114.023 231.054 113.664 231.39 113.812L231.398 113.797Z"
                fill="#F9BA06"/>
            <path
                d="M230.445 111.68C230.827 111.586 231.234 111.696 231.507 111.977C231.796 112.281 231.835 112.641 231.772 113.039C231.71 113.445 231.405 113.718 231.14 113.999C230.71 114.46 229.922 114.406 229.5 113.968C229.039 113.484 229.109 112.805 229.531 112.328C229.609 112.242 229.687 112.148 229.765 112.063C229.945 111.86 230.187 111.75 230.445 111.68Z"
                fill="#F9BA06"/>
            <path
                d="M231.343 110.243C231.577 110.188 231.788 110.157 232.022 110.227C232.592 110.399 232.795 111.133 232.358 111.555C232.186 111.719 231.983 111.797 231.757 111.852C231.319 111.961 230.859 111.703 230.741 111.258C230.632 110.829 230.898 110.352 231.335 110.243H231.343Z"
                fill="#F9BA06"/>
            <path
                d="M233.756 108.993C234.108 108.907 234.42 109.064 234.607 109.353C234.826 109.688 234.764 110.157 234.529 110.462C234.303 110.758 233.983 110.938 233.6 110.829C233.272 110.735 232.967 110.391 232.999 110.032C233.022 109.806 233.045 109.61 233.17 109.415C233.303 109.204 233.506 109.056 233.756 108.993Z"
                fill="#F9BA06"/>
            <path
                d="M235.545 106.041C235.74 105.916 235.912 105.83 236.154 105.83C236.349 105.83 236.529 105.908 236.662 106.049C236.857 106.252 236.872 106.479 236.833 106.736C236.802 106.971 236.63 107.135 236.482 107.306C236.201 107.634 235.631 107.572 235.357 107.283C234.99 106.9 235.107 106.307 235.537 106.033L235.545 106.041Z"
                fill="#F9BA06"/>
            <path
                d="M236.115 103.901C236.115 103.901 236.154 103.878 236.177 103.862C236.544 103.643 237.037 103.729 237.325 104.034C237.497 104.214 237.591 104.456 237.591 104.698C237.591 104.979 237.466 105.166 237.302 105.377C237.286 105.393 237.271 105.416 237.255 105.432C236.927 105.838 236.224 105.752 235.888 105.409C235.451 104.948 235.576 104.214 236.107 103.893L236.115 103.901Z"
                fill="#F9BA06"/>
            <path
                d="M238.778 105.854C238.903 105.823 239.036 105.799 239.168 105.799C239.543 105.815 239.973 106.143 239.926 106.564C239.91 106.697 239.895 106.814 239.848 106.939C239.731 107.236 239.543 107.47 239.223 107.549C238.918 107.627 238.59 107.533 238.372 107.306C237.927 106.846 238.13 106.002 238.778 105.846V105.854Z"
                fill="#F9BA06"/>
            <path
                d="M245.479 105.088C245.986 105.088 246.408 105.518 246.408 106.026C246.408 106.252 246.346 106.424 246.244 106.627C246.057 106.986 245.58 107.142 245.205 107.041C244.776 106.924 244.573 106.564 244.534 106.15C244.518 106.026 244.534 105.893 244.588 105.776C244.737 105.385 245.049 105.096 245.487 105.096L245.479 105.088Z"
                fill="#F9BA06"/>
            <path
                d="M247.877 104.331C247.939 104.346 248.002 104.354 248.056 104.37C248.416 104.448 248.666 104.823 248.658 105.174C248.658 105.651 248.275 105.971 247.822 106.002L247.635 106.018C247.166 106.049 246.783 105.596 246.783 105.159C246.783 104.573 247.33 104.221 247.869 104.339L247.877 104.331Z"
                fill="#F9BA06"/>
            <path
                d="M248.22 105.526C248.58 105.526 248.861 105.885 248.759 106.236C248.744 106.299 248.728 106.354 248.705 106.416C248.642 106.635 248.439 106.799 248.205 106.791C247.994 106.791 247.736 106.627 247.705 106.4C247.705 106.338 247.689 106.283 247.681 106.221C247.635 105.869 247.83 105.518 248.228 105.518L248.22 105.526Z"
                fill="#F9BA06"/>
            <path
                d="M236.951 100.238C236.951 100.238 237.029 100.27 237.068 100.277C237.411 100.395 237.669 100.691 237.661 101.066C237.661 101.418 237.404 101.785 237.052 101.855C237.013 101.855 236.974 101.871 236.935 101.878C236.404 101.98 235.865 101.628 235.873 101.051C235.873 100.519 236.427 100.051 236.951 100.238Z"
                fill="#F9BA06"/>
            <path
                d="M239.957 100.09C240.215 100.231 240.504 100.348 240.637 100.637C240.84 101.09 240.668 101.589 240.168 101.738C239.848 101.832 239.59 101.714 239.301 101.597C238.879 101.425 238.598 101.043 238.723 100.574C238.879 100.035 239.481 99.84 239.949 100.09H239.957Z"
                fill="#F9BA06"/>
            <path
                d="M238.942 98.6529C239.348 98.7934 239.567 99.1449 239.473 99.5744C239.395 99.9181 239.051 100.168 238.708 100.168C238.208 100.168 237.872 99.6369 238.052 99.1918C238.052 99.1761 238.052 99.1683 238.052 99.1527C238.153 98.7934 238.567 98.5279 238.934 98.6607L238.942 98.6529Z"
                fill="#F9BA06"/>
            <path
                d="M236.022 98.3561C236.412 98.2545 236.904 98.4967 236.974 98.9184C237.029 99.2152 237.084 99.512 237.131 99.8087C237.209 100.23 237.006 100.652 236.568 100.769C236.178 100.871 235.678 100.629 235.608 100.207C235.561 99.9103 235.514 99.6057 235.459 99.3089C235.389 98.8872 235.576 98.4654 236.022 98.3561Z"
                fill="#F9BA06"/>
            <path
                d="M235.295 102.722C235.537 102.972 235.678 103.253 235.615 103.612C235.553 103.917 235.193 104.089 234.912 104.026C234.779 103.995 234.686 103.862 234.686 103.729C234.686 103.683 234.701 103.636 234.717 103.597C234.428 103.456 234.264 103.136 234.444 102.824C234.623 102.527 235.053 102.48 235.295 102.73V102.722Z"
                fill="#F9BA06"/>
            <path
                d="M232.85 103.628C232.913 103.706 232.967 103.776 233.03 103.854C233.35 104.261 233.405 104.815 233.006 105.198C232.639 105.557 232.014 105.549 231.663 105.174C231.601 105.104 231.53 105.034 231.468 104.963C231.108 104.581 231.108 103.972 231.491 103.597C231.843 103.261 232.53 103.206 232.858 103.62L232.85 103.628Z"
                fill="#F9BA06"/>
            <path
                d="M232.866 106.603C232.913 106.728 232.952 106.853 233.006 106.978C233.131 107.267 232.858 107.603 232.546 107.424C232.397 107.338 232.249 107.252 232.093 107.181C231.788 107.025 231.772 106.58 231.999 106.361C232.296 106.08 232.741 106.244 232.866 106.603Z"
                fill="#F9BA06"/>
            <path
                d="M232.382 108.158C232.811 108.048 233.225 108.314 233.342 108.72C233.498 109.235 233.155 109.696 232.686 109.86C232.21 110.024 231.562 109.634 231.601 109.087C231.616 108.861 231.718 108.681 231.874 108.564C231.983 108.369 232.179 108.205 232.382 108.158Z"
                fill="#F9BA06"/>
            <path
                d="M229.719 109.173C229.797 109.157 229.89 109.134 229.969 109.134C230.047 109.134 230.125 109.15 230.195 109.173C230.257 109.173 230.32 109.173 230.39 109.196C230.492 109.228 230.593 109.29 230.695 109.329C231.046 109.501 231.124 110.009 230.937 110.321C230.757 110.626 230.257 110.813 229.945 110.563C229.781 110.438 229.656 110.329 229.57 110.165C229.484 110.04 229.406 109.93 229.359 109.766C229.289 109.524 229.453 109.212 229.711 109.165L229.719 109.173Z"
                fill="#F9BA06"/>
            <path
                d="M228.961 106.291C229.304 106.682 229.328 107.267 228.937 107.642C228.578 107.994 227.922 108.017 227.586 107.619C227.242 107.228 227.219 106.643 227.61 106.268C227.969 105.916 228.625 105.893 228.961 106.291Z"
                fill="#F9BA06"/>
            <path
                d="M229.523 104.628C229.625 104.745 229.734 104.854 229.796 104.995C229.914 105.268 229.914 105.565 229.679 105.783C229.468 105.987 229.14 106.026 228.89 105.869C228.75 105.783 228.656 105.682 228.555 105.565C228.313 105.291 228.297 104.877 228.57 104.612C228.828 104.37 229.281 104.346 229.523 104.628Z"
                fill="#F9BA06"/>
            <path
                d="M229.305 102.886C229.445 102.574 229.734 102.347 230.125 102.347C230.367 102.347 230.609 102.449 230.773 102.628C231.015 102.878 231.038 103.175 230.992 103.503C230.937 103.87 230.617 104.253 230.273 104.393C229.984 104.51 229.648 104.581 229.367 104.378C229.312 104.339 229.344 104.346 229.461 104.401C229.383 104.37 229.312 104.339 229.234 104.3C229.07 104.229 228.914 104.042 228.828 103.886C228.711 103.675 228.719 103.409 228.836 103.206C228.938 103.042 229.094 102.941 229.266 102.902C229.273 102.894 229.289 102.886 229.297 102.878L229.305 102.886Z"
                fill="#F9BA06"/>
            <path
                d="M227.859 103.284C228.328 103.565 228.562 104.284 228.226 104.76L227.976 105.112C227.719 105.471 227.266 105.729 226.813 105.573C226.602 105.502 226.516 105.44 226.352 105.291C225.985 104.963 226.024 104.44 226.211 104.042C226.274 103.909 226.336 103.784 226.391 103.651C226.641 103.12 227.406 103.011 227.867 103.284H227.859Z"
                fill="#F9BA06"/>
            <path
                d="M226.68 106.072C226.727 106.182 226.774 106.291 226.821 106.4C226.954 106.713 226.649 107.08 226.321 106.885C226.227 106.822 226.126 106.768 226.032 106.705C225.774 106.549 225.735 106.182 225.946 105.971C226.149 105.776 226.555 105.799 226.673 106.08L226.68 106.072Z"
                fill="#F9BA06"/>
            <path
                d="M228.086 108.947C228.844 109.813 227.555 111.071 226.798 110.196C226.04 109.329 227.329 108.072 228.086 108.947Z"
                fill="#F9BA06"/>
            <path
                d="M228.852 109.439C229.109 109.368 229.398 109.532 229.461 109.798C229.539 110.11 229.414 110.501 229.101 110.641C228.828 110.766 228.5 110.751 228.289 110.524C228.078 110.298 228.227 109.993 228.469 109.931C228.469 109.712 228.648 109.493 228.852 109.446V109.439Z"
                fill="#F9BA06"/>
            <path
                d="M226.079 108.415C226.188 108.626 226.196 108.837 226.212 109.064C226.243 109.438 225.868 109.759 225.517 109.759C225.15 109.759 224.806 109.438 224.822 109.064C224.822 109.001 224.822 108.931 224.829 108.868C224.837 108.697 224.884 108.564 224.954 108.408C225.157 107.962 225.868 108.025 226.071 108.423L226.079 108.415Z"
                fill="#F9BA06"/>
            <path
                d="M223.431 105.206C223.431 105.206 223.51 105.151 223.549 105.12C223.869 104.885 224.376 105.01 224.634 105.276C224.986 105.635 224.947 106.19 224.611 106.541C224.58 106.572 224.54 106.611 224.509 106.643C224.166 107.002 223.556 106.963 223.221 106.619C223.057 106.447 222.963 106.221 222.963 105.979C222.963 105.627 223.158 105.385 223.431 105.19V105.206Z"
                fill="#F9BA06"/>
            <path
                d="M225.384 102.019C225.657 102.206 225.767 102.41 225.798 102.738C225.837 103.222 225.314 103.511 224.9 103.417C224.665 103.362 224.478 103.214 224.377 103.003C224.236 102.706 224.259 102.363 224.501 102.121C224.728 101.902 225.118 101.839 225.384 102.019Z"
                fill="#F9BA06"/>
            <path
                d="M229.852 105.487C230.047 105.385 230.281 105.33 230.5 105.401C230.601 105.432 230.453 105.323 230.742 105.502C230.804 105.549 230.859 105.596 230.922 105.643C230.945 105.674 230.968 105.705 230.984 105.729C231.195 105.94 231.296 106.236 231.203 106.564C231.086 106.994 230.562 107.353 230.117 107.174C229.867 107.072 229.633 106.947 229.516 106.689C229.313 106.268 229.406 105.721 229.852 105.487Z"
                fill="#F9BA06"/>
            <path
                d="M230.164 106.322C230.226 106.229 230.312 106.158 230.437 106.127C230.702 106.057 230.991 106.221 231.054 106.486C231.093 106.643 231.093 106.783 231.062 106.932C231.062 106.971 231.046 107.103 231.054 106.947C231.054 107.025 231.038 107.111 231.015 107.181C230.991 107.236 230.968 107.283 230.945 107.338C230.913 107.4 230.921 107.385 230.968 107.306C230.945 107.439 230.851 107.588 230.757 107.681C230.624 107.806 230.429 107.9 230.249 107.939C229.734 108.041 229.219 107.689 229.219 107.135C229.219 106.658 229.687 106.236 230.164 106.33V106.322Z"
                fill="#F9BA06"/>
            <path
                d="M233.319 99.6604L233.397 99.7541C233.702 100.106 233.725 100.637 233.374 100.972C233.046 101.285 232.46 101.308 232.155 100.949L232.077 100.855C231.773 100.496 231.749 99.9728 232.101 99.6369C232.429 99.3245 233.015 99.3011 233.319 99.6604Z"
                fill="#F9BA06"/>
            <path
                d="M230.421 99.9961C230.804 100.223 230.937 100.746 230.702 101.129C230.515 101.425 230.351 101.8 230.023 101.964C229.507 102.222 228.82 101.894 228.844 101.269C228.851 100.879 229.094 100.59 229.297 100.277C229.539 99.9024 230.031 99.7618 230.429 99.9961H230.421Z"
                fill="#F9BA06"/>
            <path
                d="M231.655 100.496C231.788 100.535 231.921 100.574 232.053 100.613C232.217 100.66 232.342 100.762 232.499 100.824C232.694 100.902 232.842 101.058 232.842 101.277C232.842 101.527 232.624 101.761 232.366 101.746C232.21 101.738 232.1 101.738 231.96 101.746C231.804 101.761 231.64 101.714 231.483 101.699C231.155 101.652 230.866 101.449 230.874 101.082C230.874 100.691 231.272 100.379 231.655 100.496Z"
                fill="#F9BA06"/>
            <path
                d="M234.006 100.473C234.35 100.285 234.787 100.402 234.983 100.746C235.186 101.105 235.03 101.504 234.709 101.722C234.366 101.957 233.725 102.05 233.483 101.613C233.233 101.16 233.639 100.676 234.006 100.473Z"
                fill="#F9BA06"/>
            <path
                d="M231.53 97.5126C231.718 97.372 231.89 97.2705 232.14 97.2705C232.374 97.2705 232.593 97.3642 232.757 97.5361C233.085 97.8797 233.069 98.4264 232.733 98.7622C232.718 98.7778 232.702 98.7935 232.694 98.8013C232.358 99.1371 231.788 99.1215 231.468 98.7778C231.171 98.4654 231.109 97.8328 231.491 97.5517C231.507 97.536 231.523 97.5282 231.538 97.5126H231.53Z"
                fill="#F9BA06"/>
            <path
                d="M233.334 95.5445C233.576 95.607 233.787 95.7007 234.006 95.8179C234.67 96.1849 234.357 97.2549 233.608 97.2393C233.358 97.2393 233.131 97.1924 232.889 97.1299C232.46 97.0128 232.202 96.5442 232.319 96.1147C232.444 95.6773 232.905 95.4274 233.334 95.5445Z"
                fill="#F9BA06"/>
            <path
                d="M236.224 95.81C236.545 95.6304 237.044 95.7241 237.208 96.0834C237.294 96.2864 237.411 96.4973 237.466 96.716C237.536 97.0284 237.411 97.3564 237.13 97.5126C236.771 97.7157 236.381 97.5595 236.162 97.2393C236.068 97.1065 236.013 96.9425 235.951 96.7941C235.795 96.4348 235.857 96.0131 236.224 95.81Z"
                fill="#F9BA06"/>
            <path
                d="M237.693 97.8016C237.864 97.9031 237.927 98.0359 237.997 98.2155C238.185 98.6841 237.607 99.2152 237.154 99.0433C236.935 98.9652 236.74 98.8325 236.685 98.5826C236.685 98.5747 236.685 98.5669 236.685 98.5591C236.552 97.9812 237.169 97.4892 237.693 97.8016Z"
                fill="#F9BA06"/>
            <path
                d="M234.647 96.0053C234.959 95.8335 235.318 95.896 235.592 96.099C235.834 96.2786 235.974 96.5286 236.138 96.7785C236.396 97.1768 236.201 97.7391 235.81 97.9656C235.365 98.2155 234.896 98.0203 234.623 97.6298C234.498 97.4502 234.35 97.2783 234.295 97.0675C234.186 96.6692 234.256 96.224 234.647 96.0053Z"
                fill="#F9BA06"/>
            <path
                d="M236.56 93.2875C237.255 94.0919 236.068 95.2478 235.365 94.4434C234.67 93.6389 235.857 92.483 236.56 93.2875Z"
                fill="#F9BA06"/>
            <path
                d="M240.762 116.249L240.816 116.233C241.113 116.132 241.48 116.264 241.691 116.483C241.988 116.788 242.003 117.217 241.816 117.577C241.808 117.592 241.8 117.608 241.792 117.631C241.511 118.162 240.73 118.209 240.34 117.795C240.113 117.561 240.027 117.217 240.113 116.905C240.207 116.569 240.457 116.374 240.769 116.257L240.762 116.249Z"
                fill="#F9BA06"/>
            <path
                d="M244.003 114C244.768 114.882 243.464 116.155 242.691 115.265C241.925 114.382 243.229 113.109 244.003 114Z"
                fill="#F9BA06"/>
            <path
                d="M245.034 111.461C245.135 111.539 245.245 111.61 245.331 111.703C245.799 112.227 245.315 113.008 244.643 112.859C244.518 112.828 244.401 112.766 244.284 112.711C243.925 112.547 243.847 112.024 244.034 111.711C244.222 111.399 244.714 111.227 245.034 111.461Z"
                fill="#F9BA06"/>
            <path
                d="M246.487 109.095C246.705 109.04 246.986 109.048 247.189 109.173C247.385 109.29 247.533 109.524 247.588 109.743C247.674 110.071 247.533 110.345 247.393 110.626C247.166 111.071 246.533 111.18 246.135 110.938C245.721 110.688 245.542 110.095 245.823 109.681C245.995 109.423 246.166 109.181 246.487 109.095Z"
                fill="#F9BA06"/>
            <path
                d="M242.878 99.9885C243.534 100.746 242.417 101.839 241.754 101.074C241.098 100.317 242.214 99.2231 242.878 99.9885Z"
                fill="#F9BA06"/>
            <path
                d="M238.841 96.5833C239.567 97.4189 238.333 98.6295 237.599 97.786C236.873 96.9503 238.107 95.7398 238.841 96.5833Z"
                fill="#F9BA06"/>
            <path
                d="M256.6 115.694C257.334 116.538 256.085 117.756 255.351 116.905C254.617 116.061 255.866 114.843 256.6 115.694Z"
                fill="#F9BA06"/>
            <path
                d="M252.86 111.086C253.305 110.969 253.766 111.243 253.883 111.688C253.93 111.867 253.992 112.039 253.976 112.227C253.945 112.602 253.734 112.922 253.359 113.016C252.992 113.109 252.633 112.937 252.438 112.625C252.336 112.461 252.305 112.289 252.266 112.11C252.165 111.664 252.407 111.211 252.867 111.086H252.86Z"
                fill="#F9BA06"/>
            <path
                d="M251.11 124.73C251.423 125.09 251.446 125.629 251.087 125.972C250.759 126.292 250.157 126.308 249.845 125.949C249.532 125.59 249.509 125.051 249.868 124.707C250.196 124.387 250.798 124.363 251.11 124.73Z"
                fill="#F9BA06"/>
            <path
                d="M254.257 121.497C254.702 121.193 255.327 121.528 255.452 122.005C255.53 122.286 255.436 122.598 255.226 122.802C255.187 122.841 255.14 122.864 255.101 122.895C255.007 122.989 254.882 123.059 254.757 123.09C254.429 123.176 254.085 123.02 253.914 122.731C253.64 122.27 253.835 121.771 254.249 121.489L254.257 121.497Z"
                fill="#F9BA06"/>
            <path
                d="M256.038 130.463C256.749 131.283 255.538 132.478 254.82 131.642C254.109 130.815 255.32 129.627 256.038 130.463Z"
                fill="#F9BA06"/>
            <path
                d="M257.256 133.447C258.006 134.313 256.733 135.555 255.976 134.681C255.226 133.814 256.499 132.572 257.256 133.447Z"
                fill="#F9BA06"/>
            <path
                d="M258.694 133.251C258.694 133.251 258.725 133.267 258.74 133.282C259.014 133.439 259.186 133.759 259.178 134.063C259.178 134.509 258.881 134.79 258.498 134.938C258.483 134.946 258.459 134.954 258.444 134.962C258.084 135.102 257.538 134.868 257.389 134.516C257.249 134.181 257.21 133.915 257.405 133.571C257.663 133.118 258.256 133.009 258.694 133.251Z"
                fill="#F9BA06"/>
            <path
                d="M251.985 136.438C251.985 136.438 252.016 136.438 252.032 136.453C252.688 136.602 252.867 137.484 252.406 137.929C252.18 138.14 251.86 138.226 251.563 138.14C251.165 138.023 250.962 137.687 250.923 137.289C250.923 137.273 250.923 137.258 250.923 137.242C250.868 136.672 251.5 136.321 251.992 136.438H251.985Z"
                fill="#F9BA06"/>
            <path
                d="M256.811 129.26C257.319 129.26 257.624 129.659 257.67 130.127C257.702 130.408 257.616 130.729 257.381 130.908C257.085 131.127 256.741 131.228 256.397 131.018C256.062 130.814 255.937 130.494 255.944 130.119C255.944 129.651 256.335 129.26 256.811 129.26Z"
                fill="#F9BA06"/>
            <path
                d="M258.241 130.963C258.358 130.861 258.483 130.783 258.654 130.721C258.951 130.619 259.326 130.791 259.467 131.064C259.514 131.15 259.545 131.244 259.576 131.338C259.607 131.431 259.607 131.517 259.6 131.611C259.607 131.83 259.545 132.033 259.404 132.228C259.186 132.533 258.842 132.712 258.459 132.603C257.975 132.47 257.803 132.017 257.873 131.564C257.92 131.299 258.045 131.111 258.241 130.963Z"
                fill="#F9BA06"/>
            <path
                d="M258.202 126.956C258.241 126.956 258.288 126.964 258.334 126.972C258.748 127.011 259.108 127.238 259.217 127.659C259.334 128.112 259.053 128.69 258.561 128.784C258.522 128.784 258.475 128.8 258.436 128.807C257.936 128.901 257.428 128.659 257.296 128.136C257.155 127.589 257.592 126.902 258.209 126.964L258.202 126.956Z"
                fill="#F9BA06"/>
            <path
                d="M261.239 126.199C261.427 126.129 261.599 126.051 261.802 126.051C262.075 126.051 262.356 126.168 262.559 126.355C262.864 126.535 262.981 126.964 262.77 127.253C262.676 127.495 262.497 127.706 262.27 127.839C262.028 127.972 261.739 127.964 261.474 127.987C261.075 128.026 260.693 127.675 260.599 127.308C260.536 127.074 260.575 126.816 260.7 126.605C260.833 126.386 261.021 126.285 261.247 126.191L261.239 126.199Z"
                fill="#F9BA06"/>
            <path
                d="M263.715 124.895C263.973 124.895 264.152 125.004 264.34 125.16C264.496 125.285 264.613 125.433 264.668 125.636C264.754 125.949 264.652 126.293 264.418 126.519C264.004 126.917 263.278 126.824 262.997 126.308C262.981 126.277 262.957 126.238 262.942 126.207C262.739 125.871 262.817 125.41 263.098 125.137C263.262 124.98 263.489 124.887 263.715 124.887V124.895Z"
                fill="#F9BA06"/>
            <path
                d="M264.348 125.168C264.317 125.144 264.293 125.121 264.262 125.098C264.293 125.121 264.324 125.144 264.348 125.168Z"
                fill="#F9BA06"/>
            <path
                d="M264.786 139.741C264.645 139.726 264.887 139.71 264.903 139.71C264.981 139.702 265.051 139.694 265.114 139.702C265.239 139.679 265.356 139.663 265.481 139.655C265.988 139.632 266.223 140.436 265.738 140.639C265.684 140.663 265.629 140.686 265.582 140.71C265.567 140.733 265.551 140.764 265.527 140.788C265.418 141.124 265.153 141.389 264.871 141.624C264.512 141.92 263.825 141.85 263.731 141.303C263.653 140.85 263.786 140.429 264.09 140.085C264.254 139.898 264.52 139.718 264.786 139.741Z"
                fill="#F9BA06"/>
            <path
                d="M268.69 137.609C269.104 137.539 269.526 137.664 269.729 138.062C269.862 138.328 269.878 138.632 269.714 138.898C269.573 139.14 269.284 139.312 269.003 139.296C269.057 139.296 269.112 139.281 269.167 139.273C269.018 139.312 268.862 139.312 268.714 139.273C268.714 139.273 268.714 139.273 268.706 139.273C268.675 139.273 268.644 139.265 268.605 139.249C268.3 139.163 268.019 138.843 268.05 138.515C268.05 138.492 268.05 138.468 268.058 138.437C268.058 138.281 268.097 138.133 268.183 138C268.292 137.828 268.487 137.648 268.698 137.617L268.69 137.609Z"
                fill="#F9BA06"/>
            <path
                d="M272.291 138.75C272.447 138.929 272.603 139.109 272.752 139.281C272.791 139.304 272.83 139.32 272.861 139.351C272.861 139.351 272.877 139.351 272.884 139.351C273.134 139.374 273.353 139.507 273.415 139.765C273.47 139.984 273.376 140.288 273.15 140.39C273.111 140.405 273.072 140.429 273.033 140.444C272.892 140.64 272.72 140.757 272.455 140.843C272.174 140.936 271.814 140.819 271.619 140.608C271.424 140.39 271.221 140.179 271.026 139.96C270.705 139.609 270.698 139.062 271.049 138.726C271.377 138.414 271.971 138.39 272.283 138.75H272.291Z"
                fill="#F9BA06"/>
            <path
                d="M276.703 140.21C276.774 140.194 276.844 140.179 276.914 140.194C276.945 140.194 276.969 140.194 276.992 140.194C276.844 140.21 276.86 140.21 277.016 140.194C277.328 139.991 277.789 140.265 277.789 140.639C277.789 140.671 277.789 140.71 277.781 140.741C277.781 140.764 277.781 140.796 277.789 140.843C277.789 140.913 277.773 140.983 277.758 141.046C277.68 141.303 277.5 141.467 277.281 141.6C276.891 141.858 276.313 141.663 276.016 141.342C275.805 141.108 275.758 140.772 275.922 140.499C276.102 140.202 276.407 140.132 276.703 140.202V140.21Z"
                fill="#F9BA06"/>
            <path
                d="M277.328 139.835C277.523 139.835 277.672 139.937 277.773 140.077C277.789 140.085 277.797 140.093 277.804 140.108C277.984 140.257 278.031 140.561 277.968 140.772C277.883 141.069 277.609 141.28 277.305 141.28C276.633 141.28 276.406 140.429 276.891 140.046C276.992 139.921 277.156 139.843 277.32 139.843L277.328 139.835Z"
                fill="#F9BA06"/>
            <path
                d="M271.853 132.712C272.111 133.009 272.127 133.439 271.838 133.72C271.572 133.978 271.08 134.001 270.83 133.704C270.573 133.407 270.557 132.978 270.846 132.697C271.111 132.439 271.603 132.416 271.853 132.712Z"
                fill="#F9BA06"/>
            <path
                d="M260.045 128.425C260.646 129.12 259.623 130.119 259.014 129.417C258.413 128.721 259.436 127.722 260.045 128.425Z"
                fill="#F9BA06"/>
            <path
                d="M255.242 133.056C255.984 133.915 254.718 135.149 253.969 134.282C253.227 133.423 254.492 132.189 255.242 133.056Z"
                fill="#F9BA06"/>
            <g style={{mixBlendMode:"lighten"}} opacity="0.16">
                <path
                    d="M235.967 95.9974C235.232 95.154 233.858 96.2161 234.592 97.0674C235.326 97.9109 236.701 96.8487 235.967 95.9974Z"
                    fill="white"/>
                <path
                    d="M237.49 98.8638C237.677 98.5983 237.693 98.2468 237.49 97.9891C237.302 97.7548 236.927 97.622 236.646 97.7704C236.49 97.8485 236.365 97.9266 236.271 98.075C236.099 98.3483 236.068 98.6764 236.271 98.9419C236.466 99.1918 236.818 99.2934 237.115 99.1606C237.271 99.0903 237.396 99.0044 237.497 98.8638H237.49Z"
                    fill="white"/>
                <path
                    d="M239.926 98.9731C240.176 98.6451 240.192 98.1453 239.926 97.8173C239.793 97.6533 239.637 97.5283 239.442 97.4502C239.13 97.3252 238.856 97.3643 238.544 97.4502C237.989 97.5986 237.724 98.1843 237.771 98.7154C237.802 99.0747 238.06 99.4105 238.395 99.5511C238.802 99.7229 239.247 99.5979 239.528 99.2699C239.684 99.2074 239.825 99.1137 239.934 98.9731H239.926Z"
                    fill="white"/>
                <path
                    d="M235.982 101.363C235.896 101.019 235.756 100.762 235.412 100.613C234.85 100.371 234.194 100.754 234.108 101.355C234.037 101.863 234.405 102.347 234.912 102.417C235.162 102.449 235.42 102.386 235.623 102.23C235.857 102.042 236.06 101.668 235.982 101.363Z"
                    fill="white"/>
                <path
                    d="M235.49 99.0357C235.529 98.7623 235.451 98.4811 235.287 98.2625C235.084 97.9969 234.678 97.7861 234.334 97.872C234.311 97.872 234.295 97.8798 234.272 97.8876C233.827 97.9969 233.468 98.2703 233.397 98.7545C233.335 99.1997 233.585 99.6605 234.006 99.8245C234.022 99.8245 234.045 99.8401 234.061 99.8479C234.678 100.098 235.389 99.7073 235.482 99.0357H235.49Z"
                    fill="white"/>
                <path
                    d="M234.327 97.9422C234.444 97.6142 234.444 97.1846 234.194 96.9113C233.936 96.6301 233.694 96.5051 233.311 96.5208C233.288 96.5208 233.264 96.5208 233.241 96.5208C232.874 96.5364 232.569 96.8332 232.421 97.1456C232.241 97.5282 232.265 98.0281 232.53 98.3639C232.64 98.7076 233.061 98.8794 233.389 98.7232C233.811 98.6763 234.186 98.3483 234.327 97.95V97.9422Z"
                    fill="white"/>
                <path
                    d="M233.124 100.605C232.804 100.191 232.069 100.027 231.663 100.418C231.648 100.434 231.632 100.449 231.616 100.457C231.413 100.652 231.265 100.848 231.226 101.136C231.187 101.41 231.265 101.699 231.437 101.917C231.788 102.363 232.444 102.449 232.897 102.105C232.913 102.089 232.929 102.082 232.944 102.066C233.389 101.722 233.475 101.051 233.132 100.605H233.124Z"
                    fill="white"/>
                <path
                    d="M234.928 103.589C235.053 103.3 235.006 102.956 234.811 102.698C234.459 102.245 233.764 102.253 233.413 102.698C233.389 102.73 233.366 102.761 233.342 102.792C233.1 103.097 233.108 103.542 233.342 103.847C233.553 104.12 234.03 104.307 234.358 104.112C234.389 104.096 234.42 104.073 234.459 104.057C234.67 103.932 234.834 103.815 234.928 103.581V103.589Z"
                    fill="white"/>
                <path
                    d="M238.825 102.558C238.607 102.308 238.38 102.144 238.044 102.113C237.654 102.074 237.185 102.339 237.162 102.777C237.146 103.112 237.193 103.386 237.419 103.651C237.74 104.018 238.271 104.12 238.669 103.808C239.036 103.526 239.145 102.925 238.825 102.558Z"
                    fill="white"/>
                <path
                    d="M240.551 100.426C240.551 100.426 240.527 100.395 240.52 100.379C240.254 99.965 239.614 99.9337 239.254 100.215C238.77 100.59 238.825 101.269 239.254 101.644C239.27 101.66 239.286 101.668 239.301 101.683C239.606 101.941 240.09 101.917 240.395 101.683C240.801 101.363 240.816 100.84 240.551 100.426Z"
                    fill="white"/>
                <path
                    d="M241.129 102.464C240.379 101.597 238.95 102.683 239.707 103.565C240.457 104.432 241.886 103.339 241.129 102.464Z"
                    fill="white"/>
                <path
                    d="M236.787 107.65C236.787 107.291 236.662 106.955 236.302 106.807C236.107 106.721 235.912 106.736 235.732 106.807C235.607 106.83 235.49 106.869 235.381 106.955C235.147 107.135 235.029 107.431 235.061 107.728C235.131 108.283 235.709 108.587 236.217 108.392C236.505 108.283 236.771 107.978 236.779 107.658L236.787 107.65Z"
                    fill="white"/>
                <path
                    d="M235.014 107.916C235.03 107.767 235.03 107.627 235.03 107.478C235.03 107.213 234.826 106.986 234.639 106.83C234.42 106.643 234.092 106.627 233.827 106.728C233.405 106.892 233.257 107.244 233.171 107.658C233.069 108.158 233.491 108.642 233.967 108.704C234.467 108.775 234.951 108.415 235.022 107.908L235.014 107.916Z"
                    fill="white"/>
                <path
                    d="M232.311 104.885C232.311 104.885 232.366 104.831 232.389 104.799C232.678 104.487 232.632 103.94 232.389 103.62C232.054 103.191 231.468 103.151 231.038 103.448C231.007 103.472 230.976 103.495 230.945 103.519C230.507 103.823 230.46 104.487 230.773 104.893C230.929 105.096 231.163 105.229 231.413 105.26C231.78 105.315 232.077 105.151 232.327 104.885H232.311Z"
                    fill="white"/>
                <path
                    d="M231.468 104.448C231.702 103.995 231.406 103.409 230.968 103.222C230.836 103.167 230.695 103.152 230.562 103.159C230.507 103.159 230.453 103.152 230.39 103.159C230.461 103.159 230.515 103.159 230.554 103.159C230.515 103.159 230.461 103.159 230.383 103.159C229.937 103.214 229.469 103.714 229.602 104.198C229.656 104.385 229.672 104.526 229.805 104.682C229.922 104.815 230.039 104.924 230.211 104.987C230.742 105.19 231.226 104.932 231.476 104.456L231.468 104.448Z"
                    fill="white"/>
                <path
                    d="M230.421 101.371C230.617 101.168 230.773 100.996 230.921 100.738C230.999 100.605 231.062 100.504 231.085 100.34C231.124 100.027 230.999 99.6917 230.749 99.4964C230.023 98.9419 229.351 99.6995 228.961 100.246C228.664 100.652 228.727 101.23 229.133 101.543C229.328 101.691 229.57 101.761 229.812 101.722C230.078 101.691 230.242 101.55 230.421 101.371Z"
                    fill="white"/>
                <path
                    d="M231.577 98.1297C231.53 98.0438 231.475 97.9657 231.429 97.8797C231.179 97.4658 230.538 97.4502 230.187 97.7235C229.726 98.0828 229.757 98.7623 230.187 99.1215C230.257 99.184 230.327 99.2387 230.405 99.3012C230.694 99.5433 231.14 99.5198 231.429 99.3012C231.819 98.9966 231.819 98.5202 231.577 98.1297Z"
                    fill="white"/>
                <path
                    d="M228.165 101.144C228.032 100.832 227.657 100.582 227.306 100.652C227.165 100.683 227.04 100.707 226.892 100.707C226.665 100.707 226.415 100.887 226.282 101.058C226.087 101.316 226.04 101.668 226.165 101.972C226.376 102.48 227.134 102.753 227.571 102.339C227.673 102.245 227.79 102.175 227.907 102.105C228.211 101.917 228.29 101.449 228.157 101.144H228.165Z"
                    fill="white"/>
                <path
                    d="M225.4 104.042C225.587 103.847 225.736 103.644 225.751 103.355C225.775 102.847 225.486 102.324 224.931 102.269C224.728 102.246 224.541 102.253 224.353 102.331C224.205 102.394 224.08 102.495 223.955 102.589C223.502 102.917 223.439 103.604 223.775 104.034C223.939 104.245 224.181 104.385 224.447 104.425C224.845 104.479 225.142 104.3 225.408 104.034L225.4 104.042Z"
                    fill="white"/>
                <path
                    d="M227.422 104.706C227.36 104.198 226.969 103.854 226.485 103.761C226.227 103.714 225.993 103.667 225.728 103.722C225.212 103.831 224.79 104.182 224.712 104.729C224.665 105.042 224.751 105.37 224.947 105.612C225.118 105.83 225.431 106.049 225.72 106.057C226.001 106.057 226.212 106.08 226.477 105.971L226.766 105.854C227.219 105.674 227.493 105.174 227.43 104.698L227.422 104.706Z"
                    fill="white"/>
                <path
                    d="M229.274 104.237C229.172 103.987 229.047 103.761 228.821 103.604C228.266 103.229 227.563 103.518 227.438 104.174C227.383 104.456 227.462 104.706 227.563 104.963C227.758 105.432 228.321 105.651 228.781 105.455C229.25 105.26 229.469 104.706 229.274 104.237Z"
                    fill="white"/>
                <path
                    d="M230.593 105.221C230.406 104.768 229.883 104.573 229.438 104.753C229.227 104.838 229.024 104.917 228.821 105.034C228.61 105.151 228.446 105.276 228.352 105.502C228.258 105.721 228.258 105.971 228.352 106.19C228.547 106.65 229.055 106.822 229.508 106.658C229.727 106.58 229.922 106.486 230.125 106.377C230.554 106.158 230.789 105.69 230.593 105.221Z"
                    fill="white"/>
                <path
                    d="M230.023 109.017C230.304 108.353 229.859 107.689 229.195 107.556C229 107.517 228.812 107.47 228.609 107.47C228.437 107.447 228.266 107.47 228.102 107.541C227.875 107.634 227.688 107.822 227.586 108.056C227.422 108.439 227.532 108.946 227.891 109.196C228.125 109.36 228.375 109.478 228.641 109.587C229.172 109.821 229.804 109.548 230.031 109.025L230.023 109.017Z"
                    fill="white"/>
                <path
                    d="M228.242 109.485C228.203 109.345 228.188 109.204 228.149 109.064C228.117 108.962 228.031 108.845 227.977 108.751C227.969 108.728 227.953 108.712 227.938 108.697C228.086 108.408 228.086 108.072 227.86 107.783C227.571 107.416 227.001 107.322 226.626 107.627C226.485 107.744 226.337 107.861 226.188 107.986C225.845 108.267 225.681 108.884 226.032 109.243C226.274 109.485 226.509 109.751 226.821 109.907C226.93 109.962 227.04 110.016 227.149 110.071C227.235 110.102 227.329 110.126 227.414 110.141C227.61 110.118 227.664 110.118 227.571 110.157C227.914 110.227 228.328 109.86 228.235 109.501L228.242 109.485Z"
                    fill="white"/>
                <path
                    d="M230.195 110.735C230.343 110.368 230.289 109.946 230.054 109.642C229.797 109.314 229.445 109.22 229.047 109.228C228.641 109.228 228.321 109.493 228.008 109.72C227.493 110.079 227.43 110.868 227.805 111.344C228.219 111.875 228.906 111.899 229.43 111.547C229.531 111.485 229.625 111.414 229.726 111.352C229.953 111.204 230.101 110.977 230.203 110.735H230.195Z"
                    fill="white"/>
                <path
                    d="M231.749 110.048C231.843 109.829 231.905 109.618 231.866 109.376C231.78 108.79 231.077 108.486 230.601 108.861C230.413 109.009 230.304 109.196 230.218 109.407C230.054 109.829 230.242 110.313 230.663 110.493C231.077 110.665 231.577 110.469 231.749 110.048Z"
                    fill="white"/>
                <path
                    d="M233.327 107.845C233.459 107.509 233.35 107.181 233.085 106.947C232.788 106.682 232.311 106.682 231.975 106.869C231.647 107.049 231.429 107.338 231.483 107.736C231.53 108.072 231.827 108.423 232.186 108.447C232.413 108.454 232.616 108.462 232.827 108.369C233.053 108.267 233.233 108.087 233.327 107.853V107.845Z"
                    fill="white"/>
                <path
                    d="M236.505 106.486C236.654 106.307 236.771 106.15 236.802 105.908C236.826 105.721 236.779 105.526 236.662 105.369C236.49 105.151 236.263 105.096 236.006 105.096C235.771 105.096 235.584 105.245 235.396 105.362C235.029 105.596 235.006 106.166 235.256 106.479C235.584 106.9 236.185 106.861 236.513 106.479L236.505 106.486Z"
                    fill="white"/>
                <path
                    d="M238.7 106.229C238.7 106.229 238.731 106.19 238.747 106.174C239.012 105.846 239.005 105.338 238.747 105.01C238.599 104.815 238.372 104.682 238.13 104.651C237.849 104.612 237.646 104.706 237.419 104.838C237.396 104.854 237.38 104.862 237.357 104.878C236.904 105.143 236.896 105.854 237.185 106.229C237.575 106.728 238.325 106.705 238.716 106.229H238.7Z"
                    fill="white"/>
                <path
                    d="M237.154 103.315C237.201 103.191 237.247 103.073 237.263 102.941C237.302 102.566 237.037 102.097 236.615 102.082C236.482 102.082 236.365 102.082 236.232 102.105C235.92 102.175 235.67 102.331 235.537 102.636C235.412 102.925 235.459 103.269 235.654 103.511C236.053 104.018 236.919 103.932 237.154 103.315Z"
                    fill="white"/>
                <path
                    d="M238.864 96.7941C238.934 96.2943 238.575 95.81 238.067 95.7398C237.841 95.7085 237.661 95.7398 237.45 95.8179C237.068 95.9506 236.841 96.4036 236.896 96.7863C236.958 97.2315 237.278 97.4736 237.685 97.5829C237.81 97.6142 237.942 97.6142 238.067 97.5829C238.473 97.4892 238.809 97.2237 238.872 96.7863L238.864 96.7941Z"
                    fill="white"/>
                <path
                    d="M239.957 94.5215C239.957 94.459 239.95 94.3965 239.942 94.334C239.918 93.967 239.582 93.6702 239.231 93.6233C238.755 93.5608 238.388 93.8889 238.294 94.334L238.255 94.5215C238.153 94.9745 238.552 95.4274 238.981 95.4899C239.559 95.568 239.989 95.076 239.95 94.5293L239.957 94.5215Z"
                    fill="white"/>
                <path
                    d="M238.817 94.0138C238.864 93.6545 238.552 93.3343 238.192 93.3812C238.13 93.389 238.067 93.3968 238.005 93.4046C237.778 93.4358 237.591 93.6155 237.56 93.8498C237.528 94.0606 237.653 94.334 237.872 94.3965C237.927 94.4121 237.989 94.4277 238.044 94.4433C238.388 94.5449 238.762 94.3965 238.817 94.006V94.0138Z"
                    fill="white"/>
                <path
                    d="M242.449 105.924C242.449 105.885 242.433 105.846 242.426 105.799C242.355 105.44 242.105 105.151 241.73 105.096C241.387 105.049 240.981 105.245 240.864 105.588C240.848 105.627 240.84 105.666 240.824 105.705C240.645 106.221 240.918 106.799 241.488 106.877C242.012 106.947 242.55 106.471 242.449 105.924Z"
                    fill="white"/>
                <path
                    d="M243.027 102.972C242.926 102.691 242.848 102.394 242.582 102.222C242.168 101.957 241.637 102.058 241.426 102.527C241.285 102.831 241.371 103.097 241.442 103.401C241.551 103.839 241.887 104.182 242.371 104.112C242.926 104.042 243.207 103.472 243.027 102.964V102.972Z"
                    fill="white"/>
                <path
                    d="M244.3 104.182C244.222 103.761 243.902 103.487 243.464 103.526C243.113 103.55 242.816 103.862 242.769 104.198C242.707 104.69 243.175 105.104 243.644 104.987C243.66 104.987 243.667 104.987 243.683 104.987C244.05 104.94 244.37 104.565 244.3 104.182Z"
                    fill="white"/>
                <path
                    d="M244.183 107.119C244.339 106.752 244.167 106.229 243.769 106.096C243.48 106.002 243.191 105.908 242.91 105.807C242.504 105.674 242.051 105.807 241.879 106.229C241.723 106.596 241.887 107.135 242.3 107.26C242.589 107.353 242.878 107.439 243.167 107.533C243.574 107.658 244.011 107.533 244.191 107.119H244.183Z"
                    fill="white"/>
                <path
                    d="M239.754 107.213C239.544 106.939 239.278 106.752 238.919 106.768C238.606 106.783 238.38 107.119 238.411 107.4C238.427 107.541 238.544 107.65 238.677 107.666C238.723 107.666 238.77 107.666 238.809 107.658C238.911 107.962 239.2 108.173 239.536 108.033C239.856 107.9 239.965 107.478 239.754 107.205V107.213Z"
                    fill="white"/>
                <path
                    d="M238.505 109.501C238.442 109.431 238.372 109.36 238.31 109.29C237.95 108.915 237.412 108.783 236.974 109.118C236.568 109.431 236.482 110.048 236.802 110.454C236.865 110.532 236.92 110.602 236.982 110.68C237.31 111.094 237.919 111.172 238.333 110.852C238.716 110.555 238.864 109.884 238.505 109.501Z"
                    fill="white"/>
                <path
                    d="M235.568 109.056C235.451 108.993 235.334 108.931 235.217 108.868C234.951 108.704 234.576 108.931 234.709 109.259C234.771 109.415 234.834 109.579 234.889 109.743C234.998 110.063 235.435 110.149 235.685 109.954C236.013 109.704 235.912 109.236 235.568 109.064V109.056Z"
                    fill="white"/>
                <path
                    d="M233.959 109.321C234.131 108.915 233.928 108.47 233.537 108.291C233.053 108.064 232.546 108.33 232.319 108.783C232.093 109.236 232.382 109.931 232.928 109.97C233.155 109.985 233.342 109.915 233.483 109.767C233.694 109.681 233.881 109.517 233.959 109.321Z"
                    fill="white"/>
                <path
                    d="M232.569 111.813C232.593 111.735 232.632 111.649 232.64 111.571C232.647 111.493 232.64 111.414 232.64 111.336C232.655 111.274 232.655 111.211 232.64 111.133C232.624 111.032 232.577 110.915 232.546 110.813C232.429 110.438 231.937 110.29 231.601 110.43C231.273 110.571 231.023 111.032 231.218 111.375C231.32 111.555 231.413 111.696 231.562 111.805C231.671 111.914 231.773 112 231.921 112.071C232.148 112.172 232.483 112.055 232.569 111.805V111.813Z"
                    fill="white"/>
                <path
                    d="M235.326 112.976C234.982 112.586 234.412 112.477 233.983 112.805C233.584 113.109 233.467 113.75 233.811 114.148C234.155 114.538 234.725 114.648 235.154 114.32C235.552 114.015 235.67 113.375 235.326 112.976Z"
                    fill="white"/>
                <path
                    d="M237.044 112.649C236.943 112.531 236.849 112.406 236.724 112.328C236.466 112.172 236.177 112.133 235.927 112.328C235.693 112.508 235.615 112.828 235.724 113.094C235.787 113.242 235.881 113.351 235.982 113.469C236.224 113.75 236.623 113.82 236.927 113.586C237.208 113.367 237.286 112.922 237.044 112.641V112.649Z"
                    fill="white"/>
                <path
                    d="M238.739 113.109C239.075 113.015 239.333 112.758 239.387 112.375C239.419 112.133 239.356 111.883 239.208 111.688C238.989 111.407 238.7 111.344 238.372 111.344C237.997 111.344 237.575 111.61 237.388 111.93C237.232 112.195 237.115 112.523 237.271 112.828C237.294 112.891 237.294 112.859 237.263 112.734C237.286 112.812 237.302 112.891 237.325 112.969C237.372 113.148 237.536 113.32 237.677 113.429C237.864 113.578 238.13 113.609 238.349 113.515C238.528 113.437 238.645 113.304 238.708 113.133C238.716 113.125 238.731 113.117 238.739 113.101V113.109Z"
                    fill="white"/>
                <path
                    d="M238.138 114.484C237.927 113.976 237.255 113.648 236.732 113.914L236.349 114.109C235.951 114.312 235.639 114.726 235.725 115.195C235.764 115.413 235.81 115.507 235.935 115.694C236.209 116.101 236.732 116.14 237.154 116.015C237.287 115.976 237.427 115.929 237.568 115.89C238.13 115.718 238.349 114.984 238.138 114.484Z"
                    fill="white"/>
                <path
                    d="M235.209 115.257C235.108 115.194 235.006 115.132 234.905 115.07C234.616 114.89 234.202 115.14 234.358 115.491C234.405 115.593 234.452 115.702 234.491 115.804C234.608 116.077 234.967 116.179 235.201 115.991C235.42 115.819 235.459 115.413 235.201 115.257H235.209Z"
                    fill="white"/>
                <path
                    d="M232.569 113.453C231.812 112.586 230.39 113.672 231.148 114.554C231.897 115.421 233.327 114.328 232.569 113.453Z"
                    fill="white"/>
                <path
                    d="M232.187 112.625C232.288 112.375 232.171 112.07 231.921 111.969C231.624 111.844 231.218 111.914 231.039 112.203C230.875 112.453 230.843 112.781 231.039 113.023C231.234 113.265 231.554 113.164 231.656 112.93C231.874 112.969 232.108 112.812 232.194 112.625H232.187Z"
                    fill="white"/>
                <path
                    d="M232.811 115.523C232.616 115.39 232.413 115.351 232.187 115.296C231.819 115.21 231.452 115.538 231.406 115.89C231.359 116.249 231.624 116.639 231.999 116.671C232.062 116.671 232.132 116.686 232.194 116.686C232.366 116.702 232.499 116.678 232.671 116.632C233.139 116.499 233.186 115.78 232.811 115.523Z"
                    fill="white"/>
                <path
                    d="M235.607 118.6C235.607 118.6 235.67 118.529 235.701 118.498C235.974 118.209 235.927 117.694 235.701 117.405C235.396 117.006 234.842 116.96 234.443 117.249C234.404 117.28 234.365 117.303 234.326 117.334C233.92 117.623 233.873 118.233 234.162 118.615C234.311 118.803 234.521 118.928 234.756 118.959C235.107 119.006 235.373 118.85 235.599 118.607L235.607 118.6Z"
                    fill="white"/>
                <path
                    d="M239.044 117.116C238.903 116.819 238.716 116.678 238.395 116.608C237.927 116.499 237.56 116.983 237.591 117.405C237.614 117.647 237.732 117.85 237.927 117.983C238.2 118.17 238.544 118.194 238.817 117.983C239.067 117.787 239.184 117.413 239.044 117.124V117.116Z"
                    fill="white"/>
                <path
                    d="M236.248 112.203C236.38 112.024 236.466 111.797 236.427 111.571C236.412 111.469 236.498 111.625 236.365 111.321C236.326 111.258 236.287 111.188 236.248 111.126C236.216 111.094 236.193 111.071 236.17 111.047C235.99 110.805 235.717 110.665 235.373 110.712C234.928 110.766 234.506 111.235 234.615 111.703C234.678 111.969 234.772 112.211 235.006 112.367C235.396 112.625 235.943 112.617 236.24 112.203H236.248Z"
                    fill="white"/>
                <path
                    d="M235.459 111.782C235.56 111.735 235.646 111.657 235.693 111.54C235.803 111.29 235.678 110.977 235.42 110.876C235.271 110.813 235.139 110.798 234.975 110.798C234.936 110.798 234.803 110.79 234.959 110.798C234.881 110.79 234.795 110.79 234.725 110.805C234.67 110.821 234.615 110.837 234.561 110.852C234.498 110.876 234.506 110.876 234.592 110.837C234.459 110.837 234.295 110.915 234.194 110.993C234.045 111.102 233.928 111.29 233.873 111.454C233.694 111.953 233.967 112.516 234.522 112.586C234.99 112.649 235.482 112.25 235.459 111.766V111.782Z"
                    fill="white"/>
                <path
                    d="M242.504 109.603L242.426 109.509C242.121 109.157 241.598 109.056 241.215 109.353C240.856 109.626 240.754 110.212 241.059 110.563L241.137 110.657C241.441 111.008 241.965 111.11 242.347 110.813C242.707 110.54 242.808 109.954 242.504 109.603Z"
                    fill="white"/>
                <path
                    d="M241.753 112.43C241.582 112.024 241.082 111.813 240.676 111.992C240.355 112.133 239.957 112.25 239.754 112.547C239.426 113.023 239.653 113.75 240.277 113.82C240.668 113.867 240.98 113.671 241.324 113.515C241.73 113.328 241.941 112.859 241.761 112.438L241.753 112.43Z"
                    fill="white"/>
                <path
                    d="M241.441 111.125C241.418 110.993 241.402 110.852 241.379 110.719C241.355 110.548 241.277 110.415 241.238 110.251C241.191 110.04 241.059 109.876 240.832 109.845C240.59 109.813 240.324 109.993 240.301 110.251C240.285 110.407 240.27 110.516 240.239 110.649C240.207 110.797 240.223 110.969 240.223 111.125C240.223 111.461 240.387 111.766 240.746 111.821C241.137 111.875 241.496 111.532 241.441 111.133V111.125Z"
                    fill="white"/>
                <path
                    d="M241.801 108.806C242.035 108.493 241.98 108.041 241.668 107.806C241.34 107.556 240.926 107.65 240.668 107.939C240.387 108.244 240.2 108.861 240.598 109.165C241.012 109.478 241.543 109.142 241.801 108.806Z"
                    fill="white"/>
                <path
                    d="M244.378 111.68C244.542 111.516 244.667 111.36 244.706 111.118C244.737 110.891 244.675 110.649 244.534 110.469C244.245 110.095 243.698 110.032 243.316 110.313C243.3 110.329 243.284 110.337 243.269 110.352C242.894 110.641 242.824 111.204 243.113 111.571C243.378 111.914 243.995 112.055 244.331 111.727C244.347 111.711 244.362 111.696 244.378 111.688V111.68Z"
                    fill="white"/>
                <path
                    d="M246.58 110.173C246.549 109.931 246.494 109.696 246.401 109.47C246.127 108.759 245.026 108.915 244.94 109.665C244.909 109.915 244.917 110.149 244.94 110.391C244.995 110.837 245.424 111.157 245.862 111.102C246.307 111.04 246.627 110.626 246.572 110.18L246.58 110.173Z"
                    fill="white"/>
                <path
                    d="M246.729 107.275C246.947 106.986 246.932 106.471 246.596 106.268C246.408 106.151 246.213 106.002 246.01 105.924C245.713 105.807 245.37 105.885 245.174 106.143C244.925 106.471 245.018 106.877 245.307 107.135C245.424 107.244 245.581 107.322 245.721 107.408C246.057 107.619 246.479 107.611 246.729 107.275Z"
                    fill="white"/>
                <path
                    d="M244.971 105.541C244.893 105.362 244.776 105.276 244.612 105.182C244.175 104.932 243.573 105.424 243.675 105.901C243.722 106.127 243.823 106.338 244.058 106.432C244.065 106.432 244.073 106.432 244.081 106.44C244.636 106.65 245.206 106.111 244.971 105.549V105.541Z"
                    fill="white"/>
                <path
                    d="M246.307 108.806C246.525 108.525 246.51 108.158 246.346 107.853C246.197 107.588 245.979 107.408 245.752 107.213C245.393 106.9 244.807 107.017 244.534 107.369C244.222 107.775 244.346 108.267 244.698 108.587C244.854 108.736 245.003 108.907 245.206 108.993C245.588 109.157 246.033 109.15 246.307 108.798V108.806Z"
                    fill="white"/>
                <path
                    d="M249.275 107.299C248.58 106.494 247.26 107.502 247.963 108.314C248.658 109.118 249.978 108.111 249.275 107.299Z"
                    fill="white"/>
                <path
                    d="M250.602 113.617C249.868 112.773 248.494 113.836 249.228 114.687C249.962 115.53 251.336 114.468 250.602 113.617Z"
                    fill="white"/>
                <path
                    d="M252.133 116.491C252.321 116.225 252.336 115.874 252.133 115.616C251.946 115.374 251.571 115.249 251.29 115.398C251.134 115.476 251.009 115.554 250.915 115.702C250.743 115.975 250.712 116.303 250.915 116.569C251.11 116.819 251.47 116.92 251.758 116.788C251.915 116.717 252.04 116.632 252.141 116.491H252.133Z"
                    fill="white"/>
                <path
                    d="M254.562 116.6C254.812 116.272 254.828 115.772 254.562 115.444C254.429 115.28 254.273 115.155 254.078 115.077C253.765 114.952 253.492 114.991 253.18 115.077C252.625 115.226 252.36 115.811 252.406 116.343C252.438 116.702 252.695 117.038 253.031 117.178C253.437 117.35 253.882 117.225 254.164 116.897C254.32 116.835 254.46 116.741 254.57 116.6H254.562Z"
                    fill="white"/>
                <path
                    d="M250.626 118.982C250.532 118.639 250.399 118.381 250.056 118.233C249.494 117.99 248.837 118.373 248.752 118.974C248.681 119.482 249.048 119.966 249.556 120.037C249.806 120.068 250.064 120.005 250.267 119.849C250.501 119.662 250.704 119.287 250.626 118.982Z"
                    fill="white"/>
                <path
                    d="M250.134 116.663C250.173 116.389 250.095 116.108 249.923 115.89C249.72 115.624 249.314 115.413 248.97 115.499C248.947 115.499 248.931 115.507 248.908 115.515C248.463 115.624 248.104 115.897 248.033 116.382C247.971 116.827 248.228 117.288 248.642 117.452C248.658 117.452 248.681 117.467 248.697 117.475C249.314 117.725 250.025 117.334 250.118 116.663H250.134Z"
                    fill="white"/>
                <path
                    d="M248.97 115.562C249.087 115.234 249.087 114.804 248.837 114.531C248.58 114.249 248.338 114.125 247.955 114.14C247.931 114.14 247.908 114.14 247.885 114.14C247.517 114.156 247.213 114.453 247.064 114.765C246.885 115.148 246.908 115.647 247.174 115.983C247.291 116.327 247.705 116.499 248.033 116.343C248.455 116.296 248.83 115.968 248.97 115.569V115.562Z"
                    fill="white"/>
                <path
                    d="M247.767 118.233C247.447 117.819 246.713 117.655 246.307 118.045C246.291 118.061 246.276 118.076 246.26 118.084C246.057 118.279 245.909 118.475 245.869 118.764C245.83 119.037 245.909 119.326 246.08 119.545C246.424 119.99 247.088 120.076 247.541 119.732C247.556 119.716 247.572 119.709 247.588 119.693C248.033 119.349 248.119 118.678 247.775 118.233H247.767Z"
                    fill="white"/>
                <path
                    d="M249.571 121.216C249.696 120.927 249.649 120.583 249.454 120.333C249.103 119.88 248.408 119.888 248.056 120.333C248.033 120.365 248.009 120.396 247.986 120.427C247.744 120.732 247.751 121.177 247.986 121.481C248.197 121.755 248.673 121.942 249.001 121.747C249.032 121.731 249.064 121.708 249.103 121.692C249.314 121.567 249.478 121.45 249.571 121.224V121.216Z"
                    fill="white"/>
                <path
                    d="M253.461 120.185C253.243 119.935 253.016 119.771 252.68 119.74C252.29 119.701 251.821 119.966 251.798 120.404C251.782 120.74 251.829 121.013 252.055 121.271C252.376 121.638 252.907 121.739 253.305 121.427C253.672 121.138 253.781 120.544 253.461 120.177V120.185Z"
                    fill="white"/>
                <path
                    d="M255.187 118.053C255.187 118.053 255.163 118.022 255.156 118.006C254.89 117.592 254.25 117.561 253.89 117.842C253.406 118.217 253.461 118.896 253.89 119.271C253.906 119.287 253.922 119.295 253.937 119.31C254.242 119.568 254.726 119.545 255.031 119.31C255.437 118.99 255.452 118.467 255.187 118.053Z"
                    fill="white"/>
                <path
                    d="M255.765 120.091C255.007 119.225 253.586 120.318 254.343 121.193C255.093 122.06 256.522 120.974 255.765 120.091Z"
                    fill="white"/>
                <path
                    d="M251.43 125.277C251.43 124.91 251.305 124.582 250.954 124.434C250.759 124.348 250.564 124.363 250.384 124.434C250.259 124.457 250.142 124.496 250.032 124.582C249.798 124.762 249.681 125.059 249.712 125.355C249.783 125.91 250.36 126.214 250.868 126.019C251.157 125.91 251.423 125.605 251.43 125.285V125.277Z"
                    fill="white"/>
                <path
                    d="M249.65 125.543C249.665 125.394 249.665 125.254 249.665 125.105C249.665 124.84 249.462 124.613 249.275 124.457C249.056 124.27 248.728 124.254 248.463 124.356C248.033 124.52 247.892 124.871 247.806 125.285C247.705 125.785 248.127 126.269 248.603 126.332C249.103 126.402 249.587 126.043 249.65 125.535V125.543Z"
                    fill="white"/>
                <path
                    d="M246.948 122.512C246.948 122.512 247.002 122.458 247.026 122.426C247.315 122.114 247.268 121.567 247.026 121.247C246.69 120.818 246.104 120.779 245.675 121.075C245.643 121.099 245.612 121.122 245.573 121.146C245.136 121.45 245.089 122.114 245.401 122.52C245.557 122.723 245.792 122.856 246.042 122.887C246.416 122.942 246.705 122.778 246.955 122.512H246.948Z"
                    fill="white"/>
                <path
                    d="M246.104 122.075C246.338 121.622 246.041 121.036 245.604 120.849C245.471 120.794 245.331 120.779 245.19 120.786C245.135 120.786 245.081 120.786 245.018 120.786C245.089 120.786 245.143 120.786 245.182 120.786C245.143 120.786 245.089 120.786 245.01 120.786C244.565 120.841 244.097 121.341 244.229 121.825C244.276 122.013 244.3 122.153 244.433 122.309C244.55 122.442 244.667 122.552 244.839 122.614C245.37 122.817 245.854 122.559 246.104 122.083V122.075Z"
                    fill="white"/>
                <path
                    d="M245.057 118.99C245.252 118.787 245.409 118.615 245.557 118.358C245.635 118.225 245.698 118.123 245.721 117.959C245.76 117.647 245.635 117.311 245.385 117.116C244.667 116.561 243.995 117.319 243.597 117.866C243.3 118.272 243.362 118.85 243.768 119.162C243.964 119.31 244.206 119.373 244.448 119.342C244.713 119.303 244.877 119.17 245.057 118.99Z"
                    fill="white"/>
                <path
                    d="M246.213 115.749C246.166 115.663 246.112 115.585 246.065 115.499C245.815 115.085 245.174 115.069 244.823 115.343C244.362 115.702 244.393 116.382 244.823 116.741C244.893 116.803 244.971 116.858 245.042 116.92C245.331 117.163 245.776 117.139 246.065 116.92C246.455 116.616 246.447 116.132 246.213 115.749Z"
                    fill="white"/>
                <path
                    d="M242.8 118.771C242.668 118.459 242.293 118.201 241.941 118.279C241.801 118.311 241.676 118.334 241.535 118.334C241.309 118.334 241.059 118.514 240.926 118.685C240.731 118.943 240.684 119.295 240.809 119.599C241.02 120.107 241.777 120.38 242.215 119.966C242.316 119.873 242.425 119.802 242.55 119.732C242.863 119.545 242.933 119.076 242.8 118.771Z"
                    fill="white"/>
                <path
                    d="M240.043 121.661C240.231 121.466 240.379 121.263 240.395 120.974C240.418 120.466 240.129 119.943 239.575 119.888C239.364 119.865 239.184 119.873 238.997 119.951C238.848 120.013 238.723 120.115 238.599 120.209C238.146 120.537 238.075 121.224 238.419 121.653C238.583 121.864 238.825 122.005 239.091 122.044C239.489 122.099 239.786 121.919 240.051 121.653L240.043 121.661Z"
                    fill="white"/>
                <path
                    d="M242.058 122.333C241.996 121.825 241.605 121.482 241.121 121.388C240.864 121.341 240.629 121.294 240.364 121.349C239.848 121.458 239.426 121.81 239.348 122.356C239.309 122.669 239.395 122.989 239.583 123.239C239.747 123.457 240.067 123.684 240.356 123.684C240.637 123.684 240.848 123.707 241.113 123.598L241.402 123.481C241.855 123.301 242.129 122.801 242.066 122.325L242.058 122.333Z"
                    fill="white"/>
                <path
                    d="M243.909 121.856C243.808 121.606 243.683 121.38 243.456 121.224C242.902 120.849 242.199 121.138 242.074 121.794C242.019 122.075 242.097 122.325 242.199 122.583C242.394 123.051 242.956 123.27 243.417 123.075C243.886 122.88 244.104 122.325 243.909 121.856Z"
                    fill="white"/>
                <path
                    d="M245.237 122.848C245.05 122.395 244.526 122.2 244.081 122.38C243.87 122.466 243.667 122.544 243.464 122.661C243.253 122.778 243.089 122.903 242.996 123.13C242.902 123.348 242.902 123.598 242.996 123.817C243.191 124.278 243.698 124.449 244.151 124.285C244.37 124.207 244.565 124.114 244.768 124.004C245.198 123.786 245.44 123.317 245.237 122.848Z"
                    fill="white"/>
                <path
                    d="M244.667 126.644C244.948 125.98 244.503 125.316 243.839 125.183C243.644 125.144 243.457 125.098 243.254 125.098C243.074 125.074 242.91 125.098 242.746 125.168C242.519 125.262 242.332 125.449 242.23 125.683C242.066 126.066 242.176 126.574 242.535 126.824C242.769 126.988 243.019 127.105 243.285 127.214C243.816 127.448 244.448 127.175 244.675 126.652L244.667 126.644Z"
                    fill="white"/>
                <path
                    d="M242.878 127.113C242.839 126.972 242.823 126.831 242.784 126.691C242.753 126.589 242.667 126.472 242.613 126.378C242.605 126.363 242.589 126.339 242.573 126.324C242.722 126.035 242.722 125.699 242.495 125.41C242.206 125.043 241.636 124.949 241.261 125.254C241.113 125.371 240.972 125.488 240.824 125.613C240.48 125.894 240.316 126.511 240.668 126.87C240.91 127.12 241.144 127.378 241.457 127.534C241.566 127.589 241.675 127.644 241.785 127.698C241.871 127.73 241.964 127.753 242.05 127.769C242.245 127.745 242.3 127.745 242.206 127.784C242.55 127.854 242.964 127.487 242.87 127.128L242.878 127.113Z"
                    fill="white"/>
                <path
                    d="M244.839 128.362C244.987 128.003 244.932 127.573 244.698 127.269C244.44 126.941 244.089 126.847 243.691 126.855C243.284 126.855 242.964 127.12 242.652 127.347C242.136 127.706 242.074 128.495 242.449 128.971C242.863 129.502 243.55 129.526 244.073 129.174C244.175 129.112 244.276 129.042 244.37 128.979C244.597 128.831 244.745 128.604 244.846 128.362H244.839Z"
                    fill="white"/>
                <path
                    d="M246.385 127.667C246.471 127.441 246.541 127.238 246.502 126.995C246.416 126.41 245.721 126.105 245.237 126.472C245.049 126.621 244.94 126.808 244.854 127.019C244.682 127.441 244.878 127.925 245.299 128.105C245.713 128.276 246.221 128.081 246.385 127.659V127.667Z"
                    fill="white"/>
                <path
                    d="M247.971 125.465C248.103 125.129 247.994 124.801 247.736 124.567C247.44 124.301 246.963 124.301 246.627 124.488C246.299 124.668 246.081 124.957 246.135 125.355C246.182 125.691 246.479 126.043 246.838 126.066C247.065 126.082 247.268 126.082 247.479 125.988C247.705 125.886 247.885 125.707 247.978 125.472L247.971 125.465Z"
                    fill="white"/>
                <path
                    d="M251.142 124.114C251.29 123.934 251.407 123.778 251.438 123.536C251.462 123.348 251.415 123.153 251.298 122.997C251.126 122.778 250.899 122.723 250.642 122.723C250.407 122.723 250.22 122.872 250.033 122.989C249.666 123.223 249.642 123.786 249.892 124.106C250.22 124.528 250.821 124.488 251.149 124.106L251.142 124.114Z"
                    fill="white"/>
                <path
                    d="M253.344 123.856C253.344 123.856 253.375 123.817 253.391 123.801C253.656 123.473 253.648 122.965 253.391 122.637C253.242 122.442 253.016 122.309 252.774 122.278C252.492 122.239 252.289 122.333 252.063 122.466C252.039 122.481 252.024 122.489 252 122.505C251.547 122.778 251.54 123.481 251.829 123.856C252.219 124.356 252.969 124.332 253.359 123.856H253.344Z"
                    fill="white"/>
                <path
                    d="M251.789 120.943C251.836 120.818 251.883 120.7 251.899 120.568C251.938 120.193 251.672 119.724 251.25 119.709C251.118 119.709 250.993 119.709 250.868 119.732C250.555 119.802 250.305 119.959 250.173 120.263C250.055 120.552 250.094 120.896 250.29 121.138C250.688 121.646 251.555 121.56 251.789 120.943Z"
                    fill="white"/>
                <path
                    d="M253.5 114.413C253.57 113.914 253.211 113.429 252.703 113.359C252.477 113.328 252.297 113.359 252.086 113.437C251.704 113.57 251.477 114.023 251.532 114.406C251.594 114.851 251.914 115.093 252.321 115.202C252.446 115.234 252.571 115.234 252.703 115.202C253.109 115.109 253.445 114.843 253.508 114.406L253.5 114.413Z"
                    fill="white"/>
                <path
                    d="M254.594 112.149C254.594 112.086 254.586 112.024 254.578 111.961C254.555 111.594 254.219 111.297 253.867 111.25C253.391 111.18 253.032 111.516 252.93 111.953L252.891 112.141C252.789 112.594 253.188 113.047 253.617 113.109C254.195 113.187 254.625 112.695 254.586 112.149H254.594Z"
                    fill="white"/>
                <path
                    d="M253.461 111.641C253.508 111.282 253.196 110.954 252.836 111.008C252.774 111.016 252.711 111.024 252.649 111.032C252.422 111.063 252.235 111.243 252.204 111.477C252.172 111.688 252.297 111.961 252.516 112.024C252.571 112.039 252.633 112.055 252.688 112.078C253.032 112.18 253.406 112.031 253.461 111.641Z"
                    fill="white"/>
                <path
                    d="M257.085 123.551C257.077 123.512 257.069 123.465 257.061 123.426C256.991 123.067 256.741 122.778 256.366 122.723C256.023 122.676 255.616 122.872 255.499 123.215C255.484 123.254 255.476 123.293 255.46 123.333C255.281 123.848 255.554 124.426 256.124 124.504C256.647 124.574 257.186 124.098 257.085 123.551Z"
                    fill="white"/>
                <path
                    d="M257.663 120.599C257.561 120.318 257.483 120.013 257.218 119.849C256.804 119.584 256.273 119.685 256.062 120.154C255.921 120.458 256.007 120.724 256.077 121.029C256.187 121.466 256.523 121.81 257.007 121.739C257.561 121.669 257.842 121.099 257.663 120.591V120.599Z"
                    fill="white"/>
                <path
                    d="M258.936 121.81C258.858 121.388 258.537 121.115 258.1 121.154C257.749 121.177 257.452 121.489 257.405 121.825C257.343 122.317 257.811 122.731 258.28 122.614C258.295 122.614 258.303 122.614 258.319 122.614C258.686 122.567 259.006 122.192 258.936 121.81Z"
                    fill="white"/>
                <path
                    d="M258.818 124.738C258.975 124.371 258.803 123.848 258.397 123.715C258.108 123.622 257.819 123.528 257.538 123.426C257.132 123.294 256.679 123.426 256.507 123.848C256.35 124.215 256.515 124.754 256.928 124.879C257.217 124.973 257.506 125.059 257.795 125.152C258.201 125.277 258.647 125.152 258.818 124.738Z"
                    fill="white"/>
                <path
                    d="M254.39 124.84C254.179 124.567 253.914 124.387 253.554 124.402C253.242 124.418 253.016 124.754 253.047 125.035C253.062 125.176 253.18 125.285 253.312 125.301C253.359 125.301 253.406 125.301 253.445 125.293C253.547 125.597 253.836 125.808 254.171 125.668C254.492 125.535 254.601 125.113 254.39 124.84Z"
                    fill="white"/>
                <path
                    d="M253.149 127.128C253.086 127.058 253.016 126.988 252.953 126.917C252.602 126.542 252.055 126.41 251.618 126.745C251.212 127.058 251.126 127.675 251.446 128.081C251.508 128.159 251.563 128.229 251.626 128.307C251.954 128.721 252.563 128.807 252.977 128.479C253.359 128.182 253.508 127.511 253.149 127.128Z"
                    fill="white"/>
                <path
                    d="M250.204 126.683C250.087 126.621 249.962 126.558 249.853 126.496C249.587 126.332 249.212 126.558 249.345 126.886C249.407 127.042 249.47 127.206 249.525 127.363C249.634 127.683 250.071 127.769 250.321 127.573C250.649 127.324 250.548 126.855 250.204 126.683Z"
                    fill="white"/>
                <path
                    d="M248.595 126.941C248.767 126.535 248.564 126.089 248.174 125.91C247.689 125.683 247.182 125.957 246.955 126.402C246.729 126.855 247.018 127.55 247.564 127.589C247.791 127.605 247.978 127.534 248.119 127.386C248.33 127.3 248.517 127.136 248.595 126.949V126.941Z"
                    fill="white"/>
                <path
                    d="M247.213 129.432C247.236 129.354 247.275 129.268 247.283 129.19C247.291 129.112 247.283 129.034 247.283 128.956C247.291 128.893 247.299 128.831 247.283 128.753C247.268 128.651 247.221 128.534 247.189 128.432C247.072 128.058 246.58 127.909 246.244 128.05C245.916 128.19 245.666 128.651 245.862 128.995C245.963 129.174 246.057 129.315 246.205 129.424C246.315 129.534 246.416 129.62 246.565 129.69C246.799 129.791 247.127 129.674 247.213 129.424V129.432Z"
                    fill="white"/>
                <path
                    d="M249.962 130.596C249.618 130.205 249.048 130.096 248.619 130.424C248.228 130.729 248.111 131.369 248.447 131.767C248.79 132.158 249.361 132.267 249.79 131.939C250.188 131.635 250.306 130.994 249.962 130.596Z"
                    fill="white"/>
                <path
                    d="M251.688 130.276C251.586 130.158 251.493 130.033 251.36 129.955C251.102 129.799 250.813 129.76 250.563 129.955C250.329 130.135 250.251 130.455 250.36 130.721C250.423 130.869 250.516 130.978 250.618 131.096C250.86 131.369 251.258 131.447 251.563 131.213C251.844 130.994 251.922 130.541 251.68 130.268L251.688 130.276Z"
                    fill="white"/>
                <path
                    d="M253.375 130.736C253.711 130.643 253.969 130.385 254.023 130.002C254.055 129.76 253.992 129.51 253.844 129.315C253.625 129.034 253.336 128.971 253.008 128.971C252.633 128.971 252.211 129.237 252.024 129.557C251.868 129.823 251.751 130.143 251.907 130.455C251.938 130.518 251.93 130.486 251.899 130.362C251.922 130.44 251.938 130.518 251.961 130.596C252.008 130.775 252.164 130.947 252.305 131.057C252.493 131.205 252.758 131.236 252.977 131.143C253.156 131.064 253.274 130.932 253.336 130.76C253.344 130.752 253.359 130.736 253.367 130.729L253.375 130.736Z"
                    fill="white"/>
                <path
                    d="M252.781 132.111C252.57 131.603 251.899 131.275 251.376 131.541L250.993 131.736C250.595 131.939 250.282 132.353 250.368 132.822C250.407 133.04 250.454 133.142 250.579 133.321C250.852 133.728 251.376 133.767 251.797 133.642C251.93 133.603 252.071 133.556 252.203 133.517C252.766 133.345 252.984 132.611 252.774 132.111H252.781Z"
                    fill="white"/>
                <path
                    d="M249.853 132.884C249.751 132.822 249.65 132.759 249.548 132.697C249.259 132.517 248.845 132.767 249.001 133.118C249.048 133.22 249.095 133.329 249.134 133.431C249.251 133.704 249.611 133.806 249.845 133.618C250.064 133.446 250.103 133.04 249.845 132.884H249.853Z"
                    fill="white"/>
                <path
                    d="M247.205 131.08C246.456 130.213 245.026 131.306 245.784 132.181C246.541 133.048 247.963 131.955 247.205 131.08Z"
                    fill="white"/>
                <path
                    d="M246.83 130.252C246.932 130.002 246.815 129.698 246.565 129.596C246.268 129.471 245.862 129.541 245.682 129.83C245.518 130.08 245.487 130.408 245.682 130.65C245.877 130.893 246.198 130.791 246.299 130.557C246.518 130.596 246.752 130.44 246.838 130.252H246.83Z"
                    fill="white"/>
                <path
                    d="M247.447 133.142C247.252 133.009 247.049 132.97 246.822 132.915C246.455 132.829 246.088 133.157 246.041 133.509C245.995 133.868 246.26 134.259 246.635 134.29C246.698 134.29 246.768 134.305 246.83 134.305C247.002 134.321 247.135 134.305 247.307 134.251C247.775 134.118 247.822 133.399 247.447 133.142Z"
                    fill="white"/>
                <path
                    d="M250.243 136.227C250.243 136.227 250.305 136.157 250.344 136.125C250.618 135.836 250.571 135.321 250.344 135.032C250.032 134.634 249.485 134.587 249.087 134.868C249.048 134.899 249.009 134.923 248.97 134.954C248.564 135.243 248.517 135.852 248.806 136.235C248.954 136.422 249.165 136.547 249.399 136.578C249.751 136.625 250.016 136.469 250.243 136.227Z"
                    fill="white"/>
                <path
                    d="M253.679 134.743C253.539 134.446 253.351 134.306 253.031 134.235C252.563 134.126 252.195 134.61 252.235 135.032C252.258 135.274 252.375 135.477 252.57 135.61C252.844 135.789 253.187 135.821 253.461 135.61C253.711 135.415 253.828 135.04 253.687 134.751L253.679 134.743Z"
                    fill="white"/>
                <path
                    d="M250.883 129.823C251.016 129.643 251.102 129.417 251.063 129.19C251.047 129.089 251.133 129.253 251.001 128.94C250.962 128.87 250.922 128.807 250.883 128.745C250.852 128.714 250.829 128.69 250.813 128.675C250.634 128.44 250.36 128.292 250.017 128.339C249.571 128.393 249.15 128.862 249.259 129.331C249.321 129.596 249.415 129.838 249.649 129.994C250.04 130.252 250.587 130.244 250.891 129.83L250.883 129.823Z"
                    fill="white"/>
                <path
                    d="M250.103 129.401C250.204 129.354 250.29 129.276 250.337 129.159C250.439 128.909 250.321 128.596 250.064 128.495C249.915 128.432 249.782 128.417 249.618 128.425C249.579 128.425 249.447 128.417 249.603 128.425C249.525 128.417 249.439 128.417 249.369 128.432C249.314 128.448 249.259 128.464 249.205 128.479C249.142 128.503 249.15 128.503 249.236 128.464C249.103 128.464 248.939 128.542 248.837 128.62C248.689 128.737 248.572 128.917 248.517 129.081C248.338 129.581 248.611 130.143 249.165 130.213C249.634 130.276 250.126 129.877 250.103 129.393V129.401Z"
                    fill="white"/>
                <path
                    d="M257.147 127.222L257.069 127.128C256.765 126.777 256.241 126.675 255.859 126.98C255.499 127.253 255.398 127.831 255.703 128.19L255.781 128.284C256.085 128.643 256.609 128.737 256.991 128.44C257.35 128.167 257.452 127.581 257.147 127.23V127.222Z"
                    fill="white"/>
                <path
                    d="M256.397 130.049C256.225 129.643 255.733 129.432 255.319 129.612C254.999 129.752 254.601 129.869 254.398 130.166C254.07 130.643 254.296 131.369 254.921 131.439C255.312 131.486 255.624 131.291 255.968 131.135C256.374 130.947 256.585 130.479 256.405 130.057L256.397 130.049Z"
                    fill="white"/>
                <path
                    d="M256.077 128.753C256.054 128.612 256.038 128.479 256.015 128.339C255.991 128.167 255.913 128.034 255.874 127.87C255.827 127.659 255.695 127.495 255.468 127.464C255.226 127.433 254.96 127.612 254.937 127.87C254.921 128.026 254.906 128.136 254.875 128.268C254.843 128.417 254.859 128.589 254.859 128.745C254.859 129.081 255.023 129.385 255.382 129.44C255.773 129.495 256.132 129.151 256.077 128.753Z"
                    fill="white"/>
                <path
                    d="M256.436 126.433C256.671 126.121 256.616 125.668 256.304 125.433C255.976 125.184 255.562 125.277 255.304 125.566C255.023 125.879 254.835 126.488 255.234 126.792C255.64 127.105 256.179 126.769 256.436 126.433Z"
                    fill="white"/>
                <path
                    d="M259.014 129.307C259.186 129.143 259.311 128.987 259.342 128.745C259.373 128.518 259.311 128.276 259.17 128.097C258.881 127.722 258.334 127.659 257.952 127.94C257.936 127.956 257.92 127.964 257.905 127.979C257.53 128.268 257.46 128.831 257.749 129.198C258.014 129.541 258.631 129.69 258.967 129.354C258.983 129.338 258.998 129.323 259.006 129.315L259.014 129.307Z"
                    fill="white"/>
                <path
                    d="M261.216 127.8C261.185 127.558 261.13 127.323 261.037 127.097C260.763 126.394 259.662 126.542 259.576 127.292C259.545 127.534 259.553 127.776 259.584 128.018C259.639 128.464 260.06 128.784 260.505 128.729C260.951 128.667 261.271 128.253 261.216 127.808V127.8Z"
                    fill="white"/>
                <path
                    d="M261.372 124.894C261.591 124.605 261.575 124.09 261.239 123.879C261.052 123.762 260.857 123.614 260.654 123.536C260.357 123.418 260.013 123.496 259.818 123.754C259.568 124.082 259.662 124.488 259.951 124.746C260.068 124.855 260.224 124.934 260.365 125.019C260.701 125.23 261.122 125.222 261.38 124.887L261.372 124.894Z"
                    fill="white"/>
                <path
                    d="M259.608 123.161C259.53 122.981 259.412 122.895 259.248 122.801C258.811 122.552 258.21 123.044 258.311 123.52C258.366 123.746 258.46 123.957 258.694 124.051C258.702 124.051 258.709 124.051 258.717 124.059C259.272 124.27 259.842 123.731 259.608 123.169V123.161Z"
                    fill="white"/>
                <path
                    d="M260.951 126.433C261.169 126.152 261.154 125.785 260.99 125.48C260.841 125.215 260.623 125.035 260.396 124.84C260.037 124.527 259.451 124.645 259.178 125.004C258.865 125.41 258.99 125.902 259.342 126.222C259.498 126.371 259.646 126.542 259.849 126.628C260.232 126.792 260.677 126.785 260.951 126.433Z"
                    fill="white"/>
                <path
                    d="M263.911 124.926C263.216 124.121 261.896 125.129 262.599 125.941C263.294 126.746 264.614 125.738 263.911 124.926Z"
                    fill="white"/>
                <path
                    d="M264.621 129.737C263.887 128.893 262.505 129.955 263.247 130.807C263.981 131.65 265.355 130.588 264.621 129.737Z"
                    fill="white"/>
                <path
                    d="M266.152 132.611C266.339 132.345 266.355 132.002 266.152 131.736C265.965 131.502 265.59 131.369 265.309 131.517C265.152 131.595 265.027 131.674 264.934 131.822C264.762 132.095 264.723 132.423 264.934 132.689C265.129 132.939 265.48 133.04 265.777 132.908C265.933 132.837 266.058 132.751 266.16 132.611H266.152Z"
                    fill="white"/>
                <path
                    d="M268.581 132.72C268.839 132.392 268.847 131.892 268.581 131.564C268.448 131.4 268.292 131.275 268.097 131.197C267.785 131.072 267.511 131.111 267.199 131.197C266.644 131.346 266.379 131.931 266.426 132.462C266.457 132.822 266.715 133.157 267.05 133.298C267.457 133.47 267.902 133.345 268.183 133.017C268.339 132.954 268.48 132.861 268.589 132.72H268.581Z"
                    fill="white"/>
                <path
                    d="M264.645 135.102C264.559 134.759 264.418 134.509 264.075 134.352C263.512 134.11 262.856 134.493 262.77 135.094C262.7 135.602 263.067 136.086 263.575 136.157C263.825 136.188 264.082 136.125 264.285 135.969C264.52 135.782 264.723 135.415 264.645 135.102Z"
                    fill="white"/>
                <path
                    d="M264.153 132.775C264.192 132.501 264.114 132.22 263.95 132.002C263.747 131.736 263.341 131.525 262.997 131.611C262.974 131.611 262.958 131.619 262.935 131.627C262.49 131.736 262.13 132.009 262.06 132.494C261.998 132.939 262.247 133.4 262.669 133.564C262.685 133.564 262.708 133.579 262.724 133.587C263.341 133.829 264.052 133.446 264.145 132.775H264.153Z"
                    fill="white"/>
                <path
                    d="M262.989 131.681C263.106 131.353 263.106 130.924 262.856 130.65C262.598 130.369 262.356 130.237 261.974 130.26C261.95 130.26 261.927 130.26 261.903 130.26C261.536 130.276 261.232 130.572 261.083 130.885C260.904 131.267 260.927 131.767 261.193 132.103C261.31 132.447 261.724 132.619 262.052 132.462C262.473 132.416 262.848 132.088 262.989 131.689V131.681Z"
                    fill="white"/>
                <path
                    d="M261.786 134.353C261.466 133.939 260.732 133.775 260.326 134.165C260.31 134.181 260.294 134.196 260.279 134.204C260.076 134.399 259.927 134.595 259.888 134.884C259.849 135.157 259.927 135.446 260.099 135.665C260.443 136.11 261.107 136.196 261.56 135.852C261.575 135.844 261.591 135.829 261.606 135.813C262.052 135.462 262.138 134.798 261.794 134.353H261.786Z"
                    fill="white"/>
                <path
                    d="M263.591 137.328C263.715 137.039 263.669 136.688 263.473 136.438C263.122 135.985 262.427 135.993 262.068 136.438C262.044 136.469 262.021 136.5 261.997 136.531C261.755 136.836 261.763 137.281 261.997 137.586C262.208 137.859 262.685 138.047 263.013 137.851C263.044 137.828 263.075 137.812 263.114 137.789C263.325 137.664 263.489 137.547 263.583 137.32L263.591 137.328Z"
                    fill="white"/>
                <path
                    d="M267.48 136.305C267.261 136.055 267.035 135.891 266.699 135.86C266.308 135.821 265.84 136.086 265.816 136.524C265.801 136.859 265.847 137.133 266.074 137.391C266.394 137.758 266.925 137.859 267.324 137.547C267.691 137.258 267.8 136.664 267.48 136.297V136.305Z"
                    fill="white"/>
                <path
                    d="M269.214 134.173C269.214 134.173 269.19 134.142 269.182 134.126C268.917 133.712 268.276 133.681 267.917 133.97C267.433 134.345 267.488 135.024 267.917 135.399C267.933 135.415 267.948 135.422 267.964 135.438C268.269 135.704 268.753 135.672 269.057 135.438C269.471 135.118 269.479 134.595 269.214 134.181V134.173Z"
                    fill="white"/>
                <path
                    d="M269.791 136.211C269.042 135.344 267.612 136.43 268.378 137.312C269.135 138.179 270.557 137.094 269.791 136.211Z"
                    fill="white"/>
                <path
                    d="M265.449 141.397C265.449 141.038 265.324 140.702 264.973 140.554C264.777 140.468 264.582 140.483 264.402 140.554C264.277 140.577 264.16 140.616 264.051 140.702C263.817 140.882 263.7 141.178 263.739 141.475C263.809 142.03 264.387 142.334 264.894 142.139C265.183 142.03 265.449 141.725 265.457 141.397H265.449Z"
                    fill="white"/>
                <path
                    d="M263.677 141.663C263.692 141.514 263.692 141.374 263.692 141.225C263.692 140.96 263.489 140.733 263.302 140.577C263.083 140.39 262.755 140.374 262.49 140.475C262.068 140.639 261.92 140.991 261.834 141.405C261.732 141.905 262.154 142.389 262.63 142.459C263.13 142.53 263.614 142.17 263.677 141.663Z"
                    fill="white"/>
                <path
                    d="M260.966 138.632C260.966 138.632 261.021 138.578 261.044 138.546C261.333 138.234 261.287 137.687 261.044 137.367C260.709 136.938 260.123 136.899 259.693 137.195C259.662 137.219 259.631 137.242 259.592 137.266C259.154 137.57 259.108 138.234 259.42 138.64C259.576 138.835 259.81 138.976 260.06 139.007C260.427 139.062 260.724 138.898 260.966 138.632Z"
                    fill="white"/>
                <path
                    d="M260.123 138.195C260.357 137.742 260.06 137.156 259.623 136.969C259.49 136.914 259.35 136.898 259.217 136.906C259.162 136.906 259.108 136.906 259.045 136.906C259.115 136.906 259.17 136.906 259.209 136.906H259.037C258.592 136.969 258.124 137.461 258.256 137.945C258.311 138.132 258.327 138.273 258.459 138.429C258.576 138.562 258.694 138.671 258.865 138.734C259.397 138.937 259.881 138.679 260.131 138.203L260.123 138.195Z"
                    fill="white"/>
                <path
                    d="M259.076 135.11C259.272 134.907 259.428 134.735 259.576 134.477C259.654 134.345 259.717 134.243 259.74 134.071C259.787 133.759 259.654 133.423 259.404 133.228C258.678 132.673 258.014 133.431 257.616 133.978C257.319 134.384 257.382 134.962 257.788 135.274C257.983 135.422 258.225 135.485 258.467 135.454C258.733 135.415 258.905 135.282 259.084 135.102L259.076 135.11Z"
                    fill="white"/>
                <path
                    d="M260.24 131.869C260.193 131.783 260.138 131.705 260.091 131.619C259.841 131.205 259.201 131.189 258.85 131.463C258.389 131.822 258.42 132.501 258.85 132.861C258.92 132.923 258.998 132.978 259.068 133.04C259.357 133.282 259.802 133.259 260.091 133.04C260.482 132.736 260.474 132.259 260.24 131.869Z"
                    fill="white"/>
                <path
                    d="M256.819 134.891C256.686 134.579 256.311 134.329 255.96 134.399C255.819 134.431 255.694 134.462 255.554 134.462C255.327 134.462 255.077 134.641 254.945 134.813C254.749 135.071 254.702 135.422 254.827 135.727C255.038 136.235 255.796 136.508 256.233 136.094C256.335 136 256.444 135.93 256.569 135.86C256.874 135.672 256.952 135.204 256.819 134.899V134.891Z"
                    fill="white"/>
                <path
                    d="M254.062 137.781C254.249 137.586 254.398 137.383 254.413 137.094C254.437 136.586 254.148 136.063 253.593 136.008C253.383 135.985 253.203 135.992 253.008 136.071C252.859 136.133 252.734 136.235 252.609 136.328C252.156 136.656 252.094 137.344 252.43 137.773C252.594 137.984 252.836 138.125 253.101 138.164C253.5 138.218 253.796 138.039 254.062 137.773V137.781Z"
                    fill="white"/>
                <path
                    d="M256.085 138.445C256.015 137.937 255.632 137.594 255.148 137.5C254.89 137.453 254.648 137.406 254.39 137.461C253.875 137.57 253.453 137.922 253.375 138.468C253.328 138.781 253.414 139.101 253.609 139.351C253.781 139.57 254.093 139.796 254.382 139.796C254.663 139.796 254.874 139.819 255.14 139.71L255.429 139.593C255.882 139.413 256.155 138.914 256.093 138.437L256.085 138.445Z"
                    fill="white"/>
                <path
                    d="M257.928 137.976C257.827 137.726 257.702 137.5 257.475 137.344C256.921 136.969 256.218 137.258 256.093 137.914C256.038 138.195 256.116 138.445 256.218 138.703C256.413 139.171 256.975 139.39 257.436 139.195C257.905 138.999 258.123 138.445 257.928 137.976Z"
                    fill="white"/>
                <path
                    d="M259.256 138.968C259.068 138.515 258.545 138.32 258.1 138.5C257.889 138.585 257.686 138.664 257.491 138.781C257.28 138.898 257.116 139.023 257.022 139.249C256.928 139.468 256.928 139.718 257.022 139.937C257.217 140.397 257.725 140.561 258.178 140.405C258.397 140.327 258.592 140.233 258.795 140.124C259.225 139.905 259.459 139.437 259.264 138.968H259.256Z"
                    fill="white"/>
                <path
                    d="M258.686 142.764C258.967 142.1 258.522 141.436 257.858 141.303C257.663 141.264 257.475 141.217 257.272 141.217C257.101 141.194 256.929 141.217 256.765 141.288C256.538 141.381 256.351 141.569 256.249 141.803C256.085 142.186 256.202 142.694 256.554 142.943C256.788 143.107 257.038 143.225 257.304 143.334C257.835 143.568 258.467 143.295 258.694 142.772L258.686 142.764Z"
                    fill="white"/>
                <path
                    d="M256.897 143.232C256.858 143.092 256.843 142.951 256.804 142.811C256.772 142.709 256.686 142.592 256.632 142.491C256.624 142.475 256.608 142.451 256.593 142.436C256.741 142.147 256.741 141.811 256.515 141.522C256.226 141.155 255.656 141.061 255.281 141.366C255.132 141.483 254.992 141.608 254.843 141.725C254.5 142.006 254.336 142.623 254.687 142.983C254.929 143.232 255.164 143.49 255.476 143.646C255.585 143.701 255.695 143.756 255.804 143.81C255.89 143.842 255.984 143.857 256.069 143.881C256.265 143.857 256.319 143.857 256.226 143.896C256.569 143.967 256.983 143.6 256.89 143.24L256.897 143.232Z"
                    fill="white"/>
                <path
                    d="M258.858 144.482C259.006 144.123 258.952 143.693 258.717 143.389C258.46 143.061 258.108 142.967 257.71 142.975C257.304 142.975 256.991 143.24 256.671 143.467C256.156 143.826 256.093 144.615 256.468 145.091C256.882 145.622 257.569 145.646 258.093 145.294C258.194 145.232 258.288 145.161 258.389 145.099C258.616 144.951 258.764 144.724 258.866 144.482H258.858Z"
                    fill="white"/>
                <path
                    d="M260.412 143.787C260.498 143.56 260.568 143.357 260.529 143.115C260.443 142.53 259.748 142.225 259.264 142.592C259.076 142.74 258.967 142.928 258.881 143.139C258.709 143.56 258.904 144.045 259.326 144.224C259.74 144.396 260.248 144.201 260.412 143.779V143.787Z"
                    fill="white"/>
                <path
                    d="M261.99 141.584C262.122 141.249 262.013 140.921 261.755 140.686C261.458 140.421 260.982 140.421 260.646 140.608C260.326 140.788 260.099 141.077 260.154 141.475C260.201 141.811 260.498 142.162 260.857 142.186C261.084 142.201 261.287 142.201 261.497 142.108C261.724 142.006 261.904 141.827 261.997 141.592L261.99 141.584Z"
                    fill="white"/>
                <path
                    d="M265.16 140.233C265.309 140.062 265.418 139.898 265.457 139.655C265.481 139.468 265.434 139.273 265.317 139.117C265.145 138.898 264.918 138.843 264.661 138.843C264.426 138.843 264.239 138.992 264.051 139.109C263.684 139.335 263.661 139.905 263.911 140.226C264.239 140.647 264.84 140.608 265.168 140.226L265.16 140.233Z"
                    fill="white"/>
                <path
                    d="M267.363 139.976C267.363 139.976 267.394 139.937 267.41 139.921C267.675 139.593 267.667 139.085 267.41 138.757C267.261 138.562 267.035 138.429 266.793 138.398C266.511 138.359 266.308 138.453 266.082 138.585C266.058 138.601 266.043 138.609 266.019 138.624C265.566 138.898 265.559 139.601 265.848 139.976C266.238 140.475 266.988 140.452 267.378 139.976H267.363Z"
                    fill="white"/>
                <path
                    d="M265.808 137.062C265.855 136.938 265.902 136.82 265.918 136.688C265.957 136.313 265.691 135.844 265.269 135.828C265.137 135.828 265.012 135.828 264.887 135.852C264.574 135.922 264.324 136.078 264.192 136.383C264.067 136.672 264.114 137.016 264.309 137.258C264.699 137.765 265.566 137.679 265.808 137.062Z"
                    fill="white"/>
                <path
                    d="M267.527 130.533C267.597 130.033 267.238 129.549 266.73 129.479C266.503 129.448 266.324 129.479 266.113 129.557C265.73 129.69 265.504 130.143 265.558 130.526C265.613 130.971 265.941 131.213 266.347 131.322C266.472 131.353 266.597 131.353 266.73 131.322C267.136 131.228 267.472 130.963 267.534 130.526L267.527 130.533Z"
                    fill="white"/>
                <path
                    d="M268.62 128.268C268.62 128.206 268.612 128.144 268.604 128.081C268.581 127.714 268.245 127.417 267.894 127.37C267.417 127.308 267.05 127.636 266.956 128.081L266.917 128.268C266.816 128.721 267.214 129.174 267.651 129.237C268.229 129.315 268.659 128.831 268.62 128.276V128.268Z"
                    fill="white"/>
                <path
                    d="M267.48 127.761C267.527 127.402 267.214 127.081 266.855 127.128C266.792 127.136 266.73 127.144 266.667 127.152C266.441 127.183 266.254 127.363 266.222 127.597C266.191 127.808 266.316 128.081 266.535 128.151C266.597 128.167 266.652 128.183 266.706 128.206C267.05 128.308 267.425 128.159 267.48 127.769V127.761Z"
                    fill="white"/>
                <path
                    d="M271.111 139.663C271.111 139.624 271.096 139.585 271.088 139.538C271.018 139.179 270.768 138.89 270.393 138.835C270.049 138.788 269.643 138.984 269.526 139.327C269.51 139.366 269.502 139.405 269.487 139.445C269.307 139.96 269.581 140.538 270.151 140.616C270.674 140.686 271.221 140.21 271.111 139.663Z"
                    fill="white"/>
                <path
                    d="M271.681 136.719C271.58 136.438 271.502 136.133 271.236 135.969C270.822 135.704 270.299 135.805 270.08 136.274C269.94 136.578 270.026 136.844 270.096 137.148C270.205 137.586 270.541 137.929 271.025 137.859C271.58 137.789 271.861 137.219 271.681 136.711V136.719Z"
                    fill="white"/>
                <path
                    d="M272.962 137.922C272.884 137.5 272.564 137.227 272.126 137.266C271.775 137.289 271.478 137.601 271.431 137.937C271.369 138.429 271.838 138.843 272.306 138.726C272.322 138.726 272.33 138.726 272.345 138.726C272.712 138.679 273.032 138.304 272.962 137.922Z"
                    fill="white"/>
                <path
                    d="M272.845 140.858C273.001 140.491 272.829 139.968 272.423 139.835C272.134 139.741 271.845 139.648 271.564 139.546C271.158 139.413 270.705 139.546 270.533 139.968C270.377 140.335 270.541 140.874 270.955 140.999C271.244 141.092 271.533 141.178 271.822 141.272C272.228 141.397 272.673 141.272 272.845 140.85V140.858Z"
                    fill="white"/>
                <path
                    d="M268.417 140.96C268.206 140.686 267.94 140.507 267.581 140.515C267.269 140.53 267.042 140.866 267.073 141.147C267.089 141.28 267.206 141.397 267.339 141.413C267.386 141.413 267.433 141.413 267.472 141.405C267.573 141.71 267.862 141.92 268.198 141.78C268.518 141.647 268.628 141.225 268.417 140.952V140.96Z"
                    fill="white"/>
                <path
                    d="M267.167 143.24C267.097 143.17 267.035 143.1 266.972 143.029C266.613 142.654 266.074 142.514 265.637 142.858C265.23 143.17 265.145 143.787 265.465 144.193C265.527 144.271 265.582 144.341 265.644 144.42C265.972 144.833 266.582 144.912 266.996 144.591C267.378 144.295 267.527 143.623 267.167 143.24Z"
                    fill="white"/>
                <path
                    d="M264.223 142.803C264.106 142.74 263.989 142.678 263.871 142.608C263.606 142.444 263.231 142.67 263.364 142.998C263.434 143.154 263.489 143.318 263.543 143.482C263.653 143.802 264.09 143.881 264.34 143.693C264.668 143.443 264.567 142.975 264.223 142.803Z"
                    fill="white"/>
                <path
                    d="M262.614 143.061C262.786 142.654 262.583 142.209 262.192 142.03C261.708 141.803 261.201 142.069 260.974 142.514C260.748 142.967 261.037 143.662 261.583 143.701C261.81 143.717 261.997 143.646 262.138 143.498C262.349 143.42 262.536 143.248 262.614 143.061Z"
                    fill="white"/>
                <path
                    d="M261.232 145.552C261.255 145.474 261.294 145.388 261.302 145.31C261.31 145.232 261.302 145.154 261.302 145.075C261.31 145.013 261.318 144.951 261.302 144.872C261.287 144.771 261.24 144.654 261.209 144.552C261.091 144.177 260.599 144.029 260.264 144.17C259.936 144.31 259.678 144.771 259.881 145.115C259.982 145.294 260.068 145.435 260.217 145.544C260.326 145.646 260.428 145.739 260.576 145.81C260.81 145.911 261.138 145.802 261.224 145.544L261.232 145.552Z"
                    fill="white"/>
                <path
                    d="M263.981 146.716C263.637 146.325 263.067 146.216 262.638 146.544C262.239 146.848 262.122 147.497 262.466 147.887C262.81 148.278 263.38 148.387 263.809 148.059C264.208 147.754 264.325 147.106 263.981 146.716Z"
                    fill="white"/>
                <path
                    d="M265.707 146.396C265.605 146.278 265.511 146.153 265.386 146.075C265.129 145.919 264.84 145.88 264.59 146.075C264.356 146.255 264.277 146.575 264.387 146.841C264.449 146.989 264.543 147.098 264.645 147.216C264.887 147.497 265.293 147.567 265.59 147.333C265.871 147.114 265.949 146.661 265.707 146.388V146.396Z"
                    fill="white"/>
                <path
                    d="M267.394 146.856C267.73 146.762 267.995 146.505 268.042 146.122C268.074 145.88 268.011 145.63 267.863 145.435C267.644 145.154 267.355 145.091 267.027 145.091C266.652 145.091 266.23 145.357 266.043 145.677C265.887 145.942 265.77 146.263 265.926 146.575C265.957 146.638 265.949 146.606 265.918 146.481C265.941 146.559 265.957 146.638 265.98 146.716C266.027 146.895 266.191 147.067 266.324 147.176C266.512 147.325 266.777 147.356 266.996 147.262C267.175 147.184 267.293 147.051 267.355 146.88C267.363 146.872 267.378 146.864 267.386 146.848L267.394 146.856Z"
                    fill="white"/>
                <path
                    d="M266.8 148.231C266.589 147.723 265.918 147.395 265.394 147.661L265.012 147.856C264.613 148.059 264.301 148.473 264.387 148.942C264.426 149.16 264.473 149.262 264.598 149.441C264.871 149.847 265.394 149.887 265.816 149.762C265.949 149.723 266.089 149.676 266.222 149.637C266.785 149.465 267.003 148.731 266.792 148.231H266.8Z"
                    fill="white"/>
                <path
                    d="M263.872 149.004C263.77 148.941 263.669 148.879 263.575 148.817C263.286 148.637 262.872 148.887 263.028 149.238C263.075 149.34 263.122 149.449 263.161 149.551C263.278 149.824 263.637 149.926 263.872 149.738C264.09 149.566 264.13 149.16 263.872 149.004Z"
                    fill="white"/>
                <path
                    d="M261.224 147.2C260.474 146.333 259.045 147.426 259.81 148.301C260.56 149.168 261.989 148.075 261.224 147.2Z"
                    fill="white"/>
                <path
                    d="M260.849 146.372C260.95 146.122 260.833 145.818 260.583 145.716C260.287 145.599 259.881 145.669 259.701 145.95C259.537 146.2 259.506 146.528 259.701 146.77C259.896 147.012 260.216 146.911 260.318 146.677C260.537 146.716 260.771 146.567 260.857 146.372H260.849Z"
                    fill="white"/>
                <path
                    d="M261.466 149.262C261.271 149.129 261.068 149.09 260.849 149.035C260.482 148.949 260.115 149.277 260.068 149.629C260.021 149.988 260.287 150.379 260.662 150.41C260.724 150.41 260.794 150.425 260.857 150.425C261.029 150.441 261.169 150.425 261.333 150.371C261.802 150.238 261.849 149.52 261.474 149.262H261.466Z"
                    fill="white"/>
                <path
                    d="M264.262 152.347C264.262 152.347 264.325 152.276 264.364 152.245C264.637 151.956 264.59 151.441 264.364 151.152C264.051 150.754 263.505 150.707 263.106 150.996C263.067 151.027 263.028 151.05 262.989 151.082C262.583 151.371 262.536 151.98 262.825 152.355C262.966 152.542 263.184 152.667 263.419 152.698C263.77 152.745 264.036 152.597 264.27 152.355L264.262 152.347Z"
                    fill="white"/>
                <path
                    d="M267.698 150.863C267.55 150.566 267.37 150.425 267.05 150.355C266.582 150.246 266.215 150.73 266.254 151.152C266.277 151.394 266.394 151.597 266.589 151.73C266.863 151.917 267.206 151.941 267.48 151.73C267.73 151.534 267.847 151.167 267.706 150.871L267.698 150.863Z"
                    fill="white"/>
                <path
                    d="M264.903 145.943C265.035 145.763 265.121 145.536 265.082 145.31C265.067 145.208 265.153 145.372 265.02 145.06C264.981 144.99 264.942 144.927 264.903 144.865C264.871 144.834 264.848 144.81 264.832 144.795C264.653 144.56 264.379 144.412 264.036 144.459C263.591 144.513 263.169 144.982 263.278 145.451C263.341 145.716 263.434 145.958 263.669 146.114C264.059 146.372 264.606 146.364 264.911 145.95L264.903 145.943Z"
                    fill="white"/>
                <path
                    d="M264.121 145.521C264.223 145.474 264.309 145.396 264.356 145.279C264.465 145.029 264.34 144.716 264.082 144.615C263.934 144.552 263.793 144.537 263.637 144.545C263.598 144.545 263.465 144.537 263.621 144.545C263.543 144.537 263.457 144.537 263.387 144.552C263.332 144.568 263.278 144.584 263.223 144.599C263.161 144.623 263.168 144.623 263.262 144.584C263.129 144.584 262.965 144.662 262.864 144.74C262.715 144.849 262.598 145.037 262.544 145.201C262.364 145.7 262.637 146.263 263.192 146.333C263.66 146.396 264.152 145.997 264.129 145.513L264.121 145.521Z"
                    fill="white"/>
                <path
                    d="M271.166 143.342L271.088 143.248C270.783 142.889 270.26 142.795 269.877 143.092C269.518 143.373 269.417 143.951 269.721 144.302L269.799 144.396C270.104 144.748 270.627 144.849 271.01 144.552C271.369 144.279 271.471 143.701 271.166 143.342Z"
                    fill="white"/>
                <path
                    d="M270.416 146.169C270.244 145.763 269.752 145.552 269.339 145.732C269.018 145.872 268.62 145.989 268.417 146.286C268.089 146.762 268.315 147.489 268.94 147.559C269.331 147.606 269.643 147.411 269.987 147.255C270.393 147.067 270.604 146.598 270.424 146.177L270.416 146.169Z"
                    fill="white"/>
                <path
                    d="M270.096 144.872C270.073 144.74 270.057 144.599 270.034 144.466C270.01 144.295 269.932 144.162 269.893 143.998C269.846 143.787 269.714 143.623 269.487 143.592C269.245 143.56 268.979 143.74 268.956 143.998C268.94 144.154 268.925 144.263 268.894 144.396C268.862 144.544 268.878 144.716 268.878 144.872C268.878 145.208 269.034 145.513 269.401 145.568C269.792 145.622 270.151 145.279 270.096 144.88V144.872Z"
                    fill="white"/>
                <path
                    d="M270.455 142.545C270.69 142.233 270.635 141.78 270.323 141.545C269.995 141.296 269.581 141.389 269.323 141.678C269.042 141.991 268.854 142.6 269.253 142.904C269.667 143.217 270.198 142.881 270.455 142.545Z"
                    fill="white"/>
                <path
                    d="M273.033 145.427C273.204 145.263 273.329 145.107 273.361 144.865C273.392 144.638 273.329 144.396 273.189 144.216C272.9 143.842 272.353 143.779 271.97 144.06C271.955 144.068 271.939 144.084 271.924 144.099C271.549 144.38 271.478 144.951 271.767 145.318C272.033 145.661 272.65 145.81 272.986 145.474C273.001 145.458 273.017 145.443 273.033 145.435V145.427Z"
                    fill="white"/>
                <path
                    d="M275.243 143.92C275.212 143.678 275.157 143.443 275.071 143.217C274.798 142.514 273.697 142.662 273.611 143.412C273.579 143.654 273.587 143.896 273.611 144.138C273.665 144.584 274.087 144.904 274.532 144.849C274.977 144.787 275.298 144.373 275.243 143.927V143.92Z"
                    fill="white"/>
                <path
                    d="M275.392 141.014C275.61 140.725 275.595 140.21 275.259 139.999C275.071 139.882 274.876 139.733 274.673 139.655C274.376 139.538 274.033 139.624 273.837 139.874C273.587 140.202 273.681 140.608 273.97 140.866C274.087 140.975 274.243 141.053 274.384 141.139C274.72 141.35 275.142 141.342 275.392 141.006V141.014Z"
                    fill="white"/>
                <path
                    d="M273.626 139.281C273.548 139.101 273.431 139.015 273.267 138.921C272.829 138.671 272.228 139.163 272.329 139.64C272.376 139.866 272.478 140.077 272.712 140.171C272.712 140.171 272.728 140.171 272.736 140.179C273.29 140.39 273.86 139.851 273.626 139.288V139.281Z"
                    fill="white"/>
                <path
                    d="M274.97 142.553C275.188 142.272 275.173 141.905 275.009 141.608C274.86 141.342 274.634 141.163 274.415 140.967C274.056 140.655 273.47 140.772 273.197 141.124C272.884 141.53 273.009 142.022 273.361 142.342C273.517 142.49 273.665 142.662 273.868 142.748C274.251 142.912 274.696 142.912 274.97 142.553Z"
                    fill="white"/>
                <path
                    d="M277.937 141.046C277.242 140.241 275.922 141.249 276.625 142.061C277.32 142.865 278.64 141.858 277.937 141.046Z"
                    fill="white"/>
                <path
                    d="M255.804 133.611L255.827 133.564C255.975 133.282 255.897 132.9 255.71 132.658C255.452 132.322 255.03 132.244 254.648 132.377C254.632 132.377 254.609 132.392 254.593 132.392C254.023 132.595 253.875 133.361 254.226 133.814C254.421 134.071 254.749 134.204 255.077 134.165C255.421 134.118 255.655 133.899 255.811 133.611H255.804Z"
                    fill="white"/>
                <path
                    d="M258.49 130.729C257.725 129.846 256.28 130.955 257.053 131.845C257.819 132.728 259.264 131.619 258.49 130.729Z"
                    fill="white"/>
                <path
                    d="M261.154 130.065C261.091 129.955 261.037 129.838 260.958 129.737C260.505 129.198 259.662 129.565 259.724 130.252C259.732 130.385 259.779 130.502 259.818 130.627C259.928 131.01 260.435 131.158 260.771 131.018C261.115 130.877 261.349 130.416 261.162 130.065H261.154Z"
                    fill="white"/>
                <path
                    d="M263.7 128.964C263.786 128.753 263.817 128.472 263.723 128.261C263.637 128.05 263.427 127.878 263.216 127.784C262.903 127.651 262.614 127.753 262.31 127.855C261.833 128.011 261.638 128.62 261.826 129.057C262.013 129.502 262.575 129.76 263.028 129.549C263.309 129.417 263.575 129.276 263.7 128.971V128.964Z"
                    fill="white"/>
                <path
                    d="M272.197 133.837C271.541 133.079 270.299 134.032 270.963 134.798C271.619 135.555 272.861 134.602 272.197 133.837Z"
                    fill="white"/>
                <path
                    d="M275.001 138.32C274.275 137.484 272.908 138.531 273.634 139.374C274.361 140.21 275.728 139.163 275.001 138.32Z"
                    fill="white"/>
                <path
                    d="M258.608 118.014C257.874 117.17 256.491 118.233 257.233 119.084C257.967 119.927 259.342 118.865 258.608 118.014Z"
                    fill="white"/>
                <path
                    d="M262.638 122.372C262.817 121.95 262.607 121.458 262.193 121.278C262.021 121.208 261.857 121.122 261.669 121.114C261.294 121.091 260.951 121.255 260.795 121.606C260.646 121.958 260.763 122.341 261.052 122.575C261.201 122.7 261.365 122.755 261.537 122.817C261.966 122.981 262.45 122.801 262.63 122.372H262.638Z"
                    fill="white"/>
                <path
                    d="M248.884 122.161C248.572 121.802 248.049 121.7 247.65 122.005C247.291 122.286 247.182 122.872 247.494 123.231C247.806 123.59 248.33 123.692 248.72 123.387C249.079 123.106 249.189 122.52 248.876 122.161H248.884Z"
                    fill="white"/>
                <path
                    d="M252.532 119.506C252.899 119.107 252.656 118.436 252.196 118.248C251.922 118.131 251.602 118.178 251.376 118.358C251.329 118.389 251.298 118.428 251.266 118.467C251.157 118.553 251.071 118.662 251.024 118.779C250.891 119.092 250.993 119.451 251.258 119.662C251.672 119.998 252.196 119.873 252.539 119.506H252.532Z"
                    fill="white"/>
                <path
                    d="M243.917 116.467C243.206 115.64 241.855 116.678 242.574 117.514C243.284 118.342 244.635 117.303 243.917 116.467Z"
                    fill="white"/>
                <path
                    d="M241.129 114.835C240.379 113.968 238.965 115.054 239.723 115.929C240.473 116.796 241.886 115.71 241.129 114.835Z"
                    fill="white"/>
                <path
                    d="M241.527 113.445C241.527 113.445 241.511 113.406 241.504 113.39C241.386 113.101 241.097 112.883 240.793 112.844C240.356 112.781 240.028 113.031 239.824 113.39C239.817 113.406 239.801 113.429 239.793 113.445C239.606 113.781 239.754 114.359 240.09 114.554C240.402 114.741 240.66 114.82 241.027 114.671C241.511 114.476 241.707 113.914 241.527 113.445Z"
                    fill="white"/>
                <path
                    d="M237.419 119.631C237.419 119.631 237.419 119.599 237.419 119.584C237.364 118.912 236.521 118.607 236.013 119.006C235.771 119.193 235.638 119.506 235.685 119.81C235.74 120.224 236.052 120.474 236.435 120.568C236.451 120.568 236.466 120.568 236.482 120.576C237.036 120.708 237.474 120.13 237.435 119.631H237.419Z"
                    fill="white"/>
                <path
                    d="M245.214 115.874C245.284 115.374 244.933 115.007 244.48 114.898C244.206 114.827 243.878 114.866 243.667 115.077C243.41 115.335 243.261 115.671 243.417 116.038C243.574 116.397 243.87 116.561 244.237 116.616C244.706 116.678 245.143 116.35 245.214 115.882V115.874Z"
                    fill="white"/>
                <path
                    d="M243.73 114.21C243.847 114.109 243.941 114 244.027 113.836C244.167 113.554 244.05 113.156 243.8 112.984C243.722 112.93 243.636 112.883 243.55 112.836C243.464 112.789 243.378 112.781 243.285 112.773C243.066 112.734 242.863 112.773 242.644 112.875C242.308 113.055 242.082 113.367 242.137 113.757C242.199 114.249 242.621 114.492 243.082 114.484C243.347 114.484 243.55 114.382 243.73 114.203V114.21Z"
                    fill="white"/>
                <path
                    d="M247.689 114.82C247.689 114.773 247.689 114.734 247.689 114.687C247.712 114.265 247.541 113.883 247.134 113.711C246.697 113.531 246.088 113.726 245.932 114.203C245.916 114.242 245.908 114.281 245.893 114.328C245.729 114.812 245.893 115.343 246.393 115.554C246.916 115.773 247.658 115.429 247.681 114.82H247.689Z"
                    fill="white"/>
                <path
                    d="M248.876 111.93C248.97 111.758 249.08 111.602 249.103 111.399C249.142 111.133 249.064 110.829 248.908 110.602C248.775 110.274 248.369 110.095 248.049 110.266C247.799 110.329 247.557 110.477 247.4 110.68C247.229 110.899 247.197 111.188 247.135 111.454C247.041 111.844 247.33 112.274 247.682 112.422C247.908 112.516 248.166 112.516 248.392 112.422C248.627 112.32 248.759 112.156 248.876 111.938V111.93Z"
                    fill="white"/>
                <path
                    d="M250.516 109.665C250.548 109.407 250.469 109.22 250.344 109.009C250.243 108.837 250.118 108.704 249.915 108.618C249.618 108.494 249.267 108.54 249.009 108.736C248.556 109.087 248.54 109.821 249.009 110.173C249.04 110.196 249.071 110.22 249.103 110.243C249.407 110.493 249.876 110.477 250.18 110.243C250.36 110.102 250.485 109.891 250.516 109.665Z"
                    fill="white"/>
                <path
                    d="M250.337 109.001C250.353 109.032 250.376 109.064 250.392 109.095C250.368 109.064 250.353 109.032 250.337 108.993V109.001Z"
                    fill="white"/>
                <path
                    d="M235.982 106.486C235.982 106.627 236.021 106.385 236.029 106.369C236.052 106.291 236.06 106.229 236.068 106.158C236.107 106.041 236.138 105.924 236.162 105.799C236.263 105.299 235.498 104.956 235.224 105.409C235.193 105.455 235.162 105.51 235.131 105.549C235.099 105.565 235.076 105.573 235.045 105.588C234.693 105.651 234.397 105.877 234.131 106.119C233.787 106.439 233.756 107.127 234.287 107.299C234.725 107.439 235.162 107.369 235.545 107.119C235.755 106.978 235.974 106.752 235.982 106.486Z"
                    fill="white"/>
                <path
                    d="M238.638 102.925C238.763 102.527 238.708 102.089 238.341 101.839C238.091 101.668 237.802 101.613 237.513 101.73C237.255 101.839 237.044 102.097 237.013 102.378C237.029 102.324 237.044 102.277 237.06 102.222C236.998 102.363 236.982 102.511 236.998 102.667C236.998 102.667 236.998 102.667 236.998 102.675C236.998 102.706 236.998 102.737 236.998 102.777C237.037 103.097 237.31 103.409 237.646 103.433C237.669 103.433 237.693 103.433 237.724 103.433C237.88 103.456 238.028 103.433 238.177 103.37C238.364 103.292 238.567 103.112 238.63 102.917L238.638 102.925Z"
                    fill="white"/>
                <path
                    d="M238.028 99.1996C237.872 99.02 237.716 98.8403 237.567 98.6607C237.552 98.6217 237.536 98.5826 237.513 98.5436C237.513 98.5436 237.513 98.5279 237.513 98.5201C237.528 98.2702 237.419 98.0359 237.177 97.9344C236.966 97.8485 236.653 97.8953 236.521 98.1062C236.497 98.1452 236.474 98.1843 236.45 98.2155C236.24 98.3327 236.099 98.4811 235.974 98.731C235.841 98.9965 235.904 99.3636 236.083 99.5901C236.271 99.8166 236.458 100.043 236.638 100.27C236.935 100.637 237.481 100.723 237.856 100.426C238.215 100.145 238.325 99.5667 238.012 99.2074L238.028 99.1996Z"
                    fill="white"/>
                <path
                    d="M237.208 94.6229C237.232 94.5604 237.255 94.4901 237.255 94.4199C237.255 94.3964 237.255 94.3652 237.263 94.3418C237.224 94.4823 237.224 94.4745 237.263 94.3183C237.513 94.0372 237.302 93.5373 236.927 93.4905C236.896 93.4905 236.857 93.4905 236.826 93.4905C236.802 93.4905 236.771 93.4748 236.724 93.467C236.654 93.4592 236.583 93.467 236.513 93.467C236.248 93.5061 236.06 93.6623 235.896 93.8575C235.592 94.209 235.693 94.8104 235.974 95.1462C236.169 95.3883 236.497 95.482 236.794 95.3571C237.107 95.2243 237.232 94.9275 237.2 94.6229H237.208Z"
                    fill="white"/>
                <path
                    d="M237.669 94.0606C237.693 93.8654 237.623 93.7014 237.49 93.5842C237.49 93.5686 237.474 93.5608 237.466 93.5452C237.341 93.3421 237.052 93.2562 236.834 93.2796C236.529 93.3187 236.279 93.5686 236.24 93.8654C236.146 94.5292 236.959 94.8729 237.404 94.4511C237.544 94.3652 237.646 94.2168 237.669 94.0528V94.0606Z"
                    fill="white"/>
                <path
                    d="M243.94 100.496C243.683 100.199 243.253 100.121 242.941 100.371C242.644 100.598 242.558 101.082 242.816 101.371C243.073 101.668 243.495 101.746 243.815 101.496C244.112 101.269 244.198 100.785 243.94 100.496Z"
                    fill="white"/>
                <path
                    d="M246.502 112.789C245.901 112.094 244.768 112.969 245.37 113.664C245.971 114.359 247.103 113.484 246.502 112.789Z"
                    fill="white"/>
                <path
                    d="M241.23 116.889C240.488 116.03 239.083 117.108 239.832 117.975C240.574 118.834 241.98 117.756 241.23 116.889Z"
                    fill="white"/>
            </g>
        </g>
        <path d="M120.286 49.5108L117.826 49.7833L122.904 95.6367L125.365 95.3642L120.286 49.5108Z" fill="#B3B3B3"/>
        <path d="M119.641 43.7289L117.181 44.0015L117.821 49.7846L120.282 49.512L119.641 43.7289Z" fill="black"/>
        <path
            d="M117.184 43.9984L117.816 42.3661C117.98 41.9522 118.543 41.9053 118.777 42.2802L119.644 43.7251L117.184 43.9984Z"
            fill="black"/>
        <path opacity="0.28" d="M119.032 43.7919L118.418 43.8598L124.138 95.504L124.752 95.4361L119.032 43.7919Z"
              fill="white"/>
        <path
            d="M131.024 148.926L129.102 149.137C128.532 149.199 127.97 149.168 127.431 149.043C125.877 148.692 124.541 147.614 123.901 146.083L123.05 144.052L122.979 143.888L120.652 122.833C120.621 122.544 120.613 122.255 120.629 121.974L121.089 113.671C121.121 113.133 121.097 112.594 121.027 112.063C120.941 111.375 120.777 110.696 120.535 110.04L119.223 106.533C119.02 105.986 118.879 105.408 118.809 104.838C118.762 104.432 118.746 104.018 118.762 103.612L119.129 95.8803C119.184 94.7947 120.004 93.92 121.082 93.8028L126.752 93.178C127.829 93.0609 128.829 93.7325 129.11 94.7791L131.164 102.238C131.266 102.636 131.344 103.042 131.383 103.448C131.445 104.026 131.43 104.62 131.352 105.198L130.836 108.907C130.743 109.602 130.727 110.298 130.797 110.985C130.844 111.524 130.938 112.047 131.094 112.57L133.359 120.576C133.437 120.849 133.492 121.138 133.523 121.427L135.85 142.483L135.819 142.654L135.429 144.826C135.132 146.458 134.07 147.801 132.633 148.489C132.133 148.731 131.594 148.887 131.024 148.949V148.926Z"
            fill="#04316C"/>
        <path
            d="M133.085 121.677C133.038 121.263 132.664 120.958 132.25 121.005C131.836 121.052 131.531 121.427 131.578 121.841C131.625 122.255 132 122.559 132.414 122.512C132.828 122.466 133.132 122.091 133.085 121.677Z"
            fill="#12467F"/>
        <path
            d="M130.062 122.013C130.016 121.599 129.641 121.294 129.227 121.341C128.813 121.388 128.508 121.763 128.555 122.177C128.602 122.59 128.977 122.895 129.391 122.848C129.805 122.801 130.109 122.426 130.062 122.013Z"
            fill="#12467F"/>
        <path
            d="M127.032 122.348C126.986 121.935 126.611 121.63 126.197 121.677C125.783 121.724 125.478 122.099 125.525 122.512C125.572 122.926 125.947 123.231 126.361 123.184C126.775 123.137 127.079 122.762 127.032 122.348Z"
            fill="#12467F"/>
        <path
            d="M124.01 122.684C123.963 122.27 123.588 121.966 123.174 122.013C122.76 122.059 122.456 122.434 122.503 122.848C122.549 123.262 122.924 123.567 123.338 123.52C123.752 123.473 124.057 123.098 124.01 122.684Z"
            fill="#12467F"/>
        <path
            d="M120.98 123.02C120.956 122.786 120.816 122.583 120.628 122.466L120.761 123.637C120.917 123.481 121.003 123.254 120.98 123.02Z"
            fill="#12467F"/>
        <path
            d="M133.421 124.082C133.452 124.348 133.616 124.566 133.843 124.676L133.702 123.403C133.507 123.559 133.39 123.809 133.421 124.074V124.082Z"
            fill="#12467F"/>
        <path
            d="M131.906 124.254C131.859 123.84 131.484 123.536 131.07 123.582C130.657 123.629 130.352 124.004 130.399 124.418C130.446 124.832 130.821 125.137 131.234 125.09C131.648 125.043 131.953 124.668 131.906 124.254Z"
            fill="#12467F"/>
        <path
            d="M128.883 124.59C128.837 124.176 128.462 123.871 128.048 123.918C127.634 123.965 127.329 124.34 127.376 124.754C127.423 125.168 127.798 125.472 128.212 125.426C128.626 125.379 128.93 125.004 128.883 124.59Z"
            fill="#12467F"/>
        <path
            d="M125.853 124.926C125.806 124.512 125.431 124.207 125.017 124.254C124.604 124.301 124.299 124.676 124.346 125.09C124.393 125.504 124.768 125.808 125.181 125.761C125.595 125.715 125.9 125.34 125.853 124.926Z"
            fill="#12467F"/>
        <path
            d="M122.83 125.262C122.784 124.848 122.409 124.543 121.995 124.59C121.581 124.637 121.276 125.012 121.323 125.426C121.37 125.84 121.745 126.144 122.159 126.097C122.573 126.05 122.877 125.676 122.83 125.262Z"
            fill="#12467F"/>
        <path
            d="M133.593 126.285C133.546 125.871 133.171 125.566 132.757 125.613C132.343 125.66 132.038 126.035 132.085 126.449C132.132 126.863 132.507 127.167 132.921 127.12C133.335 127.073 133.64 126.699 133.593 126.285Z"
            fill="#12467F"/>
        <path
            d="M130.57 126.613C130.523 126.199 130.148 125.894 129.735 125.941C129.321 125.988 129.016 126.363 129.063 126.777C129.11 127.191 129.485 127.495 129.899 127.448C130.312 127.402 130.617 127.027 130.57 126.613Z"
            fill="#12467F"/>
        <path
            d="M127.548 126.949C127.501 126.535 127.126 126.23 126.712 126.277C126.298 126.324 125.994 126.699 126.04 127.113C126.087 127.526 126.462 127.831 126.876 127.784C127.29 127.737 127.595 127.362 127.548 126.949Z"
            fill="#12467F"/>
        <path
            d="M124.518 127.284C124.471 126.87 124.096 126.566 123.682 126.613C123.268 126.66 122.964 127.034 123.01 127.448C123.057 127.862 123.432 128.167 123.846 128.12C124.26 128.073 124.565 127.698 124.518 127.284Z"
            fill="#12467F"/>
        <path
            d="M121.495 127.62C121.472 127.386 121.331 127.183 121.144 127.066L121.276 128.237C121.433 128.081 121.518 127.862 121.495 127.62Z"
            fill="#12467F"/>
        <path
            d="M133.929 128.69C133.96 128.956 134.124 129.174 134.351 129.284L134.21 128.011C134.015 128.167 133.898 128.417 133.929 128.69Z"
            fill="#12467F"/>
        <path
            d="M132.413 128.854C132.366 128.44 131.991 128.136 131.578 128.183C131.164 128.229 130.859 128.604 130.906 129.018C130.953 129.432 131.328 129.737 131.742 129.69C132.155 129.643 132.46 129.268 132.413 128.854Z"
            fill="#12467F"/>
        <path
            d="M129.391 129.19C129.344 128.776 128.969 128.471 128.555 128.518C128.141 128.565 127.837 128.94 127.884 129.354C127.931 129.768 128.305 130.073 128.719 130.026C129.133 129.979 129.438 129.604 129.391 129.19Z"
            fill="#12467F"/>
        <path
            d="M126.361 129.526C126.314 129.112 125.939 128.807 125.525 128.854C125.111 128.901 124.807 129.276 124.853 129.69C124.9 130.104 125.275 130.408 125.689 130.361C126.103 130.315 126.408 129.94 126.361 129.526Z"
            fill="#12467F"/>
        <path
            d="M123.338 129.862C123.291 129.448 122.917 129.143 122.503 129.19C122.089 129.237 121.784 129.612 121.831 130.026C121.878 130.44 122.253 130.744 122.667 130.697C123.081 130.65 123.385 130.276 123.338 129.862Z"
            fill="#12467F"/>
        <path
            d="M134.108 130.885C134.061 130.471 133.687 130.166 133.273 130.213C132.859 130.26 132.554 130.635 132.601 131.049C132.648 131.463 133.023 131.767 133.437 131.72C133.851 131.674 134.155 131.299 134.108 130.885Z"
            fill="#12467F"/>
        <path
            d="M131.078 131.213C131.031 130.799 130.656 130.494 130.242 130.541C129.828 130.588 129.524 130.963 129.571 131.377C129.618 131.791 129.992 132.095 130.406 132.048C130.82 132.002 131.125 131.627 131.078 131.213Z"
            fill="#12467F"/>
        <path
            d="M128.056 131.549C128.009 131.135 127.634 130.83 127.22 130.877C126.806 130.924 126.501 131.299 126.548 131.713C126.595 132.127 126.97 132.431 127.384 132.384C127.798 132.337 128.102 131.963 128.056 131.549Z"
            fill="#12467F"/>
        <path
            d="M125.025 131.884C124.978 131.471 124.603 131.166 124.189 131.213C123.775 131.26 123.471 131.635 123.518 132.048C123.565 132.462 123.939 132.767 124.353 132.72C124.767 132.673 125.072 132.298 125.025 131.884Z"
            fill="#12467F"/>
        <path
            d="M122.003 132.22C121.979 131.986 121.839 131.783 121.651 131.666L121.784 132.837C121.94 132.681 122.026 132.455 122.003 132.22Z"
            fill="#12467F"/>
        <path
            d="M134.444 133.29C134.475 133.556 134.639 133.775 134.866 133.884L134.725 132.611C134.53 132.767 134.413 133.017 134.444 133.283V133.29Z"
            fill="#12467F"/>
        <path
            d="M132.921 133.454C132.874 133.04 132.499 132.736 132.085 132.783C131.671 132.83 131.367 133.204 131.414 133.618C131.461 134.032 131.835 134.337 132.249 134.29C132.663 134.243 132.968 133.868 132.921 133.454Z"
            fill="#12467F"/>
        <path
            d="M129.899 133.79C129.852 133.376 129.477 133.072 129.063 133.118C128.649 133.165 128.344 133.54 128.391 133.954C128.438 134.368 128.813 134.673 129.227 134.626C129.641 134.579 129.945 134.204 129.899 133.79Z"
            fill="#12467F"/>
        <path
            d="M126.876 134.126C126.829 133.712 126.455 133.407 126.041 133.454C125.627 133.501 125.322 133.876 125.369 134.29C125.416 134.704 125.791 135.009 126.205 134.962C126.619 134.915 126.923 134.54 126.876 134.126Z"
            fill="#12467F"/>
        <path
            d="M123.846 134.462C123.799 134.048 123.424 133.743 123.01 133.79C122.596 133.837 122.292 134.212 122.339 134.626C122.386 135.04 122.76 135.344 123.174 135.297C123.588 135.251 123.893 134.876 123.846 134.462Z"
            fill="#12467F"/>
        <path
            d="M134.616 135.485C134.569 135.071 134.194 134.766 133.78 134.813C133.366 134.86 133.062 135.235 133.109 135.649C133.156 136.063 133.53 136.367 133.944 136.321C134.358 136.274 134.663 135.899 134.616 135.485Z"
            fill="#12467F"/>
        <path
            d="M131.586 135.821C131.539 135.407 131.164 135.102 130.75 135.149C130.336 135.196 130.032 135.571 130.078 135.985C130.125 136.399 130.5 136.703 130.914 136.656C131.328 136.61 131.633 136.235 131.586 135.821Z"
            fill="#12467F"/>
        <path
            d="M128.563 136.156C128.516 135.743 128.141 135.438 127.727 135.485C127.314 135.532 127.009 135.907 127.056 136.32C127.103 136.734 127.478 137.039 127.891 136.992C128.305 136.945 128.61 136.57 128.563 136.156Z"
            fill="#12467F"/>
        <path
            d="M125.54 136.485C125.494 136.071 125.119 135.766 124.705 135.813C124.291 135.86 123.986 136.235 124.033 136.649C124.08 137.063 124.455 137.367 124.869 137.32C125.283 137.273 125.587 136.899 125.54 136.485Z"
            fill="#12467F"/>
        <path
            d="M122.511 136.82C122.487 136.586 122.347 136.383 122.159 136.266L122.292 137.437C122.448 137.281 122.534 137.062 122.511 136.82Z"
            fill="#12467F"/>
        <path
            d="M134.952 137.89C134.983 138.156 135.147 138.375 135.374 138.484L135.233 137.211C135.038 137.367 134.921 137.617 134.952 137.883V137.89Z"
            fill="#12467F"/>
        <path
            d="M133.437 138.054C133.39 137.64 133.015 137.336 132.601 137.383C132.187 137.43 131.882 137.804 131.929 138.218C131.976 138.632 132.351 138.937 132.765 138.89C133.179 138.843 133.484 138.468 133.437 138.054Z"
            fill="#12467F"/>
        <path
            d="M130.406 138.39C130.36 137.976 129.985 137.672 129.571 137.719C129.157 137.765 128.852 138.14 128.899 138.554C128.946 138.968 129.321 139.273 129.735 139.226C130.149 139.179 130.453 138.804 130.406 138.39Z"
            fill="#12467F"/>
        <path
            d="M127.384 138.726C127.337 138.304 126.962 138.008 126.548 138.054C126.134 138.101 125.829 138.476 125.876 138.89C125.923 139.304 126.298 139.609 126.712 139.562C127.126 139.515 127.431 139.14 127.384 138.726Z"
            fill="#12467F"/>
        <path
            d="M124.353 139.062C124.307 138.648 123.932 138.343 123.518 138.39C123.104 138.437 122.799 138.812 122.846 139.226C122.893 139.64 123.268 139.944 123.682 139.897C124.096 139.851 124.4 139.476 124.353 139.062Z"
            fill="#12467F"/>
        <path
            d="M135.124 140.085C135.077 139.671 134.702 139.367 134.288 139.413C133.874 139.46 133.57 139.835 133.617 140.249C133.663 140.663 134.038 140.968 134.452 140.921C134.866 140.874 135.171 140.499 135.124 140.085Z"
            fill="#12467F"/>
        <path
            d="M132.101 140.421C132.054 140.007 131.679 139.702 131.266 139.749C130.852 139.796 130.547 140.171 130.594 140.585C130.641 140.999 131.016 141.303 131.43 141.256C131.843 141.21 132.148 140.835 132.101 140.421Z"
            fill="#12467F"/>
        <path
            d="M129.071 140.757C129.024 140.343 128.649 140.038 128.236 140.085C127.822 140.132 127.517 140.507 127.564 140.921C127.611 141.335 127.986 141.639 128.4 141.592C128.813 141.545 129.118 141.171 129.071 140.757Z"
            fill="#12467F"/>
        <path
            d="M126.048 141.092C126.001 140.679 125.626 140.374 125.213 140.421C124.799 140.468 124.494 140.843 124.541 141.256C124.588 141.67 124.963 141.975 125.377 141.928C125.79 141.881 126.095 141.506 126.048 141.092Z"
            fill="#12467F"/>
        <path
            d="M123.018 141.428C122.995 141.194 122.854 140.999 122.667 140.874L122.8 142.045C122.956 141.889 123.042 141.663 123.018 141.428Z"
            fill="#12467F"/>
        <path
            d="M135.459 142.491C135.459 142.553 135.483 142.608 135.498 142.662L135.826 142.623L135.733 141.811C135.537 141.967 135.42 142.217 135.452 142.483L135.459 142.491Z"
            fill="#12467F"/>
        <path
            d="M133.109 141.991C132.695 142.037 132.39 142.412 132.437 142.826C132.437 142.889 132.461 142.943 132.476 142.998L133.937 142.834C133.937 142.779 133.944 142.717 133.937 142.654C133.89 142.24 133.515 141.936 133.101 141.983L133.109 141.991Z"
            fill="#12467F"/>
        <path
            d="M130.079 142.327C129.665 142.373 129.36 142.748 129.407 143.162C129.407 143.225 129.43 143.279 129.446 143.334L130.906 143.17C130.906 143.115 130.914 143.053 130.906 142.99C130.86 142.576 130.485 142.272 130.071 142.319L130.079 142.327Z"
            fill="#12467F"/>
        <path
            d="M127.056 142.662C126.642 142.709 126.337 143.084 126.384 143.498C126.384 143.56 126.407 143.615 126.423 143.67L127.883 143.506C127.883 143.451 127.891 143.389 127.883 143.326C127.837 142.912 127.462 142.608 127.048 142.654L127.056 142.662Z"
            fill="#12467F"/>
        <path
            d="M124.026 142.99C123.612 143.037 123.307 143.412 123.354 143.826C123.354 143.889 123.377 143.943 123.393 143.998L124.853 143.834C124.853 143.771 124.861 143.717 124.853 143.654C124.807 143.24 124.432 142.936 124.018 142.983L124.026 142.99Z"
            fill="#12467F"/>
        <g style={{mixBlendMode:"multiply"}} opacity="0.18">
            <path
                d="M130.797 110.977L121.011 112.063C120.925 111.375 120.761 110.696 120.519 110.04L119.207 106.533C119.004 105.987 118.863 105.409 118.793 104.838L131.375 103.448C131.437 104.026 131.422 104.62 131.343 105.198L130.828 108.907C130.734 109.603 130.719 110.298 130.789 110.985L130.797 110.977Z"
                fill="#04316C"/>
        </g>
        <g style={{mixBlendMode:"multiply"}} opacity="0.18">
            <path
                d="M131.024 148.926L129.102 149.137C128.532 149.199 127.97 149.168 127.431 149.043C125.877 148.692 124.541 147.614 123.901 146.083L123.05 144.053L135.819 142.639L135.428 144.81C135.132 146.442 134.069 147.786 132.632 148.473C132.133 148.715 131.594 148.871 131.024 148.934V148.926Z"
                fill="#04316C"/>
        </g>
        <path
            d="M130.391 143.24L128.47 143.451C127.899 143.514 127.337 143.498 126.806 143.412L124.526 122.84C124.401 121.7 125.447 120.646 126.876 120.49H126.931C128.352 120.326 129.61 121.122 129.735 122.262L132.015 142.834C131.515 143.037 130.969 143.178 130.399 143.24H130.391Z"
            fill="#FCCB02"/>
        <path
            d="M189.591 147.879C189.591 147.879 189.536 147.825 189.513 147.801C185.818 144.404 184.686 138.992 186.717 134.407C187.373 132.915 187.849 131.322 188.115 129.659H188.154V79.6198C188.154 78.1906 191.817 77.0269 196.331 77.0269H204.5V129.659C204.5 133.204 203.563 136.516 201.923 139.374C199.556 143.506 195.729 146.661 191.153 148.121C191.09 148.145 191.028 148.168 190.957 148.184C190.481 148.332 189.958 148.215 189.591 147.879Z"
            fill="#FCCB02"/>
        <path d="M200.696 113.023H192.519V56.307L193.394 57.0099L200.696 62.8518V113.023Z" fill="#9B9CA0"/>
        <g style={{mixBlendMode:"multiply"}}>
            <path d="M193.394 113.023H192.519V56.307L193.394 57.0099V113.023Z" fill="#9B9CA0"/>
        </g>
        <path d="M190.676 129.385V67.7564L196.401 63.6639L202.126 67.7564V129.385H190.676Z" fill="#828387"/>
        <path
            d="M198.447 129.385V72.2081H199.337V70.4821H198.447V68.7561H194.354V70.4821H193.472V72.2081H194.354V129.385H198.447Z"
            fill="#595C63"/>
        <path
            d="M193.488 70.8336H194.37V69.0998H197.767V70.8336H198.65V71.8645H197.767V129.385H194.37V71.8645H193.488V70.8336Z"
            fill="#9B9CA0"/>
        <path d="M196.737 62.2661H196.073V129.385H196.737V62.2661Z" fill="#9B9CA0"/>
        <path
            d="M199.338 129.659V77.0269H202.883C203.789 77.0269 204.516 77.7611 204.516 78.6592V129.651H199.338V129.659Z"
            fill="#FCCB02"/>
        <path
            d="M188.154 129.659V79.6198C188.154 78.1906 189.317 77.0269 190.746 77.0269H193.339V129.659H188.161H188.154Z"
            fill="#FCCB02"/>
        <path
            d="M195.964 103.347H195.893C194.03 103.347 192.519 104.857 192.519 106.721V112.922C192.519 114.785 194.03 116.296 195.893 116.296H195.964C197.827 116.296 199.337 114.785 199.337 112.922V106.721C199.337 104.857 197.827 103.347 195.964 103.347Z"
            fill="black"/>
        <path
            d="M189.591 147.879C189.591 147.879 189.536 147.825 189.513 147.801C185.818 144.404 184.686 138.992 186.717 134.407C187.373 132.915 187.849 131.322 188.115 129.659H188.154V82.4862H188.185C189.825 82.4862 191.16 83.8139 191.16 85.4618V148.129C191.098 148.153 191.036 148.176 190.965 148.192C190.489 148.34 189.966 148.223 189.598 147.887L189.591 147.879Z"
            fill="black"/>
        <path
            d="M201.923 139.374V85.0322C201.923 83.6264 203.063 82.4783 204.476 82.4783H204.5V129.651C204.5 133.197 203.563 136.508 201.923 139.366V139.374Z"
            fill="black"/>
        <path
            d="M199.322 134.259L198.307 136.922C197.643 138.671 195.167 138.671 194.503 136.922L193.488 134.259C193.144 133.353 193.816 132.376 194.784 132.376H198.025C198.994 132.376 199.666 133.345 199.322 134.259Z"
            fill="black"/>
        <path opacity="0.54"
              d="M194.558 133.767C194.558 133.673 194.628 133.54 194.784 133.54H198.025C198.181 133.54 198.252 133.673 198.252 133.767C198.252 133.79 198.252 133.821 198.236 133.853L197.221 136.516C197.026 137.039 196.541 137.078 196.401 137.078C196.26 137.078 195.784 137.039 195.581 136.516L194.565 133.853C194.565 133.853 194.55 133.798 194.55 133.774L194.558 133.767Z"
              fill="#595C63"/>
        <path d="M189.762 85.3759H187.458V86.602H189.762V85.3759Z" fill="black"/>
        <path d="M189.762 88.6717H187.458V89.8979H189.762V88.6717Z" fill="black"/>
        <path d="M189.762 91.9596H187.458V93.1858H189.762V91.9596Z" fill="black"/>
        <path d="M189.762 95.2477H187.458V96.4739H189.762V95.2477Z" fill="black"/>
        <path d="M189.762 98.5436H187.458V99.7697H189.762V98.5436Z" fill="black"/>
        <path d="M189.762 101.832H187.458V103.058H189.762V101.832Z" fill="black"/>
        <path d="M189.762 105.12H187.458V106.346H189.762V105.12Z" fill="black"/>
        <path d="M189.762 108.415H187.458V109.642H189.762V108.415Z" fill="black"/>
        <path d="M189.762 111.703H187.458V112.93H189.762V111.703Z" fill="black"/>
        <path d="M189.762 114.991H187.458V116.218H189.762V114.991Z" fill="black"/>
        <path d="M189.762 118.287H187.458V119.513H189.762V118.287Z" fill="black"/>
        <path d="M189.762 121.575H187.458V122.801H189.762V121.575Z" fill="black"/>
        <path d="M189.762 124.871H187.458V126.097H189.762V124.871Z" fill="black"/>
        <path d="M189.762 128.159H187.458V129.385H189.762V128.159Z" fill="black"/>
        <path d="M205.25 85.3759H202.946V86.602H205.25V85.3759Z" fill="black"/>
        <path d="M205.25 88.6717H202.946V89.8979H205.25V88.6717Z" fill="black"/>
        <path d="M205.25 91.9596H202.946V93.1858H205.25V91.9596Z" fill="black"/>
        <path d="M205.25 95.2477H202.946V96.4739H205.25V95.2477Z" fill="black"/>
        <path d="M205.25 98.5436H202.946V99.7697H205.25V98.5436Z" fill="black"/>
        <path d="M205.25 101.832H202.946V103.058H205.25V101.832Z" fill="black"/>
        <path d="M205.25 105.12H202.946V106.346H205.25V105.12Z" fill="black"/>
        <path d="M205.25 108.415H202.946V109.642H205.25V108.415Z" fill="black"/>
        <path d="M205.25 111.703H202.946V112.93H205.25V111.703Z" fill="black"/>
        <path d="M205.25 114.991H202.946V116.218H205.25V114.991Z" fill="black"/>
        <path d="M205.25 118.287H202.946V119.513H205.25V118.287Z" fill="black"/>
        <path d="M205.25 121.575H202.946V122.801H205.25V121.575Z" fill="black"/>
        <path d="M205.25 124.871H202.946V126.097H205.25V124.871Z" fill="black"/>
        <path d="M205.25 128.159H202.946V129.385H205.25V128.159Z" fill="black"/>
        <path opacity="0.54"
              d="M195.964 116.296H195.893C194.027 116.296 192.519 114.788 192.519 112.922V109.821H199.337V112.922C199.337 114.788 197.822 116.296 195.964 116.296Z"
              fill="#595C63"/>
        <path
            d="M89.5522 93.9278L91.2783 90.3821C91.6453 89.6245 92.7622 89.7339 92.973 90.5461L93.8321 93.8107L100.463 100.16L98.3541 102.363L89.5366 93.9278H89.5522Z"
            fill="#FF0000"/>
        <path
            d="M61.3113 98.3093C55.4538 101.137 49.1042 101.738 43.1999 100.449C35.0384 98.6998 27.7438 93.3343 23.831 85.2275C17.0988 71.2632 22.9641 54.4795 36.9206 47.7473C47.7218 42.538 60.2179 44.8732 68.434 52.6598C70.8473 54.9325 72.8779 57.666 74.4008 60.8291C81.1331 74.7855 75.2756 91.5692 61.3191 98.3093H61.3113Z"
            fill="#999999"/>
        <path
            d="M67.2392 110.594C58.5389 114.796 49.0966 115.702 40.3259 113.804L43.2 100.449L49.1122 73.0283L68.4263 52.6676L77.814 42.7645C81.391 46.1384 84.4134 50.2153 86.6783 54.9013C96.6908 75.6446 87.9826 100.582 67.2392 110.594Z"
            fill="#999999"/>
        <path
            d="M40.6616 112.274C48.8387 113.664 57.5547 112.602 65.6615 108.697C86.069 98.8404 94.8006 74.6528 85.1631 54.6748C83.1012 50.3949 80.3443 46.6539 77.1031 43.5221L49.1199 73.0283L40.6694 112.274H40.6616Z"
            fill="#282830"/>
        <path
            d="M93.7306 133.501L39.248 81.3772C35.6476 77.933 35.5226 72.2239 38.9669 68.6313L44.2698 63.0861C47.7141 59.4857 53.4232 59.3608 57.0158 62.805L111.498 114.921L93.7306 133.501Z"
            fill="#999999"/>
        <g style={{mixBlendMode:"multiply"}}>
            <path
                d="M93.7306 133.501L39.248 81.3772C35.6476 77.933 35.5226 72.2239 38.9669 68.6313L44.2698 63.0861C47.7141 59.4857 53.4232 59.3608 57.0158 62.805L111.498 114.921L93.7306 133.501Z"
                fill="#999999"/>
        </g>
        <path
            d="M41.7158 78.7999C39.5446 76.7224 39.4665 73.2704 41.544 71.0992L46.847 65.5541C48.9244 63.3829 52.3765 63.3048 54.5477 65.3823L106.453 115.031L93.6133 128.448L41.7158 78.7999Z"
            fill="#999999"/>
        <path
            d="M51.3454 75.1604C52.5247 73.9264 52.4778 71.9739 51.2517 70.7946C50.0177 69.6153 48.0652 69.6622 46.8859 70.8883C45.7066 72.1223 45.7534 74.0748 46.9796 75.2541C48.2136 76.4334 50.1661 76.3866 51.3454 75.1604Z"
            fill="black"/>
        <path opacity="0.26"
              d="M47.3936 74.8324C46.4018 73.8796 46.3705 72.302 47.3155 71.3101C48.2684 70.3183 49.846 70.2792 50.8379 71.232C51.8297 72.1848 51.8688 73.7625 50.916 74.7543C49.9631 75.7462 48.3855 75.7853 47.3936 74.8324Z"
              fill="#999999"/>
        <path
            d="M58.2966 81.8145C59.6945 80.3463 59.6477 78.0267 58.1794 76.6209C56.7111 75.2229 54.3915 75.2698 52.9857 76.738C51.5799 78.2063 51.6346 80.5259 53.1029 81.9317C54.5712 83.3375 56.8907 83.2828 58.2966 81.8145Z"
            fill="black"/>
        <path opacity="0.26"
              d="M53.5167 81.5022C52.2905 80.3307 52.2437 78.3782 53.423 77.152C54.5945 75.9258 56.547 75.879 57.7732 77.0583C58.9993 78.2298 59.0384 80.1823 57.8669 81.4084C56.6954 82.6346 54.7429 82.6815 53.5167 81.5022Z"
              fill="#999999"/>
        <path d="M72.266 73.4853L50.5846 96.1498L54.885 100.264L76.5664 77.5991L72.266 73.4853Z" fill="#6B84A5"/>
        <path
            d="M122.003 134.306L113.568 143.123C113.404 143.295 113.232 143.459 113.045 143.607C110.147 146.138 105.75 146.153 102.837 143.514L54.8914 100.246L76.5719 77.5815L121.917 123.559C124.674 126.355 124.869 130.736 122.464 133.751C122.417 133.814 122.378 133.876 122.323 133.915C122.229 134.04 122.12 134.173 122.003 134.29V134.306Z"
            fill="#04316C"/>
        <path
            d="M129.125 160.657C130.89 149.988 122.776 141.795 122.432 141.452L119.675 144.232C119.745 144.302 126.696 151.371 125.267 160.016L129.133 160.657H129.125Z"
            fill="black"/>
        <path d="M119.777 136.649L114.799 141.853L118.845 145.724L123.823 140.52L119.777 136.649Z" fill="#FCCB02"/>
        <path d="M119.777 136.649L114.799 141.853L118.845 145.724L123.823 140.52L119.777 136.649Z" fill="#04316C"/>
        <g style={{mixBlendMode:"multiply"}} opacity="0.4">
            <path
                d="M117.207 131.439L117.09 131.322L115.677 129.971L113.755 128.136L112.225 126.667L110.311 124.832L108.773 123.364L106.859 121.528L105.328 120.06L103.883 118.678C100.033 114.999 93.9413 115.132 90.2628 118.974L90.1925 119.045L91.5749 120.365L93.0978 121.825L95.0269 123.668L96.5499 125.129L98.4711 126.964L100.002 128.432L101.923 130.268L103.454 131.736L105.375 133.571L106.898 135.032L110.358 138.343C110.491 138.234 110.616 138.117 110.733 137.992L113.716 134.876L115.177 133.353L117.207 131.431V131.439Z"
                fill="#04316C"/>
        </g>
        <path d="M115.185 133.361L113.724 134.891L106.906 135.04L105.375 133.579L115.185 133.361Z" fill="black"/>
        <path d="M117.208 131.439L103.462 131.744L101.923 130.276L115.677 129.971L117.208 131.439Z" fill="black"/>
        <path d="M113.755 128.136L100.002 128.44L98.4711 126.972L112.224 126.667L113.755 128.136Z" fill="black"/>
        <path d="M110.311 124.84L96.55 125.137L95.0271 123.676L108.773 123.364L110.311 124.84Z" fill="black"/>
        <path d="M106.859 121.536L93.0974 121.833L91.5745 120.373L105.328 120.068L106.859 121.536Z" fill="black"/>
        <path
            d="M84.6239 106.861L84.5692 106.924C82.9135 108.65 80.1722 108.712 78.4383 107.056L69.1601 98.1765C67.434 96.5208 67.3716 93.7795 69.0273 92.0535L69.082 91.991C70.7377 90.265 73.479 90.2025 75.205 91.8582L84.4833 100.738C86.2093 102.394 86.2718 105.135 84.6161 106.861H84.6239Z"
            fill="#ED1C24"/>
        <g style={{mixBlendMode:"multiply"}}>
            <path
                d="M84.624 106.861L84.5693 106.924C82.9136 108.65 80.1723 108.712 78.4385 107.056L73.7993 102.62L79.8521 96.2942L84.4912 100.73C86.2172 102.386 86.2797 105.127 84.624 106.853V106.861Z"
                fill="#C1272D"/>
        </g>
        <path d="M112.271 49.1375L104.93 42.1241L79.6803 71.0914L84.4444 75.6525L112.271 49.1375Z" fill="#060A0D"/>
        <path d="M75.9398 76.988L78.728 79.6512L81.7895 76.7303L78.7202 73.7937L75.9398 76.988Z" fill="#060A0D"/>
        <path
            d="M86.2483 78.1127L83.5929 75.5745L82.0856 77.0115L84.8113 79.62C85.2252 80.0105 85.8734 79.9949 86.2717 79.5888L86.2874 79.5732C86.6779 79.1593 86.67 78.511 86.2561 78.1127H86.2483Z"
            fill="#060A0D"/>
        <path
            d="M77.1424 69.4045C76.7285 69.014 76.0803 69.0218 75.682 69.4357L75.6663 69.4513C75.2758 69.8653 75.2837 70.5135 75.6976 70.9118L78.4233 73.5203L79.79 71.9505L77.1346 69.4123L77.1424 69.4045Z"
            fill="#060A0D"/>
        <path d="M79.6805 71.0913L77.9623 73.0673L82.5468 77.4565L84.4446 75.6524L79.6805 71.0913Z" fill="#060A0D"/>
        <path
            d="M111.366 50.0435L104.071 43.0613C103.564 42.5771 103.548 41.7805 104.032 41.2729L104.048 41.2572C104.532 40.7496 105.329 40.734 105.836 41.2182L113.131 48.2003C113.638 48.6846 113.654 49.4812 113.17 49.9888L113.154 50.0044C112.67 50.5121 111.873 50.5277 111.366 50.0435Z"
            fill="#060A0D"/>
        <path
            d="M202.251 141.709L201.352 141.553L198.947 135.063C198.268 133.228 198.619 131.174 199.869 129.674L202.618 126.363C203.039 125.855 203.407 125.308 203.711 124.731C204.211 123.809 204.555 122.801 204.726 121.763L213.317 71.3335C213.513 70.1854 213.497 69.0139 213.286 67.8815C213.185 67.3426 213.036 66.8037 212.849 66.2882L211.357 62.2505C210.678 60.4151 211.029 58.3611 212.279 56.8616L215.168 53.3783C215.684 52.7613 216.691 53.1284 216.691 53.9328L216.668 60.0949C216.652 61.5085 217.785 62.6644 219.198 62.6722L222.885 62.7113C224.298 62.7269 225.454 61.5944 225.47 60.1808L225.54 53.0425L226.641 53.2299L229.039 59.7044C229.718 61.5476 229.367 63.6094 228.102 65.1167L225.353 68.3969C225.165 68.6156 224.993 68.8421 224.829 69.0764C224.001 70.2557 223.447 71.6069 223.205 73.0361L214.661 123.45C214.442 124.754 214.489 126.082 214.793 127.355C214.887 127.737 214.997 128.112 215.137 128.479L216.629 132.517C217.308 134.352 216.957 136.406 215.707 137.906L213.411 140.671C212.786 141.421 211.568 140.975 211.576 139.999L211.623 134.805C211.638 133.368 210.482 132.189 209.038 132.173L204.945 132.134C203.508 132.119 202.329 133.275 202.305 134.72L202.235 141.702L202.251 141.709Z"
            fill="#999999"/>
        <g opacity="0.29">
            <path
                d="M208.359 118.259L215.587 75.8445C215.774 74.7435 216.819 74.0026 217.92 74.1902L217.959 74.1968C219.06 74.3844 219.801 75.4296 219.613 76.5306L212.387 118.937C212.2 120.038 211.155 120.779 210.054 120.591L210.015 120.585C208.914 120.397 208.173 119.352 208.361 118.251L208.359 118.259Z"
                fill="white"/>
            <path
                d="M209.998 120.943H209.959C208.671 120.716 207.796 119.482 208.014 118.194L215.239 75.7852C215.457 74.4965 216.691 73.6218 217.98 73.8405C219.316 74.067 220.182 75.3009 219.964 76.5896L212.74 118.998C212.521 120.287 211.287 121.161 209.998 120.943ZM217.91 74.5512C216.965 74.3872 216.106 75.0042 215.949 75.9101L208.725 118.318C208.569 119.224 209.178 120.084 210.084 120.24H210.123C211.029 120.404 211.888 119.787 212.044 118.881L219.269 76.4724C219.425 75.5665 218.816 74.7074 217.91 74.5512Z"
                fill="#666666"/>
        </g>
        <path opacity="0.29"
              d="M217.972 69.4825C216.278 69.1936 214.708 68.6391 213.294 67.8815C213.193 67.3426 213.044 66.8037 212.857 66.2882L211.365 62.2505C210.686 60.4151 211.037 58.3611 212.287 56.8616L215.176 53.3783C215.692 52.7613 216.699 53.1284 216.699 53.9328L216.676 60.0949C216.66 61.5085 217.793 62.6644 219.206 62.6722L222.893 62.7113C224.306 62.7269 225.462 61.5944 225.478 60.1808L225.548 53.0425L226.649 53.2299L229.047 59.7044C229.727 61.5476 229.375 63.6094 228.11 65.1167L225.361 68.3969C225.173 68.6156 225.001 68.8421 224.837 69.0764C222.721 69.7168 220.37 69.8808 217.98 69.4747L217.972 69.4825Z"
              fill="white"/>
        <path
            d="M218.058 68.9515C216.363 68.6626 214.793 68.1081 213.38 67.3505C213.278 66.8116 213.13 66.2727 212.942 65.7572L211.451 61.7195C210.771 59.8841 211.123 57.8301 212.372 56.3306L215.262 52.8473C215.777 52.2303 216.785 52.5974 216.785 53.4018L216.761 59.5639C216.746 60.9775 217.878 62.1334 219.292 62.149L222.978 62.1803C224.392 62.1959 225.548 61.0634 225.563 59.6498L225.634 52.5115L226.735 52.6989L229.133 59.1734C229.812 61.0166 229.461 63.0784 228.195 64.5857L225.446 67.866C225.259 68.0846 225.087 68.3111 224.923 68.5454C222.806 69.1858 220.463 69.3499 218.066 68.9437L218.058 68.9515Z"
            fill="#999999"/>
        <path opacity="0.29"
              d="M202.251 141.71L201.352 141.553L198.947 135.063C198.268 133.228 198.619 131.174 199.869 129.674L202.618 126.363C203.039 125.855 203.407 125.308 203.711 124.731C205.265 124.496 206.89 124.504 208.545 124.785C210.896 125.184 213.021 126.097 214.793 127.37C214.887 127.753 214.997 128.128 215.137 128.495L216.629 132.533C217.308 134.368 216.957 136.422 215.707 137.922L213.411 140.686C212.786 141.436 211.568 140.991 211.576 140.015L211.623 134.821C211.638 133.384 210.482 132.205 209.038 132.189L204.945 132.15C203.508 132.134 202.329 133.29 202.305 134.735L202.235 141.717L202.251 141.71Z"
              fill="white"/>
        <path
            d="M202.118 142.506L201.22 142.35L198.814 135.86C198.135 134.024 198.486 131.97 199.736 130.471L202.485 127.159C202.907 126.652 203.274 126.105 203.578 125.527C205.132 125.293 206.757 125.301 208.413 125.582C210.763 125.98 212.888 126.894 214.661 128.167C214.754 128.55 214.864 128.925 215.004 129.292L216.496 133.329C217.176 135.165 216.824 137.219 215.574 138.718L213.278 141.483C212.654 142.233 211.435 141.788 211.443 140.811L211.49 135.618C211.505 134.181 210.35 133.001 208.905 132.978L204.812 132.939C203.375 132.923 202.196 134.079 202.18 135.524L202.11 142.506H202.118Z"
            fill="#999999"/>
        <path d="M224.415 204.728H72.3856L64.5756 119.349H232.225L224.415 204.728Z" fill="#86BD3D"/>
        <path d="M235.81 119.349H60.9907V127.12H235.81V119.349Z" fill="#608929"/>
        <path
            d="M148.932 76.5974L137.24 80.8695L136.818 79.4793L134.139 77.5815L134.514 55.237L138.044 54.2061L150.408 72.8486L148.932 76.5974Z"
            fill="#BFBCB5"/>
        <path
            d="M141.027 96.6847L139.177 90.3586L153.289 86.2427L155.132 92.561L148.627 94.4589L147.697 93.7872L147.533 94.7791L141.027 96.6847Z"
            fill="#BFBCB5"/>
        <path
            d="M147.299 92.4283C151.915 91.0772 154.562 86.2428 153.219 81.6271C151.868 77.0114 147.033 74.3638 142.418 75.7071C137.802 77.0582 135.154 81.8926 136.498 86.5083C137.849 91.1241 142.683 93.7717 147.299 92.4205V92.4283Z"
            fill="#BFBCB5"/>
        <g opacity="0.33">
            <mask id="mask2_9728_6354" style={{maskType:"luminance"}} maskUnits="userSpaceOnUse" x="134" y="54" width="22"
                  height="43">
                <path
                    d="M153.383 86.5551C153.852 84.9697 153.891 83.2359 153.399 81.5333C152.704 79.1591 151.095 77.2925 149.072 76.1991L150.4 72.8173L138.044 54.1748L136.467 54.6356L136.092 54.745L134.514 55.2058L134.139 77.5502L136.81 79.448L136.951 79.9401C135.928 81.9082 135.631 84.2512 136.303 86.5317C136.849 88.3983 137.958 89.9525 139.388 91.0615L141.02 96.6535L148.119 94.576L155.133 92.5298L153.391 86.5551H153.383Z"
                    fill="white"/>
            </mask>
            <g mask="url(#mask2_9728_6354)">
                <path
                    d="M124.611 63.867L120.597 71.2084L136.802 79.4402L137.021 80.2056L160.701 93.1702L164.715 85.8366L124.611 63.867Z"
                    fill="white"/>
                <path d="M128.371 56.996L126.364 60.6611L166.474 82.6205L168.481 78.9555L128.371 56.996Z" fill="white"/>
            </g>
        </g>
        <path d="M147.603 92.1394L147.229 92.2487L147.689 93.7795L148.259 94.5683L148.4 94.5292L147.603 92.1394Z"
              fill="#808080"/>
        <path
            d="M154.828 158.189L154.859 158.173C155.492 157.782 155.874 157.111 155.906 156.4C155.921 156.166 155.898 155.931 155.827 155.697L154.484 150.816C154.219 149.847 153.836 148.91 153.336 148.043C147.471 137.82 145.901 125.48 146.565 113.687L147.455 97.7313L147.674 93.795L144.636 93.6232L139.84 93.3577L139.622 97.3017L138.724 113.25C137.95 127.113 141.395 140.686 148.681 152.503L151.751 157.478C152.391 158.517 153.758 158.845 154.797 158.196L154.828 158.189Z"
            fill="#04316C"/>
        <path
            d="M176.368 151.894H176.329C175.587 151.909 174.9 151.55 174.486 150.972C174.353 150.785 174.243 150.574 174.173 150.339L172.674 145.497C172.377 144.537 172.189 143.545 172.143 142.545C171.58 130.775 166.254 119.529 159.342 109.946L149.993 96.9815L147.689 93.7872L150.157 92.0065L154.055 89.1949L156.366 92.397L165.707 105.354C173.83 116.616 178.234 129.908 178.453 143.795L178.547 149.637C178.562 150.863 177.586 151.87 176.368 151.886V151.894Z"
            fill="#04316C"/>
        <mask id="mask3_9728_6354" style={{maskType:"luminance"}} maskUnits="userSpaceOnUse" x="147" y="89" width="32"
              height="63">
            <path
                d="M176.414 151.948H176.375C175.633 151.964 174.946 151.597 174.54 151.027C174.399 150.839 174.298 150.628 174.22 150.394L172.728 145.552C172.431 144.591 172.244 143.599 172.205 142.6C171.643 130.83 166.316 119.584 159.404 110.009L150.056 97.044L147.752 93.8497L150.22 92.069L154.117 89.2574L156.429 92.4595L165.777 105.416C173.892 116.678 178.304 129.971 178.523 143.857L178.617 149.699C178.632 150.925 177.664 151.933 176.438 151.948H176.414Z"
                fill="white"/>
        </mask>
        <g mask="url(#mask3_9728_6354)">
            <path
                d="M153 89.8977C152.641 89.9993 152.258 89.7962 152.156 89.4369C152.055 89.0777 152.258 88.695 152.617 88.5935C152.977 88.4841 153.359 88.695 153.461 89.0542C153.57 89.4135 153.359 89.7962 153 89.8977Z"
                fill="#12467F"/>
            <path
                d="M150.376 90.6631C150.017 90.7725 149.634 90.5616 149.533 90.2024C149.431 89.8431 149.634 89.4604 149.993 89.3589C150.353 89.2495 150.735 89.4604 150.837 89.8197C150.939 90.1789 150.735 90.5616 150.376 90.6631Z"
                fill="#12467F"/>
            <path
                d="M147.752 91.4286C147.393 91.5379 147.01 91.3271 146.908 90.9678C146.807 90.6085 147.01 90.2258 147.369 90.1243C147.728 90.015 148.111 90.2258 148.213 90.5851C148.322 90.9444 148.111 91.3271 147.752 91.4286Z"
                fill="#12467F"/>
            <path
                d="M162.849 89.2885C162.489 89.3979 162.107 89.187 162.005 88.8277C161.904 88.4685 162.107 88.0858 162.466 87.9843C162.825 87.8749 163.208 88.0858 163.309 88.4451C163.411 88.8043 163.208 89.187 162.849 89.2885Z"
                fill="#12467F"/>
            <path
                d="M160.225 90.054C159.865 90.1633 159.483 89.9524 159.381 89.5932C159.272 89.2339 159.483 88.8512 159.842 88.7497C160.201 88.6404 160.584 88.8512 160.685 89.2105C160.787 89.5697 160.584 89.9524 160.225 90.054Z"
                fill="#12467F"/>
            <path
                d="M157.6 90.8194C157.241 90.9209 156.858 90.7179 156.757 90.3586C156.648 89.9993 156.858 89.6167 157.218 89.5151C157.577 89.4058 157.96 89.6167 158.061 89.9759C158.163 90.3352 157.96 90.7179 157.6 90.8194Z"
                fill="#12467F"/>
            <path
                d="M154.976 91.5925C154.617 91.7019 154.234 91.491 154.133 91.1317C154.023 90.7725 154.234 90.3898 154.593 90.2883C154.953 90.1789 155.335 90.3898 155.437 90.749C155.538 91.1083 155.335 91.491 154.976 91.5925Z"
                fill="#12467F"/>
            <path
                d="M152.352 92.358C151.993 92.4673 151.61 92.2564 151.509 91.8972C151.399 91.5379 151.61 91.1552 151.969 91.0537C152.329 90.9444 152.711 91.1552 152.813 91.5145C152.914 91.8738 152.711 92.2564 152.352 92.358Z"
                fill="#12467F"/>
            <path
                d="M149.728 93.1233C149.369 93.2326 148.986 93.0217 148.884 92.6625C148.775 92.3032 148.986 91.9205 149.345 91.819C149.704 91.7097 150.087 91.9205 150.189 92.2798C150.29 92.6391 150.087 93.0217 149.728 93.1233Z"
                fill="#12467F"/>
            <path
                d="M169.901 89.2886C169.542 89.3979 169.159 89.187 169.057 88.8278C168.948 88.4685 169.159 88.0858 169.518 87.9843C169.878 87.8828 170.26 88.0858 170.362 88.4451C170.471 88.8044 170.26 89.1871 169.901 89.2886Z"
                fill="#12467F"/>
            <path
                d="M167.277 90.0539C166.918 90.1633 166.535 89.9524 166.433 89.5932C166.332 89.2339 166.535 88.8512 166.894 88.7497C167.253 88.6403 167.636 88.8512 167.738 89.2105C167.847 89.5697 167.636 89.9524 167.277 90.0539Z"
                fill="#12467F"/>
            <path
                d="M164.652 90.8194C164.293 90.9287 163.91 90.7178 163.809 90.3586C163.707 89.9993 163.91 89.6166 164.27 89.5151C164.629 89.4058 165.012 89.6166 165.113 89.9759C165.222 90.3352 165.012 90.7178 164.652 90.8194Z"
                fill="#12467F"/>
            <path
                d="M162.037 91.5847C161.677 91.6941 161.295 91.4832 161.193 91.124C161.092 90.7647 161.295 90.382 161.654 90.2805C162.013 90.1711 162.396 90.382 162.497 90.7413C162.607 91.1005 162.396 91.4832 162.037 91.5847Z"
                fill="#12467F"/>
            <path
                d="M159.412 92.35C159.053 92.4516 158.67 92.2485 158.569 91.8892C158.467 91.53 158.67 91.1473 159.029 91.0458C159.389 90.9364 159.771 91.1473 159.873 91.5066C159.982 91.8658 159.771 92.2485 159.412 92.35Z"
                fill="#12467F"/>
            <path
                d="M156.788 93.1233C156.429 93.2249 156.046 93.0218 155.945 92.6625C155.843 92.3033 156.046 91.9206 156.406 91.8191C156.765 91.7175 157.148 91.9206 157.249 92.2798C157.358 92.6391 157.148 93.0218 156.788 93.1233Z"
                fill="#12467F"/>
            <path
                d="M154.164 93.8887C153.805 93.998 153.422 93.7871 153.32 93.4279C153.219 93.0686 153.422 92.6859 153.781 92.5844C154.14 92.4751 154.523 92.6859 154.625 93.0452C154.726 93.4045 154.523 93.7871 154.164 93.8887Z"
                fill="#12467F"/>
            <path
                d="M151.54 94.6541C151.181 94.7634 150.798 94.5525 150.696 94.1933C150.595 93.834 150.798 93.4513 151.157 93.3498C151.516 93.2404 151.899 93.4513 152.001 93.8106C152.102 94.1698 151.899 94.5525 151.54 94.6541Z"
                fill="#12467F"/>
            <path
                d="M148.916 95.4194C148.557 95.5288 148.174 95.3179 148.073 94.9586C147.971 94.5994 148.174 94.2167 148.533 94.1152C148.893 94.0136 149.275 94.2167 149.377 94.576C149.478 94.9352 149.275 95.3179 148.916 95.4194Z"
                fill="#12467F"/>
            <path
                d="M171.877 90.9756C171.518 91.0849 171.135 90.8741 171.034 90.5148C170.924 90.1555 171.135 89.7728 171.495 89.6713C171.854 89.562 172.237 89.7728 172.338 90.1321C172.44 90.4914 172.237 90.8741 171.877 90.9756Z"
                fill="#12467F"/>
            <path
                d="M169.261 91.7487C168.901 91.8581 168.519 91.6472 168.417 91.2879C168.316 90.9287 168.519 90.546 168.878 90.4444C169.237 90.3351 169.62 90.546 169.721 90.9052C169.823 91.2645 169.62 91.6472 169.261 91.7487Z"
                fill="#12467F"/>
            <path
                d="M166.636 92.5142C166.277 92.6235 165.894 92.4126 165.793 92.0534C165.683 91.6941 165.894 91.3114 166.254 91.2099C166.613 91.1006 166.996 91.3114 167.097 91.6707C167.199 92.0299 166.996 92.4126 166.636 92.5142Z"
                fill="#12467F"/>
            <path
                d="M164.012 93.2795C163.653 93.3889 163.27 93.178 163.169 92.8188C163.059 92.4595 163.27 92.0768 163.63 91.9753C163.989 91.8737 164.372 92.0768 164.473 92.4361C164.582 92.7953 164.372 93.178 164.012 93.2795Z"
                fill="#12467F"/>
            <path
                d="M161.388 94.0448C161.029 94.1464 160.646 93.9433 160.545 93.584C160.435 93.2248 160.646 92.8421 161.006 92.7406C161.365 92.6312 161.748 92.8421 161.849 93.2013C161.951 93.5606 161.748 93.9433 161.388 94.0448Z"
                fill="#12467F"/>
            <path
                d="M158.764 94.8103C158.405 94.9118 158.022 94.7087 157.92 94.3495C157.819 93.9902 158.022 93.6075 158.381 93.506C158.74 93.3967 159.123 93.6075 159.225 93.9668C159.334 94.3261 159.123 94.7087 158.764 94.8103Z"
                fill="#12467F"/>
            <path
                d="M156.14 95.5757C155.781 95.685 155.398 95.4742 155.296 95.1149C155.187 94.7557 155.398 94.373 155.757 94.2714C156.117 94.1621 156.499 94.373 156.601 94.7322C156.702 95.0915 156.499 95.4742 156.14 95.5757Z"
                fill="#12467F"/>
            <path
                d="M153.523 96.3488C153.164 96.4582 152.781 96.2473 152.68 95.8881C152.578 95.5288 152.781 95.1461 153.141 95.0446C153.5 94.943 153.883 95.1461 153.984 95.5054C154.094 95.8646 153.883 96.2473 153.523 96.3488Z"
                fill="#12467F"/>
            <path
                d="M150.9 97.1142C150.54 97.2235 150.158 97.0127 150.056 96.6534C149.947 96.2941 150.158 95.9115 150.517 95.8099C150.876 95.7006 151.259 95.9115 151.36 96.2707C151.47 96.63 151.259 97.0127 150.9 97.1142Z"
                fill="#12467F"/>
            <path
                d="M171.064 93.2795C170.705 93.3888 170.322 93.178 170.221 92.8187C170.119 92.4594 170.322 92.0768 170.682 91.9752C171.041 91.8659 171.424 92.0768 171.525 92.436C171.627 92.7953 171.424 93.178 171.064 93.2795Z"
                fill="#12467F"/>
            <path
                d="M168.441 94.0449C168.081 94.1542 167.699 93.9433 167.597 93.5841C167.496 93.2248 167.699 92.8421 168.058 92.7406C168.417 92.6313 168.8 92.8421 168.901 93.2014C169.003 93.5606 168.8 93.9433 168.441 94.0449Z"
                fill="#12467F"/>
            <path
                d="M165.824 94.8103C165.465 94.9196 165.082 94.7088 164.981 94.3495C164.879 93.9903 165.082 93.6076 165.441 93.506C165.801 93.3967 166.183 93.6076 166.285 93.9668C166.387 94.3261 166.183 94.7088 165.824 94.8103Z"
                fill="#12467F"/>
            <path
                d="M163.2 95.5757C162.841 95.685 162.458 95.4742 162.356 95.1149C162.255 94.7556 162.458 94.3729 162.817 94.2714C163.176 94.1699 163.559 94.3729 163.661 94.7322C163.762 95.0915 163.559 95.4742 163.2 95.5757Z"
                fill="#12467F"/>
            <path
                d="M160.576 96.341C160.217 96.4426 159.834 96.2395 159.732 95.8802C159.623 95.521 159.834 95.1383 160.193 95.0368C160.552 94.9274 160.935 95.1383 161.037 95.4976C161.138 95.8568 160.935 96.2395 160.576 96.341Z"
                fill="#12467F"/>
            <path
                d="M157.951 97.1064C157.592 97.2157 157.209 97.0049 157.108 96.6456C157.006 96.2864 157.209 95.9037 157.569 95.8021C157.928 95.6928 158.311 95.9037 158.412 96.2629C158.514 96.6222 158.311 97.0049 157.951 97.1064Z"
                fill="#12467F"/>
            <path
                d="M155.327 97.8718C154.968 97.9811 154.586 97.7702 154.484 97.411C154.375 97.0517 154.586 96.669 154.945 96.5675C155.304 96.4582 155.687 96.669 155.788 97.0283C155.89 97.3876 155.687 97.7703 155.327 97.8718Z"
                fill="#12467F"/>
            <path
                d="M152.703 98.645C152.344 98.7544 151.961 98.5435 151.86 98.1842C151.758 97.825 151.961 97.4423 152.321 97.3408C152.68 97.2314 153.063 97.4423 153.164 97.8016C153.266 98.1608 153.063 98.5435 152.703 98.645Z"
                fill="#12467F"/>
            <path
                d="M150.087 99.4103C149.728 99.5119 149.345 99.3088 149.244 98.9496C149.134 98.5903 149.345 98.2076 149.704 98.1061C150.064 97.9967 150.446 98.2076 150.548 98.5669C150.657 98.9261 150.446 99.3088 150.087 99.4103Z"
                fill="#12467F"/>
            <path
                d="M173.049 94.9666C172.689 95.0759 172.307 94.865 172.205 94.5058C172.104 94.1465 172.307 93.7638 172.666 93.6623C173.025 93.5529 173.408 93.7638 173.509 94.1231C173.619 94.4823 173.408 94.865 173.049 94.9666Z"
                fill="#12467F"/>
            <path
                d="M170.424 95.7318C170.065 95.8412 169.682 95.6303 169.581 95.2711C169.472 94.9118 169.682 94.5291 170.042 94.4276C170.401 94.3182 170.784 94.5291 170.885 94.8884C170.995 95.2476 170.784 95.6303 170.424 95.7318Z"
                fill="#12467F"/>
            <path
                d="M167.8 96.5051C167.441 96.6144 167.058 96.4035 166.957 96.0443C166.855 95.685 167.058 95.3023 167.418 95.2008C167.777 95.0914 168.16 95.3023 168.261 95.6616C168.37 96.0208 168.16 96.4035 167.8 96.5051Z"
                fill="#12467F"/>
            <path
                d="M165.176 97.2704C164.817 97.3798 164.434 97.1689 164.333 96.8096C164.231 96.4504 164.434 96.0677 164.793 95.9662C165.153 95.8646 165.535 96.0677 165.637 96.427C165.738 96.7862 165.535 97.1689 165.176 97.2704Z"
                fill="#12467F"/>
            <path
                d="M162.552 98.0359C162.192 98.1452 161.81 97.9343 161.708 97.5751C161.607 97.2158 161.81 96.8331 162.169 96.7316C162.528 96.6222 162.911 96.8331 163.013 97.1924C163.122 97.5516 162.911 97.9343 162.552 98.0359Z"
                fill="#12467F"/>
            <path
                d="M159.936 98.8013C159.576 98.9106 159.194 98.6998 159.092 98.3405C158.991 97.9812 159.194 97.5985 159.553 97.497C159.912 97.3877 160.295 97.5985 160.396 97.9578C160.498 98.3171 160.295 98.6998 159.936 98.8013Z"
                fill="#12467F"/>
            <path
                d="M157.311 99.5666C156.952 99.6759 156.57 99.4651 156.468 99.1058C156.366 98.7465 156.57 98.3639 156.929 98.2623C157.288 98.153 157.671 98.3638 157.772 98.7231C157.882 99.0824 157.671 99.4651 157.311 99.5666Z"
                fill="#12467F"/>
            <path
                d="M154.687 100.332C154.328 100.434 153.945 100.231 153.844 99.8712C153.734 99.512 153.945 99.1293 154.304 99.0278C154.664 98.9184 155.046 99.1293 155.148 99.4886C155.249 99.8478 155.046 100.231 154.687 100.332Z"
                fill="#12467F"/>
            <path
                d="M152.063 101.105C151.704 101.207 151.321 101.004 151.22 100.644C151.118 100.285 151.321 99.9025 151.681 99.801C152.04 99.6916 152.422 99.9025 152.524 100.262C152.626 100.621 152.422 101.004 152.063 101.105Z"
                fill="#12467F"/>
            <path
                d="M172.236 97.2626C171.877 97.372 171.494 97.1611 171.393 96.8018C171.284 96.4426 171.494 96.0599 171.854 95.9584C172.213 95.8568 172.596 96.0599 172.697 96.4192C172.799 96.7784 172.596 97.1611 172.236 97.2626Z"
                fill="#12467F"/>
            <path
                d="M169.612 98.028C169.253 98.1373 168.87 97.9265 168.768 97.5672C168.659 97.208 168.87 96.8253 169.229 96.7237C169.588 96.6144 169.971 96.8253 170.073 97.1845C170.174 97.5438 169.971 97.9265 169.612 98.028Z"
                fill="#12467F"/>
            <path
                d="M166.988 98.8013C166.629 98.9106 166.246 98.6997 166.144 98.3405C166.035 97.9812 166.246 97.5985 166.605 97.497C166.964 97.3876 167.347 97.5985 167.449 97.9578C167.558 98.317 167.347 98.6997 166.988 98.8013Z"
                fill="#12467F"/>
            <path
                d="M164.364 99.5666C164.004 99.6759 163.622 99.465 163.52 99.1058C163.411 98.7465 163.622 98.3638 163.981 98.2623C164.34 98.1529 164.723 98.3638 164.824 98.7231C164.926 99.0823 164.723 99.465 164.364 99.5666Z"
                fill="#12467F"/>
            <path
                d="M161.74 100.332C161.38 100.441 160.998 100.23 160.896 99.8712C160.795 99.512 160.998 99.1293 161.357 99.0277C161.716 98.9184 162.099 99.1293 162.201 99.4885C162.31 99.8478 162.099 100.23 161.74 100.332Z"
                fill="#12467F"/>
            <path
                d="M159.116 101.097C158.756 101.199 158.374 100.996 158.272 100.637C158.163 100.277 158.374 99.8947 158.733 99.7932C159.092 99.6838 159.475 99.8947 159.576 100.254C159.678 100.613 159.475 100.996 159.116 101.097Z"
                fill="#12467F"/>
            <path
                d="M156.491 101.863C156.132 101.972 155.749 101.761 155.648 101.402C155.546 101.043 155.749 100.66 156.108 100.558C156.468 100.449 156.85 100.66 156.952 101.019C157.061 101.379 156.85 101.761 156.491 101.863Z"
                fill="#12467F"/>
            <path
                d="M153.875 102.628C153.516 102.737 153.133 102.527 153.032 102.167C152.922 101.808 153.133 101.425 153.492 101.324C153.852 101.215 154.234 101.425 154.336 101.785C154.437 102.144 154.234 102.527 153.875 102.628Z"
                fill="#12467F"/>
            <path
                d="M151.251 103.394C150.891 103.503 150.509 103.292 150.407 102.933C150.306 102.574 150.509 102.191 150.868 102.089C151.227 101.98 151.61 102.191 151.711 102.55C151.813 102.909 151.61 103.292 151.251 103.394Z"
                fill="#12467F"/>
            <path
                d="M174.212 98.9573C173.853 99.0667 173.47 98.8558 173.369 98.4965C173.267 98.1373 173.47 97.7546 173.83 97.6531C174.189 97.5437 174.572 97.7546 174.673 98.1139C174.775 98.4731 174.572 98.8558 174.212 98.9573Z"
                fill="#12467F"/>
            <path
                d="M171.588 99.7228C171.229 99.8321 170.846 99.6213 170.745 99.262C170.643 98.9027 170.846 98.52 171.205 98.4185C171.565 98.3092 171.947 98.52 172.049 98.8793C172.15 99.2386 171.947 99.6212 171.588 99.7228Z"
                fill="#12467F"/>
            <path
                d="M168.964 100.488C168.605 100.598 168.222 100.387 168.12 100.027C168.019 99.6682 168.222 99.2855 168.581 99.184C168.94 99.0746 169.323 99.2855 169.425 99.6447C169.526 100.004 169.323 100.387 168.964 100.488Z"
                fill="#12467F"/>
            <path
                d="M166.348 101.261C165.988 101.363 165.606 101.16 165.504 100.801C165.403 100.441 165.606 100.059 165.965 99.9571C166.324 99.8478 166.707 100.059 166.808 100.418C166.91 100.777 166.707 101.16 166.348 101.261Z"
                fill="#12467F"/>
            <path
                d="M163.723 102.027C163.364 102.136 162.981 101.925 162.88 101.566C162.77 101.207 162.981 100.824 163.341 100.723C163.7 100.613 164.082 100.824 164.184 101.183C164.286 101.543 164.082 101.925 163.723 102.027Z"
                fill="#12467F"/>
            <path
                d="M161.099 102.792C160.74 102.894 160.357 102.69 160.256 102.331C160.146 101.972 160.357 101.589 160.717 101.488C161.076 101.386 161.458 101.589 161.56 101.949C161.669 102.308 161.458 102.69 161.099 102.792Z"
                fill="#12467F"/>
            <path
                d="M158.475 103.558C158.115 103.659 157.733 103.456 157.631 103.097C157.522 102.737 157.733 102.355 158.092 102.253C158.451 102.144 158.834 102.355 158.935 102.714C159.037 103.073 158.834 103.456 158.475 103.558Z"
                fill="#12467F"/>
            <path
                d="M155.851 104.323C155.492 104.432 155.109 104.221 155.007 103.862C154.906 103.503 155.109 103.12 155.468 103.019C155.827 102.909 156.21 103.12 156.312 103.479C156.421 103.839 156.21 104.221 155.851 104.323Z"
                fill="#12467F"/>
            <path
                d="M153.227 105.088C152.868 105.198 152.485 104.987 152.383 104.627C152.274 104.268 152.485 103.885 152.844 103.784C153.203 103.675 153.586 103.885 153.688 104.245C153.789 104.604 153.586 104.987 153.227 105.088Z"
                fill="#12467F"/>
            <path
                d="M173.4 101.254C173.04 101.355 172.658 101.152 172.556 100.793C172.447 100.433 172.658 100.051 173.017 99.9492C173.376 99.8399 173.759 100.051 173.861 100.41C173.97 100.769 173.759 101.152 173.4 101.254Z"
                fill="#12467F"/>
            <path
                d="M170.776 102.019C170.417 102.128 170.034 101.917 169.932 101.558C169.823 101.199 170.034 100.816 170.393 100.715C170.753 100.605 171.135 100.816 171.237 101.175C171.338 101.535 171.135 101.917 170.776 102.019Z"
                fill="#12467F"/>
            <path
                d="M168.151 102.784C167.792 102.894 167.409 102.683 167.308 102.323C167.206 101.964 167.409 101.582 167.769 101.48C168.128 101.378 168.511 101.582 168.612 101.941C168.714 102.3 168.511 102.683 168.151 102.784Z"
                fill="#12467F"/>
            <path
                d="M165.528 103.55C165.168 103.659 164.786 103.448 164.684 103.089C164.583 102.73 164.786 102.347 165.145 102.245C165.504 102.136 165.887 102.347 165.988 102.706C166.098 103.065 165.887 103.448 165.528 103.55Z"
                fill="#12467F"/>
            <path
                d="M162.903 104.323C162.544 104.424 162.161 104.221 162.06 103.862C161.951 103.503 162.161 103.12 162.521 103.019C162.88 102.909 163.263 103.12 163.364 103.479C163.466 103.839 163.263 104.221 162.903 104.323Z"
                fill="#12467F"/>
            <path
                d="M160.287 105.088C159.928 105.198 159.545 104.987 159.443 104.627C159.342 104.268 159.545 103.885 159.904 103.784C160.263 103.675 160.646 103.885 160.748 104.245C160.857 104.604 160.646 104.987 160.287 105.088Z"
                fill="#12467F"/>
            <path
                d="M157.663 105.854C157.303 105.963 156.921 105.752 156.819 105.393C156.71 105.034 156.921 104.651 157.28 104.549C157.639 104.44 158.022 104.651 158.123 105.01C158.225 105.369 158.022 105.752 157.663 105.854Z"
                fill="#12467F"/>
            <path
                d="M155.038 106.619C154.679 106.728 154.296 106.518 154.195 106.158C154.093 105.799 154.296 105.416 154.656 105.315C155.015 105.205 155.398 105.416 155.499 105.776C155.601 106.135 155.398 106.518 155.038 106.619Z"
                fill="#12467F"/>
            <path
                d="M152.414 107.384C152.055 107.494 151.672 107.283 151.571 106.924C151.462 106.564 151.672 106.182 152.032 106.08C152.391 105.971 152.774 106.182 152.875 106.541C152.977 106.9 152.774 107.283 152.414 107.384Z"
                fill="#12467F"/>
            <path
                d="M175.376 102.948C175.017 103.058 174.634 102.847 174.533 102.487C174.423 102.128 174.634 101.746 174.993 101.644C175.353 101.542 175.735 101.746 175.837 102.105C175.938 102.464 175.735 102.847 175.376 102.948Z"
                fill="#12467F"/>
            <path
                d="M172.752 103.714C172.392 103.823 172.01 103.612 171.908 103.253C171.807 102.894 172.01 102.511 172.369 102.409C172.728 102.3 173.111 102.511 173.212 102.87C173.322 103.229 173.111 103.612 172.752 103.714Z"
                fill="#12467F"/>
            <path
                d="M170.135 104.479C169.776 104.588 169.393 104.378 169.292 104.018C169.183 103.659 169.393 103.276 169.753 103.175C170.112 103.065 170.495 103.276 170.596 103.636C170.698 103.995 170.495 104.378 170.135 104.479Z"
                fill="#12467F"/>
            <path
                d="M167.511 105.244C167.152 105.354 166.769 105.143 166.668 104.784C166.566 104.424 166.769 104.042 167.129 103.94C167.488 103.839 167.871 104.042 167.972 104.401C168.082 104.76 167.871 105.143 167.511 105.244Z"
                fill="#12467F"/>
            <path
                d="M164.887 106.01C164.528 106.119 164.145 105.908 164.044 105.549C163.942 105.19 164.145 104.807 164.504 104.706C164.864 104.604 165.246 104.807 165.348 105.166C165.449 105.526 165.246 105.908 164.887 106.01Z"
                fill="#12467F"/>
            <path
                d="M162.263 106.783C161.904 106.884 161.521 106.681 161.419 106.322C161.318 105.963 161.521 105.58 161.88 105.479C162.239 105.369 162.622 105.58 162.724 105.939C162.825 106.299 162.622 106.681 162.263 106.783Z"
                fill="#12467F"/>
            <path
                d="M159.639 107.548C159.279 107.65 158.897 107.447 158.795 107.088C158.694 106.728 158.897 106.346 159.256 106.244C159.615 106.135 159.998 106.346 160.099 106.705C160.209 107.064 159.998 107.447 159.639 107.548Z"
                fill="#12467F"/>
            <path
                d="M157.015 108.314C156.655 108.423 156.273 108.212 156.171 107.853C156.07 107.494 156.273 107.111 156.632 107.01C156.991 106.9 157.374 107.111 157.475 107.47C157.577 107.83 157.374 108.212 157.015 108.314Z"
                fill="#12467F"/>
            <path
                d="M154.398 109.079C154.039 109.189 153.656 108.978 153.555 108.618C153.453 108.259 153.656 107.876 154.016 107.775C154.375 107.666 154.758 107.876 154.859 108.236C154.969 108.595 154.758 108.978 154.398 109.079Z"
                fill="#12467F"/>
            <path
                d="M174.563 105.244C174.204 105.354 173.821 105.143 173.72 104.784C173.618 104.424 173.821 104.042 174.181 103.94C174.54 103.831 174.923 104.042 175.024 104.401C175.126 104.76 174.923 105.143 174.563 105.244Z"
                fill="#12467F"/>
            <path
                d="M171.94 106.01C171.58 106.119 171.198 105.908 171.096 105.549C170.995 105.19 171.198 104.807 171.557 104.706C171.916 104.596 172.299 104.807 172.4 105.166C172.51 105.526 172.299 105.908 171.94 106.01Z"
                fill="#12467F"/>
            <path
                d="M169.315 106.775C168.956 106.877 168.573 106.674 168.472 106.315C168.362 105.955 168.573 105.573 168.932 105.471C169.292 105.362 169.674 105.573 169.776 105.932C169.877 106.291 169.674 106.674 169.315 106.775Z"
                fill="#12467F"/>
            <path
                d="M166.699 107.541C166.34 107.65 165.957 107.439 165.856 107.08C165.754 106.721 165.957 106.338 166.316 106.236C166.676 106.127 167.058 106.338 167.16 106.697C167.262 107.056 167.058 107.439 166.699 107.541Z"
                fill="#12467F"/>
            <path
                d="M164.075 108.306C163.715 108.408 163.333 108.204 163.231 107.845C163.13 107.486 163.333 107.103 163.692 107.002C164.051 106.892 164.434 107.103 164.535 107.463C164.637 107.822 164.434 108.204 164.075 108.306Z"
                fill="#12467F"/>
            <path
                d="M161.451 109.079C161.091 109.189 160.709 108.978 160.607 108.618C160.506 108.259 160.709 107.877 161.068 107.775C161.427 107.666 161.81 107.877 161.911 108.236C162.013 108.595 161.81 108.978 161.451 109.079Z"
                fill="#12467F"/>
            <path
                d="M158.827 109.845C158.467 109.954 158.085 109.743 157.983 109.384C157.882 109.025 158.085 108.642 158.444 108.54C158.803 108.439 159.186 108.642 159.287 109.001C159.397 109.36 159.186 109.743 158.827 109.845Z"
                fill="#12467F"/>
            <path
                d="M156.202 110.61C155.843 110.711 155.46 110.508 155.359 110.149C155.249 109.79 155.46 109.407 155.82 109.306C156.179 109.196 156.561 109.407 156.663 109.766C156.772 110.126 156.561 110.508 156.202 110.61Z"
                fill="#12467F"/>
            <path
                d="M153.578 111.375C153.219 111.485 152.836 111.274 152.735 110.915C152.633 110.555 152.836 110.173 153.195 110.071C153.555 109.962 153.937 110.173 154.039 110.532C154.148 110.891 153.937 111.274 153.578 111.375Z"
                fill="#12467F"/>
            <path
                d="M176.516 106.846C176.157 106.947 175.774 106.744 175.673 106.385C175.564 106.025 175.774 105.643 176.134 105.541C176.493 105.44 176.876 105.643 176.977 106.002C177.079 106.361 176.876 106.744 176.516 106.846Z"
                fill="#12467F"/>
            <path
                d="M173.892 107.619C173.533 107.72 173.15 107.517 173.049 107.158C172.947 106.799 173.15 106.416 173.509 106.314C173.869 106.205 174.251 106.416 174.353 106.775C174.454 107.135 174.251 107.517 173.892 107.619Z"
                fill="#12467F"/>
            <path
                d="M171.276 108.384C170.916 108.493 170.534 108.283 170.432 107.923C170.323 107.564 170.534 107.181 170.893 107.08C171.252 106.97 171.635 107.181 171.737 107.541C171.838 107.9 171.635 108.283 171.276 108.384Z"
                fill="#12467F"/>
            <path
                d="M168.651 109.149C168.292 109.259 167.909 109.048 167.808 108.689C167.706 108.329 167.909 107.947 168.269 107.845C168.628 107.744 169.011 107.947 169.112 108.306C169.214 108.665 169.011 109.048 168.651 109.149Z"
                fill="#12467F"/>
            <path
                d="M166.027 109.915C165.668 110.024 165.285 109.813 165.184 109.454C165.075 109.095 165.285 108.712 165.645 108.611C166.004 108.509 166.387 108.712 166.488 109.071C166.59 109.431 166.387 109.813 166.027 109.915Z"
                fill="#12467F"/>
            <path
                d="M163.403 110.68C163.044 110.782 162.661 110.579 162.56 110.219C162.451 109.86 162.661 109.477 163.021 109.376C163.38 109.267 163.763 109.478 163.864 109.837C163.966 110.196 163.763 110.579 163.403 110.68Z"
                fill="#12467F"/>
            <path
                d="M160.779 111.446C160.42 111.555 160.037 111.344 159.935 110.985C159.826 110.626 160.037 110.243 160.396 110.141C160.755 110.032 161.138 110.243 161.24 110.602C161.341 110.961 161.138 111.344 160.779 111.446Z"
                fill="#12467F"/>
            <path
                d="M158.155 112.219C157.796 112.328 157.413 112.117 157.312 111.758C157.202 111.399 157.413 111.016 157.772 110.915C158.132 110.805 158.514 111.016 158.616 111.375C158.717 111.735 158.514 112.117 158.155 112.219Z"
                fill="#12467F"/>
            <path
                d="M155.538 112.984C155.179 113.093 154.796 112.883 154.695 112.523C154.586 112.164 154.796 111.781 155.156 111.68C155.515 111.57 155.898 111.781 155.999 112.141C156.101 112.5 155.898 112.883 155.538 112.984Z"
                fill="#12467F"/>
            <path
                d="M175.704 109.142C175.345 109.251 174.962 109.04 174.86 108.681C174.751 108.322 174.962 107.939 175.321 107.837C175.681 107.728 176.063 107.939 176.165 108.298C176.266 108.657 176.063 109.04 175.704 109.142Z"
                fill="#12467F"/>
            <path
                d="M173.08 109.915C172.721 110.024 172.338 109.813 172.237 109.454C172.135 109.095 172.338 108.712 172.697 108.611C173.057 108.509 173.439 108.712 173.541 109.071C173.65 109.431 173.439 109.813 173.08 109.915Z"
                fill="#12467F"/>
            <path
                d="M170.455 110.68C170.096 110.782 169.713 110.579 169.612 110.219C169.503 109.86 169.713 109.477 170.073 109.376C170.432 109.267 170.815 109.477 170.916 109.837C171.018 110.196 170.815 110.579 170.455 110.68Z"
                fill="#12467F"/>
            <path
                d="M167.839 111.446C167.48 111.555 167.097 111.344 166.996 110.985C166.894 110.626 167.097 110.243 167.457 110.141C167.816 110.032 168.199 110.243 168.3 110.602C168.409 110.961 168.199 111.344 167.839 111.446Z"
                fill="#12467F"/>
            <path
                d="M165.215 112.211C164.856 112.32 164.473 112.109 164.371 111.75C164.262 111.391 164.473 111.008 164.832 110.907C165.191 110.797 165.574 111.008 165.676 111.368C165.777 111.727 165.574 112.109 165.215 112.211Z"
                fill="#12467F"/>
            <path
                d="M162.591 112.976C162.232 113.086 161.849 112.875 161.748 112.516C161.646 112.156 161.849 111.774 162.208 111.672C162.568 111.563 162.95 111.774 163.052 112.133C163.153 112.492 162.95 112.875 162.591 112.976Z"
                fill="#12467F"/>
            <path
                d="M159.967 113.742C159.607 113.851 159.225 113.64 159.123 113.281C159.014 112.922 159.225 112.539 159.584 112.437C159.943 112.328 160.326 112.539 160.427 112.898C160.529 113.258 160.326 113.64 159.967 113.742Z"
                fill="#12467F"/>
            <path
                d="M157.342 114.515C156.983 114.616 156.601 114.413 156.499 114.054C156.397 113.695 156.601 113.312 156.96 113.211C157.319 113.101 157.702 113.312 157.803 113.671C157.913 114.031 157.702 114.413 157.342 114.515Z"
                fill="#12467F"/>
            <path
                d="M154.719 115.28C154.359 115.39 153.977 115.179 153.875 114.82C153.766 114.46 153.977 114.078 154.336 113.976C154.695 113.867 155.078 114.078 155.179 114.437C155.281 114.796 155.078 115.179 154.719 115.28Z"
                fill="#12467F"/>
            <path
                d="M177.688 110.836C177.329 110.946 176.946 110.735 176.844 110.376C176.743 110.016 176.946 109.634 177.305 109.532C177.665 109.423 178.047 109.634 178.149 109.993C178.258 110.352 178.047 110.735 177.688 110.836Z"
                fill="#12467F"/>
            <path
                d="M175.064 111.602C174.704 111.711 174.322 111.5 174.22 111.141C174.111 110.782 174.322 110.399 174.681 110.298C175.04 110.188 175.423 110.399 175.524 110.758C175.634 111.118 175.423 111.5 175.064 111.602Z"
                fill="#12467F"/>
            <path
                d="M172.439 112.375C172.08 112.484 171.697 112.273 171.596 111.914C171.494 111.555 171.697 111.172 172.056 111.071C172.416 110.961 172.798 111.172 172.9 111.532C173.009 111.891 172.798 112.273 172.439 112.375Z"
                fill="#12467F"/>
            <path
                d="M169.816 113.14C169.456 113.25 169.074 113.039 168.972 112.68C168.863 112.32 169.074 111.938 169.433 111.836C169.792 111.735 170.175 111.938 170.276 112.297C170.386 112.656 170.175 113.039 169.816 113.14Z"
                fill="#12467F"/>
            <path
                d="M167.191 113.906C166.832 114.015 166.449 113.804 166.348 113.445C166.246 113.086 166.449 112.703 166.808 112.601C167.168 112.492 167.55 112.703 167.652 113.062C167.761 113.421 167.55 113.804 167.191 113.906Z"
                fill="#12467F"/>
            <path
                d="M164.567 114.671C164.208 114.773 163.825 114.57 163.723 114.21C163.622 113.851 163.825 113.468 164.184 113.367C164.543 113.258 164.926 113.468 165.028 113.828C165.137 114.187 164.926 114.57 164.567 114.671Z"
                fill="#12467F"/>
            <path
                d="M161.951 115.437C161.591 115.546 161.209 115.335 161.107 114.976C161.006 114.617 161.209 114.234 161.568 114.132C161.927 114.023 162.31 114.234 162.411 114.593C162.521 114.952 162.31 115.335 161.951 115.437Z"
                fill="#12467F"/>
            <path
                d="M159.327 116.202C158.967 116.311 158.585 116.1 158.483 115.741C158.382 115.382 158.585 114.999 158.944 114.898C159.303 114.796 159.686 114.999 159.787 115.358C159.897 115.718 159.686 116.1 159.327 116.202Z"
                fill="#12467F"/>
            <path
                d="M156.702 116.967C156.343 117.077 155.96 116.866 155.859 116.506C155.757 116.147 155.96 115.764 156.319 115.663C156.679 115.554 157.061 115.764 157.163 116.124C157.272 116.483 157.061 116.866 156.702 116.967Z"
                fill="#12467F"/>
            <path
                d="M176.867 113.132C176.508 113.242 176.125 113.031 176.024 112.672C175.922 112.312 176.125 111.93 176.485 111.828C176.844 111.727 177.227 111.93 177.328 112.289C177.43 112.648 177.227 113.031 176.867 113.132Z"
                fill="#12467F"/>
            <path
                d="M174.251 113.898C173.892 114.007 173.509 113.796 173.408 113.437C173.306 113.078 173.509 112.695 173.868 112.594C174.228 112.492 174.61 112.695 174.712 113.054C174.814 113.414 174.61 113.796 174.251 113.898Z"
                fill="#12467F"/>
            <path
                d="M171.627 114.671C171.268 114.773 170.885 114.57 170.784 114.21C170.674 113.851 170.885 113.468 171.244 113.367C171.604 113.257 171.986 113.468 172.088 113.828C172.189 114.187 171.986 114.57 171.627 114.671Z"
                fill="#12467F"/>
            <path
                d="M169.003 115.437C168.644 115.546 168.261 115.335 168.16 114.976C168.05 114.616 168.261 114.234 168.62 114.132C168.98 114.023 169.362 114.234 169.464 114.593C169.565 114.952 169.362 115.335 169.003 115.437Z"
                fill="#12467F"/>
            <path
                d="M166.379 116.202C166.019 116.311 165.637 116.1 165.535 115.741C165.426 115.382 165.637 114.999 165.996 114.898C166.355 114.796 166.738 114.999 166.84 115.358C166.941 115.718 166.738 116.1 166.379 116.202Z"
                fill="#12467F"/>
            <path
                d="M163.755 116.967C163.395 117.077 163.013 116.866 162.911 116.506C162.802 116.147 163.013 115.765 163.372 115.663C163.731 115.554 164.114 115.765 164.216 116.124C164.317 116.483 164.114 116.866 163.755 116.967Z"
                fill="#12467F"/>
            <path
                d="M161.13 117.733C160.771 117.842 160.388 117.631 160.287 117.272C160.177 116.913 160.388 116.53 160.748 116.428C161.107 116.319 161.489 116.53 161.591 116.889C161.693 117.248 161.489 117.631 161.13 117.733Z"
                fill="#12467F"/>
            <path
                d="M158.514 118.498C158.155 118.607 157.772 118.396 157.671 118.037C157.561 117.678 157.772 117.295 158.132 117.194C158.491 117.084 158.874 117.295 158.975 117.655C159.084 118.014 158.874 118.396 158.514 118.498Z"
                fill="#12467F"/>
            <path
                d="M155.89 119.263C155.531 119.373 155.148 119.162 155.046 118.803C154.937 118.443 155.148 118.061 155.507 117.959C155.867 117.85 156.249 118.061 156.351 118.42C156.46 118.779 156.249 119.162 155.89 119.263Z"
                fill="#12467F"/>
            <path
                d="M178.851 114.827C178.492 114.937 178.109 114.726 178.008 114.367C177.906 114.007 178.109 113.625 178.469 113.523C178.828 113.414 179.211 113.625 179.312 113.984C179.421 114.343 179.211 114.726 178.851 114.827Z"
                fill="#12467F"/>
            <path
                d="M176.228 115.593C175.868 115.702 175.486 115.491 175.384 115.132C175.282 114.773 175.486 114.39 175.845 114.289C176.204 114.179 176.587 114.39 176.688 114.749C176.79 115.109 176.587 115.491 176.228 115.593Z"
                fill="#12467F"/>
            <path
                d="M173.603 116.358C173.244 116.467 172.861 116.257 172.76 115.897C172.658 115.538 172.861 115.155 173.22 115.054C173.58 114.944 173.962 115.155 174.064 115.515C174.173 115.874 173.962 116.257 173.603 116.358Z"
                fill="#12467F"/>
            <path
                d="M170.979 117.123C170.62 117.233 170.237 117.022 170.135 116.663C170.034 116.303 170.237 115.921 170.596 115.819C170.956 115.718 171.338 115.921 171.44 116.28C171.541 116.639 171.338 117.022 170.979 117.123Z"
                fill="#12467F"/>
            <path
                d="M168.363 117.897C168.004 118.006 167.621 117.795 167.519 117.436C167.418 117.077 167.621 116.694 167.98 116.592C168.339 116.483 168.722 116.694 168.824 117.053C168.933 117.413 168.722 117.795 168.363 117.897Z"
                fill="#12467F"/>
            <path
                d="M165.738 118.662C165.379 118.771 164.996 118.561 164.895 118.201C164.793 117.842 164.996 117.459 165.356 117.358C165.715 117.248 166.098 117.459 166.199 117.819C166.301 118.178 166.098 118.561 165.738 118.662Z"
                fill="#12467F"/>
            <path
                d="M163.114 119.427C162.755 119.537 162.372 119.326 162.271 118.967C162.169 118.607 162.372 118.225 162.732 118.123C163.091 118.014 163.473 118.225 163.575 118.584C163.684 118.943 163.473 119.326 163.114 119.427Z"
                fill="#12467F"/>
            <path
                d="M160.49 120.193C160.131 120.294 159.748 120.091 159.647 119.732C159.538 119.373 159.748 118.99 160.108 118.889C160.467 118.779 160.85 118.99 160.951 119.349C161.053 119.709 160.85 120.091 160.49 120.193Z"
                fill="#12467F"/>
            <path
                d="M157.866 120.958C157.507 121.06 157.124 120.857 157.022 120.497C156.921 120.138 157.124 119.756 157.483 119.654C157.843 119.545 158.225 119.756 158.327 120.115C158.428 120.474 158.225 120.857 157.866 120.958Z"
                fill="#12467F"/>
            <path
                d="M178.039 117.123C177.68 117.225 177.297 117.022 177.196 116.663C177.086 116.303 177.297 115.921 177.656 115.819C178.016 115.718 178.398 115.921 178.5 116.28C178.609 116.639 178.398 117.022 178.039 117.123Z"
                fill="#12467F"/>
            <path
                d="M175.415 117.889C175.056 117.998 174.673 117.787 174.572 117.428C174.47 117.069 174.673 116.686 175.032 116.585C175.392 116.475 175.774 116.686 175.876 117.045C175.977 117.405 175.774 117.787 175.415 117.889Z"
                fill="#12467F"/>
            <path
                d="M172.791 118.654C172.432 118.764 172.049 118.553 171.948 118.193C171.846 117.834 172.049 117.451 172.408 117.35C172.768 117.241 173.15 117.451 173.252 117.811C173.361 118.17 173.15 118.553 172.791 118.654Z"
                fill="#12467F"/>
            <path
                d="M170.166 119.42C169.807 119.529 169.425 119.318 169.323 118.959C169.221 118.6 169.425 118.217 169.784 118.115C170.143 118.006 170.526 118.217 170.627 118.576C170.737 118.935 170.526 119.318 170.166 119.42Z"
                fill="#12467F"/>
            <path
                d="M167.543 120.193C167.183 120.294 166.801 120.091 166.699 119.732C166.598 119.373 166.801 118.99 167.16 118.889C167.519 118.779 167.902 118.99 168.003 119.349C168.113 119.709 167.902 120.091 167.543 120.193Z"
                fill="#12467F"/>
            <path
                d="M164.926 120.958C164.567 121.068 164.184 120.857 164.083 120.497C163.981 120.138 164.184 119.756 164.544 119.654C164.903 119.545 165.286 119.756 165.387 120.115C165.496 120.474 165.285 120.857 164.926 120.958Z"
                fill="#12467F"/>
            <path
                d="M162.302 121.724C161.943 121.833 161.56 121.622 161.458 121.263C161.357 120.903 161.56 120.521 161.919 120.419C162.279 120.31 162.661 120.521 162.763 120.88C162.864 121.239 162.661 121.622 162.302 121.724Z"
                fill="#12467F"/>
            <path
                d="M159.678 122.489C159.319 122.598 158.936 122.387 158.834 122.028C158.733 121.669 158.936 121.286 159.295 121.185C159.655 121.075 160.037 121.286 160.139 121.645C160.24 122.005 160.037 122.387 159.678 122.489Z"
                fill="#12467F"/>
            <path
                d="M157.053 123.254C156.694 123.364 156.311 123.153 156.21 122.794C156.108 122.434 156.311 122.052 156.671 121.95C157.03 121.849 157.413 122.052 157.514 122.411C157.616 122.77 157.413 123.153 157.053 123.254Z"
                fill="#12467F"/>
            <path
                d="M177.391 119.584C177.032 119.693 176.649 119.482 176.548 119.123C176.446 118.764 176.649 118.381 177.008 118.279C177.368 118.17 177.75 118.381 177.852 118.74C177.953 119.099 177.75 119.482 177.391 119.584Z"
                fill="#12467F"/>
            <path
                d="M174.774 120.349C174.415 120.458 174.032 120.248 173.931 119.888C173.821 119.529 174.032 119.146 174.392 119.045C174.751 118.935 175.134 119.146 175.235 119.506C175.337 119.865 175.134 120.248 174.774 120.349Z"
                fill="#12467F"/>
            <path
                d="M172.15 121.114C171.791 121.224 171.409 121.013 171.307 120.654C171.198 120.294 171.409 119.912 171.768 119.81C172.127 119.709 172.51 119.912 172.611 120.271C172.721 120.63 172.51 121.013 172.15 121.114Z"
                fill="#12467F"/>
            <path
                d="M169.526 121.88C169.167 121.981 168.784 121.778 168.683 121.419C168.573 121.06 168.784 120.677 169.144 120.575C169.503 120.466 169.885 120.677 169.987 121.036C170.089 121.395 169.885 121.778 169.526 121.88Z"
                fill="#12467F"/>
            <path
                d="M166.902 122.653C166.543 122.762 166.16 122.551 166.059 122.192C165.957 121.833 166.16 121.45 166.519 121.349C166.879 121.239 167.261 121.45 167.363 121.809C167.472 122.169 167.261 122.551 166.902 122.653Z"
                fill="#12467F"/>
            <path
                d="M164.278 123.418C163.919 123.528 163.536 123.317 163.434 122.958C163.325 122.598 163.536 122.216 163.895 122.114C164.254 122.005 164.637 122.216 164.739 122.575C164.84 122.934 164.637 123.317 164.278 123.418Z"
                fill="#12467F"/>
            <path
                d="M161.654 124.184C161.295 124.285 160.912 124.082 160.81 123.723C160.709 123.364 160.912 122.981 161.271 122.879C161.63 122.778 162.013 122.981 162.115 123.34C162.224 123.7 162.013 124.082 161.654 124.184Z"
                fill="#12467F"/>
            <path
                d="M159.03 124.949C158.67 125.058 158.288 124.847 158.186 124.488C158.077 124.129 158.288 123.746 158.647 123.645C159.006 123.543 159.389 123.746 159.49 124.106C159.592 124.465 159.389 124.847 159.03 124.949Z"
                fill="#12467F"/>
            <path
                d="M179.203 121.115C178.843 121.216 178.461 121.013 178.359 120.654C178.258 120.294 178.461 119.912 178.82 119.81C179.179 119.701 179.562 119.912 179.663 120.271C179.773 120.63 179.562 121.013 179.203 121.115Z"
                fill="#12467F"/>
            <path
                d="M176.579 121.88C176.219 121.989 175.837 121.778 175.735 121.419C175.634 121.06 175.837 120.677 176.196 120.575C176.555 120.466 176.938 120.677 177.039 121.036C177.149 121.396 176.938 121.778 176.579 121.88Z"
                fill="#12467F"/>
            <path
                d="M173.954 122.645C173.595 122.754 173.212 122.544 173.111 122.184C173.009 121.825 173.212 121.442 173.572 121.341C173.931 121.239 174.314 121.442 174.415 121.802C174.517 122.161 174.314 122.544 173.954 122.645Z"
                fill="#12467F"/>
            <path
                d="M171.33 123.411C170.971 123.52 170.588 123.309 170.487 122.95C170.385 122.591 170.588 122.208 170.948 122.106C171.307 122.005 171.69 122.208 171.791 122.567C171.9 122.926 171.69 123.309 171.33 123.411Z"
                fill="#12467F"/>
            <path
                d="M168.714 124.176C168.355 124.277 167.972 124.074 167.87 123.715C167.761 123.356 167.972 122.973 168.331 122.872C168.691 122.762 169.073 122.973 169.175 123.332C169.276 123.692 169.073 124.074 168.714 124.176Z"
                fill="#12467F"/>
            <path
                d="M166.09 124.949C165.731 125.058 165.348 124.848 165.246 124.488C165.145 124.129 165.348 123.746 165.707 123.645C166.066 123.535 166.449 123.746 166.551 124.106C166.66 124.465 166.449 124.848 166.09 124.949Z"
                fill="#12467F"/>
            <path
                d="M163.466 125.714C163.106 125.824 162.724 125.613 162.622 125.254C162.513 124.894 162.724 124.512 163.083 124.41C163.442 124.309 163.825 124.512 163.926 124.871C164.028 125.23 163.825 125.613 163.466 125.714Z"
                fill="#12467F"/>
            <path
                d="M160.841 126.48C160.482 126.589 160.099 126.378 159.998 126.019C159.896 125.66 160.099 125.277 160.459 125.176C160.818 125.074 161.201 125.277 161.302 125.636C161.411 125.996 161.201 126.378 160.841 126.48Z"
                fill="#12467F"/>
            <path
                d="M158.217 127.245C157.858 127.347 157.475 127.144 157.374 126.784C157.264 126.425 157.475 126.043 157.834 125.941C158.194 125.832 158.576 126.043 158.678 126.402C158.779 126.761 158.576 127.144 158.217 127.245Z"
                fill="#12467F"/>
            <path
                d="M178.563 123.575C178.203 123.684 177.821 123.473 177.719 123.114C177.61 122.755 177.821 122.372 178.18 122.27C178.539 122.161 178.922 122.372 179.023 122.731C179.133 123.09 178.922 123.473 178.563 123.575Z"
                fill="#12467F"/>
            <path
                d="M175.938 124.34C175.579 124.449 175.196 124.238 175.095 123.879C174.985 123.52 175.196 123.137 175.556 123.036C175.915 122.926 176.298 123.137 176.399 123.496C176.501 123.856 176.298 124.238 175.938 124.34Z"
                fill="#12467F"/>
            <path
                d="M173.314 125.105C172.955 125.215 172.572 125.004 172.471 124.644C172.361 124.285 172.572 123.903 172.931 123.801C173.291 123.699 173.673 123.903 173.775 124.262C173.876 124.621 173.673 125.004 173.314 125.105Z"
                fill="#12467F"/>
            <path
                d="M170.69 125.871C170.331 125.98 169.948 125.769 169.846 125.41C169.737 125.051 169.948 124.668 170.307 124.566C170.666 124.457 171.049 124.668 171.151 125.027C171.252 125.387 171.049 125.769 170.69 125.871Z"
                fill="#12467F"/>
            <path
                d="M168.066 126.636C167.707 126.746 167.324 126.535 167.222 126.175C167.121 125.816 167.324 125.433 167.683 125.332C168.042 125.223 168.425 125.433 168.527 125.793C168.628 126.152 168.425 126.535 168.066 126.636Z"
                fill="#12467F"/>
            <path
                d="M165.441 127.402C165.082 127.511 164.699 127.3 164.598 126.941C164.496 126.582 164.699 126.199 165.059 126.097C165.418 125.996 165.801 126.199 165.902 126.558C166.012 126.917 165.801 127.3 165.441 127.402Z"
                fill="#12467F"/>
            <path
                d="M162.825 128.175C162.466 128.276 162.083 128.073 161.982 127.714C161.872 127.355 162.083 126.972 162.442 126.87C162.802 126.761 163.184 126.972 163.286 127.331C163.387 127.69 163.184 128.073 162.825 128.175Z"
                fill="#12467F"/>
            <path
                d="M160.201 128.94C159.842 129.042 159.459 128.839 159.358 128.479C159.256 128.12 159.459 127.737 159.818 127.636C160.178 127.526 160.56 127.737 160.662 128.097C160.771 128.456 160.56 128.839 160.201 128.94Z"
                fill="#12467F"/>
            <path
                d="M177.742 125.871C177.383 125.98 177 125.769 176.899 125.41C176.797 125.051 177 124.668 177.36 124.566C177.719 124.457 178.102 124.668 178.203 125.027C178.305 125.386 178.102 125.769 177.742 125.871Z"
                fill="#12467F"/>
            <path
                d="M175.126 126.636C174.767 126.738 174.384 126.535 174.283 126.175C174.173 125.816 174.384 125.433 174.743 125.332C175.103 125.223 175.485 125.433 175.587 125.793C175.688 126.152 175.485 126.535 175.126 126.636Z"
                fill="#12467F"/>
            <path
                d="M172.502 127.401C172.143 127.511 171.76 127.3 171.658 126.941C171.557 126.581 171.76 126.199 172.119 126.097C172.478 125.988 172.861 126.199 172.963 126.558C173.064 126.917 172.861 127.3 172.502 127.401Z"
                fill="#12467F"/>
            <path
                d="M169.878 128.167C169.519 128.276 169.136 128.065 169.034 127.706C168.933 127.347 169.136 126.964 169.495 126.863C169.854 126.753 170.237 126.964 170.339 127.323C170.44 127.683 170.237 128.065 169.878 128.167Z"
                fill="#12467F"/>
            <path
                d="M167.254 128.932C166.894 129.034 166.512 128.831 166.41 128.472C166.301 128.112 166.512 127.73 166.871 127.628C167.23 127.519 167.613 127.73 167.715 128.089C167.816 128.448 167.613 128.831 167.254 128.932Z"
                fill="#12467F"/>
            <path
                d="M164.63 129.705C164.27 129.815 163.888 129.604 163.786 129.245C163.685 128.885 163.888 128.503 164.247 128.401C164.606 128.3 164.989 128.503 165.09 128.862C165.2 129.221 164.989 129.604 164.63 129.705Z"
                fill="#12467F"/>
            <path
                d="M162.005 130.471C161.646 130.58 161.264 130.369 161.162 130.01C161.053 129.651 161.264 129.268 161.623 129.167C161.982 129.065 162.365 129.268 162.466 129.627C162.568 129.987 162.365 130.369 162.005 130.471Z"
                fill="#12467F"/>
            <path
                d="M159.389 131.236C159.03 131.345 158.647 131.135 158.545 130.775C158.444 130.416 158.647 130.033 159.006 129.932C159.365 129.83 159.748 130.033 159.85 130.393C159.959 130.752 159.748 131.135 159.389 131.236Z"
                fill="#12467F"/>
            <path
                d="M177.102 128.331C176.743 128.432 176.36 128.229 176.259 127.87C176.157 127.511 176.36 127.128 176.719 127.027C177.079 126.917 177.461 127.128 177.563 127.487C177.672 127.847 177.461 128.229 177.102 128.331Z"
                fill="#12467F"/>
            <path
                d="M174.478 129.096C174.119 129.198 173.736 128.995 173.635 128.635C173.533 128.276 173.736 127.893 174.095 127.792C174.455 127.683 174.837 127.893 174.939 128.253C175.04 128.612 174.837 128.995 174.478 129.096Z"
                fill="#12467F"/>
            <path
                d="M171.853 129.862C171.494 129.963 171.112 129.76 171.01 129.401C170.908 129.042 171.112 128.659 171.471 128.557C171.83 128.448 172.213 128.659 172.314 129.018C172.424 129.377 172.213 129.76 171.853 129.862Z"
                fill="#12467F"/>
            <path
                d="M169.237 130.627C168.878 130.736 168.495 130.525 168.394 130.166C168.285 129.807 168.495 129.424 168.855 129.323C169.214 129.221 169.597 129.424 169.698 129.783C169.808 130.143 169.597 130.525 169.237 130.627Z"
                fill="#12467F"/>
            <path
                d="M166.613 131.392C166.254 131.502 165.871 131.291 165.77 130.932C165.668 130.572 165.871 130.19 166.231 130.088C166.59 129.987 166.973 130.19 167.074 130.549C167.176 130.908 166.973 131.291 166.613 131.392Z"
                fill="#12467F"/>
            <path
                d="M163.989 132.158C163.63 132.267 163.247 132.056 163.146 131.697C163.044 131.338 163.247 130.955 163.606 130.854C163.966 130.752 164.348 130.955 164.45 131.314C164.559 131.674 164.348 132.056 163.989 132.158Z"
                fill="#12467F"/>
            <path
                d="M161.365 132.931C161.006 133.04 160.623 132.829 160.521 132.47C160.42 132.111 160.623 131.728 160.982 131.627C161.342 131.517 161.724 131.728 161.826 132.087C161.927 132.447 161.724 132.829 161.365 132.931Z"
                fill="#12467F"/>
            <path
                d="M178.914 129.854C178.555 129.963 178.172 129.752 178.07 129.393C177.961 129.034 178.172 128.651 178.531 128.549C178.89 128.44 179.273 128.651 179.375 129.01C179.476 129.37 179.273 129.752 178.914 129.854Z"
                fill="#12467F"/>
            <path
                d="M176.29 130.627C175.93 130.728 175.548 130.525 175.446 130.166C175.345 129.807 175.548 129.424 175.907 129.323C176.266 129.221 176.649 129.424 176.75 129.783C176.852 130.143 176.649 130.525 176.29 130.627Z"
                fill="#12467F"/>
            <path
                d="M173.665 131.392C173.306 131.502 172.924 131.291 172.822 130.932C172.72 130.572 172.924 130.19 173.283 130.088C173.642 129.987 174.025 130.19 174.126 130.549C174.228 130.908 174.025 131.291 173.665 131.392Z"
                fill="#12467F"/>
            <path
                d="M171.041 132.158C170.682 132.267 170.299 132.056 170.198 131.697C170.096 131.338 170.299 130.955 170.659 130.853C171.018 130.752 171.4 130.955 171.502 131.314C171.604 131.674 171.4 132.056 171.041 132.158Z"
                fill="#12467F"/>
            <path
                d="M168.417 132.923C168.058 133.025 167.675 132.822 167.574 132.462C167.472 132.103 167.675 131.72 168.035 131.619C168.394 131.517 168.776 131.72 168.878 132.08C168.987 132.439 168.776 132.822 168.417 132.923Z"
                fill="#12467F"/>
            <path
                d="M165.793 133.688C165.434 133.79 165.051 133.587 164.95 133.228C164.848 132.868 165.051 132.486 165.41 132.384C165.77 132.275 166.152 132.486 166.254 132.845C166.355 133.204 166.152 133.587 165.793 133.688Z"
                fill="#12467F"/>
            <path
                d="M163.177 134.454C162.817 134.555 162.435 134.352 162.333 133.993C162.232 133.634 162.435 133.251 162.794 133.15C163.153 133.04 163.536 133.251 163.638 133.61C163.739 133.97 163.536 134.352 163.177 134.454Z"
                fill="#12467F"/>
            <path
                d="M160.553 135.227C160.193 135.328 159.811 135.125 159.709 134.766C159.6 134.407 159.811 134.024 160.17 133.923C160.529 133.813 160.912 134.024 161.013 134.383C161.115 134.743 160.912 135.125 160.553 135.227Z"
                fill="#12467F"/>
            <path
                d="M178.234 132.205C177.875 132.314 177.492 132.103 177.391 131.744C177.289 131.385 177.492 131.002 177.851 130.9C178.211 130.799 178.593 131.002 178.695 131.361C178.796 131.72 178.593 132.103 178.234 132.205Z"
                fill="#12467F"/>
            <path
                d="M175.61 132.978C175.251 133.087 174.868 132.876 174.767 132.517C174.665 132.158 174.868 131.775 175.227 131.674C175.587 131.572 175.969 131.775 176.071 132.134C176.172 132.494 175.969 132.876 175.61 132.978Z"
                fill="#12467F"/>
            <path
                d="M172.994 133.743C172.635 133.853 172.252 133.642 172.151 133.282C172.041 132.923 172.252 132.54 172.611 132.439C172.971 132.337 173.353 132.54 173.455 132.9C173.556 133.259 173.353 133.642 172.994 133.743Z"
                fill="#12467F"/>
            <path
                d="M170.37 134.509C170.01 134.61 169.628 134.407 169.526 134.048C169.417 133.689 169.628 133.306 169.987 133.204C170.346 133.095 170.729 133.306 170.831 133.665C170.932 134.024 170.729 134.407 170.37 134.509Z"
                fill="#12467F"/>
            <path
                d="M167.745 135.274C167.386 135.375 167.003 135.172 166.902 134.813C166.793 134.454 167.004 134.071 167.363 133.97C167.722 133.86 168.105 134.071 168.206 134.43C168.308 134.79 168.105 135.172 167.745 135.274Z"
                fill="#12467F"/>
            <path
                d="M165.121 136.039C164.762 136.141 164.379 135.938 164.278 135.579C164.168 135.219 164.379 134.837 164.738 134.735C165.098 134.626 165.48 134.837 165.582 135.196C165.684 135.555 165.48 135.938 165.121 136.039Z"
                fill="#12467F"/>
            <path
                d="M162.497 136.805C162.138 136.906 161.755 136.703 161.654 136.344C161.544 135.985 161.755 135.602 162.114 135.5C162.474 135.399 162.856 135.602 162.958 135.961C163.059 136.32 162.856 136.703 162.497 136.805Z"
                fill="#12467F"/>
            <path
                d="M177.422 134.501C177.063 134.602 176.68 134.399 176.579 134.04C176.477 133.681 176.68 133.298 177.039 133.196C177.399 133.087 177.781 133.298 177.883 133.657C177.984 134.017 177.781 134.399 177.422 134.501Z"
                fill="#12467F"/>
            <path
                d="M174.798 135.274C174.439 135.375 174.056 135.172 173.955 134.813C173.853 134.454 174.056 134.071 174.416 133.97C174.775 133.86 175.158 134.071 175.259 134.43C175.368 134.79 175.158 135.172 174.798 135.274Z"
                fill="#12467F"/>
            <path
                d="M172.174 136.039C171.814 136.141 171.432 135.938 171.33 135.579C171.221 135.219 171.432 134.837 171.791 134.735C172.15 134.626 172.533 134.837 172.634 135.196C172.736 135.555 172.533 135.938 172.174 136.039Z"
                fill="#12467F"/>
            <path
                d="M169.557 136.805C169.198 136.906 168.815 136.703 168.714 136.344C168.612 135.985 168.815 135.602 169.174 135.5C169.534 135.391 169.916 135.602 170.018 135.961C170.127 136.321 169.916 136.703 169.557 136.805Z"
                fill="#12467F"/>
            <path
                d="M166.933 137.57C166.574 137.672 166.191 137.469 166.09 137.109C165.98 136.75 166.191 136.367 166.551 136.266C166.91 136.164 167.292 136.367 167.394 136.727C167.496 137.086 167.292 137.469 166.933 137.57Z"
                fill="#12467F"/>
            <path
                d="M164.309 138.335C163.95 138.437 163.567 138.234 163.466 137.875C163.364 137.515 163.567 137.133 163.927 137.031C164.286 136.93 164.669 137.133 164.77 137.492C164.872 137.851 164.669 138.234 164.309 138.335Z"
                fill="#12467F"/>
            <path
                d="M161.685 139.101C161.326 139.21 160.943 138.999 160.842 138.64C160.732 138.281 160.943 137.898 161.302 137.797C161.662 137.695 162.044 137.898 162.146 138.257C162.247 138.617 162.044 138.999 161.685 139.101Z"
                fill="#12467F"/>
            <path
                d="M179.406 136.196C179.047 136.297 178.664 136.094 178.563 135.735C178.453 135.375 178.664 134.993 179.023 134.891C179.383 134.782 179.765 134.993 179.867 135.352C179.976 135.711 179.765 136.094 179.406 136.196Z"
                fill="#12467F"/>
            <path
                d="M176.782 136.961C176.423 137.062 176.04 136.859 175.938 136.5C175.829 136.141 176.04 135.758 176.399 135.657C176.758 135.555 177.141 135.758 177.243 136.117C177.352 136.477 177.141 136.859 176.782 136.961Z"
                fill="#12467F"/>
            <path
                d="M174.158 137.734C173.799 137.836 173.416 137.633 173.314 137.273C173.213 136.914 173.416 136.531 173.775 136.43C174.134 136.328 174.517 136.531 174.619 136.891C174.728 137.25 174.517 137.633 174.158 137.734Z"
                fill="#12467F"/>
            <path
                d="M171.533 138.499C171.174 138.609 170.791 138.398 170.69 138.039C170.588 137.679 170.791 137.297 171.151 137.195C171.51 137.094 171.893 137.297 171.994 137.656C172.104 138.015 171.893 138.398 171.533 138.499Z"
                fill="#12467F"/>
            <path
                d="M168.909 139.265C168.55 139.374 168.167 139.163 168.066 138.804C167.964 138.445 168.167 138.062 168.527 137.961C168.886 137.859 169.269 138.062 169.37 138.421C169.48 138.781 169.269 139.163 168.909 139.265Z"
                fill="#12467F"/>
            <path
                d="M166.286 140.03C165.926 140.14 165.544 139.929 165.442 139.569C165.341 139.21 165.544 138.827 165.903 138.726C166.262 138.624 166.645 138.827 166.746 139.187C166.856 139.546 166.645 139.929 166.286 140.03Z"
                fill="#12467F"/>
            <path
                d="M163.661 140.796C163.302 140.905 162.919 140.694 162.818 140.335C162.716 139.976 162.919 139.593 163.278 139.491C163.638 139.39 164.02 139.593 164.122 139.952C164.231 140.311 164.02 140.694 163.661 140.796Z"
                fill="#12467F"/>
            <path
                d="M178.586 138.492C178.226 138.593 177.844 138.39 177.742 138.031C177.633 137.672 177.844 137.289 178.203 137.187C178.562 137.086 178.945 137.289 179.046 137.648C179.148 138.007 178.945 138.39 178.586 138.492Z"
                fill="#12467F"/>
            <path
                d="M175.969 139.257C175.61 139.366 175.227 139.155 175.126 138.796C175.017 138.437 175.227 138.054 175.587 137.953C175.946 137.851 176.329 138.054 176.43 138.414C176.532 138.773 176.329 139.156 175.969 139.257Z"
                fill="#12467F"/>
            <path
                d="M173.345 140.03C172.986 140.14 172.603 139.929 172.502 139.569C172.392 139.21 172.603 138.827 172.963 138.726C173.322 138.624 173.705 138.827 173.806 139.187C173.908 139.546 173.705 139.929 173.345 140.03Z"
                fill="#12467F"/>
            <path
                d="M170.721 140.796C170.362 140.897 169.979 140.694 169.878 140.335C169.768 139.976 169.979 139.593 170.339 139.491C170.698 139.382 171.081 139.593 171.182 139.952C171.284 140.311 171.081 140.694 170.721 140.796Z"
                fill="#12467F"/>
            <path
                d="M168.097 141.561C167.738 141.663 167.355 141.459 167.254 141.1C167.144 140.741 167.355 140.358 167.714 140.257C168.074 140.147 168.456 140.358 168.558 140.718C168.659 141.077 168.456 141.459 168.097 141.561Z"
                fill="#12467F"/>
            <path
                d="M165.473 142.326C165.114 142.428 164.731 142.225 164.63 141.866C164.52 141.506 164.731 141.124 165.09 141.022C165.45 140.913 165.832 141.124 165.934 141.483C166.043 141.842 165.832 142.225 165.473 142.326Z"
                fill="#12467F"/>
            <path
                d="M162.848 143.092C162.489 143.193 162.107 142.99 162.005 142.631C161.896 142.272 162.107 141.889 162.466 141.787C162.825 141.678 163.208 141.889 163.309 142.248C163.411 142.607 163.208 142.99 162.848 143.092Z"
                fill="#12467F"/>
            <path
                d="M177.946 140.952C177.586 141.061 177.204 140.85 177.102 140.491C177.001 140.132 177.204 139.749 177.563 139.648C177.922 139.546 178.305 139.749 178.406 140.108C178.508 140.468 178.305 140.85 177.946 140.952Z"
                fill="#12467F"/>
            <path
                d="M175.321 141.717C174.962 141.819 174.579 141.616 174.478 141.256C174.376 140.897 174.579 140.515 174.938 140.413C175.298 140.304 175.68 140.515 175.782 140.874C175.891 141.233 175.68 141.616 175.321 141.717Z"
                fill="#12467F"/>
            <path
                d="M172.697 142.49C172.338 142.592 171.955 142.389 171.854 142.03C171.752 141.67 171.955 141.288 172.314 141.186C172.674 141.077 173.056 141.288 173.158 141.647C173.259 142.006 173.056 142.389 172.697 142.49Z"
                fill="#12467F"/>
            <path
                d="M170.073 143.256C169.714 143.357 169.331 143.154 169.23 142.795C169.128 142.436 169.331 142.053 169.691 141.952C170.05 141.842 170.432 142.053 170.534 142.412C170.643 142.772 170.432 143.154 170.073 143.256Z"
                fill="#12467F"/>
            <path
                d="M167.457 144.021C167.097 144.123 166.715 143.92 166.613 143.56C166.504 143.201 166.715 142.818 167.074 142.717C167.433 142.615 167.816 142.818 167.917 143.178C168.019 143.537 167.816 143.92 167.457 144.021Z"
                fill="#12467F"/>
            <path
                d="M164.833 144.787C164.473 144.896 164.091 144.685 163.989 144.326C163.888 143.966 164.091 143.584 164.45 143.482C164.809 143.381 165.192 143.584 165.294 143.943C165.403 144.302 165.192 144.685 164.833 144.787Z"
                fill="#12467F"/>
            <path
                d="M177.133 143.248C176.774 143.349 176.391 143.146 176.29 142.787C176.188 142.428 176.391 142.045 176.75 141.944C177.11 141.834 177.492 142.045 177.594 142.404C177.703 142.764 177.492 143.146 177.133 143.248Z"
                fill="#12467F"/>
            <path
                d="M174.509 144.013C174.15 144.115 173.767 143.912 173.666 143.553C173.564 143.193 173.767 142.811 174.126 142.709C174.486 142.608 174.868 142.811 174.97 143.17C175.079 143.529 174.868 143.912 174.509 144.013Z"
                fill="#12467F"/>
            <path
                d="M171.885 144.787C171.526 144.888 171.143 144.685 171.042 144.326C170.94 143.966 171.143 143.584 171.503 143.482C171.862 143.381 172.244 143.584 172.346 143.943C172.455 144.302 172.244 144.685 171.885 144.787Z"
                fill="#12467F"/>
            <path
                d="M169.261 145.552C168.901 145.661 168.519 145.45 168.417 145.091C168.316 144.732 168.519 144.349 168.878 144.248C169.237 144.146 169.62 144.349 169.721 144.708C169.831 145.068 169.62 145.45 169.261 145.552Z"
                fill="#12467F"/>
            <path
                d="M166.637 146.317C166.277 146.427 165.895 146.216 165.793 145.857C165.692 145.497 165.895 145.115 166.254 145.013C166.613 144.912 166.996 145.115 167.097 145.474C167.199 145.833 166.996 146.216 166.637 146.317Z"
                fill="#12467F"/>
            <path
                d="M164.02 147.083C163.661 147.192 163.278 146.981 163.177 146.622C163.075 146.263 163.278 145.88 163.638 145.778C163.997 145.677 164.379 145.88 164.481 146.239C164.583 146.599 164.379 146.981 164.02 147.083Z"
                fill="#12467F"/>
            <path
                d="M179.109 144.943C178.75 145.044 178.367 144.841 178.265 144.482C178.164 144.123 178.367 143.74 178.726 143.638C179.085 143.537 179.468 143.74 179.57 144.099C179.671 144.458 179.468 144.841 179.109 144.943Z"
                fill="#12467F"/>
            <path
                d="M176.485 145.708C176.125 145.81 175.743 145.607 175.641 145.247C175.532 144.888 175.743 144.505 176.102 144.404C176.461 144.302 176.844 144.505 176.945 144.865C177.047 145.224 176.844 145.607 176.485 145.708Z"
                fill="#12467F"/>
            <path
                d="M173.861 146.474C173.501 146.575 173.119 146.372 173.017 146.013C172.908 145.653 173.119 145.271 173.478 145.169C173.837 145.068 174.22 145.271 174.321 145.63C174.431 145.989 174.22 146.372 173.861 146.474Z"
                fill="#12467F"/>
            <path
                d="M171.244 147.239C170.885 147.348 170.502 147.137 170.401 146.778C170.291 146.419 170.502 146.036 170.862 145.935C171.221 145.825 171.603 146.036 171.705 146.395C171.807 146.755 171.603 147.137 171.244 147.239Z"
                fill="#12467F"/>
            <path
                d="M168.62 148.004C168.261 148.114 167.878 147.903 167.777 147.543C167.675 147.184 167.878 146.802 168.238 146.7C168.597 146.591 168.98 146.802 169.081 147.161C169.19 147.52 168.98 147.903 168.62 148.004Z"
                fill="#12467F"/>
            <path
                d="M165.996 148.777C165.637 148.887 165.254 148.676 165.153 148.317C165.044 147.957 165.254 147.575 165.614 147.473C165.973 147.364 166.356 147.575 166.457 147.934C166.559 148.293 166.356 148.676 165.996 148.777Z"
                fill="#12467F"/>
            <path
                d="M178.297 147.239C177.938 147.348 177.555 147.137 177.453 146.778C177.352 146.419 177.555 146.036 177.914 145.935C178.273 145.833 178.656 146.036 178.758 146.396C178.867 146.755 178.656 147.137 178.297 147.239Z"
                fill="#12467F"/>
            <path
                d="M175.673 148.004C175.314 148.106 174.931 147.903 174.829 147.543C174.728 147.184 174.931 146.802 175.29 146.7C175.649 146.591 176.032 146.802 176.134 147.161C176.235 147.52 176.032 147.903 175.673 148.004Z"
                fill="#12467F"/>
            <path
                d="M173.049 148.77C172.689 148.871 172.307 148.668 172.205 148.309C172.104 147.95 172.307 147.567 172.666 147.465C173.025 147.356 173.408 147.567 173.509 147.926C173.619 148.285 173.408 148.668 173.049 148.77Z"
                fill="#12467F"/>
            <path
                d="M170.432 149.535C170.073 149.637 169.69 149.434 169.589 149.074C169.487 148.715 169.69 148.332 170.05 148.231C170.409 148.121 170.792 148.332 170.893 148.692C170.995 149.051 170.792 149.434 170.432 149.535Z"
                fill="#12467F"/>
            <path
                d="M167.808 150.308C167.449 150.41 167.066 150.207 166.965 149.848C166.863 149.488 167.066 149.106 167.426 149.004C167.785 148.902 168.168 149.106 168.269 149.465C168.378 149.824 168.168 150.207 167.808 150.308Z"
                fill="#12467F"/>
            <path
                d="M165.184 151.074C164.825 151.183 164.442 150.972 164.34 150.613C164.239 150.254 164.442 149.871 164.801 149.769C165.16 149.668 165.543 149.871 165.645 150.23C165.746 150.589 165.543 150.972 165.184 151.074Z"
                fill="#12467F"/>
            <path
                d="M177.656 149.699C177.297 149.801 176.914 149.598 176.813 149.238C176.704 148.879 176.914 148.496 177.274 148.395C177.633 148.286 178.016 148.496 178.117 148.856C178.219 149.215 178.016 149.598 177.656 149.699Z"
                fill="#12467F"/>
            <path
                d="M175.032 150.464C174.673 150.566 174.29 150.363 174.189 150.004C174.087 149.644 174.29 149.262 174.65 149.16C175.009 149.051 175.391 149.262 175.493 149.621C175.595 149.98 175.391 150.363 175.032 150.464Z"
                fill="#12467F"/>
            <path
                d="M172.408 151.23C172.049 151.331 171.666 151.128 171.564 150.769C171.463 150.41 171.666 150.027 172.025 149.926C172.385 149.816 172.767 150.027 172.869 150.386C172.978 150.746 172.767 151.128 172.408 151.23Z"
                fill="#12467F"/>
            <path
                d="M169.784 151.995C169.424 152.097 169.042 151.894 168.94 151.534C168.839 151.175 169.042 150.792 169.401 150.691C169.76 150.589 170.143 150.792 170.244 151.152C170.346 151.511 170.143 151.894 169.784 151.995Z"
                fill="#12467F"/>
            <path
                d="M167.16 152.761C166.8 152.87 166.418 152.659 166.316 152.3C166.207 151.941 166.418 151.558 166.777 151.456C167.136 151.355 167.519 151.558 167.62 151.917C167.73 152.276 167.519 152.659 167.16 152.761Z"
                fill="#12467F"/>
            <path
                d="M179.461 151.23C179.101 151.331 178.719 151.128 178.617 150.769C178.516 150.41 178.719 150.027 179.078 149.925C179.437 149.824 179.82 150.027 179.921 150.386C180.023 150.746 179.82 151.128 179.461 151.23Z"
                fill="#12467F"/>
            <path
                d="M176.844 151.995C176.485 152.097 176.102 151.894 176 151.534C175.891 151.175 176.102 150.792 176.461 150.691C176.82 150.589 177.203 150.792 177.305 151.152C177.406 151.511 177.203 151.894 176.844 151.995Z"
                fill="#12467F"/>
            <path
                d="M174.22 152.761C173.861 152.87 173.478 152.659 173.376 152.3C173.275 151.94 173.478 151.558 173.837 151.456C174.197 151.355 174.579 151.558 174.681 151.917C174.782 152.276 174.579 152.659 174.22 152.761Z"
                fill="#12467F"/>
        </g>
        <mask id="mask4_9728_6354" style={{maskType:"luminance"}} maskUnits="userSpaceOnUse" x="138" y="93" width="18"
              height="66">
            <path
                d="M154.828 158.243L154.867 158.228C155.499 157.837 155.882 157.165 155.913 156.455C155.929 156.22 155.906 155.986 155.835 155.752L154.492 150.871C154.226 149.902 153.844 148.965 153.352 148.098C147.486 137.875 145.917 125.535 146.58 113.742L147.471 97.7859L147.689 93.8497L144.651 93.6779L139.856 93.4123L139.637 97.3564L138.739 113.304C137.966 127.167 141.41 140.741 148.697 152.558L151.766 157.532C152.407 158.571 153.773 158.899 154.812 158.251L154.828 158.243Z"
                fill="white"/>
        </mask>
        <g mask="url(#mask4_9728_6354)">
            <path
                d="M139.934 93.6935C139.575 93.8029 139.192 93.592 139.09 93.2328C138.981 92.8735 139.192 92.4908 139.551 92.3893C139.911 92.2799 140.293 92.4908 140.395 92.8501C140.496 93.2093 140.293 93.592 139.934 93.6935Z"
                fill="#12467F"/>
            <path
                d="M147.158 93.8575C146.799 93.9669 146.416 93.756 146.315 93.3968C146.213 93.0375 146.416 92.6548 146.776 92.5533C147.135 92.4439 147.518 92.6548 147.619 93.0141C147.721 93.3733 147.518 93.756 147.158 93.8575Z"
                fill="#12467F"/>
            <path
                d="M144.542 94.6228C144.183 94.7322 143.8 94.5213 143.699 94.1621C143.589 93.8028 143.8 93.4201 144.159 93.3186C144.519 93.2092 144.901 93.4201 145.003 93.7794C145.112 94.1386 144.901 94.5213 144.542 94.6228Z"
                fill="#12467F"/>
            <path
                d="M141.918 95.3883C141.558 95.4898 141.176 95.2868 141.074 94.9275C140.973 94.5682 141.176 94.1856 141.535 94.084C141.894 93.9747 142.277 94.1855 142.378 94.5448C142.488 94.9041 142.277 95.2868 141.918 95.3883Z"
                fill="#12467F"/>
            <path
                d="M139.294 96.1536C138.934 96.2629 138.552 96.0521 138.45 95.6928C138.341 95.3335 138.552 94.9509 138.911 94.8493C139.27 94.74 139.653 94.9509 139.754 95.3101C139.864 95.6694 139.653 96.0521 139.294 96.1536Z"
                fill="#12467F"/>
            <path
                d="M146.346 96.1536C145.987 96.263 145.604 96.0521 145.503 95.6928C145.401 95.3336 145.604 94.9509 145.963 94.8493C146.323 94.74 146.705 94.9509 146.807 95.3101C146.908 95.6694 146.705 96.0521 146.346 96.1536Z"
                fill="#12467F"/>
            <path
                d="M143.722 96.919C143.363 97.0283 142.98 96.8175 142.879 96.4582C142.769 96.0989 142.98 95.7163 143.339 95.6147C143.699 95.5132 144.081 95.7163 144.183 96.0755C144.292 96.4348 144.081 96.8175 143.722 96.919Z"
                fill="#12467F"/>
            <path
                d="M141.106 97.6845C140.746 97.7938 140.364 97.583 140.262 97.2237C140.153 96.8644 140.364 96.4817 140.723 96.3802C141.082 96.2709 141.465 96.4817 141.566 96.841C141.668 97.2003 141.465 97.583 141.106 97.6845Z"
                fill="#12467F"/>
            <path
                d="M138.482 98.4498C138.123 98.5591 137.74 98.3483 137.638 97.989C137.529 97.6298 137.74 97.2471 138.099 97.1455C138.458 97.044 138.841 97.2471 138.943 97.6063C139.052 97.9656 138.841 98.3483 138.482 98.4498Z"
                fill="#12467F"/>
            <path
                d="M148.33 97.8485C147.971 97.9578 147.588 97.747 147.486 97.3877C147.385 97.0284 147.588 96.6457 147.947 96.5442C148.306 96.4427 148.689 96.6457 148.791 97.005C148.9 97.3643 148.689 97.747 148.33 97.8485Z"
                fill="#12467F"/>
            <path
                d="M145.706 98.6138C145.347 98.7231 144.964 98.5122 144.862 98.153C144.761 97.7937 144.964 97.411 145.323 97.3095C145.682 97.208 146.065 97.411 146.167 97.7703C146.268 98.1295 146.065 98.5122 145.706 98.6138Z"
                fill="#12467F"/>
            <path
                d="M143.082 99.3791C142.723 99.4807 142.34 99.2776 142.238 98.9183C142.129 98.5591 142.34 98.1764 142.699 98.0749C143.058 97.9655 143.441 98.1764 143.543 98.5356C143.652 98.8949 143.441 99.2776 143.082 99.3791Z"
                fill="#12467F"/>
            <path
                d="M140.457 100.145C140.098 100.254 139.716 100.043 139.614 99.6838C139.512 99.3245 139.716 98.9418 140.075 98.8403C140.434 98.731 140.817 98.9418 140.918 99.3011C141.028 99.6603 140.817 100.043 140.457 100.145Z"
                fill="#12467F"/>
            <path
                d="M147.518 100.145C147.158 100.254 146.776 100.043 146.674 99.6838C146.573 99.3245 146.776 98.9418 147.135 98.8403C147.494 98.731 147.877 98.9418 147.978 99.3011C148.088 99.6603 147.877 100.043 147.518 100.145Z"
                fill="#12467F"/>
            <path
                d="M144.893 100.91C144.534 101.019 144.151 100.808 144.05 100.449C143.948 100.09 144.151 99.7072 144.511 99.6057C144.87 99.4963 145.253 99.7072 145.354 100.066C145.456 100.426 145.253 100.808 144.893 100.91Z"
                fill="#12467F"/>
            <path
                d="M142.269 101.675C141.91 101.785 141.527 101.574 141.426 101.215C141.317 100.855 141.527 100.473 141.887 100.371C142.246 100.262 142.629 100.473 142.73 100.832C142.84 101.191 142.629 101.574 142.269 101.675Z"
                fill="#12467F"/>
            <path
                d="M139.645 102.441C139.286 102.542 138.903 102.339 138.802 101.98C138.7 101.621 138.903 101.238 139.263 101.136C139.622 101.027 140.004 101.238 140.106 101.597C140.215 101.956 140.004 102.339 139.645 102.441Z"
                fill="#12467F"/>
            <path
                d="M149.494 101.832C149.134 101.941 148.752 101.73 148.65 101.371C148.541 101.012 148.752 100.629 149.111 100.527C149.47 100.418 149.853 100.629 149.955 100.988C150.056 101.347 149.853 101.73 149.494 101.832Z"
                fill="#12467F"/>
            <path
                d="M146.869 102.605C146.51 102.714 146.127 102.503 146.026 102.144C145.924 101.785 146.127 101.402 146.486 101.301C146.846 101.191 147.228 101.402 147.33 101.761C147.431 102.121 147.228 102.503 146.869 102.605Z"
                fill="#12467F"/>
            <path
                d="M144.245 103.37C143.886 103.472 143.503 103.269 143.402 102.909C143.3 102.55 143.503 102.167 143.863 102.066C144.222 101.956 144.605 102.167 144.706 102.527C144.808 102.886 144.605 103.269 144.245 103.37Z"
                fill="#12467F"/>
            <path
                d="M141.621 104.136C141.262 104.237 140.879 104.034 140.778 103.675C140.676 103.315 140.879 102.933 141.239 102.831C141.598 102.722 141.981 102.933 142.082 103.292C142.191 103.651 141.981 104.034 141.621 104.136Z"
                fill="#12467F"/>
            <path
                d="M139.005 104.901C138.646 105.01 138.263 104.799 138.161 104.44C138.052 104.081 138.263 103.698 138.622 103.597C138.982 103.495 139.364 103.698 139.466 104.057C139.567 104.417 139.364 104.799 139.005 104.901Z"
                fill="#12467F"/>
            <path
                d="M148.681 104.136C148.322 104.245 147.94 104.034 147.838 103.675C147.729 103.315 147.94 102.933 148.299 102.831C148.658 102.722 149.041 102.933 149.142 103.292C149.244 103.651 149.041 104.034 148.681 104.136Z"
                fill="#12467F"/>
            <path
                d="M146.057 104.901C145.698 105.01 145.315 104.799 145.213 104.44C145.104 104.081 145.315 103.698 145.674 103.597C146.034 103.495 146.416 103.698 146.518 104.057C146.627 104.417 146.416 104.799 146.057 104.901Z"
                fill="#12467F"/>
            <path
                d="M143.433 105.666C143.074 105.776 142.691 105.565 142.589 105.206C142.488 104.846 142.691 104.464 143.05 104.362C143.409 104.26 143.792 104.464 143.894 104.823C144.003 105.182 143.792 105.565 143.433 105.666Z"
                fill="#12467F"/>
            <path
                d="M140.809 106.432C140.45 106.533 140.067 106.33 139.965 105.971C139.864 105.612 140.067 105.229 140.426 105.127C140.785 105.018 141.168 105.229 141.27 105.588C141.379 105.947 141.168 106.33 140.809 106.432Z"
                fill="#12467F"/>
            <path
                d="M138.192 107.197C137.833 107.306 137.45 107.095 137.349 106.736C137.247 106.377 137.45 105.994 137.81 105.893C138.169 105.783 138.552 105.994 138.653 106.353C138.762 106.713 138.552 107.095 138.192 107.197Z"
                fill="#12467F"/>
            <path
                d="M150.657 105.823C150.298 105.932 149.915 105.721 149.813 105.362C149.712 105.002 149.915 104.62 150.274 104.518C150.634 104.417 151.016 104.62 151.118 104.979C151.219 105.338 151.016 105.721 150.657 105.823Z"
                fill="#12467F"/>
            <path
                d="M148.033 106.588C147.674 106.689 147.291 106.486 147.189 106.127C147.088 105.768 147.291 105.385 147.65 105.284C148.009 105.174 148.392 105.385 148.494 105.744C148.595 106.104 148.392 106.486 148.033 106.588Z"
                fill="#12467F"/>
            <path
                d="M145.417 107.361C145.057 107.47 144.675 107.26 144.573 106.9C144.464 106.541 144.675 106.158 145.034 106.057C145.393 105.947 145.776 106.158 145.877 106.518C145.979 106.877 145.776 107.26 145.417 107.361Z"
                fill="#12467F"/>
            <path
                d="M142.793 108.126C142.433 108.236 142.051 108.025 141.949 107.666C141.84 107.306 142.051 106.924 142.41 106.822C142.769 106.713 143.152 106.924 143.253 107.283C143.355 107.642 143.152 108.025 142.793 108.126Z"
                fill="#12467F"/>
            <path
                d="M140.168 108.892C139.809 109.001 139.426 108.79 139.325 108.431C139.215 108.072 139.426 107.689 139.786 107.587C140.145 107.478 140.528 107.689 140.629 108.048C140.738 108.408 140.528 108.79 140.168 108.892Z"
                fill="#12467F"/>
            <path
                d="M149.845 108.119C149.486 108.228 149.103 108.017 149.001 107.658C148.9 107.298 149.103 106.916 149.462 106.814C149.822 106.713 150.204 106.916 150.306 107.275C150.415 107.634 150.204 108.017 149.845 108.119Z"
                fill="#12467F"/>
            <path
                d="M147.221 108.892C146.862 108.993 146.479 108.79 146.377 108.431C146.276 108.072 146.479 107.689 146.838 107.587C147.197 107.478 147.58 107.689 147.682 108.048C147.791 108.407 147.58 108.79 147.221 108.892Z"
                fill="#12467F"/>
            <path
                d="M144.596 109.657C144.237 109.759 143.854 109.556 143.753 109.196C143.651 108.837 143.854 108.454 144.214 108.353C144.573 108.244 144.956 108.454 145.057 108.814C145.167 109.173 144.956 109.556 144.596 109.657Z"
                fill="#12467F"/>
            <path
                d="M141.98 110.423C141.621 110.532 141.238 110.321 141.137 109.962C141.027 109.603 141.238 109.22 141.598 109.118C141.957 109.009 142.34 109.22 142.441 109.579C142.55 109.938 142.34 110.321 141.98 110.423Z"
                fill="#12467F"/>
            <path
                d="M139.356 111.188C138.997 111.297 138.614 111.086 138.513 110.727C138.403 110.368 138.614 109.985 138.974 109.884C139.333 109.782 139.716 109.985 139.817 110.344C139.926 110.704 139.716 111.086 139.356 111.188Z"
                fill="#12467F"/>
            <path
                d="M151.829 109.813C151.469 109.923 151.087 109.712 150.985 109.353C150.876 108.993 151.087 108.611 151.446 108.509C151.805 108.4 152.188 108.611 152.289 108.97C152.399 109.329 152.188 109.712 151.829 109.813Z"
                fill="#12467F"/>
            <path
                d="M149.204 110.579C148.845 110.68 148.462 110.477 148.361 110.118C148.252 109.759 148.462 109.376 148.822 109.274C149.181 109.165 149.564 109.376 149.665 109.735C149.767 110.094 149.564 110.477 149.204 110.579Z"
                fill="#12467F"/>
            <path
                d="M146.58 111.344C146.221 111.453 145.838 111.243 145.736 110.883C145.627 110.524 145.838 110.141 146.197 110.04C146.556 109.931 146.939 110.141 147.041 110.501C147.15 110.86 146.939 111.243 146.58 111.344Z"
                fill="#12467F"/>
            <path
                d="M143.956 112.109C143.597 112.219 143.214 112.008 143.113 111.649C143.003 111.289 143.214 110.907 143.573 110.805C143.933 110.696 144.315 110.907 144.417 111.266C144.526 111.625 144.315 112.008 143.956 112.109Z"
                fill="#12467F"/>
            <path
                d="M141.332 112.883C140.973 112.992 140.59 112.781 140.489 112.422C140.379 112.063 140.59 111.68 140.949 111.578C141.309 111.477 141.691 111.68 141.793 112.039C141.894 112.399 141.691 112.781 141.332 112.883Z"
                fill="#12467F"/>
            <path
                d="M138.708 113.648C138.349 113.757 137.966 113.547 137.865 113.187C137.755 112.828 137.966 112.445 138.325 112.344C138.685 112.235 139.067 112.445 139.169 112.805C139.278 113.164 139.067 113.547 138.708 113.648Z"
                fill="#12467F"/>
            <path
                d="M151.009 112.11C150.649 112.219 150.267 112.008 150.165 111.649C150.064 111.289 150.267 110.907 150.626 110.805C150.985 110.696 151.368 110.907 151.47 111.266C151.571 111.625 151.368 112.008 151.009 112.11Z"
                fill="#12467F"/>
            <path
                d="M148.392 112.875C148.033 112.984 147.65 112.773 147.548 112.414C147.439 112.055 147.65 111.672 148.009 111.571C148.369 111.469 148.751 111.672 148.853 112.031C148.954 112.391 148.751 112.773 148.392 112.875Z"
                fill="#12467F"/>
            <path
                d="M145.768 113.64C145.409 113.742 145.026 113.539 144.925 113.18C144.815 112.82 145.026 112.438 145.385 112.336C145.745 112.235 146.127 112.438 146.229 112.797C146.33 113.156 146.127 113.539 145.768 113.64Z"
                fill="#12467F"/>
            <path
                d="M143.144 114.406C142.785 114.515 142.402 114.304 142.301 113.945C142.199 113.586 142.402 113.203 142.762 113.101C143.121 112.992 143.504 113.203 143.605 113.562C143.714 113.921 143.504 114.304 143.144 114.406Z"
                fill="#12467F"/>
            <path
                d="M140.52 115.179C140.161 115.288 139.778 115.077 139.677 114.718C139.567 114.359 139.778 113.976 140.137 113.875C140.497 113.765 140.879 113.976 140.981 114.335C141.082 114.695 140.879 115.077 140.52 115.179Z"
                fill="#12467F"/>
            <path
                d="M152.969 113.718C152.61 113.82 152.227 113.617 152.125 113.258C152.024 112.898 152.227 112.516 152.586 112.414C152.946 112.305 153.328 112.516 153.43 112.875C153.531 113.234 153.328 113.617 152.969 113.718Z"
                fill="#12467F"/>
            <path
                d="M150.345 114.484C149.986 114.593 149.603 114.382 149.501 114.023C149.4 113.664 149.603 113.281 149.962 113.18C150.322 113.07 150.704 113.281 150.806 113.64C150.907 114 150.704 114.382 150.345 114.484Z"
                fill="#12467F"/>
            <path
                d="M147.721 115.249C147.361 115.359 146.979 115.148 146.877 114.788C146.776 114.429 146.979 114.046 147.338 113.945C147.697 113.836 148.08 114.046 148.181 114.406C148.291 114.765 148.08 115.148 147.721 115.249Z"
                fill="#12467F"/>
            <path
                d="M145.096 116.015C144.737 116.124 144.354 115.913 144.253 115.554C144.151 115.195 144.354 114.812 144.714 114.71C145.073 114.601 145.456 114.812 145.557 115.171C145.659 115.53 145.456 115.913 145.096 116.015Z"
                fill="#12467F"/>
            <path
                d="M142.473 116.78C142.113 116.881 141.731 116.678 141.629 116.319C141.52 115.96 141.731 115.577 142.09 115.476C142.449 115.366 142.832 115.577 142.933 115.936C143.035 116.296 142.832 116.678 142.473 116.78Z"
                fill="#12467F"/>
            <path
                d="M139.848 117.545C139.489 117.647 139.106 117.444 139.005 117.085C138.903 116.725 139.106 116.343 139.465 116.241C139.825 116.132 140.207 116.343 140.309 116.702C140.418 117.061 140.207 117.444 139.848 117.545Z"
                fill="#12467F"/>
            <path
                d="M152.149 116.015C151.789 116.124 151.407 115.913 151.305 115.554C151.204 115.195 151.407 114.812 151.766 114.71C152.125 114.601 152.508 114.812 152.609 115.171C152.719 115.53 152.508 115.913 152.149 116.015Z"
                fill="#12467F"/>
            <path
                d="M149.533 116.78C149.173 116.889 148.791 116.678 148.689 116.319C148.588 115.96 148.791 115.577 149.15 115.476C149.509 115.366 149.892 115.577 149.993 115.936C150.095 116.296 149.892 116.678 149.533 116.78Z"
                fill="#12467F"/>
            <path
                d="M146.908 117.545C146.549 117.655 146.166 117.444 146.065 117.085C145.963 116.725 146.166 116.343 146.526 116.241C146.885 116.132 147.268 116.343 147.369 116.702C147.479 117.061 147.268 117.444 146.908 117.545Z"
                fill="#12467F"/>
            <path
                d="M144.284 118.311C143.925 118.42 143.542 118.209 143.441 117.85C143.339 117.491 143.542 117.108 143.901 117.006C144.261 116.905 144.643 117.108 144.745 117.467C144.854 117.826 144.643 118.209 144.284 118.311Z"
                fill="#12467F"/>
            <path
                d="M141.66 119.076C141.301 119.185 140.918 118.975 140.817 118.615C140.715 118.256 140.918 117.873 141.277 117.772C141.637 117.67 142.019 117.873 142.121 118.233C142.23 118.592 142.019 118.975 141.66 119.076Z"
                fill="#12467F"/>
            <path
                d="M139.036 119.841C138.677 119.943 138.294 119.74 138.193 119.381C138.091 119.021 138.294 118.639 138.653 118.537C139.013 118.428 139.395 118.639 139.497 118.998C139.606 119.357 139.395 119.74 139.036 119.841Z"
                fill="#12467F"/>
            <path
                d="M154.133 117.702C153.773 117.803 153.391 117.6 153.289 117.241C153.18 116.881 153.391 116.499 153.75 116.397C154.109 116.288 154.492 116.499 154.593 116.858C154.703 117.217 154.492 117.6 154.133 117.702Z"
                fill="#12467F"/>
            <path
                d="M151.509 118.475C151.149 118.584 150.767 118.373 150.665 118.014C150.556 117.655 150.767 117.272 151.126 117.17C151.485 117.061 151.868 117.272 151.969 117.631C152.079 117.99 151.868 118.373 151.509 118.475Z"
                fill="#12467F"/>
            <path
                d="M148.884 119.24C148.525 119.349 148.142 119.139 148.041 118.779C147.932 118.42 148.142 118.037 148.502 117.936C148.861 117.834 149.244 118.037 149.345 118.397C149.447 118.756 149.244 119.139 148.884 119.24Z"
                fill="#12467F"/>
            <path
                d="M146.26 120.005C145.901 120.107 145.519 119.904 145.417 119.545C145.315 119.185 145.519 118.803 145.878 118.701C146.237 118.6 146.62 118.803 146.721 119.162C146.831 119.521 146.62 119.904 146.26 120.005Z"
                fill="#12467F"/>
            <path
                d="M143.636 120.771C143.277 120.88 142.894 120.669 142.792 120.31C142.683 119.951 142.894 119.568 143.253 119.467C143.612 119.357 143.995 119.568 144.097 119.927C144.206 120.287 143.995 120.669 143.636 120.771Z"
                fill="#12467F"/>
            <path
                d="M141.02 121.536C140.66 121.646 140.278 121.435 140.176 121.075C140.067 120.716 140.278 120.333 140.637 120.232C140.996 120.123 141.379 120.333 141.48 120.693C141.582 121.052 141.379 121.435 141.02 121.536Z"
                fill="#12467F"/>
            <path
                d="M138.395 122.302C138.036 122.411 137.653 122.2 137.552 121.841C137.442 121.482 137.653 121.099 138.012 120.997C138.372 120.888 138.754 121.099 138.856 121.458C138.965 121.817 138.754 122.2 138.395 122.302Z"
                fill="#12467F"/>
            <path
                d="M153.321 120.005C152.961 120.115 152.579 119.904 152.477 119.545C152.376 119.185 152.579 118.803 152.938 118.701C153.297 118.592 153.68 118.803 153.781 119.162C153.891 119.521 153.68 119.904 153.321 120.005Z"
                fill="#12467F"/>
            <path
                d="M150.696 120.771C150.337 120.88 149.954 120.669 149.853 120.31C149.744 119.951 149.954 119.568 150.314 119.466C150.673 119.357 151.056 119.568 151.157 119.927C151.267 120.287 151.056 120.669 150.696 120.771Z"
                fill="#12467F"/>
            <path
                d="M148.072 121.536C147.712 121.646 147.33 121.435 147.228 121.075C147.127 120.716 147.33 120.333 147.689 120.232C148.048 120.123 148.431 120.333 148.533 120.693C148.634 121.052 148.431 121.435 148.072 121.536Z"
                fill="#12467F"/>
            <path
                d="M145.448 122.302C145.089 122.403 144.706 122.2 144.604 121.841C144.495 121.481 144.706 121.099 145.065 120.997C145.424 120.888 145.807 121.099 145.909 121.458C146.018 121.817 145.807 122.2 145.448 122.302Z"
                fill="#12467F"/>
            <path
                d="M142.824 123.067C142.465 123.176 142.082 122.965 141.98 122.606C141.871 122.247 142.082 121.864 142.441 121.763C142.8 121.661 143.183 121.864 143.285 122.224C143.394 122.583 143.183 122.965 142.824 123.067Z"
                fill="#12467F"/>
            <path
                d="M140.2 123.832C139.84 123.942 139.458 123.731 139.356 123.372C139.247 123.012 139.458 122.63 139.817 122.528C140.176 122.419 140.559 122.63 140.66 122.989C140.77 123.348 140.559 123.731 140.2 123.832Z"
                fill="#12467F"/>
            <path
                d="M155.296 121.692C154.937 121.802 154.555 121.591 154.453 121.232C154.344 120.872 154.555 120.49 154.914 120.388C155.273 120.287 155.656 120.49 155.757 120.849C155.859 121.208 155.656 121.591 155.296 121.692Z"
                fill="#12467F"/>
            <path
                d="M152.673 122.458C152.313 122.567 151.931 122.356 151.829 121.997C151.728 121.638 151.931 121.255 152.29 121.154C152.649 121.044 153.032 121.255 153.133 121.614C153.235 121.974 153.032 122.356 152.673 122.458Z"
                fill="#12467F"/>
            <path
                d="M150.048 123.231C149.689 123.332 149.306 123.129 149.204 122.77C149.095 122.411 149.306 122.028 149.665 121.927C150.025 121.817 150.407 122.028 150.509 122.387C150.61 122.747 150.407 123.129 150.048 123.231Z"
                fill="#12467F"/>
            <path
                d="M147.432 123.996C147.073 124.098 146.69 123.895 146.588 123.536C146.487 123.176 146.69 122.794 147.049 122.692C147.408 122.583 147.791 122.794 147.893 123.153C147.994 123.512 147.791 123.895 147.432 123.996Z"
                fill="#12467F"/>
            <path
                d="M144.807 124.762C144.448 124.871 144.065 124.66 143.964 124.301C143.854 123.942 144.065 123.559 144.425 123.458C144.784 123.348 145.166 123.559 145.268 123.918C145.37 124.278 145.166 124.66 144.807 124.762Z"
                fill="#12467F"/>
            <path
                d="M142.183 125.527C141.824 125.636 141.441 125.426 141.34 125.066C141.231 124.707 141.441 124.324 141.801 124.223C142.16 124.121 142.543 124.324 142.644 124.684C142.754 125.043 142.543 125.426 142.183 125.527Z"
                fill="#12467F"/>
            <path
                d="M139.559 126.293C139.2 126.402 138.817 126.191 138.716 125.832C138.606 125.473 138.817 125.09 139.176 124.988C139.536 124.887 139.918 125.09 140.02 125.449C140.121 125.808 139.918 126.191 139.559 126.293Z"
                fill="#12467F"/>
            <path
                d="M154.484 123.989C154.125 124.098 153.742 123.887 153.64 123.528C153.531 123.169 153.742 122.786 154.101 122.684C154.461 122.575 154.843 122.786 154.945 123.145C155.046 123.504 154.843 123.887 154.484 123.989Z"
                fill="#12467F"/>
            <path
                d="M151.86 124.754C151.501 124.863 151.118 124.652 151.017 124.293C150.915 123.934 151.118 123.551 151.477 123.45C151.837 123.34 152.219 123.551 152.321 123.91C152.422 124.27 152.219 124.652 151.86 124.754Z"
                fill="#12467F"/>
            <path
                d="M149.236 125.519C148.876 125.629 148.494 125.418 148.392 125.059C148.291 124.699 148.494 124.317 148.853 124.215C149.212 124.106 149.595 124.317 149.696 124.676C149.806 125.035 149.595 125.418 149.236 125.519Z"
                fill="#12467F"/>
            <path
                d="M146.612 126.293C146.252 126.402 145.87 126.191 145.768 125.832C145.667 125.473 145.87 125.09 146.229 124.988C146.588 124.887 146.971 125.09 147.072 125.449C147.174 125.808 146.971 126.191 146.612 126.293Z"
                fill="#12467F"/>
            <path
                d="M143.995 127.058C143.636 127.167 143.253 126.956 143.151 126.597C143.042 126.238 143.253 125.855 143.612 125.754C143.972 125.652 144.354 125.855 144.456 126.214C144.557 126.574 144.354 126.956 143.995 127.058Z"
                fill="#12467F"/>
            <path
                d="M141.371 127.823C141.012 127.933 140.629 127.722 140.527 127.362C140.418 127.003 140.629 126.62 140.988 126.519C141.348 126.41 141.73 126.62 141.832 126.98C141.941 127.339 141.73 127.722 141.371 127.823Z"
                fill="#12467F"/>
            <path
                d="M138.747 128.589C138.388 128.698 138.005 128.487 137.903 128.128C137.794 127.769 138.005 127.386 138.364 127.284C138.723 127.175 139.106 127.386 139.208 127.745C139.309 128.104 139.106 128.487 138.747 128.589Z"
                fill="#12467F"/>
            <path
                d="M156.46 125.683C156.101 125.793 155.718 125.582 155.617 125.223C155.515 124.863 155.718 124.481 156.077 124.379C156.437 124.278 156.819 124.481 156.921 124.84C157.03 125.199 156.819 125.582 156.46 125.683Z"
                fill="#12467F"/>
            <path
                d="M153.844 126.449C153.485 126.558 153.102 126.347 153 125.988C152.891 125.629 153.102 125.246 153.461 125.144C153.82 125.043 154.203 125.246 154.305 125.605C154.406 125.964 154.203 126.347 153.844 126.449Z"
                fill="#12467F"/>
            <path
                d="M151.22 127.214C150.86 127.323 150.478 127.113 150.376 126.753C150.275 126.394 150.478 126.011 150.837 125.91C151.196 125.801 151.579 126.011 151.68 126.371C151.79 126.73 151.579 127.113 151.22 127.214Z"
                fill="#12467F"/>
            <path
                d="M148.596 127.979C148.236 128.089 147.854 127.878 147.752 127.519C147.643 127.159 147.854 126.777 148.213 126.675C148.572 126.566 148.955 126.777 149.056 127.136C149.158 127.495 148.955 127.878 148.596 127.979Z"
                fill="#12467F"/>
            <path
                d="M145.971 128.753C145.612 128.862 145.229 128.651 145.128 128.292C145.026 127.933 145.229 127.55 145.588 127.448C145.948 127.339 146.33 127.55 146.432 127.909C146.541 128.268 146.33 128.651 145.971 128.753Z"
                fill="#12467F"/>
            <path
                d="M143.347 129.518C142.988 129.62 142.605 129.417 142.504 129.057C142.394 128.698 142.605 128.315 142.964 128.214C143.324 128.112 143.706 128.315 143.808 128.675C143.917 129.034 143.706 129.417 143.347 129.518Z"
                fill="#12467F"/>
            <path
                d="M140.723 130.283C140.364 130.385 139.981 130.182 139.879 129.823C139.778 129.463 139.981 129.081 140.34 128.979C140.699 128.87 141.082 129.081 141.184 129.44C141.293 129.799 141.082 130.182 140.723 130.283Z"
                fill="#12467F"/>
            <path
                d="M138.099 131.049C137.74 131.15 137.357 130.947 137.255 130.588C137.146 130.229 137.357 129.846 137.716 129.745C138.076 129.643 138.458 129.846 138.56 130.205C138.669 130.565 138.458 130.947 138.099 131.049Z"
                fill="#12467F"/>
            <path
                d="M155.648 127.979C155.288 128.089 154.906 127.878 154.804 127.519C154.703 127.159 154.906 126.777 155.265 126.675C155.624 126.566 156.007 126.777 156.108 127.136C156.218 127.495 156.007 127.878 155.648 127.979Z"
                fill="#12467F"/>
            <path
                d="M153.024 128.745C152.665 128.854 152.282 128.643 152.18 128.284C152.071 127.925 152.282 127.542 152.641 127.441C153 127.331 153.383 127.542 153.485 127.901C153.586 128.261 153.383 128.643 153.024 128.745Z"
                fill="#12467F"/>
            <path
                d="M150.407 129.51C150.048 129.612 149.665 129.409 149.564 129.049C149.462 128.69 149.665 128.308 150.025 128.206C150.384 128.097 150.766 128.308 150.868 128.667C150.97 129.026 150.766 129.409 150.407 129.51Z"
                fill="#12467F"/>
            <path
                d="M147.783 130.276C147.424 130.377 147.041 130.174 146.94 129.815C146.83 129.456 147.041 129.073 147.401 128.971C147.76 128.862 148.143 129.073 148.244 129.432C148.346 129.791 148.143 130.174 147.783 130.276Z"
                fill="#12467F"/>
            <path
                d="M145.159 131.049C144.8 131.15 144.417 130.947 144.315 130.588C144.206 130.229 144.417 129.846 144.776 129.745C145.135 129.643 145.518 129.846 145.62 130.205C145.721 130.565 145.518 130.947 145.159 131.049Z"
                fill="#12467F"/>
            <path
                d="M142.535 131.814C142.175 131.916 141.793 131.713 141.691 131.353C141.582 130.994 141.793 130.611 142.152 130.51C142.511 130.408 142.894 130.611 142.996 130.971C143.097 131.33 142.894 131.713 142.535 131.814Z"
                fill="#12467F"/>
            <path
                d="M139.91 132.58C139.551 132.681 139.168 132.478 139.067 132.119C138.957 131.76 139.168 131.377 139.528 131.275C139.887 131.174 140.27 131.377 140.371 131.736C140.48 132.095 140.27 132.478 139.91 132.58Z"
                fill="#12467F"/>
            <path
                d="M155.007 130.44C154.648 130.541 154.265 130.338 154.164 129.979C154.062 129.62 154.265 129.237 154.625 129.135C154.984 129.026 155.367 129.237 155.468 129.596C155.577 129.955 155.367 130.338 155.007 130.44Z"
                fill="#12467F"/>
            <path
                d="M152.383 131.205C152.024 131.307 151.641 131.104 151.54 130.744C151.431 130.385 151.641 130.002 152.001 129.901C152.36 129.799 152.743 130.002 152.844 130.362C152.954 130.721 152.743 131.104 152.383 131.205Z"
                fill="#12467F"/>
            <path
                d="M149.759 131.97C149.4 132.072 149.017 131.869 148.916 131.51C148.814 131.15 149.017 130.768 149.376 130.666C149.736 130.565 150.118 130.768 150.22 131.127C150.329 131.486 150.118 131.869 149.759 131.97Z"
                fill="#12467F"/>
            <path
                d="M147.135 132.736C146.776 132.845 146.393 132.634 146.291 132.275C146.19 131.916 146.393 131.533 146.752 131.431C147.112 131.33 147.494 131.533 147.596 131.892C147.697 132.251 147.494 132.634 147.135 132.736Z"
                fill="#12467F"/>
            <path
                d="M144.519 133.509C144.16 133.618 143.777 133.407 143.675 133.048C143.574 132.689 143.777 132.306 144.136 132.205C144.495 132.103 144.878 132.306 144.98 132.665C145.089 133.025 144.878 133.407 144.519 133.509Z"
                fill="#12467F"/>
            <path
                d="M141.894 134.274C141.535 134.384 141.152 134.173 141.051 133.814C140.941 133.454 141.152 133.072 141.511 132.97C141.871 132.869 142.253 133.072 142.355 133.431C142.464 133.79 142.253 134.173 141.894 134.274Z"
                fill="#12467F"/>
            <path
                d="M139.27 135.04C138.911 135.141 138.528 134.938 138.427 134.579C138.325 134.22 138.528 133.837 138.888 133.735C139.247 133.626 139.629 133.837 139.731 134.196C139.84 134.555 139.629 134.938 139.27 135.04Z"
                fill="#12467F"/>
            <path
                d="M156.819 131.97C156.46 132.072 156.077 131.869 155.976 131.51C155.866 131.15 156.077 130.768 156.436 130.666C156.796 130.565 157.178 130.768 157.28 131.127C157.381 131.486 157.178 131.869 156.819 131.97Z"
                fill="#12467F"/>
            <path
                d="M154.195 132.736C153.836 132.845 153.453 132.634 153.351 132.275C153.25 131.916 153.453 131.533 153.812 131.431C154.171 131.33 154.554 131.533 154.656 131.892C154.765 132.251 154.554 132.634 154.195 132.736Z"
                fill="#12467F"/>
            <path
                d="M151.571 133.501C151.212 133.611 150.829 133.4 150.727 133.04C150.618 132.681 150.829 132.298 151.188 132.197C151.547 132.095 151.93 132.298 152.032 132.658C152.133 133.017 151.93 133.4 151.571 133.501Z"
                fill="#12467F"/>
            <path
                d="M148.947 134.267C148.588 134.376 148.205 134.165 148.104 133.806C148.002 133.447 148.205 133.064 148.564 132.962C148.924 132.861 149.306 133.064 149.408 133.423C149.517 133.782 149.306 134.165 148.947 134.267Z"
                fill="#12467F"/>
            <path
                d="M146.322 135.032C145.963 135.141 145.58 134.93 145.479 134.571C145.37 134.212 145.58 133.829 145.94 133.728C146.299 133.626 146.682 133.829 146.783 134.188C146.885 134.548 146.682 134.93 146.322 135.032Z"
                fill="#12467F"/>
            <path
                d="M143.698 135.805C143.339 135.907 142.957 135.704 142.855 135.344C142.753 134.985 142.957 134.602 143.316 134.501C143.675 134.392 144.058 134.602 144.159 134.962C144.269 135.321 144.058 135.704 143.698 135.805Z"
                fill="#12467F"/>
            <path
                d="M141.074 136.57C140.715 136.672 140.332 136.469 140.231 136.11C140.121 135.75 140.332 135.368 140.691 135.266C141.051 135.157 141.433 135.368 141.535 135.727C141.644 136.086 141.433 136.469 141.074 136.57Z"
                fill="#12467F"/>
            <path
                d="M138.458 137.336C138.099 137.437 137.716 137.234 137.614 136.875C137.513 136.516 137.716 136.133 138.075 136.032C138.435 135.922 138.817 136.133 138.919 136.492C139.028 136.852 138.817 137.234 138.458 137.336Z"
                fill="#12467F"/>
            <path
                d="M156.171 134.431C155.812 134.54 155.429 134.329 155.328 133.97C155.226 133.611 155.429 133.228 155.788 133.126C156.148 133.025 156.53 133.228 156.632 133.587C156.733 133.946 156.53 134.329 156.171 134.431Z"
                fill="#12467F"/>
            <path
                d="M153.547 135.196C153.188 135.305 152.805 135.094 152.703 134.735C152.602 134.376 152.805 133.993 153.164 133.892C153.523 133.782 153.906 133.993 154.008 134.352C154.109 134.712 153.906 135.094 153.547 135.196Z"
                fill="#12467F"/>
            <path
                d="M150.923 135.961C150.563 136.071 150.181 135.86 150.079 135.501C149.978 135.141 150.181 134.759 150.54 134.657C150.899 134.548 151.282 134.759 151.383 135.118C151.485 135.477 151.282 135.86 150.923 135.961Z"
                fill="#12467F"/>
            <path
                d="M148.306 136.727C147.947 136.828 147.565 136.625 147.463 136.266C147.354 135.907 147.565 135.524 147.924 135.422C148.283 135.313 148.666 135.524 148.767 135.883C148.869 136.242 148.666 136.625 148.306 136.727Z"
                fill="#12467F"/>
            <path
                d="M145.682 137.492C145.323 137.594 144.941 137.391 144.839 137.031C144.73 136.672 144.941 136.289 145.3 136.188C145.659 136.086 146.042 136.289 146.143 136.649C146.245 137.008 146.042 137.391 145.682 137.492Z"
                fill="#12467F"/>
            <path
                d="M143.058 138.257C142.699 138.359 142.316 138.156 142.215 137.797C142.113 137.437 142.316 137.055 142.675 136.953C143.035 136.852 143.417 137.055 143.519 137.414C143.628 137.773 143.417 138.156 143.058 138.257Z"
                fill="#12467F"/>
            <path
                d="M140.434 139.031C140.075 139.132 139.692 138.929 139.59 138.57C139.481 138.211 139.692 137.828 140.051 137.726C140.411 137.625 140.793 137.828 140.895 138.187C140.996 138.546 140.793 138.929 140.434 139.031Z"
                fill="#12467F"/>
            <path
                d="M155.359 136.727C155 136.828 154.617 136.625 154.515 136.266C154.414 135.907 154.617 135.524 154.976 135.422C155.335 135.313 155.718 135.524 155.82 135.883C155.929 136.242 155.718 136.625 155.359 136.727Z"
                fill="#12467F"/>
            <path
                d="M152.735 137.492C152.375 137.594 151.993 137.39 151.891 137.031C151.79 136.672 151.993 136.289 152.352 136.188C152.711 136.078 153.094 136.289 153.195 136.649C153.305 137.008 153.094 137.39 152.735 137.492Z"
                fill="#12467F"/>
            <path
                d="M150.11 138.257C149.751 138.359 149.368 138.156 149.267 137.797C149.165 137.437 149.368 137.055 149.728 136.953C150.087 136.852 150.47 137.055 150.571 137.414C150.68 137.773 150.47 138.156 150.11 138.257Z"
                fill="#12467F"/>
            <path
                d="M147.494 139.023C147.135 139.132 146.752 138.921 146.651 138.562C146.549 138.203 146.752 137.82 147.111 137.719C147.471 137.617 147.853 137.82 147.955 138.179C148.056 138.539 147.853 138.921 147.494 139.023Z"
                fill="#12467F"/>
            <path
                d="M144.87 139.788C144.511 139.897 144.128 139.687 144.027 139.327C143.917 138.968 144.128 138.585 144.488 138.484C144.847 138.382 145.229 138.585 145.331 138.945C145.433 139.304 145.229 139.687 144.87 139.788Z"
                fill="#12467F"/>
            <path
                d="M142.246 140.554C141.886 140.663 141.504 140.452 141.402 140.093C141.301 139.734 141.504 139.351 141.863 139.249C142.222 139.148 142.605 139.351 142.706 139.71C142.816 140.069 142.605 140.452 142.246 140.554Z"
                fill="#12467F"/>
            <path
                d="M139.622 141.327C139.262 141.436 138.88 141.225 138.778 140.866C138.669 140.507 138.88 140.124 139.239 140.022C139.598 139.921 139.981 140.124 140.082 140.483C140.192 140.843 139.981 141.225 139.622 141.327Z"
                fill="#12467F"/>
            <path
                d="M154.679 139.077C154.32 139.179 153.937 138.976 153.836 138.617C153.734 138.257 153.937 137.875 154.297 137.773C154.656 137.672 155.039 137.875 155.14 138.234C155.242 138.593 155.039 138.976 154.679 139.077Z"
                fill="#12467F"/>
            <path
                d="M152.063 139.843C151.704 139.944 151.321 139.741 151.22 139.382C151.118 139.023 151.321 138.64 151.681 138.539C152.04 138.437 152.422 138.64 152.524 138.999C152.626 139.359 152.422 139.741 152.063 139.843Z"
                fill="#12467F"/>
            <path
                d="M149.439 140.608C149.08 140.718 148.697 140.507 148.596 140.147C148.486 139.788 148.697 139.405 149.056 139.304C149.416 139.202 149.798 139.405 149.9 139.765C150.001 140.124 149.798 140.507 149.439 140.608Z"
                fill="#12467F"/>
            <path
                d="M146.815 141.374C146.456 141.483 146.073 141.272 145.972 140.913C145.862 140.554 146.073 140.171 146.432 140.069C146.792 139.968 147.174 140.171 147.276 140.53C147.377 140.889 147.174 141.272 146.815 141.374Z"
                fill="#12467F"/>
            <path
                d="M144.191 142.139C143.831 142.248 143.449 142.038 143.347 141.678C143.238 141.319 143.449 140.936 143.808 140.835C144.167 140.733 144.55 140.936 144.652 141.296C144.761 141.655 144.55 142.038 144.191 142.139Z"
                fill="#12467F"/>
            <path
                d="M141.566 142.904C141.207 143.014 140.824 142.803 140.723 142.444C140.621 142.084 140.824 141.702 141.184 141.6C141.543 141.491 141.926 141.702 142.027 142.061C142.137 142.42 141.926 142.803 141.566 142.904Z"
                fill="#12467F"/>
            <path
                d="M138.942 143.678C138.583 143.779 138.201 143.576 138.099 143.217C137.99 142.858 138.201 142.475 138.56 142.373C138.919 142.264 139.302 142.475 139.403 142.834C139.505 143.193 139.302 143.576 138.942 143.678Z"
                fill="#12467F"/>
            <path
                d="M156.491 140.608C156.132 140.718 155.749 140.507 155.648 140.147C155.546 139.788 155.749 139.405 156.108 139.304C156.468 139.202 156.85 139.405 156.952 139.765C157.053 140.124 156.85 140.507 156.491 140.608Z"
                fill="#12467F"/>
            <path
                d="M153.867 141.374C153.508 141.483 153.125 141.272 153.023 140.913C152.922 140.554 153.125 140.171 153.484 140.069C153.843 139.968 154.226 140.171 154.328 140.53C154.437 140.889 154.226 141.272 153.867 141.374Z"
                fill="#12467F"/>
            <path
                d="M151.243 142.139C150.884 142.248 150.501 142.037 150.399 141.678C150.298 141.319 150.501 140.936 150.86 140.835C151.219 140.733 151.602 140.936 151.704 141.296C151.805 141.655 151.602 142.037 151.243 142.139Z"
                fill="#12467F"/>
            <path
                d="M148.627 142.904C148.267 143.014 147.885 142.803 147.783 142.443C147.682 142.084 147.885 141.702 148.244 141.6C148.603 141.498 148.986 141.702 149.087 142.061C149.197 142.42 148.986 142.803 148.627 142.904Z"
                fill="#12467F"/>
            <path
                d="M146.002 143.67C145.643 143.771 145.26 143.568 145.159 143.209C145.057 142.85 145.26 142.467 145.619 142.366C145.979 142.256 146.361 142.467 146.463 142.826C146.564 143.186 146.361 143.568 146.002 143.67Z"
                fill="#12467F"/>
            <path
                d="M143.378 144.435C143.019 144.537 142.636 144.334 142.535 143.974C142.433 143.615 142.636 143.232 142.996 143.131C143.355 143.021 143.738 143.232 143.839 143.592C143.949 143.951 143.738 144.334 143.378 144.435Z"
                fill="#12467F"/>
            <path
                d="M140.754 145.208C140.395 145.31 140.012 145.107 139.91 144.747C139.809 144.388 140.012 144.006 140.371 143.904C140.73 143.795 141.113 144.006 141.215 144.365C141.324 144.724 141.113 145.107 140.754 145.208Z"
                fill="#12467F"/>
            <path
                d="M138.13 145.974C137.771 146.075 137.388 145.872 137.286 145.513C137.177 145.154 137.388 144.771 137.747 144.669C138.107 144.568 138.489 144.771 138.591 145.13C138.7 145.489 138.489 145.872 138.13 145.974Z"
                fill="#12467F"/>
            <path
                d="M155.851 143.061C155.491 143.17 155.109 142.959 155.007 142.6C154.898 142.241 155.109 141.858 155.468 141.756C155.827 141.647 156.21 141.858 156.311 142.217C156.421 142.576 156.21 142.959 155.851 143.061Z"
                fill="#12467F"/>
            <path
                d="M153.227 143.834C152.868 143.943 152.485 143.732 152.383 143.373C152.274 143.014 152.485 142.631 152.844 142.53C153.203 142.42 153.586 142.631 153.688 142.99C153.789 143.35 153.586 143.732 153.227 143.834Z"
                fill="#12467F"/>
            <path
                d="M150.603 144.599C150.243 144.701 149.861 144.498 149.759 144.138C149.657 143.779 149.861 143.396 150.22 143.295C150.579 143.186 150.962 143.396 151.063 143.756C151.165 144.115 150.962 144.498 150.603 144.599Z"
                fill="#12467F"/>
            <path
                d="M147.978 145.364C147.619 145.466 147.237 145.263 147.135 144.904C147.033 144.544 147.237 144.162 147.596 144.06C147.955 143.951 148.338 144.162 148.439 144.521C148.549 144.88 148.338 145.263 147.978 145.364Z"
                fill="#12467F"/>
            <path
                d="M145.355 146.13C144.995 146.231 144.613 146.028 144.511 145.669C144.402 145.31 144.613 144.927 144.972 144.826C145.331 144.716 145.714 144.927 145.815 145.286C145.917 145.646 145.714 146.028 145.355 146.13Z"
                fill="#12467F"/>
            <path
                d="M142.738 146.895C142.379 146.997 141.996 146.794 141.895 146.434C141.785 146.075 141.996 145.693 142.355 145.591C142.715 145.489 143.097 145.693 143.199 146.052C143.3 146.411 143.097 146.794 142.738 146.895Z"
                fill="#12467F"/>
            <path
                d="M140.114 147.661C139.755 147.762 139.372 147.559 139.27 147.2C139.161 146.841 139.372 146.458 139.731 146.356C140.09 146.255 140.473 146.458 140.575 146.817C140.684 147.176 140.473 147.559 140.114 147.661Z"
                fill="#12467F"/>
            <path
                d="M155.039 145.364C154.68 145.466 154.297 145.263 154.195 144.904C154.094 144.544 154.297 144.162 154.656 144.06C155.015 143.951 155.398 144.162 155.5 144.521C155.609 144.88 155.398 145.263 155.039 145.364Z"
                fill="#12467F"/>
            <path
                d="M152.415 146.13C152.056 146.231 151.673 146.028 151.571 145.669C151.462 145.31 151.673 144.927 152.032 144.826C152.391 144.724 152.774 144.927 152.876 145.286C152.977 145.646 152.774 146.028 152.415 146.13Z"
                fill="#12467F"/>
            <path
                d="M149.79 146.895C149.431 147.005 149.049 146.794 148.947 146.434C148.845 146.075 149.049 145.693 149.408 145.591C149.767 145.489 150.15 145.693 150.251 146.052C150.361 146.411 150.15 146.794 149.79 146.895Z"
                fill="#12467F"/>
            <path
                d="M147.166 147.661C146.807 147.77 146.424 147.559 146.323 147.2C146.221 146.841 146.424 146.458 146.783 146.356C147.143 146.255 147.525 146.458 147.627 146.817C147.736 147.176 147.525 147.559 147.166 147.661Z"
                fill="#12467F"/>
            <path
                d="M144.542 148.426C144.183 148.528 143.8 148.325 143.699 147.965C143.589 147.606 143.8 147.223 144.159 147.122C144.519 147.02 144.901 147.223 145.003 147.583C145.112 147.942 144.901 148.324 144.542 148.426Z"
                fill="#12467F"/>
            <path
                d="M141.918 149.191C141.559 149.301 141.176 149.09 141.075 148.731C140.965 148.371 141.176 147.989 141.535 147.887C141.895 147.786 142.277 147.989 142.379 148.348C142.488 148.707 142.277 149.09 141.918 149.191Z"
                fill="#12467F"/>
            <path
                d="M139.301 149.957C138.942 150.058 138.56 149.855 138.458 149.496C138.356 149.137 138.56 148.754 138.919 148.652C139.278 148.543 139.661 148.754 139.762 149.113C139.872 149.473 139.661 149.855 139.301 149.957Z"
                fill="#12467F"/>
            <path
                d="M154.391 147.817C154.031 147.918 153.649 147.715 153.547 147.356C153.446 146.997 153.649 146.614 154.008 146.513C154.367 146.411 154.75 146.614 154.851 146.973C154.961 147.333 154.75 147.715 154.391 147.817Z"
                fill="#12467F"/>
            <path
                d="M151.766 148.59C151.407 148.699 151.024 148.489 150.923 148.129C150.813 147.77 151.024 147.387 151.384 147.286C151.743 147.184 152.125 147.387 152.227 147.747C152.329 148.106 152.125 148.489 151.766 148.59Z"
                fill="#12467F"/>
            <path
                d="M149.15 149.356C148.791 149.465 148.408 149.254 148.307 148.895C148.205 148.535 148.408 148.153 148.767 148.051C149.127 147.95 149.509 148.153 149.611 148.512C149.712 148.871 149.509 149.254 149.15 149.356Z"
                fill="#12467F"/>
            <path
                d="M146.525 150.121C146.166 150.222 145.784 150.019 145.682 149.66C145.573 149.301 145.784 148.918 146.143 148.817C146.502 148.715 146.885 148.918 146.986 149.277C147.088 149.637 146.885 150.019 146.525 150.121Z"
                fill="#12467F"/>
            <path
                d="M143.902 150.886C143.542 150.988 143.16 150.785 143.058 150.425C142.949 150.066 143.16 149.683 143.519 149.582C143.878 149.473 144.261 149.683 144.362 150.043C144.472 150.402 144.261 150.785 143.902 150.886Z"
                fill="#12467F"/>
            <path
                d="M141.278 151.652C140.918 151.753 140.536 151.55 140.434 151.191C140.325 150.832 140.536 150.449 140.895 150.347C141.254 150.238 141.637 150.449 141.738 150.808C141.84 151.167 141.637 151.55 141.278 151.652Z"
                fill="#12467F"/>
            <path
                d="M156.202 149.348C155.843 149.457 155.46 149.246 155.359 148.887C155.249 148.528 155.46 148.145 155.819 148.043C156.179 147.942 156.561 148.145 156.663 148.504C156.764 148.863 156.561 149.246 156.202 149.348Z"
                fill="#12467F"/>
            <path
                d="M153.578 150.113C153.219 150.222 152.836 150.011 152.735 149.652C152.633 149.293 152.836 148.91 153.195 148.809C153.555 148.699 153.937 148.91 154.039 149.269C154.148 149.629 153.937 150.011 153.578 150.113Z"
                fill="#12467F"/>
            <path
                d="M150.954 150.878C150.595 150.988 150.212 150.777 150.111 150.418C150.001 150.058 150.212 149.676 150.572 149.574C150.931 149.465 151.313 149.676 151.415 150.035C151.517 150.394 151.313 150.777 150.954 150.878Z"
                fill="#12467F"/>
            <path
                d="M148.33 151.652C147.971 151.753 147.588 151.55 147.486 151.191C147.385 150.832 147.588 150.449 147.947 150.347C148.306 150.238 148.689 150.449 148.791 150.808C148.892 151.167 148.689 151.55 148.33 151.652Z"
                fill="#12467F"/>
            <path
                d="M145.714 152.417C145.354 152.519 144.972 152.315 144.87 151.956C144.761 151.597 144.972 151.214 145.331 151.113C145.69 151.011 146.073 151.214 146.175 151.574C146.276 151.933 146.073 152.315 145.714 152.417Z"
                fill="#12467F"/>
            <path
                d="M143.089 153.182C142.73 153.284 142.347 153.081 142.246 152.722C142.144 152.362 142.347 151.98 142.706 151.878C143.066 151.777 143.448 151.98 143.55 152.339C143.659 152.698 143.448 153.081 143.089 153.182Z"
                fill="#12467F"/>
            <path
                d="M140.465 153.948C140.106 154.049 139.723 153.846 139.622 153.487C139.512 153.128 139.723 152.745 140.082 152.644C140.442 152.542 140.824 152.745 140.926 153.104C141.027 153.464 140.824 153.846 140.465 153.948Z"
                fill="#12467F"/>
            <path
                d="M155.562 151.808C155.203 151.909 154.82 151.706 154.719 151.347C154.609 150.988 154.82 150.605 155.179 150.504C155.539 150.394 155.921 150.605 156.023 150.964C156.124 151.324 155.921 151.706 155.562 151.808Z"
                fill="#12467F"/>
            <path
                d="M152.938 152.573C152.578 152.675 152.196 152.472 152.094 152.112C151.993 151.753 152.196 151.37 152.555 151.269C152.914 151.16 153.297 151.37 153.399 151.73C153.508 152.089 153.297 152.472 152.938 152.573Z"
                fill="#12467F"/>
            <path
                d="M150.314 153.339C149.955 153.44 149.572 153.237 149.47 152.878C149.361 152.519 149.572 152.136 149.931 152.034C150.29 151.933 150.673 152.136 150.775 152.495C150.876 152.854 150.673 153.237 150.314 153.339Z"
                fill="#12467F"/>
            <path
                d="M147.689 154.112C147.33 154.213 146.947 154.01 146.846 153.651C146.744 153.292 146.947 152.909 147.307 152.807C147.666 152.706 148.049 152.909 148.15 153.268C148.26 153.628 148.049 154.01 147.689 154.112Z"
                fill="#12467F"/>
            <path
                d="M145.065 154.877C144.706 154.986 144.323 154.776 144.222 154.416C144.112 154.057 144.323 153.674 144.683 153.573C145.042 153.471 145.425 153.674 145.526 154.034C145.635 154.393 145.425 154.776 145.065 154.877Z"
                fill="#12467F"/>
            <path
                d="M142.441 155.642C142.082 155.752 141.699 155.541 141.597 155.182C141.496 154.822 141.699 154.44 142.058 154.338C142.417 154.237 142.8 154.44 142.902 154.799C143.011 155.158 142.8 155.541 142.441 155.642Z"
                fill="#12467F"/>
            <path
                d="M154.742 154.104C154.383 154.213 154 154.002 153.898 153.643C153.789 153.284 154 152.901 154.359 152.8C154.718 152.698 155.101 152.901 155.203 153.26C155.304 153.62 155.101 154.002 154.742 154.104Z"
                fill="#12467F"/>
            <path
                d="M152.126 154.869C151.766 154.971 151.384 154.768 151.282 154.409C151.181 154.049 151.384 153.667 151.743 153.565C152.102 153.464 152.485 153.667 152.586 154.026C152.688 154.385 152.485 154.768 152.126 154.869Z"
                fill="#12467F"/>
            <path
                d="M149.501 155.635C149.142 155.744 148.759 155.533 148.658 155.174C148.548 154.815 148.759 154.432 149.119 154.33C149.478 154.229 149.861 154.432 149.962 154.791C150.064 155.15 149.861 155.533 149.501 155.635Z"
                fill="#12467F"/>
            <path
                d="M146.877 156.408C146.518 156.517 146.135 156.306 146.034 155.947C145.932 155.588 146.135 155.205 146.495 155.104C146.854 155.002 147.237 155.205 147.338 155.564C147.44 155.924 147.237 156.306 146.877 156.408Z"
                fill="#12467F"/>
            <path
                d="M144.253 157.173C143.894 157.283 143.511 157.072 143.409 156.713C143.3 156.353 143.511 155.971 143.87 155.869C144.229 155.768 144.612 155.971 144.714 156.33C144.815 156.689 144.612 157.072 144.253 157.173Z"
                fill="#12467F"/>
            <path
                d="M141.629 157.939C141.27 158.04 140.887 157.837 140.786 157.478C140.676 157.119 140.887 156.736 141.246 156.634C141.606 156.525 141.988 156.736 142.09 157.095C142.191 157.454 141.988 157.837 141.629 157.939Z"
                fill="#12467F"/>
            <path
                d="M156.726 155.799C156.367 155.908 155.984 155.697 155.882 155.338C155.781 154.979 155.984 154.596 156.343 154.494C156.702 154.393 157.085 154.596 157.187 154.955C157.296 155.314 157.085 155.697 156.726 155.799Z"
                fill="#12467F"/>
            <path
                d="M154.102 156.564C153.742 156.673 153.36 156.463 153.258 156.103C153.157 155.744 153.36 155.361 153.719 155.26C154.078 155.158 154.461 155.361 154.562 155.721C154.672 156.08 154.461 156.463 154.102 156.564Z"
                fill="#12467F"/>
            <path
                d="M151.477 157.329C151.118 157.439 150.735 157.228 150.634 156.869C150.532 156.509 150.735 156.127 151.095 156.025C151.454 155.924 151.837 156.127 151.938 156.486C152.047 156.845 151.837 157.228 151.477 157.329Z"
                fill="#12467F"/>
            <path
                d="M148.853 158.095C148.494 158.196 148.111 157.993 148.01 157.634C147.908 157.275 148.111 156.892 148.471 156.79C148.83 156.681 149.212 156.892 149.314 157.251C149.423 157.611 149.212 157.993 148.853 158.095Z"
                fill="#12467F"/>
            <path
                d="M146.229 158.868C145.87 158.97 145.487 158.766 145.386 158.407C145.284 158.048 145.487 157.665 145.847 157.564C146.206 157.454 146.588 157.665 146.69 158.025C146.799 158.384 146.588 158.766 146.229 158.868Z"
                fill="#12467F"/>
            <path
                d="M143.612 159.633C143.253 159.735 142.87 159.532 142.769 159.173C142.66 158.813 142.87 158.431 143.23 158.329C143.589 158.22 143.972 158.431 144.073 158.79C144.183 159.149 143.972 159.532 143.612 159.633Z"
                fill="#12467F"/>
            <path
                d="M155.914 158.095C155.554 158.196 155.172 157.993 155.07 157.634C154.969 157.275 155.172 156.892 155.531 156.79C155.89 156.681 156.273 156.892 156.374 157.251C156.484 157.611 156.273 157.993 155.914 158.095Z"
                fill="#12467F"/>
            <path
                d="M153.289 158.86C152.93 158.962 152.547 158.759 152.445 158.399C152.336 158.04 152.547 157.657 152.906 157.556C153.265 157.447 153.648 157.657 153.75 158.017C153.859 158.376 153.648 158.759 153.289 158.86Z"
                fill="#12467F"/>
            <path
                d="M150.665 159.626C150.306 159.727 149.923 159.524 149.822 159.165C149.72 158.806 149.923 158.423 150.282 158.321C150.642 158.212 151.024 158.423 151.126 158.782C151.235 159.141 151.024 159.524 150.665 159.626Z"
                fill="#12467F"/>
        </g>
        <path
            d="M174.486 150.964C174.353 150.777 174.243 150.566 174.173 150.332L172.674 145.49C172.377 144.529 172.189 143.537 172.143 142.537C171.58 130.768 166.254 119.521 159.342 109.938L149.993 96.9738L147.689 93.7795L150.157 91.9988L152.321 95.0057L161.662 107.962C169.784 119.225 174.189 132.517 174.407 146.403L174.478 150.957L174.486 150.964Z"
            fill="#FCCB02"/>
        <path d="M150.001 96.9815L147.697 93.7872L154.055 89.2027L156.366 92.4048L150.001 96.9815Z" fill="#FCCB02"/>
        <path
            d="M155.913 156.392C155.929 156.158 155.906 155.924 155.835 155.689L154.492 150.808C154.226 149.84 153.844 148.903 153.344 148.036C147.479 137.812 145.909 125.472 146.573 113.679L147.463 97.7235L147.682 93.7873L144.644 93.6154L144.441 97.3174L143.542 113.265C142.769 127.128 146.213 140.702 153.5 152.519L155.898 156.4L155.913 156.392Z"
            fill="#FCCB02"/>
        <path d="M147.471 97.7235L147.697 93.7873L139.864 93.3499L139.645 97.294L147.471 97.7235Z" fill="#FCCB02"/>
        <path
            d="M145.815 87.3439C147.627 86.8129 148.666 84.915 148.135 83.1031C147.604 81.2912 145.706 80.2525 143.894 80.7835C142.082 81.3146 141.043 83.2124 141.574 85.0244C142.106 86.8363 144.003 87.875 145.815 87.3439Z"
            fill="#666666"/>
        <path
            d="M147.526 92.5611L147.416 92.1862C147.362 92.2096 147.299 92.2253 147.245 92.2409C142.738 93.5608 138.005 90.9679 136.686 86.4537C136.092 84.4153 136.295 82.33 137.123 80.5259L136.959 79.9636C135.936 81.9317 135.639 84.2747 136.311 86.563C137.685 91.2803 142.645 93.9903 147.354 92.6158C147.409 92.6001 147.471 92.5845 147.526 92.5611ZM153.024 81.6739C153.438 83.0797 153.469 84.5168 153.18 85.8601L153.391 86.5864C153.86 85.001 153.899 83.2672 153.399 81.5646C152.024 76.8474 147.065 74.1373 142.356 75.5118L142.465 75.8867C146.971 74.5668 151.704 77.1676 153.024 81.6661V81.6739Z"
            fill="#808080"/>
        <path
            d="M140.817 76.1756L138.528 68.3422C138.38 67.8267 138.669 67.2956 139.184 67.1473L140.528 66.7568C141.043 66.6084 141.574 66.9051 141.723 67.4128L144.011 75.2462L140.817 76.1834V76.1756Z"
            fill="#666666"/>
        <path d="M139.951 66.9251L139.763 66.9798L142.127 75.0685L142.315 75.0138L139.951 66.9251Z" fill="#F2F2F2"/>
        <path d="M136.466 54.6569L136.091 54.7663L139.662 66.9937L140.037 66.8842L136.466 54.6569Z" fill="#666666"/>
        <path d="M152.032 119.349H60.9907V127.12H152.032V119.349Z" fill="#608929"/>
        <path d="M161.092 204.728H72.3856L64.5756 127.12H161.092V204.728Z" fill="#86BD3D"/>
        <path
            d="M30.2428 214.304C25.8692 214.304 24.479 212.46 24.0807 210.914C23.7996 209.813 24.0026 208.774 24.6743 207.915C25.6661 206.634 28.1029 204.932 37.7248 204.932C44.4961 204.932 51.3845 205.775 51.6735 205.814L56.7656 206.447L52.0405 208.438C51.4704 208.68 38.0294 214.311 30.2506 214.311L30.2428 214.304ZM37.717 207.665C29.001 207.665 27.1891 209.133 26.8376 209.586C26.7127 209.742 26.658 209.906 26.7361 210.227C27.0173 211.328 28.798 211.562 30.2428 211.562C34.5227 211.562 40.9503 209.594 45.5192 207.985C43.1918 207.813 40.4271 207.673 37.7248 207.673L37.717 207.665Z"
            fill="black"/>
        <path
            d="M30.2428 214.304C25.8692 214.304 24.479 212.46 24.0807 210.914C23.7996 209.813 24.0026 208.774 24.6743 207.915C25.6661 206.634 28.1029 204.932 37.7248 204.932C44.4961 204.932 51.3845 205.775 51.6735 205.814L56.7656 206.447L52.0405 208.438C51.4704 208.68 38.0294 214.311 30.2506 214.311L30.2428 214.304ZM37.717 207.665C29.001 207.665 27.1891 209.133 26.8376 209.586C26.7127 209.742 26.658 209.906 26.7361 210.227C27.0173 211.328 28.798 211.562 30.2428 211.562C34.5227 211.562 40.9503 209.594 45.5192 207.985C43.1918 207.813 40.4271 207.673 37.7248 207.673L37.717 207.665Z"
            fill="#1A1A1A"/>
        <path
            d="M83.96 189.319L89.2708 184.547C90.083 183.821 90.2626 182.618 89.6925 181.681L87.3026 177.745C86.7091 176.769 85.4829 176.386 84.4442 176.847L82.8197 177.573L83.96 189.319Z"
            fill="#FCCB02"/>
        <path d="M109.874 202.339H61.2719V209.641H109.874V202.339Z" fill="#FCCB02"/>
        <path d="M109.874 202.339H103.797V209.641H109.874V202.339Z" fill="#BBB2AD"/>
        <path
            d="M109.874 202.339L111.17 203.315C111.334 203.44 111.436 203.635 111.436 203.846V209.961C111.436 210.313 111.037 210.508 110.756 210.297L109.874 209.633V202.331V202.339Z"
            fill="#9B9694"/>
        <path
            d="M107.054 205.986C107.054 205.267 106.476 204.689 105.758 204.689C105.039 204.689 104.461 205.275 104.461 205.986C104.461 206.697 105.039 207.282 105.758 207.282C106.476 207.282 107.054 206.704 107.054 205.986Z"
            fill="#9B9694"/>
        <path
            d="M111.295 204.955C111.295 205.369 111.131 205.713 110.904 205.76C110.897 205.76 110.873 205.76 110.858 205.76C110.842 205.76 110.826 205.76 110.811 205.76C110.592 205.72 110.42 205.369 110.42 204.955C110.42 204.541 110.592 204.198 110.811 204.151C110.818 204.151 110.834 204.151 110.858 204.151C110.881 204.151 110.889 204.151 110.904 204.151C111.123 204.198 111.295 204.533 111.295 204.955Z"
            fill="#726D6B"/>
        <path
            d="M111.693 204.955C111.693 205.385 111.35 205.736 110.912 205.76C110.904 205.76 110.881 205.76 110.865 205.76C110.85 205.76 110.834 205.76 110.818 205.76C110.6 205.72 110.428 205.369 110.428 204.955C110.428 204.541 110.6 204.198 110.818 204.151C110.826 204.151 110.842 204.151 110.865 204.151C110.889 204.151 110.897 204.151 110.912 204.151C111.35 204.174 111.693 204.526 111.693 204.955Z"
            fill="#726D6B"/>
        <path
            d="M111.295 208.493C111.295 208.907 111.131 209.25 110.904 209.297C110.897 209.297 110.873 209.297 110.858 209.297C110.842 209.297 110.826 209.297 110.811 209.297C110.592 209.25 110.42 208.907 110.42 208.493C110.42 208.079 110.592 207.735 110.811 207.688C110.818 207.688 110.834 207.688 110.858 207.688C110.881 207.688 110.889 207.688 110.904 207.688C111.123 207.728 111.295 208.071 111.295 208.493Z"
            fill="#726D6B"/>
        <path
            d="M111.693 208.493C111.693 208.922 111.35 209.274 110.912 209.297C110.904 209.297 110.881 209.297 110.865 209.297C110.85 209.297 110.834 209.297 110.818 209.297C110.6 209.25 110.428 208.907 110.428 208.493C110.428 208.079 110.6 207.735 110.818 207.688C110.826 207.688 110.842 207.688 110.865 207.688C110.889 207.688 110.897 207.688 110.912 207.688C111.35 207.712 111.693 208.063 111.693 208.493Z"
            fill="#726D6B"/>
        <path d="M63.7559 207.165H63.4825V209.641H63.7559V207.165Z" fill="black"/>
        <path d="M66.1068 207.165H65.8335V209.641H66.1068V207.165Z" fill="black"/>
        <path d="M68.4577 207.165H68.1843V209.641H68.4577V207.165Z" fill="black"/>
        <path d="M70.8158 207.165H70.5425V209.641H70.8158V207.165Z" fill="black"/>
        <path d="M73.1667 207.165H72.8933V209.641H73.1667V207.165Z" fill="black"/>
        <path d="M75.5253 207.165H75.2441V209.641H75.5253V207.165Z" fill="black"/>
        <path d="M77.8683 207.165H77.595V209.641H77.8683V207.165Z" fill="black"/>
        <path d="M80.227 207.165H79.9458V209.641H80.227V207.165Z" fill="black"/>
        <path d="M82.57 207.165H82.2966V209.641H82.57V207.165Z" fill="black"/>
        <path d="M84.9208 207.165H84.6475V209.641H84.9208V207.165Z" fill="black"/>
        <path d="M87.2798 207.165H86.9987V209.641H87.2798V207.165Z" fill="black"/>
        <path d="M89.6228 207.165H89.3495V209.641H89.6228V207.165Z" fill="black"/>
        <path d="M91.981 207.165H91.7076V209.641H91.981V207.165Z" fill="black"/>
        <path d="M94.3318 207.165H94.0585V209.641H94.3318V207.165Z" fill="black"/>
        <path d="M96.6827 207.165H96.4093V209.641H96.6827V207.165Z" fill="black"/>
        <path d="M99.0413 207.165H98.7601V209.641H99.0413V207.165Z" fill="black"/>
        <path d="M101.384 207.165H101.111V209.641H101.384V207.165Z" fill="black"/>
        <path
            d="M86.3579 209.641H54.6727C49.9867 209.641 46.1832 205.837 46.1832 201.151V179.518C46.1832 173.965 50.6818 169.458 56.2425 169.458H77.8762C82.5622 169.458 86.3657 173.262 86.3657 177.948V209.633L86.3579 209.641Z"
            fill="black"/>
        <path
            d="M62.4123 205.72C55.6098 205.72 50.1038 200.206 50.1038 193.412V183.946C50.1038 178.112 54.8288 173.387 60.6629 173.387H73.4479C78.415 173.387 82.4372 177.409 82.4372 182.376V205.72H62.4123Z"
            fill="#212121"/>
        <path
            d="M78.4151 189.546C78.4151 182.837 72.9715 177.393 66.2627 177.393C62.8498 177.393 59.7726 178.799 57.5624 181.064L74.7444 198.246C77.0093 196.036 78.4151 192.959 78.4151 189.546Z"
            fill="#FCCB02"/>
        <path
            d="M57.5701 181.072C55.4379 183.259 54.118 186.25 54.118 189.554C54.118 196.262 59.5616 201.706 66.2704 201.706C69.574 201.706 72.5575 200.386 74.7521 198.254L57.5701 181.072Z"
            fill="#FCCB02"/>
        <g style={{mixBlendMode:"multiply"}} opacity="0.55">
            <path
                d="M57.5701 181.072C55.4379 183.259 54.118 186.25 54.118 189.554C54.118 196.262 59.5616 201.706 66.2704 201.706C69.574 201.706 72.5575 200.386 74.7521 198.254L57.5701 181.072Z"
                fill="#FFA33E"/>
        </g>
        <path
            d="M66.2705 200.261C60.3661 200.261 55.563 195.458 55.563 189.554C55.563 183.649 60.3661 178.846 66.2705 178.846C72.1749 178.846 76.978 183.649 76.978 189.554C76.978 195.458 72.1749 200.261 66.2705 200.261ZM66.2705 179.666C60.8191 179.666 56.383 184.102 56.383 189.554C56.383 195.005 60.8191 199.441 66.2705 199.441C71.7219 199.441 76.158 195.005 76.158 189.554C76.158 184.102 71.7219 179.666 66.2705 179.666Z"
            fill="black"/>
        <path
            d="M56.6873 209.641H50.822C48.2603 209.641 46.175 207.556 46.175 204.994V199.73C51.6811 199.949 56.1562 204.221 56.6795 209.641H56.6873Z"
            fill="#FCCB02"/>
        <path
            d="M144.699 132.056H152.11C154.211 132.056 155.914 133.759 155.914 135.86V138.554C155.914 140.655 154.211 142.358 152.11 142.358H144.699C142.598 142.358 140.895 140.655 140.895 138.554V135.86C140.895 133.759 142.598 132.056 144.699 132.056Z"
            fill="black"/>
    </svg>
)
