import {useHttpClient} from "@src/utils/hooks/xhr/useHttpClient";
import {useMutation} from "@tanstack/react-query";
import {BoxIssue, TAction, XhrBase} from "@src/core/xhr";

export const useRelabelBox = ({code, nextCode, onSuccess}: {code: string; nextCode: string, onSuccess?: (data: any, variables: {     reasons: BoxIssue[]; }, context: unknown) => unknown}) => {
    const {xhr} = useHttpClient();

    return useMutation({
        mutationFn: async () => {
            const response = await xhr.post({
                relativeUrl: XhrBase.BOX_ACTION({code, action: "relabel"}),
                payload: XhrBase.BOX_ACTION_body({code: nextCode})
            });

            return response
        },
        onSuccess
    })
}
