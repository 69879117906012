import styles from "./LoginPage.module.scss";
import {FullLogo} from "@src/components/icons/core/FullLogo";
import {Space} from "@src/components/shared/Space/Space";
import {FormCore} from "@src/components/shared/form/Form/Form";
import {FieldValues, SubmitHandler, useForm} from "react-hook-form";
import {TextInputControl} from "@src/components/shared/form/TextInput/TextInput";
import {Button} from "@src/components/shared/form/Button/Button";
import {useLoginMutation} from "@src/services/auth/useLoginMutation";
import {useNavigate} from "react-router-dom";
import {useTranslationCascade} from "@src/utils/hooks/i18n/useTranslationCascade";
import {LanguageSelectorButton} from "@src/components/core/pages/AccountDetails/components/LanguageSelectorButton";
import React from "react";

export const LoginPage = () => {
    const methods = useForm();
    const {mutateAsync, isPending} = useLoginMutation();
    const navigate = useNavigate();

    const {t} = useTranslationCascade();

    const onLogIn: SubmitHandler<FieldValues> = (props) => {
        mutateAsync({login: props.email, password: props.password});
        navigate('/home');
    }

    return (
        <div className={styles.LoginPage}>
            <div className={styles.languageSelect}>
                <LanguageSelectorButton/>
            </div>

            <div className={styles.topBar}>
                <FullLogo />
            </div>
            <div className={styles.bottomBar}>
                <FormCore methods={methods} onSubmit={onLogIn}>
                    <Space direction={'vertical'}>
                        <TextInputControl label={ t('translations:login.loginInput') } path={'email'}/>
                        <TextInputControl type="password" label={ t('translations:login.passwordInput') } path={'password'}/>
                        {/*<Button variant={"text"} align={"right"}>*/}
                        {/*    Login*/}
                        {/*</Button>*/}
                        <Button loading={isPending} onClick={() => methods.handleSubmit(onLogIn)()}>
                            { t('translations:login.loginButton') }
                        </Button>
                    </Space>
                </FormCore>
            </div>
        </div>
    )
}
