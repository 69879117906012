import {PageTemplate} from "@src/components/core/PageTemplate/PageTemplate";
import {PropsWithChildren, ReactNode, useContext} from "react";

import {Typography} from "@mui/material";

import styles from "./BoxActionContainer.module.scss"


export const BoxActionContainer = ({topBar, bottomBar, title}: {topBar: ReactNode, bottomBar: ReactNode, title?: ReactNode}) => {

    return (
        <PageTemplate>
            <div className={styles.BoxActionContainer}>
                <div className={styles.topBar}>
                    <div className={styles.title}>
                        {
                            typeof title === "string"
                            ? (
                                <Typography variant="h5" gutterBottom sx={{color: 'white', textAlign: 'center'}}>
                                    {title}
                                </Typography>
                            )
                            : title
                        }
                    </div>
                    <div className={styles.bottomBarContent}>
                        {topBar}
                    </div>
                </div>
                <div className={styles.bottomBar}>
                    {bottomBar}
                </div>
            </div>
        </PageTemplate>
    )
}
