import React, {useEffect, useState, useCallback, ReactNode, ReactElement} from 'react';
import { Snackbar, SnackbarProps } from '@mui/material';

interface DisposableSnackbarProps extends Omit<SnackbarProps, 'open' | 'onClose'> {
    onClose: () => void;
    children?: ReactElement; // Make `children` optional and use `ReactNode`
}

export const DisposableSnackbar: React.FC<DisposableSnackbarProps> = ({
                                                                          onClose,
                                                                          children,
                                                                          ...props
                                                                      }) => {
    const [open, setOpen] = useState<boolean>(false);

    const closeSnackbar = useCallback(() => {
        setOpen(false);
        setTimeout(onClose, 200); // Delay to match snackbar close animation duration
    }, [onClose]);

    useEffect(() => {
        setOpen(true); // Open snackbar on mount
    }, []);

    return (
        <Snackbar open={open} onClose={closeSnackbar} {...props}>
            {children}
        </Snackbar>
    );
};
