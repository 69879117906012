import {SVGProps} from "react";

export const EditIcon = (props: SVGProps<SVGSVGElement>) => {

    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2.99854 17.2512V21.0012H6.74854L17.8085 9.94125L14.0585 6.19125L2.99854 17.2512ZM20.7085 7.04125C21.0985 6.65125 21.0985 6.02125 20.7085 5.63125L18.3685 3.29125C17.9785 2.90125 17.3485 2.90125 16.9585 3.29125L15.1285 5.12125L18.8785 8.87125L20.7085 7.04125Z"
                fill="white" fillOpacity="0.56"/>
        </svg>
    )
}
