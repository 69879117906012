import {useContext} from "react";

import {BoxAction} from "@src/components/shared/SaveBoxButton/components/ReportBox/components/BoxActionBase/BoxActionBase";
import {ChangeQrCode} from "@src/components/icons/BoxActions/ChangeQrCode";
import {useTranslationCascade} from "@src/utils/hooks/i18n/useTranslationCascade";
import {ApplicationMap} from "@src/core/applicationMap";
import {DialogWindowContextHolderContext} from "@src/components/context/DialogWindowContextHolder/DialogWindowContextHolder.context";
import {ChangeQrCodeBoxContent} from "@src/components/shared/SaveBoxButton/components/ReportBox/components/ChangeQrCodeBoxButton/ChangeQrCodeBoxContent/ChangeQrCodeBoxContent";
import {useBoxHistory} from "@src/services/box/useBoxHistory";
import {useGetBoxInfoResult} from "@src/services/box/useGetBoxInfo";


export const ChangeQrCodeBoxButton = ({code}: {code: string}) => {
    const {t} = useTranslationCascade();
    const {drawerApi} = useContext(DialogWindowContextHolderContext);
    const {data: boxHistory} = useBoxHistory({code});

    const {data} = useGetBoxInfoResult({code, refetchOnMount: false});

    const boxTodoActions = data?.actions;

    const onClick = () => {
        drawerApi.open({
            key: ApplicationMap.REPORT_BOX_Action,
            anchor: "right",
            children: (
                <ChangeQrCodeBoxContent code={code} />
            )
        })
    }

    return (
        <BoxAction
            code={code}
            event={"relabeled"}
            label={ t('translations:boxStaff.reviewOptions.qrChange') }
            icon={<ChangeQrCode />}
            onClick={onClick}
            done={boxHistory?.currentActions?.['relabeled']}
            requested={boxTodoActions['relabel'] === "planned"}
        />
    )
}
