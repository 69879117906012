import React, {ReactNode} from "react";
import {Button} from "@mui/material";
import {Space} from "@src/components/shared/Space/Space";
import {ArrowRight} from "@src/components/icons/core/arrows/ArrowRight";

import styles from "./BoxBoxPendingTodoItem.module.scss";

export const BoxBoxPendingTodoItem = ({code, label, icon, onClick}: { code: string, label: string, icon: ReactNode, onClick: ((event: any) => void); }) => {

    return (
        <div className={styles.BoxBoxPendingTodoItem}>
            <Button variant="contained" onClick={onClick}>
                <Space padding={0} align={'center'}>
                    <span className={styles.icon}>
                        {icon}
                    </span>
                    {label}
                </Space>

                <ArrowRight />
            </Button>
        </div>
    )
}
