export const ChangeLanguageIcon = () => {

    return (
        <svg width="32" height="32" viewBox="0 0 29 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1.5 3.75H6.75M12 3.75L10.125 3.75M6.75 3.75L10.125 3.75M6.75 3.75V2.25M10.125 3.75C9.50959 5.80135 8.2209 7.74049 6.75 9.44464M3 13.125C4.18905 12.1058 5.532 10.8558 6.75 9.44464M6.75 9.44464C6 8.625 4.8 6.975 4.5 6.375M6.75 9.44464L9 11.625"
                stroke="#343434" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path>
            <path
                d="M10.125 15.75L10.9821 13.5M16.125 15.75L15.2679 13.5M10.9821 13.5L13.125 7.875L15.2679 13.5M10.9821 13.5H15.2679"
                stroke="#343434" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path>
            <circle cx="19" cy="13" r="10" ></circle>
            <g clip-path="url(#clip0_16_1526)">
                <rect x="11" y="4" width="17" height="17" rx="8.5" ></rect>
                <path
                    d="M19.4993 19.5833C23.4114 19.5833 26.5827 16.412 26.5827 12.5C26.5827 8.58797 23.4114 5.41666 19.4993 5.41666C15.5873 5.41666 12.416 8.58797 12.416 12.5C12.416 16.412 15.5873 19.5833 19.4993 19.5833Z"
                    stroke="#343434" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M12.7715 12.8542L16.6673 14.2708L15.959 16.75L16.6673 18.875" stroke="#343434"
                      stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M23.041 18.5208L22.6868 16.75L20.916 16.0417V13.5625L23.041 12.8542L26.2285 13.2083"
                      stroke="#343434" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path>
                <path
                    d="M24.4583 7.89584L24.1042 8.95834L21.625 9.31251V11.4375L23.3958 10.7292H24.8125L26.2292 11.4375"
                    stroke="#343434" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M12.7715 11.4375L14.5423 10.0208L16.3132 9.66667L17.7298 7.54167L17.0215 6.125"
                      stroke="#343434" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path>
            </g>
            <defs>
                <clipPath id="clip0_16_1526">
                    <rect x="11" y="4" width="17" height="17" rx="8.5" fill="white"></rect>
                </clipPath>
            </defs>
        </svg>
    )
}
