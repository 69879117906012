import {IRequestBase, IXhrMethods} from "@src/utils/hooks/xhr/useHttpClient";

export function useXhrMethods(xhr: IXhrMethods){

    const errorHandling = async (propsWithError: IRequestBase & {error: any}) => {
        const axiosResponseErrorStatusCode = propsWithError.error.response?.status;

        propsWithError.error.status = axiosResponseErrorStatusCode;

        if (axiosResponseErrorStatusCode === 401) {
            // todo: define rules

            throw propsWithError.error;
        } else if (axiosResponseErrorStatusCode === 400) {
            // todo: define rules
        } else if (axiosResponseErrorStatusCode === 404) {
            // todo: define rules
        }

        throw propsWithError.error;
    };


    const preparePayload = (props: IRequestBase) => {
        props.payload = props.payload;
    }

    const refreshAccess = async (error: any, method: "get" | "post" | "put" | "delete" | "patch", props: IRequestBase) => {

        // TODO: resolve correct error handling
        await errorHandling({error, ...props});
    }


    return {
        errorHandling,
        preparePayload,
        refreshAccess,
    }
}
