import {LogoutButton} from "@src/components/core/pages/Home/components/LogoutButton/LogoutButton";
import {LanguageSelectorButton} from "@src/components/core/pages/AccountDetails/components/LanguageSelectorButton";

import styles from "./AccountDetails.module.scss";
import {Avatar, Divider, Typography} from "@mui/material";
import {AccountAvatar} from "@src/components/core/pages/AccountDetails/components/AccountAvatar/AccountAvatar";
import {CloseIcon} from "@src/components/icons/core/CloseIcon";
import React, {useContext} from "react";
import {DisposableDrawerContext} from "@src/utils/hooks/contextMenu/components/DisposableDrawer/DisposableDrawer";
import {useIsLoggedIn} from "@src/services/auth/useIsLoggedIn";
import {useTranslationCascade} from "@src/utils/hooks/i18n/useTranslationCascade";


export const AccountDetails = () => {
    const foundDrawerContext = useContext(DisposableDrawerContext);
    const {data} = useIsLoggedIn();
    const {t} = useTranslationCascade();

    return (
        <div className={styles.AccountDetails}>
            <div className={styles.header}>
                <div className={styles.drawerTitle}>
                    <Typography variant="h5" gutterBottom sx={{textAlign: 'left', fontWeight: '500', margin: 0}}>
                        {t('translations:personalAccountDrawer.title')}
                    </Typography>
                </div>
                <div className={styles.closeIcon}>
                    <button className={styles.actionButton} onClick={() => foundDrawerContext.onClose()}>
                        <CloseIcon/>
                    </button>
                </div>
            </div>
            <div className={styles.topBar}>
                <div className={styles.changeLanguageBar}>
                    <AccountAvatar/>
                    <Divider/>
                    <div>
                        <Typography variant="h6" gutterBottom
                                    sx={{fontSize: '14px', textAlign: 'left', fontWeight: '500', margin: 0}}>
                            { t('translations:personalAccountDrawer.section.userRole') }
                        </Typography>

                        <Typography variant="h6" gutterBottom
                                    sx={{fontSize: '18px', textAlign: 'left', fontWeight: '500', margin: 0}}>
                            {/* TODO: complete translation for user role */}
                            {data?.role}
                        </Typography>
                    </div>
                    <Divider/>
                    <div>
                        <Typography variant="h6" gutterBottom
                                    sx={{fontSize: '14px', textAlign: 'left', fontWeight: '500', margin: 0}}>
                            { t('translations:personalAccountDrawer.section.location') }
                        </Typography>

                        <Typography variant="h6" gutterBottom
                                    sx={{fontSize: '18px', textAlign: 'left', fontWeight: '500', margin: 0}}>
                            {/* TODO: complete translation for location */}
                            {data?.location}
                        </Typography>
                    </div>
                    <Divider/>
                    <div>
                        <Typography variant="h6" gutterBottom
                                    sx={{fontSize: '14px', textAlign: 'left', fontWeight: '500', margin: 0}}>
                            { t('translations:personalAccountDrawer.section.appLocale') }
                        </Typography>
                        <LanguageSelectorButton/>
                    </div>
                </div>
            </div>
            <div className={styles.bottomBar}>
                <LogoutButton/>
            </div>
        </div>
    )
}
