import {
    BoxAction
} from "@src/components/shared/SaveBoxButton/components/ReportBox/components/BoxActionBase/BoxActionBase";
import {RecycleBoxIcon} from "@src/components/icons/BoxActions/RecycleBoxIcon";
import {useContext} from "react";
import {
    DialogWindowContextHolderContext
} from "@src/components/context/DialogWindowContextHolder/DialogWindowContextHolder.context";
import {ApplicationMap} from "@src/core/applicationMap";
import {
    RecycleContent
} from "@src/components/shared/SaveBoxButton/components/ReportBox/components/RecycleBoxButton/components/RecycleContent/RecycleContent";
import {useTranslationCascade} from "@src/utils/hooks/i18n/useTranslationCascade";

export const RecycleBoxButton = ({code}: {code: string}) => {
    const {drawerApi} = useContext(DialogWindowContextHolderContext);
    const {t} = useTranslationCascade();

    const onClick = () => {
        drawerApi.open({
            key: ApplicationMap.RECYCLE_BOX,
            anchor: "right",
            children: (
                <RecycleContent code={code} />
            )
        })
    }


    return (
        <BoxAction code={code} event={"edited"} label={ t('translations:boxStaff.reviewOptions.recycleTitle') } icon={<RecycleBoxIcon />} onClick={onClick}/>
    )
}
