import {IRequestBase} from "@src/utils/hooks/xhr/useHttpClient";

const defaultApiVersion = 'default';

const getApiUrl = ({apiVersion = defaultApiVersion, relativeUrl}: IRequestBase) => {
    switch (apiVersion) {
        case 1:
            return `/api/v2${relativeUrl}`;

        case 'default':
            return `/api/v1${relativeUrl}`;

        // NOTION: in case of next api versions please define next versions bellow here

        default:
            throw new Error(`Unknown api version(${apiVersion})`);
    }
};

export const prepareUrl = (props: IRequestBase) => {
    const result = props.absoluteUrl
        ? props.absoluteUrl
        : getApiUrl(props);

    return result;
}

export const setDefaultResponseType = (props: IRequestBase) => {
    props.responseType = !!props.responseType ? props.responseType : 'json';
}
