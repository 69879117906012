import {PropsWithChildren} from "react";
import {useDrawer} from "@src/utils/hooks/contextMenu/useDrawer";
import { DialogWindowContextHolderContext } from "@src/components/context/DialogWindowContextHolder/DialogWindowContextHolder.context";
import {useSnackbar} from "@src/utils/hooks/contextMenu/useSnackbar";
import {useDialog} from "@src/utils/hooks/contextMenu/useModal";

export const DialogWindowContextHolder = ({children}: PropsWithChildren) => {
    const [drawerApi, drawerContextHolder] = useDrawer();
    const [dialogApi, dialogContext] = useDialog();
    const [snackbarApi, snackbarContextHolder] = useSnackbar();

    return (
        <DialogWindowContextHolderContext.Provider value={{drawerApi, snackbarApi, dialogApi}}>
            {dialogContext}
            {snackbarContextHolder}
            {drawerContextHolder}
            {children}
        </DialogWindowContextHolderContext.Provider>
    )
}
