import {
    BoxBoxPendingTodoItem
} from "@src/components/core/pages/BoxInfoSuperAdmin/components/BoxBoxPendingTodos/components/BoxBoxPendingTodoItem/BoxBoxPendingTodoItem";
import {BoxCleaned} from "@src/components/icons/boxHistory/BoxCleaned";
import {ApplicationMap} from "@src/core/applicationMap";
import {
    WashComplete
} from "@src/components/shared/SaveBoxButton/components/ReportBox/components/WashBoxButton/components/WashBoxContent/components/WashComplete/WashComplete";
import {useContext} from "react";
import {
    DialogWindowContextHolderContext
} from "@src/components/context/DialogWindowContextHolder/DialogWindowContextHolder.context";
import {useTranslationCascade} from "@src/utils/hooks/i18n/useTranslationCascade";

export const CleanTodoButton = ({code}: {code: string}) => {
    const {drawerApi} = useContext(DialogWindowContextHolderContext);
    const {t} = useTranslationCascade();

    const onClick = () => {
        console.log(245234)
        drawerApi.open({
            key: ApplicationMap.REPORT_BOX_Action,
            anchor: "right",
            children: (
                <WashComplete code={code} />
            )
        })
    }

    return (
        <BoxBoxPendingTodoItem
            onClick={onClick}
            code={code}
            label={ t('translations:boxStaff.reviewOptions.clean') }
            icon={<BoxCleaned/>}
        />
    )
}
