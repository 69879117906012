import {useEffect, useState} from "react";
import debounce from "lodash/debounce";

const getIsMicro = () => window.innerWidth <= 384;
const getIsMini = () => window.innerWidth <= 768 && window.innerWidth > 384;
const getIsMobile = () => window.innerWidth <= 992 && window.innerWidth > 769;
const getIsTablet = () => window.innerWidth <= 1200 && window.innerWidth > 992;
const getIsDesktop = () => window.innerWidth >= 1201;

//roughly based on https://stackoverflow.com/a/66588540/828184
export const useResponsiveLayout = () => {
    const [screenSize, setScreenSize] = useState(window.innerWidth);
    const [screenHeight, setScreenHeight] = useState(window.innerHeight);
    const [isMicro, setIsMicro] = useState(getIsMicro());
    const [isMini, setIsMini] = useState(getIsMini());
    const [isMobile, setIsMobile] = useState(getIsMobile());
    const [isTablet, setIsTablet] = useState(getIsTablet());
    const [isDesktop, setIsDesktop] = useState(getIsDesktop());

    const setSizes = debounce(() => {
        setScreenSize(window.innerWidth);
        setScreenHeight(window.innerHeight);
        setIsMicro(getIsMicro());
        setIsMini(getIsMini());
        setIsMobile(getIsMobile());
        setIsTablet(getIsTablet());
        setIsDesktop(getIsDesktop());
    }, 100);

    useEffect(() => {
        setSizes();
        window.addEventListener("resize", setSizes);
        return () => {
            window.removeEventListener("resize", setSizes);
        };
    }, []);

    const isMobileDevice = isMicro || isMini || isMobile;
    const hasLowResolution = isMicro || isMini;

    //as function with reducing re-renderings in mind
    const getHasLowResolution = () => {
        return hasLowResolution;
    }

    const isSmallScreenOnlySlider = screenSize < 1375;

    return {
        screenHeight,
        isMicro,
        isMini,
        isMobile,
        isTablet,
        isDesktop,
        isMobileDevice,
        hasLowResolution,
        screenSize,
        getHasLowResolution,
        isSmallScreenOnlySlider
    };
};
