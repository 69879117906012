import {useMutation} from "@tanstack/react-query";
import {useHttpClient} from "@src/utils/hooks/xhr/useHttpClient";
import {BoxIssue, XhrBase} from "@src/core/xhr";
import {useGetBoxInfoResult} from "@src/services/box/useGetBoxInfo";
import {divide} from "lodash";
import {PhotoReportImageBlob} from "@src/utils/blob/base64ToBinary";

export const useCompleteBoxReview = ({code, onSuccess}: {code: string, onSuccess?: (data: any, variables: {     reasons: BoxIssue[]; }, context: unknown) => unknown}) => {
    const {xhr} = useHttpClient();

    const {data} = useGetBoxInfoResult({code, refetchOnMount: false});

    const boxTodoActions = data?.actions;

    return useMutation({
        mutationFn: async () => {
            await Promise.all([
                ((boxTodoActions['repair'] && boxTodoActions['repair'] === "done")) && await xhr.delete({ relativeUrl: XhrBase.DELETE_BOX_TODO({code, action: 'repair'}) }),
                ((boxTodoActions['clean'] && boxTodoActions['clean'] === "done")) && await xhr.delete({ relativeUrl: XhrBase.DELETE_BOX_TODO({code, action: 'clean'}) }),
                ((boxTodoActions['relabel'] && boxTodoActions['relabel'] === "done")) && await xhr.delete({ relativeUrl: XhrBase.DELETE_BOX_TODO({code, action: 'relabel'}) }),
            ]);

            await xhr.post({
                relativeUrl: XhrBase.CONFIRM_BOX_REVIEW({code}),
            });
        },
        onSuccess: onSuccess
    })
}

export const useRecycleBox = ({code, onSuccess}: {code: string, onSuccess?: (data: any, variables: {     reasons: BoxIssue[]; }, context: unknown) => unknown}) => {
    const {xhr} = useHttpClient();

    return useMutation({
        mutationFn: async ({reasons}: {reasons: BoxIssue[]}) => {
            const response = await xhr.post({
                relativeUrl: XhrBase.RECYCLE_BOX({code}),
                payload: XhrBase.RECYCLE_BOX_body({reasons})
            });

            return response;
        },
        onSuccess: onSuccess
    })
}

export const useDoPhotoReport = ({code}: {code: string}) => {
    const {xhr} = useHttpClient();

    return useMutation({
        mutationFn: async ({images}: {images: Map<string, PhotoReportImageBlob>}) => {
            await Promise.all(
                Array.from(images).map(([_, image]) => {

                    return xhr.post({
                        relativeUrl: XhrBase.UPLOAD_PHOTO({code}),
                        payload: image,
                        config: {
                            headers: {
                                'Content-Type': 'image/jpeg'
                            }
                        }
                    })
                })
            )
        }
    })
}

