import {isEmptyArray} from "@src/utils/functions/arr/arr";
import {isPositiveNumberOrZero} from "@src/utils/functions/number/numberFunctions";

export const getFirstFailedFieldRef = (methods: any) => {
    try {
        if (!methods.formState.errors) {
            return null;
        }

        const err = Object.values(methods.formState.errors);

        const firstError: any = err[0];

        if (firstError?.ref && firstError.ref.focus) {
            methods.setFocus(firstError?.ref)

            return firstError?.ref;
        }

        if (firstError?.ref && firstError.ref.scrollIntoView) {
            firstError.ref.scrollIntoView();

            return firstError.ref;
        }
        else if (firstError instanceof Object) {
            getFirstFailedFieldRef(firstError);
        }

        return;
    } catch (e) {
        return null
    }
}

export const getFieldValidationErrors = (obj: any, fieldName: string) => {
    if (!fieldName) {
        return null
    }

    return fieldName
        .split('.')
        // @ts-ignore
        .reduce((acc, value) => acc ? acc[value.replace('[').replace(']')] : obj[value], null);
};

export const emptyValueCorrection = (value: any) => {
    if (!value && !isPositiveNumberOrZero(value)) {
        return "";
    }

    if (isEmptyArray(value)) {
        return "";
    }

    return value;
};

type NestedObject = { [key: string]: NestedObject } | any;

export function getObjectProperty(obj: NestedObject, path: string): any {
    // Split the path into an array of keys
    const keys: string[] = path.split('.');

    // Use Array.reduce to traverse the object using the keys
    return keys.reduce((currentObject: NestedObject, key: string) => {
        // Check if the current object is defined and has the key
        if (currentObject && currentObject.hasOwnProperty(key)) {
            return currentObject[key];
        } else {
            // If the key is not found, return undefined
            return undefined;
        }
    }, obj);
}

export function setObjectProperty(obj: NestedObject, path: string, value: any): void {
    // Split the path into an array of keys
    const keys: string[] = path.split('.');

    const iterateObject = (object: Record<string, any>, keys: string[], value: any) => {
        if (keys.length === 0) {
            return;
        }

        const keysCopy = Object.assign(keys, []);

        const nextKey = keysCopy.shift()!;

        if (!object[nextKey]) {
            object[nextKey] = {}
        }

        if (keysCopy.length === 0) {
            object[nextKey] = value;
        }

        iterateObject(object[nextKey], keysCopy, value);
    }

    iterateObject(obj, keys, value);
}
