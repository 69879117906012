export const WelcomeGPI = () => {

    return (
        <svg width="193" height="205" viewBox="0 0 193 205" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_10130_6476)">
                <path
                    d="M159.86 204.95H18.16C11.47 195.36 4.98 184.36 1.95 173.07C-1.08 161.78 0.390001 148.55 8.62 140.25C13.44 135.39 20.16 132.56 24.35 127.14C30.43 119.28 29.59 108.03 26.36 98.63C23.13 89.23 17.83 80.56 15.63 70.87C13.43 61.18 15.26 49.51 23.53 44C31.76 38.51 42.82 41.13 51.99 44.86C61.15 48.59 70.73 53.35 80.45 51.52C89.08 49.9 95.83 43.43 102.41 37.62C112.16 29.02 122.86 21.16 135.12 16.83C147.38 12.5 161.42 12 173.08 17.74C184.74 23.48 193.29 36.19 192.01 49.13C189.95 69.92 165.93 82.26 160.81 102.51C157.94 113.84 161.52 125.75 165.95 136.56C170.38 147.37 175.77 158.1 176.73 169.74C177.69 181.38 171.56 204.94 159.87 204.94L159.86 204.95Z"
                    fill="white" fill-opacity="0.1"/>
                <path
                    d="M152.1 116.3L136.93 82.42C134.52 77.03 129.16 73.56 123.25 73.56L122.21 97.76L135.98 126.43L152.1 116.29V116.3Z"
                    fill="#DB9A83"/>
                <path d="M127.65 175.85H48.96L53.35 73.57H123.25L127.65 175.85Z" fill="#449EDD"/>
                <path
                    d="M88.3001 69.58C84.9901 69.58 81.8801 68.32 79.1701 66.11L78.6001 74.98H98.0101L97.4401 66.11C94.7201 68.32 91.6101 69.58 88.3101 69.58H88.3001Z"
                    fill="#E9B49D"/>
                <path
                    d="M79.9502 54.1L79.1702 66.12C81.8902 68.33 85.0002 69.59 88.3002 69.59C91.6102 69.59 94.7202 68.33 97.4302 66.12L96.6502 54.1H79.9402H79.9502Z"
                    fill="#272425"/>
                <path
                    d="M76.76 73.57C78.22 78.56 82.84 82.21 88.3 82.21C93.76 82.21 98.38 78.56 99.84 73.57H76.75H76.76Z"
                    fill="#E9B49D"/>
                <path
                    d="M123.25 73.57C130.04 73.57 136.19 77.55 138.98 83.74L147.14 101.85L126.57 111.05L122.21 97.78L123.25 73.58V73.57Z"
                    fill="#449EDD"/>
                <path d="M128.22 110.3L145.88 102.4L130.58 115.2L128.22 110.3Z" fill="#272425"/>
                <path d="M153.64 84.21L143.32 78.27L150.33 62.61L164.77 70.92L153.64 84.21Z" fill="#E9B49D"/>
                <path
                    d="M143.32 78.27L142.86 69.3C142.81 68.3 142.87 67.3 143.05 66.32L144.37 59.1C145.62 59.4 146.55 60.44 146.7 61.71L147.74 70.58L143.31 78.28L143.32 78.27Z"
                    fill="#E9B49D"/>
                <path
                    d="M150.33 62.61L157.36 48.99C157.72 48.29 158.59 48.03 159.27 48.42C159.87 48.76 160.12 49.5 159.86 50.14L153.91 64.67L150.33 62.61Z"
                    fill="#E9B49D"/>
                <path
                    d="M153.9 64.67L162.19 50.25C162.62 49.5 163.57 49.25 164.32 49.68C165.01 50.08 165.29 50.93 164.97 51.66L158.21 67.17L153.89 64.69L153.9 64.67Z"
                    fill="#E9B49D"/>
                <path
                    d="M158.22 67.15L166.8 54.14C167.17 53.57 167.92 53.4 168.51 53.73C169.11 54.07 169.33 54.83 169.01 55.44L161.77 69.18L158.22 67.14V67.15Z"
                    fill="#E9B49D"/>
                <path
                    d="M161.77 69.19L168.73 60.08C169.09 59.61 169.74 59.48 170.25 59.78C170.83 60.11 171.01 60.85 170.66 61.42L164.77 70.93L161.77 69.2V69.19Z"
                    fill="#E9B49D"/>
                <path
                    d="M142.37 134.6C137.77 133.2 134.91 128.61 135.68 123.86L143.32 76.93L153.65 82.87L154.79 125.15C154.97 131.71 148.66 136.52 142.38 134.61L142.37 134.6Z"
                    fill="#E9B49D"/>
                <path
                    d="M36.07 196.39C35.06 194.64 33.21 193.54 31.19 193.48L28.9 189.71L26.18 186.28L25.65 191.57L27.77 194.24C28.85 195.59 30.48 196.38 32.21 196.38H36.06L36.07 196.39Z"
                    fill="#DB9A83"/>
                <path
                    d="M26.1901 188.27L32.0901 195.69C33.1701 197.04 34.8001 197.83 36.5301 197.83H40.3801C39.3701 196.07 37.5201 194.97 35.5001 194.92L33.2101 191.15L29.6801 186.7L26.1901 188.27Z"
                    fill="#DB9A83"/>
                <path
                    d="M30.51 189.72L36.41 197.14C37.49 198.49 39.12 199.28 40.85 199.28H44.7C43.69 197.52 41.84 196.42 39.82 196.37L37.53 192.6L34 188.15L30.51 189.72Z"
                    fill="#DB9A83"/>
                <path
                    d="M34.8401 191.16L40.7401 198.58C41.8201 199.93 43.4501 200.72 45.1801 200.72H49.0301C48.0201 198.96 46.1701 197.86 44.1501 197.81L41.8601 194.04L38.3301 189.59L34.8401 191.16Z"
                    fill="#DB9A83"/>
                <path
                    d="M54.4 97.77L53.36 73.57C47.45 73.57 42.1 77.04 39.68 82.43L20.01 125.16C17.64 130.31 17.11 136.11 18.51 141.6L27.23 175.85L25.79 190.26L41.94 196.89V175.86L34.58 138.88L54.41 97.78L54.4 97.77Z"
                    fill="#E9B49D"/>
                <path
                    d="M53.3501 73.57C46.5601 73.57 40.4101 77.55 37.6201 83.74L29.4601 101.85L50.0301 111.05L54.3901 97.78L53.3501 73.58V73.57Z"
                    fill="#449EDD"/>
                <path d="M48.38 110.3L30.73 102.4L46.03 115.2L48.38 110.3Z" fill="#272425"/>
                <path
                    d="M41.9399 175.85L47.5399 185.21L49.2699 195.15C47.6399 195.15 46.1999 194.1 45.6999 192.54L44.2299 187.94L41.9399 185.49V175.84V175.85Z"
                    fill="#E9B49D"/>
                <path d="M128.22 204.95H48.3801L50.6601 175.85H125.95L128.22 204.95Z" fill="#8496BA"/>
                <path d="M50.66 175.85H125.95L126.48 182.65L49.99 184.37L50.66 175.85Z" fill="#272425"/>
                <path
                    d="M67.1 52.24C71.2753 52.24 74.66 48.8553 74.66 44.68C74.66 40.5047 71.2753 37.12 67.1 37.12C62.9248 37.12 59.54 40.5047 59.54 44.68C59.54 48.8553 62.9248 52.24 67.1 52.24Z"
                    fill="#DB9A83"/>
                <path
                    d="M67.1 48.79C69.3699 48.79 71.21 46.9499 71.21 44.68C71.21 42.4101 69.3699 40.57 67.1 40.57C64.8301 40.57 62.99 42.4101 62.99 44.68C62.99 46.9499 64.8301 48.79 67.1 48.79Z"
                    fill="#C97E67"/>
                <path
                    d="M109.5 52.24C113.675 52.24 117.06 48.8553 117.06 44.68C117.06 40.5047 113.675 37.12 109.5 37.12C105.325 37.12 101.94 40.5047 101.94 44.68C101.94 48.8553 105.325 52.24 109.5 52.24Z"
                    fill="#DB9A83"/>
                <path
                    d="M109.5 48.79C111.76 48.79 113.61 46.95 113.61 44.68C113.61 42.41 111.77 40.57 109.5 40.57C107.23 40.57 105.39 42.41 105.39 44.68C105.39 46.95 107.23 48.79 109.5 48.79Z"
                    fill="#C97E67"/>
                <path
                    d="M88.3001 64.54C77.6901 64.54 69.0901 55.94 69.0901 45.33V32.65C69.0901 22.04 77.6901 13.44 88.3001 13.44C98.9101 13.44 107.51 22.04 107.51 32.65V45.33C107.51 55.94 98.9101 64.54 88.3001 64.54Z"
                    fill="#E9B49D"/>
                <path
                    d="M100.75 9.11C102 9.26 103.3 8.82 104.18 7.79C105.39 6.39 105.43 4.38 104.4 2.94L102.28 5.38C102.32 5.15 102.34 4.91 102.34 4.67C102.34 2.64 100.93 0.939998 99.0399 0.489998V4.9C98.8499 4.52 98.6099 4.16 98.2999 3.84C96.8799 2.39 94.6899 2.15 93.0199 3.15L97.2099 7.45C94.4399 6.41 91.4399 5.84 88.3099 5.84C74.2899 5.84 62.9199 17.21 62.9199 31.23C62.9199 37.68 65.3299 43.57 69.2999 48.05L71.2199 34.79L77.6999 34.09C84.5099 33.35 90.7699 30 95.1599 24.73L97.4499 21.98L94.5099 33.5L96.4799 32.17C98.3999 30.88 99.8699 29.03 100.71 26.87L101.32 28.78C101.99 30.88 103.46 32.63 105.42 33.64L107.34 48.03C111.31 43.55 113.72 37.66 113.72 31.21C113.72 21.71 108.5 13.44 100.78 9.08L100.75 9.11Z"
                    fill="#272425"/>
                <path
                    d="M82.3399 40.59C82.3399 41.67 81.4599 42.55 80.3799 42.55C79.2999 42.55 78.4199 41.67 78.4199 40.59C78.4199 39.51 79.2999 38.63 80.3799 38.63C81.4599 38.63 82.3399 39.51 82.3399 40.59Z"
                    fill="#272425"/>
                <path
                    d="M80.3199 39.8C80.3199 40.14 80.0499 40.41 79.7099 40.41C79.3699 40.41 79.0999 40.14 79.0999 39.8C79.0999 39.46 79.3699 39.19 79.7099 39.19C80.0499 39.19 80.3199 39.46 80.3199 39.8Z"
                    fill="white"/>
                <path
                    d="M82.86 37.38C82.64 37.38 82.42 37.3 82.25 37.13C81.73 36.61 81.04 36.33 80.31 36.33C79.58 36.33 78.89 36.62 78.37 37.13C78.03 37.47 77.49 37.47 77.15 37.13C76.81 36.79 76.81 36.25 77.15 35.91C77.99 35.07 79.12 34.6 80.31 34.6C81.5 34.6 82.63 35.06 83.47 35.91C83.81 36.25 83.81 36.79 83.47 37.13C83.3 37.3 83.08 37.38 82.86 37.38Z"
                    fill="#272425"/>
                <path
                    d="M96.23 42.55C97.3125 42.55 98.19 41.6725 98.19 40.59C98.19 39.5075 97.3125 38.63 96.23 38.63C95.1475 38.63 94.27 39.5075 94.27 40.59C94.27 41.6725 95.1475 42.55 96.23 42.55Z"
                    fill="#272425"/>
                <path
                    d="M95.5499 40.41C95.8868 40.41 96.1599 40.1369 96.1599 39.8C96.1599 39.4631 95.8868 39.19 95.5499 39.19C95.213 39.19 94.9399 39.4631 94.9399 39.8C94.9399 40.1369 95.213 40.41 95.5499 40.41Z"
                    fill="white"/>
                <path
                    d="M93.74 37.38C93.96 37.38 94.18 37.3 94.35 37.13C94.87 36.61 95.56 36.33 96.29 36.33C97.02 36.33 97.71 36.62 98.23 37.13C98.57 37.47 99.11 37.47 99.45 37.13C99.79 36.79 99.79 36.25 99.45 35.91C98.61 35.07 97.48 34.6 96.29 34.6C95.1 34.6 93.97 35.06 93.13 35.91C92.79 36.25 92.79 36.79 93.13 37.13C93.3 37.3 93.52 37.38 93.74 37.38Z"
                    fill="#272425"/>
                <path
                    d="M84.03 47.31C84.68 49.03 86.35 50.26 88.3 50.26C90.25 50.26 91.92 49.03 92.57 47.31H84.02H84.03Z"
                    fill="#C97E67"/>
                <path
                    d="M79.0801 51.56C80.4901 55.28 84.0801 57.93 88.3001 57.93C92.5201 57.93 96.1101 55.28 97.5201 51.56H79.0701H79.0801Z"
                    fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0_10130_6476">
                    <rect width="191.63" height="204.45" fill="white" transform="translate(0.5 0.5)"/>
                </clipPath>
            </defs>
        </svg>
    )
}
