import {ReactNode} from "react";
import {CircleFlag} from "react-circle-flags";


export const LanguagesBase: Record<string, ReactNode> = {
    en: <CircleFlag countryCode="gb" height="24" />,
    de: <CircleFlag countryCode="at" height="24" />,
    tr: <CircleFlag countryCode="tr" height="24" />,
    ua: <CircleFlag countryCode="ua" height="24" />
}
