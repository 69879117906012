import {PropsWithChildren, useContext} from "react";
import bg from "@src/assets/background.svg"

import styles from './PageTemplate.module.scss';
import {DisposableDrawerContext} from "@src/utils/hooks/contextMenu/components/DisposableDrawer/DisposableDrawer";
import {CloseIcon} from "@src/components/icons/core/CloseIcon";
import {DialogWindowContextHolderContext} from "@src/components/context/DialogWindowContextHolder/DialogWindowContextHolder.context";


export const PageTemplate = ({children, forbidPageClose}: PropsWithChildren<{forbidPageClose?: boolean}>) => {
    const foundDrawerContext = useContext(DisposableDrawerContext);
    const {drawerApi} = useContext(DialogWindowContextHolderContext);

    const foundDrawerKey = foundDrawerContext?.config?.key;

    const onClose = () => {
        drawerApi.close(foundDrawerKey!)
    }

    return (
        <div className={styles.PageTemplate}>
            {
                foundDrawerKey &&
                !forbidPageClose &&
                <div className={styles.closeIcon}>
                    <button className={styles.actionButton} onClick={onClose}>
                        <CloseIcon/>
                    </button>
                </div>
            }

            <img src={bg} className={styles.PageTemplateBg}/>
            <div className={styles.PageTemplateChildren}>
                {children}
            </div>
        </div>
    )
}
