import {CirclesCount} from "@src/components/core/pages/BoxInfoSuperAdmin/components/CirclesCount/CirclesCount";
import React, {useEffect, useState} from "react";

import classNames from "classnames";

import styles from "./CircleCountWrapper.module.scss";


export const CircleCountWrapper  = ({circles}: {circles: number}) => {

    const [isInit, setInit] = useState(false);
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        setTimeout(() => {
            setInit(true);

            setTimeout(() => {
                setProgress(100)
            }, 500);
        }, 750);
    }, []);

    return (
        <div className={styles.CirclesCount}>
            <div className={classNames(styles.CirclesCountProgress, isInit && styles.isInit)}>
                <CirclesCount progress={progress} radius={40} strokeWidth={15} />
            </div>
            <div className={styles.CirclesCountText}>
                {circles}
            </div>
        </div>
)
}
