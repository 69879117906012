import React, { useState, useCallback } from 'react';
import { DrawerProps } from '@mui/material';
import DisposableDrawer from '@src/utils/hooks/contextMenu/components/DisposableDrawer/DisposableDrawer';

export interface OpenDrawerConfig extends Omit<DrawerProps, 'open' | 'onClose'> {
    key?: string;
    children?: React.ReactNode;
    onOpen?: () => void;
    onClose?: () => void;
}

interface DrawerInstance {
    key: string;
    config: OpenDrawerConfig;
    isOpen: boolean;
}

export interface HookAPI {
    open: (config: OpenDrawerConfig) => void;
    close: (key: string) => void;
    closeAll: () => void;
    closeMany: (keys: string[]) => void;
    getIsOpenOneOf: (keys: string[]) => boolean;
    leaveOnly: (key: string) => void; // New function to leave only one drawer open
}

export const useDrawer = (): readonly [HookAPI, React.ReactElement] => {
    const [drawerInstances, setDrawerInstances] = useState<DrawerInstance[]>([]);

    const open = useCallback((config: OpenDrawerConfig) => {
        if (config.onOpen) {
            config.onOpen();
        }

        const key = config.key || `drawer_${new Date().getTime()}`;
        const newInstance: DrawerInstance = { key, config, isOpen: false };

        // Add the drawer to the state with `isOpen` set to false initially
        setDrawerInstances((prev) => [...prev, newInstance]);

        // Set `isOpen` to true after the instance is added to trigger the opening animation
        setTimeout(() => {
            setDrawerInstances((prev) =>
                prev.map((instance) =>
                    instance.key === key ? { ...instance, isOpen: true } : instance
                )
            );
        }, 10); // Short delay to allow DOM to recognize the new drawer instance
    }, []);

    const close = useCallback((key: string) => {
        setDrawerInstances((prev) =>
            prev.map((instance) =>
                instance.key === key ? { ...instance, isOpen: false } : instance
            )
        );

        const closingInstance = drawerInstances.find((instance) => instance.key === key);
        if (closingInstance?.config.onClose) {
            closingInstance.config.onClose();
        }

        // Remove the drawer after the closing animation completes
        setTimeout(() => {
            setDrawerInstances((prev) => prev.filter((instance) => instance.key !== key));
        }, 200); // Adjust for animation duration if needed
    }, [drawerInstances]);

    const closeMany = useCallback((keys: string[]) => {
        setDrawerInstances((prev) =>
            prev.map((instance) =>
                keys.includes(instance.key) ? { ...instance, isOpen: false } : instance
            )
        );

        // Call onClose for each drawer that is being closed
        drawerInstances.forEach((instance) => {
            if (keys.includes(instance.key) && instance.config.onClose) {
                instance.config.onClose();
            }
        });

        // Remove specified drawers after the closing animation completes
        setTimeout(() => {
            setDrawerInstances((prev) => prev.filter((instance) => !keys.includes(instance.key)));
        }, 200); // Adjust for animation duration if needed
    }, [drawerInstances]);

    const closeAll = useCallback(() => {
        drawerInstances.forEach((instance) => {
            if (instance.config.onClose) {
                instance.config.onClose();
            }
        });

        // First, trigger close animation for all drawers
        setDrawerInstances((prev) => prev.map((instance) => ({ ...instance, isOpen: false })));

        // Remove all drawers after animation completes
        setTimeout(() => {
            setDrawerInstances([]);
        }, 200); // Adjust for animation duration if needed
    }, [drawerInstances]);

    const getIsOpenOneOf = useCallback((keys: string[]) => {
        return drawerInstances.some((instance) => keys.includes(instance.key) && instance.isOpen);
    }, [drawerInstances]);

    const contextHolder = (
        <>
            {drawerInstances.map(({ key, config, isOpen }) => (
                <DisposableDrawer
                    key={key}
                    config={config}
                    isOpen={isOpen} // Control visibility with isOpen
                    onClose={() => close(key)}
                    {...config}
                />
            ))}
        </>
    );

    const leaveOnly = useCallback((key: string) => {
        setDrawerInstances((prev) =>
            prev.map((instance) =>
                instance.key === key ? { ...instance, isOpen: true } : { ...instance, isOpen: false }
            )
        );

        // Call onClose for all drawers except the one to remain open
        drawerInstances.forEach((instance) => {
            if (instance.key !== key && instance.config.onClose) {
                instance.config.onClose();
            }
        });

        // Remove all drawers except the specified one after animation completes
        setTimeout(() => {
            setDrawerInstances((prev) => prev.filter((instance) => instance.key === key || instance.isOpen));
        }, 200); // Adjust for animation duration if needed
    }, [drawerInstances]);

    const drawerApi: HookAPI = {
        open,
        close,
        closeAll,
        closeMany, // Expose the new closeMany function
        getIsOpenOneOf,
        leaveOnly
    };

    return [drawerApi, contextHolder] as const;
};
