import React, { useState, useEffect, MouseEvent } from 'react';
import {Button, IconButton, Menu, MenuItem, Typography} from '@mui/material';
import { useTranslationCascade } from '@src/utils/hooks/i18n/useTranslationCascade';
import { ChangeLanguageIcon } from '@src/components/icons/core/ChangeLanguageIcon';
import {LanguagesBase} from "@src/components/core/pages/AccountDetails/components/LanguagesBase";
import {Space} from "@src/components/shared/Space/Space";

import styles from "./LanguageSelectorButton.module.scss";


export const LanguageSelectorButton: React.FC = () => {
    const { i18n, t } = useTranslationCascade();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    // Dynamically get languages from i18n configuration
    const languages = Object.keys(i18n.options.resources).map((lang) => ({
        code: lang,
        label: i18n.options.resources[lang]?.translation?.languageName || lang,
    }));

    // Load language from localStorage
    useEffect(() => {
        const storedLanguage = localStorage.getItem('i18nextLng');
        if (storedLanguage && storedLanguage !== i18n.language) {
            i18n.changeLanguage(storedLanguage);
        }
    }, [i18n]);

    const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleLanguageChange = (lang: string) => {
        i18n.changeLanguage(lang); // Change language using i18next
        localStorage.setItem('i18nextLng', lang); // Store language in localStorage
        handleCloseMenu();
    };

    return (
        <>
            <div className={styles.changeLanguageContainer}>
                <Button
                    onClick={handleOpenMenu}
                >
                    <Space padding={4} direction="horizontal" align={'center'}>
                        {LanguagesBase[i18n.language]}

                        <div className={styles.languageSelectWrapper}>
                            <ChangeLanguageIcon />
                        </div>
                    </Space>
                </Button>
            </div>

            <Menu
                id="language-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
            >
                {languages.map((lang) => (
                    <MenuItem
                        key={lang.code}
                        selected={lang.code === i18n.language}
                        onClick={() => handleLanguageChange(lang.code)}
                    >
                        <Space padding={0} direction="horizontal">
                            {LanguagesBase[lang.label]}
                            <Typography>
                                { t(`translations:languages.${lang.code}`) }
                            </Typography>
                        </Space>
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};
