import React, { useEffect, ReactElement } from 'react';
import { Dialog, DialogProps } from '@mui/material';

interface DisposableDialogProps extends Omit<DialogProps, 'open' | 'onClose' | 'children'> {
    isOpen: boolean;
    onClose: () => void;
    children: ReactElement; // Enforce ReactElement
}

/**
 * A dialog component that disposes itself after the close animation completes.
 */
const DisposableDialog: React.FC<DisposableDialogProps> = ({
                                                               isOpen,
                                                               onClose,
                                                               children,
                                                               ...props
                                                           }) => {
    useEffect(() => {
        if (!isOpen) {
            const timer = setTimeout(onClose, 200); // Adjust for animation duration
            return () => clearTimeout(timer);
        }
    }, [isOpen, onClose]);

    return (
        <Dialog open={isOpen} onClose={onClose} {...props}>
            {children}
        </Dialog>
    );
};

export default DisposableDialog;
