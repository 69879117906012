import {ManualEnter} from "@src/components/icons/ManualEnter";

import styles from "./ManualEnterComponent.module.scss";
import {
    ManualEnterDrawerBody
} from "@src/components/core/pages/Scanner/components/ManualEnterComponent/components/ManualEnterDrawerBody/ManualEnterDrawerBody";
import {ApplicationMap} from "@src/core/applicationMap";
import {useDialogWindow} from "@src/utils/hooks/contextMenu/useDialogWindow";


export const ManualEnterComponent = () => {
    const {openDialog} = useDialogWindow();

    const openManualEnter = () => {
        openDialog({
            key: ApplicationMap.MANUAL_ENTER,
            children: <ManualEnterDrawerBody />,
        })
    }

    return (
        <button
            onClick={openManualEnter}
            className={styles.actionButton}
        >
            <ManualEnter/>
        </button>
    )
}
