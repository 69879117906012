import React from "react";

import {Alert, Typography} from "@mui/material";

import {ActionDrawerBody} from "@src/components/shared/ActionDrawerBody/ActionDrawerBody";
import {BoxHistory} from "@src/components/core/pages/BoxHistory/BoxHistory";
import {useTranslationCascade} from "@src/utils/hooks/i18n/useTranslationCascade";


export const BoxRecycledHistory = ({code}: { code: string }) => {
    const {t} = useTranslationCascade();

    return (
        <ActionDrawerBody
            title={
                <Typography variant="h6" gutterBottom sx={{color: 'white', textAlign: 'left'}}>
                    {t('translations:box.history.recycledLabel', {code})}
                </Typography>
            }
            message={
                <Alert variant="filled" severity="warning" sx={{width: '100%'}}>
                    {t("translations:box.history.recycled")}
                </Alert>
            }
            description={<BoxHistory code={code} refetchOnMount />}
        />
    )
}
