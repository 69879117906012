import { BoxCleaned } from "@src/components/icons/boxHistory/BoxCleaned";
import { BoxRepaired } from "@src/components/icons/boxHistory/BoxRepaired";
import { ReactNode } from "react";
import {EventType} from "@src/services/box/useBoxHistory";
import {QrCodeChanged} from "@src/components/icons/BoxActions/QrCodeChanged";

export const BoxHistoryIconBase: Record<EventType, ReactNode> = {
    cleaned: <BoxCleaned />,
    repaired: <BoxRepaired />,
    registered: <div>Registered Icon</div>,
    relabeled: <QrCodeChanged />,
    checked_in: <div>Checked Icon</div>,
    edited: <div>Edited Icon</div>,
    dispatched: <div>Dispatched Icon</div>, // Add a proper icon or placeholder
    reviewed: <div>Reviewed Icon</div>, // Add a proper icon or placeholder
    recycled: <div>Recycled icon</div>, // Add a proper icon or placeholder
    photo: <div>photo</div>, // Add a proper icon or placeholder
};
