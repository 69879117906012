import {IDetectedBarcode, Scanner} from "@yudiel/react-qr-scanner";

import styles from "./ScannerContainer.module.scss";


export const ScannerContainer = ({isPaused, onScan}: {isPaused?: boolean; onScan: (detectedCodes: IDetectedBarcode[]) => void}) => {

    return (
        <div className={styles.ScannerContainerWrapper}>
            <div className={styles.ScannerContainer}>
                <Scanner
                    onScan={onScan}
                    classNames={{
                        container: 'scanner-container',
                        video: 'scanner-video',
                    }}
                    allowMultiple={true}
                    paused={isPaused}
                    scanDelay={1}
                />
            </div>
            <div className={styles.frameWrapper}>
                <svg className={styles.frame} width="200" height="200" viewBox="0 0 100 100"
                     xmlns="http://www.w3.org/2000/svg"
                     preserveAspectRatio="none">
                    <line x1="5" y1="5" x2="25" y2="5" stroke="#ff5733" stroke-width="2"/>
                    <line x1="5" y1="5" x2="5" y2="25" stroke="#ff5733" stroke-width="2"/>

                    <line x1="75" y1="5" x2="95" y2="5" stroke="#ff5733" stroke-width="2"/>
                    <line x1="95" y1="5" x2="95" y2="25" stroke="#ff5733" stroke-width="2"/>

                    <line x1="5" y1="75" x2="5" y2="95" stroke="#ff5733" stroke-width="2"/>
                    <line x1="5" y1="95" x2="25" y2="95" stroke="#ff5733" stroke-width="2"/>

                    <line x1="75" y1="95" x2="95" y2="95" stroke="#ff5733" stroke-width="2"/>
                    <line x1="95" y1="75" x2="95" y2="95" stroke="#ff5733" stroke-width="2"/>
                </svg>
            </div>
        </div>
    )
}
