import {UseMutationResult} from "@tanstack/react-query";
import {MouseEventHandler, PropsWithChildren, ReactNode} from "react";

import {Button} from "@src/components/shared/form/Button/Button";
import {ApplicationMap} from "@src/core/applicationMap";
import {useDialogWindow} from "@src/utils/hooks/contextMenu/useDialogWindow";
import {ConfirmSubmit} from "@src/components/shared/SaveBoxButton/components/ReportBox/components/ConfirmSubmit/ConfirmSubmit";


interface ISaveBoxButton {
    title: ReactNode,
    message: ReactNode,
    submitContent: ReactNode
    mutation:  UseMutationResult<any, Error, any, unknown>,
    confirm?: boolean,
    icon?: ReactNode,
    onClickPrimaryAction?: MouseEventHandler<HTMLButtonElement>,
}

export const SaveBoxButton = ({title, message, icon, submitContent, mutation, confirm = true, onClickPrimaryAction}: PropsWithChildren<ISaveBoxButton>) => {
    const {openDialog} = useDialogWindow();

    const saveBox = async () => {

        if (confirm) {
            openDialog({
                key: ApplicationMap.REPORT_BOX_CONFIRM,
                children: (
                    <ConfirmSubmit
                        mutation={mutation}
                        title={title}
                        message={message}
                        submitContent={submitContent}
                        icon={icon}
                    />
                )
            })

            return;
        }

        await mutation.mutateAsync({});
    }

    return (
        <Button onClick={onClickPrimaryAction ? onClickPrimaryAction : saveBox} icon={icon}>
            {submitContent}
            {/*{children ? children: t('translations:box.actions.submit') }*/}
        </Button>
    )
}
