import {Checkbox, FormControlLabel} from "@mui/material";
import {useController, useFormContext} from "react-hook-form";

export const CheckboxControl = ({label, path}: {label: string; path: string}) => {
    const {control} = useFormContext();
    const {field} = useController({name: path, control});

    return (
        <FormControlLabel
            checked={field.value}
            onChange={field.onChange}
            control={<Checkbox
                sx={{
                    color: "white",
                    '&.Mui-checked': {
                        color: "white",
                    },
                }}
            />}
            sx={{
                color: "white",
            }}
            label={label}
        />
    )
}
