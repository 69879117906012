import {CircularProgress} from "@mui/material";
import React from "react";

import styles from "./FullPageLoader.module.scss";

export const FullPageLoader = () => {

    return (
        <div className={styles.FullPageLoader}>
            <CircularProgress sx={{color: "white"}}/>
        </div>
    )
}
