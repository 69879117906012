import Camera from 'react-html5-camera-photo';

import styles from "./PhotoReport.module.scss"

import 'react-html5-camera-photo/build/css/index.css';
import {PageTemplate} from "@src/components/core/PageTemplate/PageTemplate";
import useStateRef from "react-usestateref";
import {base64ToBinary, PhotoReportImageBlob} from "@src/utils/blob/base64ToBinary";
import {Button} from "@src/components/shared/form/Button/Button";
import React, {useContext} from "react";
import {
    DialogWindowContextHolderContext
} from "@src/components/context/DialogWindowContextHolder/DialogWindowContextHolder.context";
import {ViewPhotoItem} from "@src/components/shared/PhotoReport/components/ViewPhotoItem/ViewPhotoItem";
import {useDialogWindow} from "@src/utils/hooks/contextMenu/useDialogWindow";


function getLastTwoItems<K, V>(map: Map<K, V>): Map<K, V> {
    // Convert the map to an array of its entries
    const entries = Array.from(map.entries());

    // Return the last two entries (or fewer if the map has less than two items)
    return new Map(entries.slice(-4));
}


export const PhotoReport = ({onComplete, images: images_o}: { images: any, onComplete: any }) => {
    const {openDialog} = useDialogWindow();

    const [, isDisabled, setIsDisabled] = useStateRef(true);
    const [, setImages, images] = useStateRef<Map<string, PhotoReportImageBlob>>(new Map(images_o));

    const addPhoto = (proto: PhotoReportImageBlob) => {
        setImages((photos) => {
            photos.set(proto.id, proto)
            return new Map(photos)
        })
    }

    function handleTakePhoto(dataUri: string) {
        addPhoto(base64ToBinary(dataUri))
    }

    const lastTwoImages = getLastTwoItems(images.current)

    const onViewItems = () => {
        openDialog({
            key: "PHOTO_REPORT_BOX_IMAGES",
            children: (
                <ViewPhotoItem images={images.current} onComplete={onComplete} setImages={setImages}/>
            )
        });
    }

    return (
        <PageTemplate>
            <div className={styles.PhotoReport}>
                <Camera
                    onTakePhoto={(dataUri) => {
                        handleTakePhoto(dataUri);
                    }}
                />
                {
                    images.current && images.current.size > 0 && <>
                        <Button
                            className={styles.madePhotos} onClick={onViewItems}
                            icon={
                                <div className={styles.previewImages}>

                                    {
                                        Array.from(lastTwoImages).reverse().map(([id, img]) => {

                                            return (
                                                <img src={URL.createObjectURL(img)} alt={id} key={id}/>
                                            )
                                        })
                                    }
                                </div>
                            }
                        >
                            send
                        </Button>
                    </>
                }


                <div>
                </div>
            </div>

            {/*<div>*/}
            {/*    <Button>*/}
            {/*        */}
            {/*    </Button>*/}
            {/*</div>*/}
        </PageTemplate>
    )
}
