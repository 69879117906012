import {useBoxHistory} from "@src/services/box/useBoxHistory";

import {
    BoxHistoryItem,
    BoxHistoryRegistered
} from "@src/components/core/pages/BoxHistory/components/BoxHistoryItem/BoxHistoryItem";

import styles from "./BoxHistory.module.scss"


export const BoxHistory = ({code, refetchOnMount}: { code: string; refetchOnMount?: boolean }) => {
    const {data} = useBoxHistory({code, refetchOnMount});

    const totalCircles = data?.groups?.length! + 1

    return (
        <div className={styles.BoxHistory}>

            {
                data?.groups?.map((group, index) => {

                    return (
                        <BoxHistoryItem
                            iteration={totalCircles - index}
                            actions={group}
                        />
                    )
                })
            }
            {
                data?.registered &&
                <BoxHistoryRegistered
                    registered={data.registered}
                />
            }
        </div>
    )
}
