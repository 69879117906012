import {Alert, Typography} from "@mui/material";
import {Button} from "@src/components/shared/form/Button/Button";
import {ActionDrawerBody} from "@src/components/shared/ActionDrawerBody/ActionDrawerBody";
import React, {useContext} from "react";
import {
    DialogWindowContextHolderContext
} from "@src/components/context/DialogWindowContextHolder/DialogWindowContextHolder.context";
import {useCompleteBoxReview, useDoPhotoReport, useRecycleBox} from "@src/services/box/useCompleteBoxReview";
import {useDialogWindow} from "@src/utils/hooks/contextMenu/useDialogWindow";
import {ApplicationMap} from "@src/core/applicationMap";
import {BoxIssue} from "@src/core/xhr";
import {BoxIssues} from "@src/components/shared/BoxIssues/BoxIssues";
import {Space} from "@src/components/shared/Space/Space";
import {useTranslationCascade} from "@src/utils/hooks/i18n/useTranslationCascade";
import {PhotoReportImageBlob} from "@src/utils/blob/base64ToBinary";


import styles from "./RecycleConfirm.module.scss"

export const RecycleConfirm = ({code, issues, images}: {code: string; issues: BoxIssue[], images: Map<string, PhotoReportImageBlob>}) => {
    const {snackbarApi} = useContext(DialogWindowContextHolderContext);
    const {mutateAsync, isPending} = useRecycleBox({code});
    const {mutateAsync: attachPhotos} = useDoPhotoReport({code});
    const {leaveOnly} = useDialogWindow();
    const {t} = useTranslationCascade();

    const onSubmit = async () => {
        await attachPhotos({images});
        await mutateAsync({reasons: issues});

        leaveOnly(ApplicationMap.SCANNER);

        snackbarApi.open({
            key: "BOX_COMPLETE",
            anchorOrigin: {
                vertical: "top",
                horizontal: "center"
            },
            autoHideDuration: 4000,
            children: (
                <Alert severity="success" icon sx={{ width: '100%' }}>
                    {t("translations:boxStaff.reviewOptions.recycleOption.recycleBoxRecycled", {code})}
                </Alert>
            )
        });
    }

    return (
        <ActionDrawerBody
            title={t("translations:boxStaff.reviewOptions.recycleOption.recycleBoxConfirmTitleConfirm")}
            description={(
                <Space padding={0} direction={"vertical"}>
                    <div>
                        { t('translations:boxStaff.reviewOptions.recycleOption.recycleBoxConfirmTitle') }
                    </div>

                    <BoxIssues issues={issues} code={code} closable={false}/>

                    <div className={styles.RecycleConfirm}>
                        <Space overflow direction={"horizontal"}>
                            {
                                Array.from(images).map(([id, blob]) => {

                                    return (
                                        <img src={URL.createObjectURL(blob)} alt={id} />
                                    )
                                })
                            }
                        </Space>
                    </div>
                </Space>
            )}
            action={
                <Button onClick={onSubmit} loading={isPending}>
                    { t('translations:boxStaff.reviewOptions.recycleOption.recycleBoxConfirmButton') }
                </Button>
            }
        />
    )
}
