import {PageTemplate} from "@src/components/core/PageTemplate/PageTemplate";

import styles from "./Home.module.scss";
import {Button} from "@src/components/shared/form/Button/Button";
import {useNavigate} from "react-router-dom";
import {useContext} from "react";
import {
    DialogWindowContextHolderContext
} from "@src/components/context/DialogWindowContextHolder/DialogWindowContextHolder.context";
import Scanner from "@src/components/core/pages/Scanner/Scanner";
import {ApplicationMap} from "@src/core/applicationMap";
import {AccountDetails} from "@src/components/core/pages/AccountDetails/AccountDetails";
import {WelcomeGBO} from "@src/components/icons/core/WelcomeGBO";
import {Typography} from "@mui/material";
import {AccountIcon} from "@src/components/icons/core/AccountIcon";
import {useIsLoggedIn} from "@src/services/auth/useIsLoggedIn";
import {WelcomeGPI} from "@src/components/icons/core/WelcomeGPI";
import {useTranslationCascade} from "@src/utils/hooks/i18n/useTranslationCascade";
import {UserRoleIconBase} from "@src/components/core/pages/Home/UserRoleIconBase";


const Home = () => {
    const {drawerApi} = useContext(DialogWindowContextHolderContext);
    const {data, error, isInitialLoading} = useIsLoggedIn();
    const {t} = useTranslationCascade();

    const startToScan = () => {
        drawerApi.open({
            key: ApplicationMap.SCANNER,
            anchor: "right",
            children: (
                <Scanner />
            )
        })
    };

    const onOpenAccount = () => {
        drawerApi.open({
            key: ApplicationMap.ACCOUNT_DETAILS,
            anchor: "right",
            children: (
                <AccountDetails />
            )
        });
    }

    return (
        <PageTemplate>
            <div className={styles.Home}>

                <div className={styles.topBar}>
                    <div className={styles.topBarActionsWrapper}>
                        <div className={styles.topBarActions} onClick={onOpenAccount}>
                            <AccountIcon/>
                        </div>
                    </div>
                    <div className={styles.topBarContainer}>
                        <div className={styles.topBarWelcomeMessage}>
                            <Typography variant="h4" gutterBottom sx={{color: 'white', textAlign: 'center'}}>
                                {t('translations:dashboard.welcome')}
                                <div>
                                    {t(`translations:dashboard.userGroups.${data?.role}.${data?.location}`)}
                                </div>
                            </Typography>
                        </div>

                        {
                            data?.location &&
                            UserRoleIconBase[data?.location]
                        }
                    </div>
                </div>
                <div className={styles.toolbarWrapper}>
                    <Button className={styles.startScanningButton} onClick={startToScan}>
                        {t("translations:dashboard.startToScanButton")}
                    </Button>
                </div>
            </div>
        </PageTemplate>
    )
}

export default Home;
