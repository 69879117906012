import {PageTemplate} from "@src/components/core/PageTemplate/PageTemplate";
import bg from "@src/assets/img_box.png";
import {Space} from "@src/components/shared/Space/Space";
import {Alert, AlertTitle, Card, Chip, FormGroup, Typography} from "@mui/material";
import {Button} from "@src/components/shared/form/Button/Button";
import {useGetBoxInfoResult} from "@src/services/box/useGetBoxInfo";
import React, {useContext, useEffect} from "react";
import {
    DialogWindowContextHolderContext
} from "@src/components/context/DialogWindowContextHolder/DialogWindowContextHolder.context";
import {ApplicationMap} from "@src/core/applicationMap";

import {EditIcon} from "@src/components/icons/core/EditIcon";
import {ActionDrawerBody} from "@src/components/shared/ActionDrawerBody/ActionDrawerBody";
import {ReportBoxBody} from "@src/components/core/pages/BoxInfo/ReportBoxBody/ReportBoxBody";
import {BoxHistory} from "@src/components/core/pages/BoxHistory/BoxHistory";
import {useBoxHistory} from "@src/services/box/useBoxHistory";
import {SaveBoxButton} from "@src/components/shared/SaveBoxButton/SaveBoxButton";
import {FormCore} from "@src/components/shared/form/Form/Form";
import {useForm} from "react-hook-form";
import {BoxIssues} from "@src/components/shared/BoxIssues/BoxIssues";
import {TBoxIssue} from "@src/components/shared/BoxIssues/BoxIssuesNames";
import {Radio} from "@src/components/shared/form/Radio/Radio";
import {useCheckInBox} from "@src/services/box/useDispatchBox";

import styles from "./BoxInfoSuperAdmin.module.scss";
import {useDialogWindow} from "@src/utils/hooks/contextMenu/useDialogWindow";
import {useTranslationCascade} from "@src/utils/hooks/i18n/useTranslationCascade";
import {
    BoxBoxPendingTodos
} from "@src/components/core/pages/BoxInfoSuperAdmin/components/BoxBoxPendingTodos/BoxBoxPendingTodos";
import dayjs from "dayjs";
import {DisposableDrawerContext} from "@src/utils/hooks/contextMenu/components/DisposableDrawer/DisposableDrawer";
import {ReportBox} from "@src/components/shared/SaveBoxButton/components/ReportBox/ReportBox";
import {
    ConfirmSubmit
} from "@src/components/shared/SaveBoxButton/components/ReportBox/components/ConfirmSubmit/ConfirmSubmit";
import {useCompleteBoxReview} from "@src/services/box/useCompleteBoxReview";
import {useMutation} from "@tanstack/react-query";
import {CirclesCount} from "@src/components/core/pages/BoxInfoSuperAdmin/components/CirclesCount/CirclesCount";
import {
    CircleCountWrapper
} from "@src/components/core/pages/BoxInfoSuperAdmin/components/CircleCountWrapper/CircleCountWrapper";


export const BoxInfoSuperAdmin = ({code}: { code: string }) => {
    const foundDrawerContext = useContext(DisposableDrawerContext);
    const {data, isFetching} = useGetBoxInfoResult({code});
    const {data: boxHistory} = useBoxHistory({code, refetchOnMount: true});
    const methods: any = useForm({
        values: {
            issues: data?.issuesObj
        }
    });
    const {openDialog} = useDialogWindow();
    const {t} = useTranslationCascade();

    const onBoxIssues = () => {
        openDialog({
            key: ApplicationMap.BOX_ISSUES,
            children: (
                <ReportBoxBody methods={methods} code={code} />
            )
        })
    }
    const {drawerApi} = useContext(DialogWindowContextHolderContext);

    const onReportBox = () => {
        drawerApi.open({
            key: ApplicationMap.REPORT_BOX,
            anchor: "right",
            children: (
                <ReportBox code={code} />
            )
        })
    }

    const onLeaveOnlyScanner = () => {
        drawerApi.leaveOnly(ApplicationMap.SCANNER);
    }

    const showHistory = () => {
        openDialog({
            key: ApplicationMap.BOX_HISTORY,
            children: (
                <ActionDrawerBody
                    title={
                        <Typography variant="h6" gutterBottom sx={{color: 'white', textAlign: 'left'}}>
                            { t('translations:boxStaff.boxHistoryDrawerTitle') }
                        </Typography>
                    }
                    description={<BoxHistory code={code}/>}
                />
            )
        })
    }

    const issuesWatchValue = methods.watch('issues')

    const issues  = issuesWatchValue ? Object.entries(issuesWatchValue).reduce((acc: any, [key, value]: any) => {

        if (value) {
            return [...acc, key]
        }

        return acc;
    }, []) as TBoxIssue[] : [];

    const internalMethods: any = useForm({
        defaultValues: {
            action: 'noIssues' as any
        }
    });

    const action = internalMethods.watch("action");

    const currentActionsLength = (() => {
        if (boxHistory?.currentActions) {
            return Object.values(boxHistory.currentActions).length
        }

        return null;
    })();

    const {leaveOnly} = useDialogWindow();
    const {snackbarApi} = useContext(DialogWindowContextHolderContext);

    const {data: boxInfo} = useGetBoxInfoResult({code});

    const completeBoxReviewMutation = useCompleteBoxReview({
        code: boxInfo?.code,
        onSuccess: () => {
            snackbarApi.open({
                key: "BOX_COMPLETE",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center"
                },
                autoHideDuration: 4000,
                children: (
                    <Alert severity="success" icon sx={{ width: '100%' }}>
                        {t("translations:boxStaff.boxReviewed", {code: boxInfo?.code})}
                    </Alert>
                )
            });

            leaveOnly(ApplicationMap.SCANNER);
        }
    });

    const boxTodoActions = boxInfo?.actions;

    const isOneOfTodosDefined = (Object.values(boxTodoActions) as string[]).some((el: string) => el === "planned")


    return (
        <PageTemplate>
            <div className={styles.BoxInfoSuperAdmin}>
                <div className={styles.topBar}>
                    <Card sx={{background: "#092B25", borderRadius: '8px', padding: '16px 0 0'}}>
                        <div className={styles.cardContent}>
                            <Space padding={[0, 16, 0, 16]} justify={"space-between"}>
                                <Typography variant="h5" gutterBottom sx={{color: 'white', textAlign: 'center'}}>
                                    {data?.code}
                                </Typography>

                                <div onClick={onBoxIssues}>
                                    <EditIcon/>
                                </div>
                            </Space>
                            <FormCore methods={methods} onSubmit={() => {}}>

                                <Space padding={0} overflow>
                                    <Space padding={[0, 16, 0, 16]} overflow>
                                        {
                                            issues &&
                                            <BoxIssues issues={issues} code={code}/>
                                        }
                                    </Space>
                                </Space>
                            </FormCore>

                            <div className={styles.CirclesCountWrapper}>
                                <CircleCountWrapper circles={boxHistory?.groups?.length! + 1} />
                                {/*<div className={styles.CirclesCountText}>*/}
                                {/*    {boxHistory?.groups?.length! + 1}*/}
                                {/*</div>*/}
                                <div className={styles.CirclesCountWrapperLeftBar}>
                                    <Typography gutterBottom
                                                sx={{color: 'white', textAlign: 'left', padding: "0 11px"}}>
                                        {t("translations:boxStaff.boxHistoryTitle")}
                                    </Typography>
                                    <Button variant={"text"} onClick={showHistory}>
                                    {t("translations:boxStaff.boxHistoryOpenButton")}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Card>
                    {
                        !isFetching &&
                        <>
                            {
                                data?.status !== "ready" &&
                                <BoxBoxPendingTodos code={code} />
                            }
                            {
                                data?.status === "ready" &&
                                <Alert variant="filled" severity="info" sx={{width: '100%'}}>
                                    {t("translations:boxStaff.boxStatusReady", {time: dayjs(data.dateLastReviewed).format("YYYY-MM-DD HH:mm")})}
                                </Alert>
                            }
                        </>
                    }
                </div>
                {
                    !isFetching &&
                    <>
                        {
                            data?.status !== "ready" &&
                            <div className={styles.bottomBar}>
                                {
                                    currentActionsLength && currentActionsLength > 0
                                        ? (
                                            <Space direction={"vertical"}>
                                                <Alert variant="filled" severity="info" sx={{width: '100%'}}>
                                                    {t('translations:boxStaff.boxQualityReview.boxReviewStatusHasBeenStarted')}
                                                </Alert>
                                                <Button onClick={onReportBox}>
                                                    { t('translations:boxStaff.boxQualityReview.continueBoxReview') }
                                                </Button>
                                                {
                                                    isOneOfTodosDefined &&
                                                    <Button onClick={onLeaveOnlyScanner} variant={"outlined"}>
                                                        {t("translations:boxStaff.continueScanningWithTodos")}
                                                    </Button>
                                                }
                                            </Space>
                                        )
                                        : (
                                            <Space direction={"vertical"}>
                                                <div>
                                                    <Typography gutterBottom sx={{color: 'white', textAlign: 'left'}}>
                                                        { t('translations:boxStaff.boxQualityReview.title') }
                                                    </Typography>
                                                    <FormCore methods={internalMethods}>
                                                        <Radio
                                                            path={'action'}
                                                            radioGroup={[
                                                                {
                                                                    label: t('translations:boxStaff.boxQualityReview.successful'),
                                                                    value: 'noIssues'
                                                                },
                                                                {
                                                                    label: t('translations:boxStaff.boxQualityReview.unsuccessful'),
                                                                    value: 'issuesObserved'
                                                                }
                                                            ]}
                                                        />
                                                    </FormCore>
                                                </div>
                                                <SaveBoxButton
                                                    mutation={completeBoxReviewMutation}
                                                    onClickPrimaryAction={action === "issuesObserved" ? onReportBox : undefined}
                                                    submitContent={ t('translations:boxStaff.boxQualityReview.submitReviewSuccessful') }
                                                    title={ t('translations:boxStaff.boxQualityReview.submitReviewTitle') }
                                                    message={ t('translations:boxStaff.boxQualityReview.submitReviewMessage') }
                                                >
                                                    { t('translations:boxStaff.boxQualityReview.proceedButton') }
                                                </SaveBoxButton>
                                                {
                                                    isOneOfTodosDefined &&
                                                    <Button onClick={onLeaveOnlyScanner} variant={"outlined"}>
                                                        {t("translations:boxStaff.continueScanningWithTodos")}
                                                    </Button>
                                                }
                                            </Space>
                                        )
                                }
                            </div>
                        }
                        {
                            data?.status === "ready" &&
                            <Space>
                                <Button onClick={() => foundDrawerContext.onClose()}>
                                    { t('translations:boxStaff.boxQualityReview.continueScanning') }
                                </Button>
                            </Space>
                        }
                    </>
                }
            </div>
        </PageTemplate>
    )
}
