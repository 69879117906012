export type TAction = 'clean' | 'repair' | 'relabel'

export type BoxIssue  = 'small_defect' | 'visual_damage' | 'mechanical_damage' | 'unusable';


export class XhrBase {

    public STATUS = "/status"

    public static ME = '/users/me'

    public static BOX_GET_BY_CODE = ({code}: {code: string}) => {

        return "/boxes/by-code/:code".replace(':code', String(code))
    }

    public static BOX_SUBMIT_ISSUES_body = ({code, issues}: {code: string, issues: BoxIssue[]}) => {

        return { code, issues }
    }

    public static BOX_HISTORY = ({code}: {code: string}) => {

        return "/boxes/by-code/:code/history".replace(':code', String(code))
    }

    public static AUTH_LOGOUT =  "/auth/logout"


    public static AUTH_LOGIN =  "/auth/login"

    public static BOX_ACTION =  ({code, action}: {code: string, action: TAction}) => {

        return "/boxes/by-code/:code/actions/:action".replace(':code', String(code)).replace(':action', action)
    }

    public static BOX_ACTION_body =  (payload: {code: string}) => {

        return payload;
    }

    public static RECYCLE_BOX =  ({code}: {code: string}) => {
        return "/boxes/by-code/:code/actions/recycle".replace(':code', String(code))
    }

    public static RECYCLE_BOX_body =  (props: {reasons: BoxIssue[]}) => {
        return props
    }

    public static DISPATCH_BOX =  ({code}: {code: string}) => {

        return "/boxes/by-code/:code/actions/dispatch".replace(':code', String(code))
    }

    public static SET_TODO =  ({code, action}: {code: string, action: TAction}) => {

        return "/boxes/by-code/:code/to-do/:action".replace(':code', String(code)).replace(':action', action)
    }

    public static CLEAR_TODOS =  ({code}: {code: string}) => {

        return "/boxes/by-code/:code/to-do".replace(':code', String(code))
    }

    public static DISPATCH_BOX_body = (props: {routeId?:number;locationId?: string }) => {
        return props
    }

    public static CHECK_IN_BOX =  ({code}: {code: string}) => {

        return "/boxes/by-code/:code/actions/check-in".replace(':code', String(code))
    }

    public static RETURN_BOX =  ({code}: {code: string}) => {

        return "/boxes/by-code/:code/actions/return".replace(':code', String(code))
    }

    public static UPLOAD_PHOTO =  ({code}: {code: string}) => {

        return "/boxes/by-code/:code/actions/photo".replace(':code', String(code))
    }

    public static CHECK_IN_BOX_body = (props: {locationId?: string }) => {
        return props
    }

    public static CONFIRM_BOX_REVIEW =  ({code}: {code: string}) => {

        return "/boxes/by-code/:code/actions/review".replace(':code', String(code))
    }

    public static PUT_BOX_TODO =  ({code, action}: {code: string; action: TAction}) => {
        return "/boxes/by-code/:code/to-do/:action".replace(':code', String(code)).replace(':action', action)
    }

    public static DELETE_BOX_TODO =  ({code, action}: {code: string; action: TAction}) => {
        return "/boxes/by-code/:code/to-do/:action".replace(':code', String(code)).replace(':action', action)
    }
}
