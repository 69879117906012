import {Avatar, Typography} from "@mui/material";
import {Space} from "@src/components/shared/Space/Space";

export const AccountAvatar = () => {

    return (
        <Space padding={0} gap={8}>
            <Avatar>H</Avatar>

            <Space padding={0} gap={0} direction={'vertical'}>
                <Typography variant="h5" gutterBottom sx={{fontSize: '13px', textAlign: 'left', fontWeight: '500', margin: 0}}>
                    First Name
                </Typography>
                <Typography variant="h5" gutterBottom sx={{fontSize: '16px', textAlign: 'left', fontWeight: '500', margin: 0}}>
                    Last Name
                </Typography>
            </Space>
        </Space>
    )
}
