import {useMutation} from "@tanstack/react-query";
import {XhrBase} from "@src/core/xhr";
import {useHttpClient} from "@src/utils/hooks/xhr/useHttpClient";
import {useContext} from "react";
import {QueryDependencyContext} from "@src/components/context/QueryDeppendencyContext/QueryDeppendencyContext";
import {
    DialogWindowContextHolderContext
} from "@src/components/context/DialogWindowContextHolder/DialogWindowContextHolder.context";

export const useLogout = () => {
    const {xhr} = useHttpClient();
    const {updateDepp}  = useContext(QueryDependencyContext);
    const {drawerApi} = useContext(DialogWindowContextHolderContext);

    return useMutation({
        mutationFn: async () => {
            await xhr.post({ relativeUrl: XhrBase.AUTH_LOGOUT });
        },
        onSuccess: () => {
            updateDepp('useIsLoggedIn');
            drawerApi.closeAll();
        }
    })
}
