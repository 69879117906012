export const ChangeQrCode = () => {

    return (
        <svg width="300" height="250" viewBox="0 0 300 250" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_9788_11197)">
                <path d="M252.891 126.042H199.563V208.601H252.891V126.042Z" fill="#608929"/>
                <path d="M252.843 102.821H199.563V132.139H252.843V102.821Z" fill="#405C19"/>
                <path d="M57.3606 126.042H48.4727V208.601H57.3606H199.564V126.042H57.3606Z" fill="#86BD3D"/>
                <path d="M199.564 132.139V102.821H57.3606H48.4727V132.139H57.3606H199.564Z" fill="#608929"/>
                <path d="M105.586 147.297V161.468H252.627V147.297H105.586Z" fill="#639A2C"/>
                <path d="M48.4766 147.297V161.468H199.56V147.297H48.4766Z" fill="#ABD179"/>
                <path d="M260 99.773H199.563V126.042H260V99.773Z" fill="#608929"/>
                <path d="M222.405 126.042H236.643V99.773H222.405V126.042Z" fill="#639A2C"/>
                <path d="M199.564 126.042V99.773H50.3277H41V126.042H50.3277H199.564Z" fill="#86BD3D"/>
                <path d="M146.528 126.042H160.765V99.773H146.528V126.042Z" fill="#ABD179"/>
                <path d="M73.553 126.042H87.7905V99.773H73.553V126.042Z" fill="#ABD179"/>
                <path d="M123.582 175.344H66.9922V198.159H123.582V175.344Z" fill="white" fill-opacity="0.5"/>
                <path d="M73.4771 190.009H107.666V188.6H73.4771V190.009Z" fill="#998376"/>
                <path d="M82.0213 194.347H90.7173V192.938H82.0213V194.347Z" fill="#6D5041"/>
                <path d="M82.0213 194.347H90.7173V192.938H82.0213V194.347Z" fill="#7F6D62"/>
                <path d="M73.6371 181.449H94.8594V180.04H73.6371V181.449Z" fill="#7F6D62"/>
                <path d="M82.0213 185.671H90.7173V184.262H82.0213V185.671Z" fill="#7F6D62"/>
                <path d="M108.237 181.21H116.933V179.801H108.237V181.21Z" fill="#7F6D62"/>
                <path d="M74.5086 194.347H78.4868V192.938H74.5086V194.347Z" fill="#7F6D62"/>
                <path d="M82.1013 192.942H73.4053V194.351H82.1013V192.942Z" fill="#998376"/>
                <path d="M77.6032 184.672H73.625V186.081H77.6032V184.672Z" fill="#7F6D62"/>
            </g>
            <path d="M120.175 74.9268V53.252H142.354" stroke="white" stroke-width="4" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path d="M142.102 115L120.427 115L120.427 92.8211" stroke="white" stroke-width="4" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path d="M180.075 93.0732L180.075 114.748L157.896 114.748" stroke="white" stroke-width="4"
                  stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M158.064 53L179.739 53L179.739 75.1789" stroke="white" stroke-width="4" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <rect x="128.896" y="63.149" width="16.1626" height="16.1626" rx="2" stroke="white" stroke-width="4"/>
            <rect x="128.896" y="90.0325" width="16.1626" height="16.1626" rx="2" stroke="white" stroke-width="4"/>
            <rect x="155.78" y="63.149" width="16.1626" height="16.1626" rx="2" stroke="white" stroke-width="4"/>
            <path d="M155.124 89.7127L155.124 100.802" stroke="white" stroke-width="4" stroke-linecap="round"/>
            <path d="M161.509 91.393L171.926 91.393" stroke="white" stroke-width="4" stroke-linecap="round"/>
            <path d="M155.46 107.187L171.926 107.187" stroke="white" stroke-width="4" stroke-linecap="round"/>
            <defs>
                <clipPath id="clip0_9788_11197">
                    <rect width="219" height="108.828" fill="white" transform="translate(41 99.773)"/>
                </clipPath>
            </defs>
        </svg>

    )
}
