export class ApplicationMap {
    public static ACCOUNT_DETAILS = "ACCOUNT_DETAILS";

    public static SCANNER = "SCANNER";

    public static MANUAL_ENTER = "MANUAL_ENTER";

    public static BOX_INFO = "BOX_INFO";

    public static BOX_INFO_ADMIN = "BOX_INFO_ADMIN";

    public static BOX_ISSUES = "BOX_ISSUES";

    public static BOX_HISTORY = "BOX_HISTORY";

    public static SAVE_BOX = "SAVE_BOX";

    public static REPORT_BOX = "REPORT_BOX";

    public static REPORT_BOX_Action = "REPORT_BOX_Action";

    public static REPORT_BOX_CONFIRM = "REPORT_BOX_CONFIRM";

    public static RECYCLE_BOX = "RECYCLE_BOX";

    public static REPORT_BOX_PRE_SUBMIT_NOTION = "REPORT_BOX_PRE_SUBMIT_NOTION";
}
