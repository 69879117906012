import {useContext} from "react";

import {BoxAction} from "@src/components/shared/SaveBoxButton/components/ReportBox/components/BoxActionBase/BoxActionBase";
import {WashBoxIcon} from "@src/components/icons/BoxActions/WashBoxIcon";
import {DialogWindowContextHolderContext} from "@src/components/context/DialogWindowContextHolder/DialogWindowContextHolder.context";
import {ApplicationMap} from "@src/core/applicationMap";
import {WashBoxContent} from "@src/components/shared/SaveBoxButton/components/ReportBox/components/WashBoxButton/components/WashBoxContent/WashBoxContent";
import {useBoxHistory} from "@src/services/box/useBoxHistory";
import {useTranslationCascade} from "@src/utils/hooks/i18n/useTranslationCascade";
import {useGetBoxInfoResult} from "@src/services/box/useGetBoxInfo";


export const WashBoxButton = ({code}: {code: string}) => {
    const {t} = useTranslationCascade();
    const {drawerApi} = useContext(DialogWindowContextHolderContext);
    const {data: boxHistory} = useBoxHistory({code});

    const {data} = useGetBoxInfoResult({code, refetchOnMount: false});

    const boxTodoActions = data?.actions;

    const onClick = () => {
        drawerApi.open({
            key: ApplicationMap.REPORT_BOX_Action,
            anchor: "right",
            children: (
                <WashBoxContent code={code} />
            )
        })
    }

    return (
        <BoxAction
            onClick={onClick}
            code={code}
            event={"cleaned"}
            label={ t('translations:boxStaff.reviewOptions.clean') }
            icon={<WashBoxIcon/>}
            done={boxHistory?.currentActions?.['cleaned'] }
            requested={boxTodoActions['clean'] === "planned"}
        />
    )
}
