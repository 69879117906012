import {PageTemplate} from "@src/components/core/PageTemplate/PageTemplate";

import styles from "./BoxInfo.module.scss";

import bg from "@src/assets/img_box.png"
import {Button} from "@src/components/shared/form/Button/Button";
import {Space} from "@src/components/shared/Space/Space";
import {Alert, Chip, Typography} from "@mui/material";
import {useGetBoxInfoResult} from "@src/services/box/useGetBoxInfo";
import React, {useContext, useEffect} from "react";
import {
    DialogWindowContextHolderContext
} from "@src/components/context/DialogWindowContextHolder/DialogWindowContextHolder.context";
import {ApplicationMap} from "@src/core/applicationMap";
import {ReportBoxBody} from "@src/components/core/pages/BoxInfo/ReportBoxBody/ReportBoxBody";
import {useForm} from "react-hook-form";
import {BoxIssues} from "@src/components/shared/BoxIssues/BoxIssues";
import {TBoxIssue} from "@src/components/shared/BoxIssues/BoxIssuesNames";
import {useCheckInBox, useDispatchBox} from "@src/services/box/useDispatchBox";
import {FormCore} from "@src/components/shared/form/Form/Form";
import {useDialogWindow} from "@src/utils/hooks/contextMenu/useDialogWindow";
import {useIsLoggedIn} from "@src/services/auth/useIsLoggedIn";
import { useTranslation } from "react-i18next";
import {useTranslationCascade} from "@src/utils/hooks/i18n/useTranslationCascade";
import {CheckIcon} from "@src/components/icons/core/CheckIcon";
import {useQueryClient} from "@tanstack/react-query";
import {ReturnBox} from "@src/components/core/pages/BoxInfo/ReturnBox/ReturnBox";


// small_defect
// visual_damage
// mechanical_damage

const BoxInfo = ({code}: { code: string }) => {
    const {data} = useGetBoxInfoResult({code});
    const {snackbarApi} = useContext(DialogWindowContextHolderContext);
    const methods: any = useForm({
        values: {
            issues: data?.issuesObj
        }
    });

    const {openDialog, leaveOnly} = useDialogWindow();

    const {t} = useTranslationCascade();

    const {mutateAsync} = useCheckInBox({code});

    useEffect(() => {
        mutateAsync();
    }, []);

    const {mutateAsync: dispatchBoxMutation} = useDispatchBox({code});

    const onBoxIssues = () => {
        openDialog({
            key: ApplicationMap.BOX_ISSUES,
            children: (
                <ReportBoxBody methods={methods} code={code}/>
            )
        })
    }

    const {data: userInfo} = useIsLoggedIn();

    const issuesWatchValue = methods.watch('issues')
    const issues  = issuesWatchValue ? Object.entries(issuesWatchValue).reduce((acc: any, [key, value]: any) => {

        if (value) {
            return [...acc, key]
        }

        return acc;
    }, []) as TBoxIssue[] : [];

    const onConfirm = () => {
        // if (issues?.length > 0) {
        //     openDialog({
        //         key: ApplicationMap.BOX_ISSUES,
        //         children: (
        //             <ReturnBox code={code} />
        //         )
        //     });
        //
        //     return;
        // }

        dispatchBoxMutation();

        leaveOnly(ApplicationMap.SCANNER);

        // TODO: temp solution
        if (userInfo.location === "NEVEON" && userInfo.role === "user") {
            snackbarApi.open({
                key: "BOX_COMPLETE",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center"
                },
                autoHideDuration: 4000,
                children: (
                    <Alert severity="success" icon sx={{ width: '100%' }}>
                        {t("translations:boxUser.dispatchedNotification", {code})}
                    </Alert>
                )
            });
        }
    }

    return (
        <PageTemplate>
            <div className={styles.BoxInfoPage}>
                <div className={styles.topBar}>
                    {/*<FoundBoxPlaceholder />*/}
                    <div className={styles.boxPlaceholder} style={{backgroundImage: `url(${bg})`}}>

                    </div>
                    <Space direction={"vertical"} padding={0}>
                        <Space padding={[0, 16, 0, 16]} overflow>
                            <Typography variant="h5" gutterBottom sx={{color: 'white', textAlign: 'center'}}>
                                {data?.code}
                            </Typography>
                        </Space>
                        <Space padding={0} overflow>
                            <Space padding={[0, 16, 0, 16]} overflow>
                                <FormCore methods={methods} onSubmit={() => {}}>
                                    <BoxIssues issues={issues} code={code} />
                                </FormCore>
                            </Space>
                        </Space>
                    </Space>
                </div>
                <div className={styles.bottomBar}>
                    <Space direction={"vertical"}>
                        <Button onClick={onConfirm} icon={<CheckIcon />}>
                            { t('translations:boxUser.dispatchBoxButton') }
                        </Button>
                        <Button variant={"outlined"} onClick={onBoxIssues}>
                            { t("translations:boxUser.manageBoxIssues") }
                        </Button>
                    </Space>
                </div>
            </div>
        </PageTemplate>
    )
}

export default BoxInfo;
