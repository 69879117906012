import React, {PropsWithChildren} from "react";

import dayjs from "dayjs";
import classNames from "classnames";
import {Typography} from "@mui/material";

import {BoxEvent, EventType, TransformedGroup} from "@src/services/box/useBoxHistory";
import {BoxHistoryHasIssues} from "@src/components/icons/boxHistory/BoxHistoryHasIssues";
import {BoxHistoryHasNoIssues} from "@src/components/icons/boxHistory/BoxHistoryHasNoIssues";
import {BoxHistoryIconBase} from "@src/components/core/pages/BoxHistory/components/BoxHistoryItem/BoxHistoryIconBase";
import {BoxRegistered} from "@src/components/icons/boxHistory/BoxRegistered";
import {useTranslationCascade} from "@src/utils/hooks/i18n/useTranslationCascade";

import styles from "./BoxHistoryItem.module.scss";
import {BoxRecycledIcon} from "@src/components/icons/boxHistory/BoxRecycledIcon";
import {
    ViewPhotoItem,
    ViewPhotoItemUploaded
} from "@src/components/shared/PhotoReport/components/ViewPhotoItem/ViewPhotoItem";
import {useDialogWindow} from "@src/utils/hooks/contextMenu/useDialogWindow";
import {Space} from "@src/components/shared/Space/Space";
import {ViewImageComponent} from "@src/components/shared/PhotoReport/components/ViewImageComponent/ViewImageComponent";


export const BoxHistoryRegistered = ({registered}: { registered: BoxEvent }) => {
    const {t} = useTranslationCascade();

    return (
        <div className={styles.BoxHistoryItemWrapper}>
            <table className={styles.BoxHistoryItem}>
                <tr>
                    <td className={styles.circleNumber}>
                        <div className={styles.iteration}>1</div>
                    </td>
                    <td className={styles.circleType}>
                        <BoxRegistered/>
                    </td>
                    <td className={styles.circleLabel}>
                        <div className={styles.circleLabelWrapper}>
                            <Typography gutterBottom sx={{color: 'white', textAlign: 'left', margin: 0}}>
                                {t('translations:box.history.statement.registered')}
                            </Typography>
                            <Typography gutterBottom
                                        sx={{color: 'white', textAlign: 'left', fontSize: '10px', margin: 0}}>
                                {dayjs(registered?.date).format("DD.MM.YYYY")}
                            </Typography>
                        </div>
                    </td>
                </tr>
            </table>
        </div>
    )
}

export const BoxHistoryItem = ({iteration, actions}: PropsWithChildren<{
    iteration: number;
    actions: TransformedGroup
}>) => {
    let eventActions = actions?.actions.filter(({type}) => type !== "checked_in");
    const recycledEvent = eventActions.find(({type}) => type === "recycled");
    const photoEventsEvent = eventActions.filter(({type}) => type === "photo");

    if (photoEventsEvent?.length > 0) {
        eventActions = eventActions.filter(({type}) => type !== "photo");
    }

    if (!!recycledEvent) {
        eventActions = eventActions.filter(({type}) => type !== "recycled");
    }

    const boxHasIssues = eventActions.length > 0;
    const {t} = useTranslationCascade();

    const reviewed = actions?.reviewed;

    const {openDialog} = useDialogWindow();

    const openImages = ({image}: {image: string}) => {
        openDialog({
            key: "PHOTO_REPORT_BOX_IMAGES",
            children: (
                <ViewImageComponent
                    src={`/api/v1/boxes/images/${image}`}
                    alt="Zoomable Image"
                    maxZoom={4}
                    minZoom={1}
                />
            )
        });
    }

    return (
        <>
            <div className={styles.BoxHistoryItemWrapper}>
                {
                    !!recycledEvent &&
                    <table className={classNames(styles.BoxHistoryItem, styles.recycled)}>
                        <tr>
                            <td className={styles.circleNumber}>
                                <div className={styles.iteration}>

                                </div>
                            </td>
                            <td className={styles.circleType}>
                                <BoxRecycledIcon/>
                            </td>
                            <td className={styles.circleLabel}>
                                <div className={styles.circleLabelWrapper}>
                                    <Typography gutterBottom sx={{color: 'white', textAlign: 'left', margin: 0}}>
                                        {t('translations:box.history.statement.recycled')}
                                    </Typography>
                                    <Typography gutterBottom
                                                sx={{color: 'white', textAlign: 'left', fontSize: '10px', margin: 0}}>
                                        {dayjs(recycledEvent.date).format("DD.MM.YYYY")}
                                    </Typography>
                                </div>
                            </td>
                        </tr>
                    </table>
                }
                <table className={styles.BoxHistoryItem}>

                    <tr>
                        <td className={styles.circleNumber}>
                            <div className={styles.iteration}>
                                {iteration}
                            </div>
                        </td>
                        <td className={styles.circleType}>
                            {
                                boxHasIssues || recycledEvent
                                    ? <BoxHistoryHasIssues/>
                                    : <BoxHistoryHasNoIssues/>
                            }
                        </td>
                        <td className={styles.circleLabel}>
                            <div className={styles.circleLabelWrapper}>
                            <Typography gutterBottom sx={{color: 'white', textAlign: 'left', margin: 0}}>
                                    {
                                        boxHasIssues || recycledEvent
                                            ? t('translations:box.history.statement.unacceptable')
                                            : t('translations:box.history.statement.acceptable')
                                    }
                                </Typography>
                                <Typography gutterBottom
                                            sx={{color: 'white', textAlign: 'left', fontSize: '10px', margin: 0}}>
                                    {dayjs(reviewed?.date).format("DD.MM.YYYY")}
                                </Typography>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td>
                            {
                                eventActions.length > 0 &&
                                <div className={styles.BoxHistoryTextBaseWrapper}>
                                    {
                                        eventActions.map(({type}, i) => {

                                            return (
                                                <div
                                                    className={classNames(styles.BoxHistoryTextBase, eventActions.length - 1 === i && styles.lastChild)}>
                                                    <div>{BoxHistoryIconBase[type as EventType] ?? ""}</div>
                                                    <div>
                                                        <Typography gutterBottom sx={{
                                                            color: 'white',
                                                            textAlign: 'left',
                                                            margin: 0,
                                                            fontSize: '13px'
                                                        }}>
                                                            {t('translations:box.history.' + type as EventType)}
                                                        </Typography>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            }
                            {
                                photoEventsEvent?.length > 0 &&
                                <div className={styles.attachedPhotos}>
                                    <div className={styles.attachedPhotosWrapper}>
                                        {
                                            photoEventsEvent.map((el: any) => {
                                                return (
                                                    <div className={styles.attachedPhotoItem} onClick={() => openImages({image: el.data.imageId})}>
                                                        <img src={`/api/v1/boxes/images/${el.data.imageId}`} alt="" height={30} width={30}/>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            }
                        </td>
                    </tr>
                </table>
            </div>
        </>
    );
};
