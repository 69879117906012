import {useMutation} from "@tanstack/react-query";
import {XhrBase} from "@src/core/xhr";
import {useHttpClient} from "@src/utils/hooks/xhr/useHttpClient";

export const useDispatchBox = ({code}: {code: string}) => {
    const {xhr} = useHttpClient();

    return useMutation({
        mutationFn: async (payload) => {
            await xhr.post({
                relativeUrl: XhrBase.DISPATCH_BOX({code}),
            });
        }
    })
}

export const useReturnBox = ({code}: {code: string}) => {
    const {xhr} = useHttpClient();

    return useMutation({
        mutationFn: async (payload) => {
            try {
                await xhr.post({
                    relativeUrl: XhrBase.RETURN_BOX({code}),
                });
            } catch (e) {
                console.log(e)
            }
        }
    })
}

export const useCheckInBox = ({code}: {code: string}) => {
    const {xhr} = useHttpClient();

    return useMutation({
        mutationFn: async (payload) => {
            try {
                await xhr.post({
                    relativeUrl: XhrBase.CHECK_IN_BOX({code}),
                });
            } catch (e) {
                console.log(e)
            }
        }
    })
}
