import {useResponsiveLayout} from "@src/utils/hooks/layout/useResponsiveLayout";
import {ReactElement, useContext} from "react";
import {
    DialogWindowContextHolderContext
} from "@src/components/context/DialogWindowContextHolder/DialogWindowContextHolder.context";


export const useDialogWindow = () => {
    const { isMobileDevice } = useResponsiveLayout();
    const {drawerApi, dialogApi} = useContext(DialogWindowContextHolderContext);

    const openDialog = ({key, children}: {key: string, children: ReactElement}) => {
        if (isMobileDevice) {
            drawerApi.open({
                key: key,
                anchor: "bottom",
                children: children
            })
        }

        if (!isMobileDevice) {
            dialogApi.open({
                key: key,
                children: children
            })
        }
    }

    const leaveOnly = (key: string) => {
        drawerApi.leaveOnly(key);
        dialogApi.leaveOnly(key);
    }

    const close = (key: string) => {
        if (isMobileDevice) {
            drawerApi.close(key);
        }

        if (!isMobileDevice) {
            dialogApi.close(key);
        }
    }

    return {openDialog, leaveOnly, close}
};
