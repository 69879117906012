import {TextField} from "@mui/material";
import {TextFieldProps} from "@mui/material/TextField/TextField";
import {RegisterOptions, useController, useFormContext} from "react-hook-form";

import styles from "./TextInput.module.scss";
import {getFieldValidationErrors} from "@src/utils/functions/form/form";
import {useFormSubmittedOnce} from "@src/utils/hooks/form/useFormSubmittedOnce";


export const TextInput = (props: TextFieldProps) => {

    return (
        <TextField
            className={styles.TextInput}
            {...props}
        />
    )
}


interface ITextInputControl {
    path: string;
    label?: string;
    validation?: RegisterOptions;
}

export const TextInputControl = ({path, label, validation, placeholder, type}: ITextInputControl & TextFieldProps) => {
    const {control} = useFormContext();
    const {field} = useController({name: path, control});

    const {isFormSubmittedOnce} = useFormSubmittedOnce();

    const { formState } = useFormContext();

    // @ts-ignore
    const isFieldRequired = validation?.required === true || validation?.required?.value === true;

    const error = !validation?.disabled && getFieldValidationErrors(formState.errors, path);


    return (
        <TextInput
            required={isFieldRequired}
            error={!!error}
            label={label}
            onChange={(e) => field.onChange(e)}
            onBlur={() => field.onBlur()}
            value={field.value}
            ref={field.ref}
            placeholder={placeholder}
            type={type}
        />
    )
}
