import React, {Suspense} from 'react';
import {BrowserRouter, useLocation} from "react-router-dom";

import {QueryDependencyContextProvider} from "@src/components/context/QueryDeppendencyContext/QueryDeppendencyContextProvider";
import {DialogWindowContextHolder} from "@src/components/context/DialogWindowContextHolder/DialogWindowContextHolder";
import {AppRouting} from "@src/components/core/AppRouting/AppRouting";
import {QueryClientProvider} from "@src/components/context/QueryClientProvider/QueryClientProvider";

import dayJs from "dayjs";

import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import customParseFormat from "dayjs/plugin/customParseFormat";
import duration from "dayjs/plugin/duration";
import advancedFormat from 'dayjs/plugin/advancedFormat'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'

dayJs.extend(utc);
dayJs.extend(timezone);
dayJs.extend(duration)
dayJs.extend(customParseFormat)
dayJs.extend(advancedFormat)
dayJs.extend(weekday)
dayJs.extend(localeData)
dayJs.extend(weekOfYear)
dayJs.extend(weekYear)


export const App = () => {
    return (
        <QueryClientProvider>
            <QueryDependencyContextProvider>
                <DialogWindowContextHolder>
                    <BrowserRouter>
                        <AppRouting />
                    </BrowserRouter>
                </DialogWindowContextHolder>
            </QueryDependencyContextProvider>
        </QueryClientProvider>
    );
}
