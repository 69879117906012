import { FormGroup, Typography} from "@mui/material";
import {Space} from "@src/components/shared/Space/Space";
import {Button} from "@src/components/shared/form/Button/Button";
import {ActionDrawerBody} from "@src/components/shared/ActionDrawerBody/ActionDrawerBody";
import {FieldValues, useForm, UseFormReturn} from "react-hook-form";
import {CheckboxControl} from "@src/components/shared/form/Checkbox/Checkbox";
import {FormCore} from "@src/components/shared/form/Form/Form";
import {ApplicationMap} from "@src/core/applicationMap";
import {TBoxIssue} from "@src/components/shared/BoxIssues/BoxIssuesNames";
import {usePatchBoxIssues} from "@src/services/box/usePatchBoxIssues";
import {BoxIssue} from "@src/core/xhr";
import {useDialogWindow} from "@src/utils/hooks/contextMenu/useDialogWindow";
import {useTranslationCascade} from "@src/utils/hooks/i18n/useTranslationCascade";


export const ReportBoxBody = ({methods, code}: {methods: UseFormReturn<FieldValues, any, undefined>, code: string}) => {
    const {close} = useDialogWindow();
    const {mutateAsync} = usePatchBoxIssues({code});
    const {t} = useTranslationCascade();

    const internalMethods: any = useForm({
        defaultValues: {
            issues: methods?.watch("issues") as any
        }
    });

    const onSubmit = (values: any) => {
        methods.setValue('issues', values.issues);

        const issuesWatchValue = methods.watch('issues')

        const issues  = issuesWatchValue ? Object.entries(issuesWatchValue).reduce((acc: any, [key, value]: any) => {

            if (value) {
                return [...acc, key]
            }

            return acc;
        }, []) as TBoxIssue[] : [];

        mutateAsync({issues: issues as BoxIssue[]});

        close(ApplicationMap.BOX_ISSUES);
    }

    return (
        <ActionDrawerBody
            title={
                <Typography variant="h6" gutterBottom sx={{color: 'white', textAlign: 'left'}}>
                    { t('translations:boxIssues.title') }
                </Typography>
            }
            description={(
                <Space padding={[0, 0, 16, 0]}>
                    <FormCore methods={internalMethods}>
                        <FormGroup>
                            <CheckboxControl label={ t('translations:boxIssues.options.small_defect') } path={`issues.small_defect`} />
                            <CheckboxControl label={ t('translations:boxIssues.options.mechanical_damage') } path={`issues.mechanical_damage`} />
                            <CheckboxControl label={ t('translations:boxIssues.options.visual_damage') } path={`issues.visual_damage`} />
                            <CheckboxControl label={ t('translations:boxIssues.options.unusable') } path={`issues.unusable`} />
                        </FormGroup>
                    </FormCore>
                </Space>
            )}
            action={
                <Button onClick={internalMethods.handleSubmit(onSubmit)}>
                    {t("translations:boxIssues.addIssues")}
                </Button>
            }
        />
    )
}
// sx={{
//     color: pink[800],
//
// }}
