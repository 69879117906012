import {ScannerContainer} from "@src/components/core/pages/Scanner/components/ScannerContainer/ScannerContainer";

import styles from "./Scanner.module.scss";
import {
    ManualEnterComponent
} from "@src/components/core/pages/Scanner/components/ManualEnterComponent/ManualEnterComponent";
import {CloseIcon} from "@src/components/icons/core/CloseIcon";
import {useContext} from "react";
import {ApplicationMap} from "@src/core/applicationMap";

import {DialogWindowContextHolderContext} from "@src/components/context/DialogWindowContextHolder/DialogWindowContextHolder.context";
import {IDetectedBarcode} from "@yudiel/react-qr-scanner";
import {Alert} from "@mui/material";
import {useGetBoxInfo} from "@src/services/box/useGetBoxInfo";
import {useOpenBox} from "@src/components/core/pages/Scanner/utils/useOpenBox";
import useStateRef from "react-usestateref";


const ScannerPage = () => {
    const {snackbarApi, drawerApi} = useContext(DialogWindowContextHolderContext);
    const isPaused= drawerApi.getIsOpenOneOf([ApplicationMap.BOX_INFO, ApplicationMap.BOX_INFO_ADMIN, ApplicationMap.MANUAL_ENTER]);
    const {mutateAsync} = useGetBoxInfo();
    const {openBox} = useOpenBox();
    const [, setLastScanned, lastScanned] = useStateRef<string>("");

    const closeScanner = () => {
        drawerApi.close(ApplicationMap.SCANNER)
    }

    const onScan = async (result: IDetectedBarcode[]) => {
        const foundCode = result[0].rawValue;

        if (lastScanned.current === foundCode) {
            return;
        }

        snackbarApi.open({
            key: "box_check",
            children: (
                <Alert severity="info" icon sx={{ width: '100%' }}>
                    ...Please Wait
                </Alert>
            )
        });

        const scanned = await mutateAsync({code: result[0].rawValue});

        snackbarApi.close("box_check")

        openBox({code: scanned.code});
    }

    return (
        <>
            <ScannerContainer isPaused={isPaused} onScan={onScan} />
            <div className={styles.closeIcon}>
                <button className={styles.actionButton} onClick={closeScanner}>
                    <CloseIcon/>
                </button>
            </div>
            <div className={styles.toolbar}>
                <ManualEnterComponent />
            </div>
        </>
    )
}


export default ScannerPage
