import {
    BoxActionContainer
} from "@src/components/shared/SaveBoxButton/components/BoxActionContainer/BoxActionContainer";
import {Space} from "@src/components/shared/Space/Space";
import {WashBoxIcon} from "@src/components/icons/BoxActions/WashBoxIcon";
import {Alert, Divider, Typography} from "@mui/material";
import {Button} from "@src/components/shared/form/Button/Button";
import React, {useContext} from "react";
import {
    DialogWindowContextHolderContext
} from "@src/components/context/DialogWindowContextHolder/DialogWindowContextHolder.context";
import {useTranslationCascade} from "@src/utils/hooks/i18n/useTranslationCascade";
import {DisposableDrawerContext} from "@src/utils/hooks/contextMenu/components/DisposableDrawer/DisposableDrawer";
import {ChangeQrCode} from "@src/components/icons/BoxActions/ChangeQrCode";
import {ApplicationMap} from "@src/core/applicationMap";
import {
    StartQrCodeChange
} from "@src/components/shared/SaveBoxButton/components/ReportBox/components/ChangeQrCodeBoxButton/ChangeQrCodeBoxContent/components/StartQrCodeChange/StartQrCodeChange";
import {useTodoMutation} from "@src/services/box/useTodoMutation";

export const ChangeQrCodeBoxContent = ({code}: {code: string}) => {
    const foundDrawerContext = useContext(DisposableDrawerContext);
    const {drawerApi} = useContext(DialogWindowContextHolderContext);
    const {t} = useTranslationCascade();
    const {mutateAsync: onTodo, isPending: todoPending} = useTodoMutation({code, action: 'relabel'});

    const onClick = () => {
        drawerApi.open({
            key: ApplicationMap.REPORT_BOX_Action,
            anchor: "right",
            children: (
                <StartQrCodeChange code={code} />
            )
        })
    }

    const onRequestRelabel = async () => {
        await onTodo();

        foundDrawerContext.onClose();
    }

    return (
        <BoxActionContainer
            title={ t('translations:boxStaff.reviewOptions.changeQrCode.title') }
            topBar={(
                <Space justify={"center"} direction={"vertical"} align={"center"} padding={0}>
                    <ChangeQrCode />
                    <Typography variant="h6" gutterBottom sx={{color: 'white', textAlign: 'center'}}>
                        {code}
                    </Typography>
                </Space>
            )}
            bottomBar={(
                <Space padding={0} direction={"vertical"}>
                    <Button onClick={onClick}>
                        {t("translations:boxStaff.reviewOptions.changeQrCode.startToChangeQrCode")}
                    </Button>
                    <Button variant={"outlined"} onClick={onRequestRelabel} loading={todoPending}>
                        {t("translations:boxStaff.reviewOptions.changeQrCode.requestQrCodeChange")}
                    </Button>
                </Space>
            )}
        />
    )
}
