import { Chip } from "@mui/material";
import { BoxIssueNames, TBoxIssue } from "@src/components/shared/BoxIssues/BoxIssuesNames";

import {useFormContext} from "react-hook-form";
import {usePatchBoxIssues} from "@src/services/box/usePatchBoxIssues";
import {BoxIssue} from "@src/core/xhr";

import styles from "./BoxIssues.module.scss";
import {useTranslationCascade} from "@src/utils/hooks/i18n/useTranslationCascade";


const boxIssuesColors = {
    small_defect: "#FFA726",
    visual_damage: "#29B6F6",
    mechanical_damage: "#F44336",
    unusable: "#E6ED19",
};

export const BoxIssues = ({ issues, code, closable = true }: { issues: TBoxIssue[], code: string, closable?: boolean }) => {
    const methods = useFormContext();
    const {mutateAsync} = usePatchBoxIssues({code});

    const onDelete = (() => {
        if (closable) {
            return (deleteItem: string) => async (e: any) => {

                methods.setValue(`issues.${deleteItem}`, undefined);
                const issuesWatchValue = methods.watch('issues')

                const issues  = issuesWatchValue ? Object.entries(issuesWatchValue).reduce((acc: any, [key, value]: any) => {

                    if (value) {
                        return [...acc, key]
                    }

                    return acc;
                }, []) as TBoxIssue[] : [];

                mutateAsync({issues: issues as BoxIssue[]});
            }
        }

        return (deleteItem: string) => undefined
    })()
    const {t} = useTranslationCascade();

    return (
        <div className={styles.BoxIssues}>
            {
                issues.map((issue) => (
                    <Chip
                        key={issue} // Adding a key to avoid React warnings
                        label={ t('translations:boxIssues.options.' + issue) }
                        size="small"
                        sx={{ background: boxIssuesColors[issue], textOverflow: "none" }}
                        onDelete={onDelete(issue)}
                    />
                ))
            }
        </div>
    );
};
