import useStateRef from "react-usestateref";
import { v4 as uuidv4 } from 'uuid';
import {PropsWithChildren} from "react";

import { QueryDependencyContext } from "@src/components/context/QueryDeppendencyContext/QueryDeppendencyContext";


export const QueryDependencyContextProvider = ({children}: PropsWithChildren) => {

    const [, setDepps, deppsRef] = useStateRef(new Map());

    const deppFor = (depp: string) => {
        if (!deppsRef.current.has(depp)) {
            updateDepp(depp);
        }

        return deppsRef.current.get(depp);
    }

    const updateDepp = (depp: string) => {
        const updatedDependencies = new Map(deppsRef.current);

        updatedDependencies.set(depp, `${depp}_${uuidv4()}`);

        setDepps(updatedDependencies);
    }


    return (
        <QueryDependencyContext.Provider
            value={{
                deppFor,
                updateDepp
            }}
        >
            {children}
        </QueryDependencyContext.Provider>
    )
}
