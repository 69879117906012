import React, {ReactNode} from "react";

import {Alert, Button} from "@mui/material";

import {useTranslationCascade} from "@src/utils/hooks/i18n/useTranslationCascade";

import styles from "./BoxBoxPendingTodos.module.scss";
import {
    CleanTodoButton
} from "@src/components/core/pages/BoxInfoSuperAdmin/components/BoxBoxPendingTodos/components/CleanTodoButton/CleanTodoButton";
import {useGetBoxInfoResult} from "@src/services/box/useGetBoxInfo";
import {
    RepairBoxTodoButton
} from "@src/components/core/pages/BoxInfoSuperAdmin/components/BoxBoxPendingTodos/components/RepairBoxTodoButton/RepairBoxTodoButton";
import {
    RelabelBoxTodoButton
} from "@src/components/core/pages/BoxInfoSuperAdmin/components/BoxBoxPendingTodos/components/RelabelBoxTodoButton/RelabelBoxTodoButton";


export const BoxBoxPendingTodos = ({code}: { code: string }) => {
    const {t} = useTranslationCascade();

    const {data} = useGetBoxInfoResult({code, refetchOnMount: false});

    const boxTodoActions = data?.actions;

    const isOneOfTodosDefined = (Object.values(boxTodoActions) as string[]).some((el: string) => el === "planned")

    return (
        <div className={styles.BoxBoxPendingTodos}>
            {
                isOneOfTodosDefined &&
                <Alert variant="filled" severity="warning" sx={{width: '100%'}}>
                    {t("translations:boxStaff.todosRequested")}
                </Alert>
            }

            {
                boxTodoActions['clean'] === "planned" &&
                <CleanTodoButton code={code} />
            }
            {
                boxTodoActions['repair'] === "planned" &&
                <RepairBoxTodoButton code={code} />
            }
            {
                boxTodoActions['relabel'] === "planned" &&
                <RelabelBoxTodoButton code={code} />
            }
            {/*<BoxBoxPendingTodoItem code={code} label={"Clean"} icon={<BoxCleaned />}/>*/}
            {/*<BoxBoxPendingTodoItem code={code} label={"Change QR Code"} icon={<QrCodeChanged />}/>*/}
            {/*<BoxBoxPendingTodoItem code={code} label={"Repair"} icon={<BoxRepaired />}/>*/}
        </div>
    )
}
