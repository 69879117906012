import React, {useContext} from "react";
import {Alert, Typography} from "@mui/material";

import {PageTemplate} from "@src/components/core/PageTemplate/PageTemplate";

import {WashBoxButton} from "@src/components/shared/SaveBoxButton/components/ReportBox/components/WashBoxButton/WashBoxButton";
import {RepairBoxButton} from "@src/components/shared/SaveBoxButton/components/ReportBox/components/RepairBoxButton/RepairBoxButton";
import {RecycleBoxButton} from "@src/components/shared/SaveBoxButton/components/ReportBox/components/RecycleBoxButton/RecycleBoxButton";
import {ChangeQrCodeBoxButton} from "@src/components/shared/SaveBoxButton/components/ReportBox/components/ChangeQrCodeBoxButton/ChangeQrCodeBoxButton";
import {ApplicationMap} from "@src/core/applicationMap";
import {useDialogWindow} from "@src/utils/hooks/contextMenu/useDialogWindow";
import {useTranslationCascade} from "@src/utils/hooks/i18n/useTranslationCascade";
import {useCompleteBoxReview} from "@src/services/box/useCompleteBoxReview";
import {SaveBoxButton} from "@src/components/shared/SaveBoxButton/SaveBoxButton";

import {useGetBoxInfoResult} from "@src/services/box/useGetBoxInfo";
import {DialogWindowContextHolderContext} from "@src/components/context/DialogWindowContextHolder/DialogWindowContextHolder.context";

import styles from "./ReportBox.module.scss";


export const ReportBox = ({code}: {code: string}) => {
    const {t} = useTranslationCascade();
    const {leaveOnly} = useDialogWindow();
    const {data} = useGetBoxInfoResult({code});
    const {snackbarApi} = useContext(DialogWindowContextHolderContext);

    const submitReviewMutation = useCompleteBoxReview({
        code: data.code,
        onSuccess: async () => {
            snackbarApi.open({
                key: "BOX_COMPLETE",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center"
                },
                autoHideDuration: 4000,
                children: (
                    <Alert severity="success" icon sx={{ width: '100%' }}>
                        {t("translations:boxStaff.boxReviewed", {code: data.code})}
                    </Alert>
                )
            });

            leaveOnly(ApplicationMap.SCANNER);
        }
    })

    return (
        <PageTemplate>
            <div className={styles.ReportBox}>
                <div className={styles.ReportBoxTitle}>
                    <Typography variant="h5" gutterBottom sx={{color: 'white', textAlign: 'center', marginBottom: "20px"}}>
                        { t('translations:boxStaff.reviewOptions.title') }
                    </Typography>
                    {/*<Alert*/}
                    {/*    severity="info"*/}
                    {/*    variant="filled"*/}
                    {/*    sx={{ width: '100%' }}*/}
                    {/*>*/}
                    {/*    Please complete at least one option to continue.*/}
                    {/*</Alert>*/}
                </div>
                <div className={styles.ReportBoxActions}>
                    <WashBoxButton code={code} />
                    <RepairBoxButton code={code} />
                    <ChangeQrCodeBoxButton code={code} />
                    <RecycleBoxButton code={code} />
                </div>
                <div>
                    <SaveBoxButton
                        mutation={submitReviewMutation}
                        title={ t('translations:boxStaff.report.boxReviewConfirmTitle') }
                        message={ t('translations:boxStaff.report.boxReviewConfirmMessage') }

                        submitContent={ t('translations:boxStaff.report.submitButton') }
                    />
                </div>
            </div>
        </PageTemplate>
    )
}
