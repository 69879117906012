import axios, {AxiosRequestConfig} from "axios";
import qs from "qs";

import {prepareUrl, setDefaultResponseType} from "@src/utils/functions/xhr/xhrFunctions";
import {useXhrMethods} from "@src/utils/hooks/xhr/useXhrMethods";


export interface IRequestBase {
    relativeUrl: string;
    absoluteUrl?: string;
    payload?: any;
    config: AxiosRequestConfig;
    errorWhitelist?: (number | null)[];
    apiVersion?: number | 'default';
    retry?: number;
    responseType?: 'json';
    headers: any;
}

export interface IXhrMethods {
    get: CallableFunction;
    post: CallableFunction;
    delete: CallableFunction;
    put: CallableFunction;
    patch: CallableFunction;
    options: CallableFunction;
}

export {useHttpClient};

axios.defaults.paramsSerializer = {
    serialize: (params: Record<string, any>) => {
        return qs.stringify(params, { indices: false }); // param=value1&param=value2
    }
};

/*
* Usage:
*
* */
function useHttpClient(): {xhr: IXhrMethods} {

    const xhr: IXhrMethods = {} as IXhrMethods;

    const {preparePayload, refreshAccess} = useXhrMethods(xhr);

    xhr.get = async (props: IRequestBase) => {
        setDefaultResponseType(props);
        preparePayload(props);

        if (!props.config) {
            props.config = {};
        }

        try {
            return await axios.get(
                prepareUrl(props),
                {
                    params: props.payload,
                    responseType: props.responseType,
                    withCredentials: true,
                    ...props.config
                },
            );
        } catch (e) {
            await refreshAccess(e, "get", props);

            return;
        }
    }

    xhr.options = async (props: IRequestBase) => {
        setDefaultResponseType(props);
        preparePayload(props);

        if (!props.config) {
            props.config = {};
        }

        try {
            return await axios.options(
                prepareUrl(props),
                {
                    params: props.payload,
                    responseType: props.responseType,
                    withCredentials: true,
                    ...props.config
                },
            );
        } catch (e) {
            await refreshAccess(e, "get", props);

            return;
        }
    }

    xhr.delete = async (props: IRequestBase) => {
        setDefaultResponseType(props);
        preparePayload(props);

        try {
            return await axios.delete(
                prepareUrl(props),
                {
                    params: props.payload,
                    responseType: props.responseType,
                    withCredentials: true,
                }
            );
        } catch (e) {
            await refreshAccess(e, "delete", props);

            return;
        }
    }

    xhr.post = async (props: IRequestBase) => {
        setDefaultResponseType(props);
        preparePayload(props);

        if (!props.config) {
            props.config = {};
        } 

        try {
            return await axios.post(
                prepareUrl(props),
                props.payload,
                {
                    withCredentials: true,
                    ...props.config
                }
            );
        } catch (e) {
            await refreshAccess(e, "post", props);

            return;
        }

    }

    xhr.put = async (props: IRequestBase) => {
        setDefaultResponseType(props);
        preparePayload(props)

        if (!props.config) {
            props.config = {};
        }

        try {
            return await axios.put(
                prepareUrl(props),
                props.payload,
                props.config
            );
        } catch (e) {
            await refreshAccess(e, "put", props);

            return;
        }
    }

    xhr.patch = async (props: IRequestBase) => {
        setDefaultResponseType(props);
        preparePayload(props);

        if (!props.config) {
            props.config = {};
        }

        try {
            return await axios.patch(
                prepareUrl(props),
                props.payload,
                {
                    withCredentials: true,
                    ...props.config
                }
            );
        } catch (e) {
            await refreshAccess(e, "patch", props);

            return;
        }
    }

    return {xhr}
}
