import {BoxActionContainer} from "@src/components/shared/SaveBoxButton/components/BoxActionContainer/BoxActionContainer";
import {Space} from "@src/components/shared/Space/Space";
import {Typography} from "@mui/material";
import {Button} from "@src/components/shared/form/Button/Button";
import {useContext} from "react";
import {DisposableDrawerContext} from "@src/utils/hooks/contextMenu/components/DisposableDrawer/DisposableDrawer";
import {BoxWashedIcon} from "@src/components/icons/BoxActions/BoxWashedIcon";
import {CheckIcon} from "@src/components/icons/core/CheckIcon";
import {useCommitActionForBox} from "@src/services/box/useCommitActionForBox";
import {useTranslationCascade} from "@src/utils/hooks/i18n/useTranslationCascade";
import {useGetBoxInfoResult} from "@src/services/box/useGetBoxInfo";


export const RepairComplete = ({code}: {code: string}) => {
    const foundDrawerContext = useContext(DisposableDrawerContext);
    const {mutateAsync, isPending} = useCommitActionForBox({action: "repair", code});
    const {refetch, isFetching} = useGetBoxInfoResult({code, refetchOnMount: false});
    const {t} = useTranslationCascade();

    const onComplete = async () => {
        await mutateAsync();
        await refetch();
        foundDrawerContext.onClose();
    }

    return (
        <BoxActionContainer
            title={ t('translations:boxStaff.reviewOptions.repairOption.completeBoxRepairTitle') }
            topBar={(
                <Space justify={"center"} direction={"vertical"} align={"center"} padding={0}>
                    <BoxWashedIcon />
                    <Typography variant="h6" gutterBottom sx={{color: 'white', textAlign: 'center'}}>
                        {code}
                    </Typography>
                </Space>
            )}
            bottomBar={(
                <Space padding={0} direction={"vertical"}>
                    <Button icon={<CheckIcon />} loading={isPending || isFetching} onClick={onComplete}>
                        { t('translations:boxStaff.reviewOptions.repairOption.completeBoxRepairSubmitButton') }
                    </Button>
                    <Button variant={"outlined"} onClick={() => foundDrawerContext.onClose()}>
                        { t('translations:boxStaff.reviewOptions.repairOption.completeBoxRepairCancel') }
                    </Button>
                </Space>

            )}
        />
    )
}
