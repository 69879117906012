import React, {useContext} from "react";

import {useTranslationCascade} from "@src/utils/hooks/i18n/useTranslationCascade";
import {BoxActionContainer} from "@src/components/shared/SaveBoxButton/components/BoxActionContainer/BoxActionContainer";
import {Typography} from "@mui/material";
import {Space} from "@src/components/shared/Space/Space";
import {Button} from "@src/components/shared/form/Button/Button";
import {DisposableDrawerContext} from "@src/utils/hooks/contextMenu/components/DisposableDrawer/DisposableDrawer";
import {useRelabelBox} from "@src/services/box/useRelabelBox";
import {useGetBoxInfoResult} from "@src/services/box/useGetBoxInfo";
import {SaveBoxButton} from "@src/components/shared/SaveBoxButton/SaveBoxButton";
import {
    DialogWindowContextHolderContext
} from "@src/components/context/DialogWindowContextHolder/DialogWindowContextHolder.context";
import {ApplicationMap} from "@src/core/applicationMap";
import {XhrBase} from "@src/core/xhr";
import {useHttpClient} from "@src/utils/hooks/xhr/useHttpClient";
import {useQueryClient} from "@tanstack/react-query";
import {EventType, transformData} from "@src/services/box/useBoxHistory";
import {QueryDependencyContext} from "@src/components/context/QueryDeppendencyContext/QueryDeppendencyContext";


export const CompleteQrCodeRenew = ({code, nextCode}: {code: string, nextCode: string}) => {
    const {t} = useTranslationCascade();
    const foundDrawerContext = useContext(DisposableDrawerContext);
    const {drawerApi} = useContext(DialogWindowContextHolderContext);
    const {xhr} = useHttpClient();
    const query = useQueryClient();
    const {deppFor}  = useContext(QueryDependencyContext);

    const relabelMutation = useRelabelBox({
        code,
        nextCode,
        onSuccess: async () => {
            const nextBox = await xhr.get({
                relativeUrl: XhrBase.BOX_GET_BY_CODE({code: nextCode})
            });

            query.setQueryData(['FOUND_BOX', code], () => {

                nextBox.data.issuesObj = nextBox.data?.issues?.reduce((acc: Record<string, boolean>, el: string) => {
                    return { ...acc, [el]: true }
                }, {});

                return {...nextBox.data}
            });

            const { data } = await xhr.get({
                relativeUrl: XhrBase.BOX_HISTORY({ code: nextCode }),
            });

            query.setQueryData([deppFor("useBoxHistory"), code], () => {
                // Filter out unwanted "edited" events
                const filteredData = data.filter(({ type }: { type: EventType }) => type !== "edited");

                // Transform the data into the desired format
                const groupedData = transformData(filteredData);

                return groupedData;
            })

            drawerApi.close(ApplicationMap.REPORT_BOX_Action);
        }
    });

    return (
        <BoxActionContainer
            title={ t('translations:boxStaff.reviewOptions.changeQrCode.completeQrCodeChangeTitle') }
            topBar={(
                <Space justify={"center"} direction={"vertical"} align={"center"} padding={0}>
                    <Typography variant="h6" gutterBottom sx={{color: 'white', textAlign: 'center'}}>
                        {code}
                    </Typography>
                    <Typography variant="h6" gutterBottom sx={{color: 'white', fontSize: '14px', textAlign: 'center'}}>
                        { t('translations:boxStaff.reviewOptions.changeQrCode.completeQrCodeChangeChangingto') }
                    </Typography>
                    <Typography variant="h6" gutterBottom sx={{color: 'white', textAlign: 'center'}}>
                        {nextCode}
                    </Typography>
                </Space>
            )}
            bottomBar={(
                <Space padding={0} direction={"vertical"}>
                    <SaveBoxButton
                        mutation={relabelMutation}
                        title={ t('translations:boxStaff.reviewOptions.changeQrCode.completeQrCodeConfirmTitle') }
                        message={ t('translations:boxStaff.reviewOptions.changeQrCode.completeQrCodeConfirmMessage') }

                        submitContent={ t('translations:boxStaff.reviewOptions.changeQrCode.completeQrCodeConfirmConfirmButton') }
                    />
                    <Button variant={"outlined"} onClick={() => foundDrawerContext.onClose()}>
                        { t('translations:boxStaff.reviewOptions.changeQrCode.completeQrCodeConfirmCancelButton') }
                    </Button>
                </Space>
            )}
        />
    )
}
