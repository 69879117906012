import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from "./translations/en/translations.json"
import de from "./translations/de/translations.json"
import tr from "./translations/tr/translations.json"
import ua from "./translations/ua/translations.json"

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        // the translations
        // (tip move them in a JSON file and import them,
        // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
        resources: {
            en: { translations: en },
            de: { translations: de },
            tr: { translations: tr },
            ua: { translations: ua },
        },
        // lng: "en", // if you're using a language detector, do not define the lng option
        fallbackLng: localStorage.getItem('i18nextLng') ?? 'de',
        ns: ['translations'], // Add your namespaces here
        defaultNS: 'translations',
        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });
