import {
    BoxActionContainer
} from "@src/components/shared/SaveBoxButton/components/BoxActionContainer/BoxActionContainer";
import {Space} from "@src/components/shared/Space/Space";
import {WashBoxIcon} from "@src/components/icons/BoxActions/WashBoxIcon";
import {FormGroup, Typography} from "@mui/material";
import {Button} from "@src/components/shared/form/Button/Button";
import React, {useCallback, useContext} from "react";
import {DisposableDrawerContext} from "@src/utils/hooks/contextMenu/components/DisposableDrawer/DisposableDrawer";
import {
    DialogWindowContextHolderContext
} from "@src/components/context/DialogWindowContextHolder/DialogWindowContextHolder.context";
import {ApplicationMap} from "@src/core/applicationMap";
import {
    RecycleConfirm
} from "@src/components/shared/SaveBoxButton/components/ReportBox/components/RecycleBoxButton/components/RecycleContent/components/RecycleConfirm/RecycleConfirm";
import {FormCore} from "@src/components/shared/form/Form/Form";
import {CheckboxControl} from "@src/components/shared/form/Checkbox/Checkbox";
import {BoxIssueNames, TBoxIssue} from "@src/components/shared/BoxIssues/BoxIssuesNames";
import {useForm} from "react-hook-form";

import styles from "./RecycleContent.module.scss";
import {useTranslationCascade} from "@src/utils/hooks/i18n/useTranslationCascade";
import {useDialogWindow} from "@src/utils/hooks/contextMenu/useDialogWindow";
import {PhotoReport} from "@src/components/shared/PhotoReport/PhotoReport";
import useStateRef from "react-usestateref";
import {PhotoReportImageBlob} from "@src/utils/blob/base64ToBinary";
import {AddPhotoIcon} from "@src/components/icons/AddPhotoIcon";
import {CloseIcon} from "@src/components/icons/core/CloseIcon";


export const RecycleContent = ({code}: {code: string}) => {
    const foundDrawerContext = useContext(DisposableDrawerContext);
    const {drawerApi} = useContext(DialogWindowContextHolderContext);
    const {t} = useTranslationCascade();
    const [, setImages, images] = useStateRef<Map<string, PhotoReportImageBlob>>(new Map([]));

    const internalMethods = useForm();
    const {openDialog} = useDialogWindow();

    const onClick = () => {
        const issuesWatchValue = internalMethods.watch('issues');

        const issues  = issuesWatchValue ? Object.entries(issuesWatchValue).reduce((acc: any, [key, value]: any) => {

            if (value) {
                return [...acc, key]
            }

            return acc;
        }, []) as TBoxIssue[] : [];

        openDialog({
            key: ApplicationMap.REPORT_BOX_Action,
            children: (
                <RecycleConfirm code={code} issues={issues} images={images.current} />
            )
        })
    }

    const setPhotos = (photos: any) => {
        setImages(new Map(photos))
    }

    const openPhotoReport = () => {
        drawerApi.open({
            key: "PHOTO_REPORT_BOX",
            anchor: 'right',
            children: (
                <PhotoReport onComplete={setPhotos} images={images.current} />
            )
        });
    }

    const onDeleteImage = (id: string) => {
        setImages((prevImages: any) => {
            prevImages.delete(id);

            return new Map(prevImages);
        });
    }

    return (
        <BoxActionContainer
            title={t("translations:boxStaff.reviewOptions.recycleOption.title")}
            topBar={(
                <div className={styles.RecycleContent}>
                    <div className={styles.RecycleContentWrapper}>
                        <div className={styles.addPhoto} onClick={openPhotoReport}>
                            <AddPhotoIcon />
                        </div>
                        <FormCore methods={internalMethods}>
                            <FormGroup>
                                <CheckboxControl label={ t('translations:boxIssues.options.small_defect') } path={`issues.small_defect`} />
                                <CheckboxControl label={ t('translations:boxIssues.options.mechanical_damage') } path={`issues.mechanical_damage`} />
                                <CheckboxControl label={ t('translations:boxIssues.options.visual_damage') } path={`issues.visual_damage`} />
                                <CheckboxControl label={ t('translations:boxIssues.options.unusable') } path={`issues.unusable`} />
                            </FormGroup>
                        </FormCore>
                    </div>
                    {
                        images.current && images.current.size > 0 && <>
                            <Typography variant="h5" gutterBottom sx={{color: 'white', textAlign: 'center', marginTop: '32px'}}>
                                {t("translations:photoReport.attachedPhotos")}
                            </Typography>
                            <div className={styles.RecycleContentIcons}>
                                <Space overflow direction={"horizontal"}>
                                    {
                                        Array.from(images.current).map(([id, blod]) => {

                                            return (
                                                <div className={styles.RecycleContentIcon}>
                                                    <div onClick={() => onDeleteImage(id)} className={styles.closeIcon}>
                                                        <CloseIcon/>
                                                    </div>

                                                    <img src={URL.createObjectURL(blod)} alt={id}/>
                                                </div>
                                            )
                                        })
                                    }
                                </Space>
                            </div>
                        </>
                    }
                </div>
            )}
            bottomBar={(
                <Space padding={0} direction={"vertical"}>
                    {/*<Button onClick={openPhotoReport}>*/}
                    {/*    Attach photos*/}
                    {/*</Button>*/}
                    <Button onClick={onClick}>
                        {t("translations:boxStaff.reviewOptions.recycleOption.startRecycle")}
                    </Button>
                    {/*<Button variant={"outlined"} onClick={() => foundDrawerContext.onClose()}>*/}
                    {/*    {t("translations:boxStaff.reviewOptions.recycleOption.requestRecycle")}*/}
                    {/*</Button>*/}
                </Space>
            )}
        />
    )
}
