import React, {PropsWithChildren, ReactNode, useContext} from "react";
import {DisposableDrawerContext} from "@src/utils/hooks/contextMenu/components/DisposableDrawer/DisposableDrawer";
import {
    DialogWindowContextHolderContext
} from "@src/components/context/DialogWindowContextHolder/DialogWindowContextHolder.context";
import {CloseIcon} from "@src/components/icons/core/CloseIcon";

import styles from "./ActionDrawerBody.module.scss";
import {Typography} from "@mui/material";


export const ActionDrawerBody = ({description, title, action, message}: {title?: ReactNode; action?: ReactNode; description?: ReactNode; message?: ReactNode}) => {
    const foundDrawerContext = useContext(DisposableDrawerContext);
    const {drawerApi} = useContext(DialogWindowContextHolderContext);

    const foundDrawerKey = foundDrawerContext?.config?.key;

    const onClose = () => {
        drawerApi.close(foundDrawerKey!)
    }

    return (
        <div className={styles.ActionDrawerBody}>
            {
                title &&
                <div className={styles.ActionDrawerBodyTitle}>
                    {
                        typeof title === "string"
                        ? (
                            <Typography variant="h6" gutterBottom sx={{color: 'white', textAlign: 'left'}}>
                                {title}
                            </Typography>
                        )
                        : (
                            title
                        )
                    }
                    {
                        foundDrawerKey &&
                        <div className={styles.closeIcon}>
                            <button className={styles.actionButton} onClick={onClose}>
                                <CloseIcon/>
                            </button>
                        </div>
                    }
                </div>
            }

            {
                message &&
                <div className={styles.message}>
                    {message}
                </div>
            }


            <div className={styles.ActionDrawerBodyWrapper}>
                <div className={styles.ActionDrawerBodyWrapperContent}>
                    {
                        typeof description === "string"
                        ? (
                            <Typography gutterBottom sx={{color: 'white', textAlign: 'left'}}>
                                {description}
                            </Typography>
                        )
                        : description
                    }
                </div>

                {
                    action &&
                    <div className={styles.ActionDrawerBodyWrapperContentFooter}>
                        {action}
                    </div>
                }
            </div>
        </div>
    )
}
