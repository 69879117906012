import {useMutation} from "@tanstack/react-query";
import {TAction, XhrBase} from "@src/core/xhr";
import {useHttpClient} from "@src/utils/hooks/xhr/useHttpClient";
import {useGetBoxInfoResult} from "@src/services/box/useGetBoxInfo";

export const useTodoMutation = ({code, action}: {code: string; action: TAction}) => {
    const {xhr} = useHttpClient();
    const {refetch} = useGetBoxInfoResult({code, refetchOnMount: false});

    return useMutation({
        mutationFn: async () => {
            const response = await xhr.put({
                relativeUrl: XhrBase.PUT_BOX_TODO({code, action })
            });

            return response;
        },
        onSuccess: async () => {
            await refetch();
        }
    })
}
