import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import {PhotoReportImageBlob} from "@src/utils/blob/base64ToBinary";
import {ActionDrawerBody} from "@src/components/shared/ActionDrawerBody/ActionDrawerBody";

import styles from "./ViewPhotoItem.module.scss";
import {Button} from "@src/components/shared/form/Button/Button";
import {useContext} from "react";
import {
    DialogWindowContextHolderContext
} from "@src/components/context/DialogWindowContextHolder/DialogWindowContextHolder.context";
import {ViewImageComponent} from "@src/components/shared/PhotoReport/components/ViewImageComponent/ViewImageComponent";
import {useDialogWindow} from "@src/utils/hooks/contextMenu/useDialogWindow";
import {CloseIcon} from "@src/components/icons/core/CloseIcon";
import useStateRef from "react-usestateref";
import {useTranslationCascade} from "@src/utils/hooks/i18n/useTranslationCascade";


export const ViewPhotoItem = ({images, onComplete, setImages}: {setImages: any, onComplete: any, images: Map<string, PhotoReportImageBlob>}) => {
    const {drawerApi} = useContext(DialogWindowContextHolderContext);
    const [, setImages_o, images_o] = useStateRef<Map<string, PhotoReportImageBlob>>(new Map(images));

    const onAttachPhotosWrapped = () => {
        if (onComplete) {
            onComplete(images);
        }

        drawerApi.close("PHOTO_REPORT_BOX_IMAGES"!)
        drawerApi.close("PHOTO_REPORT_BOX"!)

    }

    const {openDialog} = useDialogWindow();

    const openImages = ({image}: {image: string}) => {
        openDialog({
            key: "PHOTO_REPORT_BOX_IMAGE_VIEW",
            children: (
                <ViewImageComponent
                    src={image}
                    alt="Zoomable Image"
                    maxZoom={4}
                    minZoom={1}
                />
            )
        });
    }

    const onDeleteImage = (id: string) => {
        setImages((prevImages: any) => {
            prevImages.delete(id);

            return new Map(prevImages);
        });
        setImages_o((prevImages: any) => {
            prevImages.delete(id);

            return new Map(prevImages);
        })
    }
    const {t} = useTranslationCascade();

    return (
        <ActionDrawerBody
            title={t("translations:photoReport.Title")}
            message={
                <div className={styles.ViewPhotoItem}>
                        {
                            Array.from(images_o.current).reverse().map(([id, img]) => {

                                return (
                                        <div key={id} className={styles.ViewPhotoItemWrapper}>
                                            <div onClick={() => onDeleteImage(id)} className={styles.closeIcon}>
                                                <CloseIcon />
                                            </div>
                                            <img
                                                src={URL.createObjectURL(img)}
                                                alt={id}
                                                loading="lazy"
                                                onClick={() => openImages({image: URL.createObjectURL(img)})}
                                            />
                                        </div>
                                )
                            })
                        }
                </div>
            }
            action={
                <Button onClick={onAttachPhotosWrapped}>
                    {t("translations:photoReport.attachPhotos")}
                </Button>
            }
        />
    )
}

export const ViewPhotoItemUploaded = ({images}: { images: string[]}) => {
    const {openDialog} = useDialogWindow();

    const openImages = ({image}: {image: string}) => {
        openDialog({
            key: "PHOTO_REPORT_BOX_IMAGES",
            children: (
                <ViewImageComponent
                    src={image}
                    alt="Zoomable Image"
                    maxZoom={4}
                    minZoom={1}
                />
            )
        });
    }
    const {t} = useTranslationCascade();

    return (
        <ActionDrawerBody
            title={t("translations:photoReport.Title")}
            message={
                <div className={styles.ViewPhotoItem}>
                    <ImageList cols={3} rowHeight={164}>
                        {
                            images.reverse().map((imageId) => {

                                return (
                                    <ImageListItem key={imageId}>
                                        <div>
                                            <img src={`/api/v1/boxes/images/${imageId}`} alt="" onClick={() => openImages({image: `/api/v1/boxes/images/${imageId}`})}/>
                                        </div>
                                    </ImageListItem>
                                )
                            })
                        }
                    </ImageList>
                </div>
            }
        />
    )
}
