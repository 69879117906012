import {PropsWithChildren} from "react";
import {QueryClient, QueryClientProvider as QueryClientProviderCascade} from "@tanstack/react-query";

const client = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: false,
            retryDelay: undefined
        }
    }
});

export const QueryClientProvider = ({ children }: PropsWithChildren) => {
    return <QueryClientProviderCascade client={client}>
        {children}
    </QueryClientProviderCascade>
}
