export const WashBoxIcon = () => (
    <svg width="194" height="210" viewBox="0 0 194 210" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M189.773 190.904C187.101 190.111 184.097 190.249 181.609 189.082C179.167 187.937 177.612 185.718 175.409 184.241C171.859 181.86 167.089 181.657 162.642 181.541C149.432 181.209 136.228 180.877 123.018 180.549L117.03 183.129C108.862 182.658 100.652 182.847 92.5109 183.706C87.309 184.255 82.0336 185.108 77.3837 187.508C76.6524 187.882 75.9257 188.311 75.4152 188.952C73.994 190.734 74.8264 193.572 76.611 194.984C78.3955 196.396 80.7918 196.714 83.0639 196.807C85.3359 196.894 87.6724 196.82 89.8019 197.623C92.6581 198.703 94.7416 201.218 97.4506 202.635C100.739 204.356 104.639 204.315 108.342 204.079C110.881 203.918 113.41 203.678 115.935 203.373C123.326 204.638 130.966 204.97 138.201 203.253C142.326 202.275 146.236 200.655 150.366 199.705C162.835 196.834 176.108 200.254 188.867 198.685C190.711 198.459 192.776 197.974 193.646 196.516C194.957 194.305 192.45 191.698 189.778 190.904H189.773Z"
            fill="#BCE6F8"/>
        <g style={{mixBlendMode:"soft-light"}}>
            <path
                d="M190.923 191.371C191.41 192.257 191.521 193.221 190.987 194.126C190.123 195.588 188.053 196.068 186.208 196.294C173.45 197.868 160.176 194.444 147.707 197.314C143.573 198.265 139.668 199.889 135.542 200.863C128.307 202.58 120.668 202.247 113.277 200.983C110.752 201.288 108.222 201.527 105.683 201.689C101.981 201.924 98.0806 201.966 94.7921 200.245C92.0831 198.828 90.0042 196.313 87.1434 195.233C85.0139 194.43 82.6774 194.504 80.4054 194.416C78.6668 194.347 76.8593 194.139 75.3047 193.42C75.6312 194.024 76.0774 194.573 76.6063 194.993C78.3908 196.405 80.7871 196.724 83.0592 196.816C85.3312 196.904 87.6677 196.83 89.7972 197.633C92.6534 198.713 94.7369 201.228 97.4459 202.644C100.734 204.366 104.635 204.324 108.337 204.089C110.876 203.927 113.406 203.687 115.931 203.383C123.322 204.647 130.961 204.979 138.196 203.263C142.322 202.284 146.231 200.665 150.361 199.714C162.83 196.844 176.104 200.263 188.862 198.694C190.707 198.468 192.772 197.983 193.641 196.525C194.759 194.638 193.094 192.464 190.923 191.375V191.371Z"
                fill="#231F20"/>
        </g>
        <path
            d="M65.4487 125.279L43.1833 64.4483L38.9841 53.0132C38.17 50.8027 35.7278 49.6721 33.5247 50.4843L27.0672 52.8701C26.0554 53.2439 25.5356 54.3699 25.9082 55.3851L36.5924 84.4899C36.965 85.5051 38.0872 86.0266 39.0991 85.6528L45.2484 83.3823L61.1253 126.866L65.4441 125.279H65.4487Z"
            fill="#2845A3"/>
        <path d="M21.2489 60.4566L20.0117 57.0879L25.9173 55.4036L26.8279 57.8955L21.2489 60.4566Z" fill="#FBB040"/>
        <path d="M26.3726 56.6495L25.9173 55.4036L20.0117 57.0879L20.628 58.7723L26.3726 56.6495Z" fill="#F7941D"/>
        <path d="M22.8588 64.8453L21.6216 61.472L27.5271 59.7876L28.4424 62.2795L22.8588 64.8453Z" fill="#FBB040"/>
        <path d="M27.9825 61.0336L27.5271 59.7876L21.6216 61.472L22.2379 63.1563L27.9825 61.0336Z" fill="#F7941D"/>
        <path d="M24.4687 69.2291L23.2314 65.8558L29.137 64.176L30.0523 66.6679L24.4687 69.2291Z" fill="#FBB040"/>
        <path d="M29.5923 65.422L29.137 64.176L23.2314 65.8558L23.8478 67.5447L29.5923 65.422Z" fill="#F7941D"/>
        <path d="M26.078 73.6131L24.8408 70.2398L30.7464 68.5601L31.6616 71.052L26.078 73.6131Z" fill="#FBB040"/>
        <path d="M31.2017 69.806L30.7464 68.5601L24.8408 70.2398L25.4571 71.9288L31.2017 69.806Z" fill="#F7941D"/>
        <path d="M27.6879 78.0015L26.4507 74.6282L32.3562 72.9438L33.2715 75.4358L27.6879 78.0015Z" fill="#FBB040"/>
        <path d="M32.8116 74.1898L32.3562 72.9438L26.4507 74.6282L27.0716 76.3126L32.8116 74.1898Z" fill="#F7941D"/>
        <path d="M29.2978 82.3856L28.0605 79.0123L33.9661 77.3325L34.8814 79.8244L29.2978 82.3856Z" fill="#FBB040"/>
        <path d="M34.4214 78.5785L33.9661 77.3325L28.0605 79.0123L28.6815 80.7012L34.4214 78.5785Z" fill="#F7941D"/>
        <path d="M30.9076 86.7696L29.6704 83.3963L35.576 81.7166L36.4912 84.2085L30.9076 86.7696Z" fill="#FBB040"/>
        <path d="M36.0313 82.9625L35.576 81.7166L29.6704 83.3963L30.2913 85.0853L36.0313 82.9625Z" fill="#F7941D"/>
        <path
            d="M37.9355 83.9638L27.2742 54.9191C26.8971 53.8854 27.4214 52.741 28.4517 52.3625L27.0995 52.8609C26.0692 53.2393 25.5449 54.3884 25.922 55.4175L36.5833 84.4622C36.9604 85.4959 38.1057 86.022 39.1313 85.6436L40.4835 85.1452C39.4533 85.5236 38.3127 84.9975 37.9355 83.9638Z"
            fill="#173684"/>
        <g style={{mixBlendMode:"soft-light"}} opacity="0.5">
            <path
                d="M26.2206 55.0529L32.6781 52.6671C34.8812 51.8503 37.328 52.9855 38.1375 55.1959L42.3367 66.6311L63.9996 125.81L65.4484 125.279L43.183 64.4483L38.9838 53.0132C38.1697 50.8027 35.7275 49.6721 33.5244 50.4843L27.0669 52.8701C26.1057 53.2254 25.6043 54.2545 25.8665 55.2236C25.9769 55.1544 26.0965 55.099 26.2206 55.0482V55.0529Z"
                fill="white"/>
        </g>
        <path
            d="M77.7304 31.3583L76.9715 31.4421C75.805 31.5709 74.9634 32.6241 75.0918 33.7945L79.7888 76.6185C79.9172 77.7889 80.9669 78.6333 82.1334 78.5045L82.8923 78.4207C84.0588 78.2919 84.9004 77.2387 84.772 76.0683L80.075 33.2443C79.9466 32.0739 78.8969 31.2295 77.7304 31.3583Z"
            fill="#FFDE17"/>
        <g style={{mixBlendMode:"soft-light"}}>
            <path
                d="M77.6461 31.3704L77.0436 31.435C76.7953 31.4627 76.5653 31.5412 76.3491 31.6427C77.2414 31.8457 77.9543 32.5841 78.0601 33.5486L82.733 76.2158C82.8388 77.1803 82.3053 78.0571 81.4774 78.4493C81.7073 78.5001 81.9511 78.5278 82.1995 78.5001L82.802 78.4355C84.0116 78.3017 84.8855 77.208 84.7521 75.9943L80.0792 33.327C79.9458 32.1134 78.8558 31.2366 77.6461 31.3704Z"
                fill="#042066"/>
        </g>
        <path d="M84.4252 73.0417L79.4419 73.5911L80.4199 82.5221L85.4032 81.9727L84.4252 73.0417Z" fill="#FBB040"/>
        <path
            d="M87.0509 75.5353L77.5461 76.583C76.8063 76.6646 76.2724 77.3324 76.3537 78.0747L76.7365 81.5701C76.8178 82.3124 77.4834 82.848 78.2232 82.7664L87.728 81.7186C88.4678 81.6371 89.0017 80.9692 88.9204 80.227L88.5376 76.7316C88.4563 75.9893 87.7907 75.4537 87.0509 75.5353Z"
            fill="#BE1E2D"/>
        <g style={{mixBlendMode:"soft-light"}}>
            <path
                d="M87.0056 75.5376L83.3169 75.9437C84.085 75.8607 84.7749 76.4144 84.8623 77.1851L85.2348 80.5769C85.3176 81.3475 84.7657 82.0397 83.9976 82.1274L87.6863 81.7213C88.4544 81.6382 89.0109 80.9414 88.9235 80.1708L88.5509 76.779C88.4682 76.0083 87.7737 75.45 87.0056 75.5376Z"
                fill="#042066"/>
        </g>
        <path
            d="M82.5629 78.9986C74.4037 79.8985 68.5211 87.2635 69.418 95.4453L69.7767 98.7402L99.3183 95.4822L98.9595 92.1873C98.0626 84.0009 90.7221 78.0987 82.5675 78.9986H82.5629Z"
            fill="#EF4136"/>
        <g style={{mixBlendMode:"soft-light"}}>
            <path
                d="M82.5627 78.9986C81.7486 79.0863 80.9575 79.2478 80.1895 79.4601C87.3322 79.8339 93.362 85.353 94.1668 92.718L94.5256 96.0129L99.3135 95.4869L98.9547 92.192C98.0579 84.0055 90.7173 78.1034 82.5627 79.0032V78.9986Z"
                fill="#042066"/>
        </g>
        <path
            d="M68.509 98.1881L100.425 94.6697C101.513 94.5498 102.492 95.3378 102.612 96.4295L102.69 97.1451C102.81 98.2368 102.024 99.2194 100.936 99.3394L69.0204 102.858C67.9323 102.978 66.953 102.19 66.8334 101.098L66.7551 100.382C66.6355 99.2906 67.4209 98.308 68.509 98.1881Z"
            fill="#EF4136"/>
        <g style={{mixBlendMode:"soft-light"}}>
            <path
                d="M100.353 94.6747L95.5649 95.2007C96.6964 95.0761 97.7128 95.8929 97.837 97.0281L97.8968 97.5911C98.021 98.7263 97.2069 99.7462 96.0755 99.8708L100.863 99.3447C101.995 99.2201 102.809 98.2003 102.685 97.0651L102.625 96.5021C102.501 95.3669 101.484 94.5501 100.353 94.6747Z"
                fill="#042066"/>
        </g>
        <path
            d="M113.953 68.025C117.007 68.7864 120.098 66.9221 120.861 63.8625L122.637 56.7236C123.395 53.6595 121.537 50.5584 118.488 49.7924L100.109 45.1962C97.0551 44.4301 93.9643 46.2991 93.2008 49.3586L91.4255 56.4975C90.6666 59.5616 92.5247 62.6627 95.5741 63.4287L95.8685 63.5026L94.4381 69.2571L93.4124 69.0033C90.7632 68.3388 88.0772 69.9585 87.4149 72.6166L81.7853 95.2746C81.123 97.9327 82.7373 100.628 85.3866 101.292L105.224 106.253C107.873 106.917 110.559 105.298 111.221 102.64L116.851 79.9816C117.513 77.3235 115.899 74.6286 113.249 73.964L112.224 73.7102L113.654 67.9557L113.948 68.0296L113.953 68.025Z"
            fill="#B9EAFC"/>
        <path
            d="M114.933 45.0503L105.45 42.678C105.058 42.58 104.661 42.8192 104.563 43.2124L104.016 45.4154C103.918 45.8086 104.157 46.2068 104.549 46.3048L114.032 48.6772C114.424 48.7752 114.821 48.5359 114.918 48.1427L115.466 45.9398C115.563 45.5466 115.325 45.1484 114.933 45.0503Z"
            fill="#2C52A8"/>
        <path
            d="M115.832 41.4268L106.348 39.0545C105.956 38.9564 105.559 39.1957 105.462 39.5889L104.914 41.7919C104.817 42.1851 105.055 42.5833 105.447 42.6813L114.93 45.0536C115.322 45.1517 115.719 44.9124 115.817 44.5192L116.364 42.3162C116.462 41.923 116.223 41.5248 115.832 41.4268Z"
            fill="#2C52A8"/>
        <path
            d="M113.838 37.074L110.148 36.1508C109.418 35.9683 108.679 36.4137 108.497 37.1457L108.255 38.1218C108.073 38.8538 108.517 39.5951 109.246 39.7776L112.937 40.7009C113.667 40.8834 114.405 40.4379 114.587 39.706L114.83 38.7298C115.012 37.9979 114.568 37.2565 113.838 37.074Z"
            fill="#1B75BC"/>
        <path
            d="M115.557 53.9L102.874 50.7272C101.649 50.4208 100.409 51.1687 100.103 52.3977L98.9405 57.0768C98.6351 58.3059 99.3805 59.5506 100.605 59.857L113.289 63.0298C114.513 63.3362 115.754 62.5883 116.059 61.3593L117.222 56.6802C117.528 55.4512 116.782 54.2064 115.557 53.9Z"
            fill="white"/>
        <path
            d="M110.685 77.1357L96.2927 73.5353C95.0677 73.2289 93.8271 73.9768 93.5217 75.2058L88.7372 94.4595C88.4318 95.6885 89.1772 96.9333 90.4022 97.2397L104.794 100.84C106.019 101.147 107.26 100.399 107.565 99.1696L112.35 79.9159C112.655 78.6868 111.91 77.4421 110.685 77.1357Z"
            fill="#1B75BC"/>
        <g style={{mixBlendMode:"soft-light"}}>
            <path
                d="M84.954 96.0637L90.5836 73.4056C91.2459 70.7476 93.9273 69.1278 96.5812 69.7924L97.6068 70.0462L99.0372 64.2917L98.7428 64.2178C95.6889 63.4564 93.8307 60.3507 94.5942 57.2866L96.3696 50.1477C97.1331 47.0835 100.224 45.2192 103.278 45.9852L100.113 45.1915C97.0595 44.4255 93.9687 46.2944 93.2052 49.354L91.4299 56.4929C90.671 59.557 92.5291 62.6581 95.5785 63.4241L95.8728 63.4979L94.4425 69.2524L93.4168 68.9986C90.7676 68.3341 88.0816 69.9539 87.4193 72.6119L81.7897 95.27C81.1274 97.928 82.7417 100.623 85.391 101.288L88.5553 102.081C85.9061 101.417 84.2917 98.7264 84.954 96.0637Z"
                fill="white"/>
        </g>
        <mask id="mask0_10202_3283" style={{maskType:"luminance"}} maskUnits="userSpaceOnUse" x="108" y="36" width="7"
              height="5">
            <path
                d="M113.838 37.074L110.148 36.1508C109.418 35.9683 108.679 36.4137 108.497 37.1457L108.255 38.1218C108.073 38.8538 108.517 39.5951 109.246 39.7776L112.937 40.7009C113.667 40.8834 114.405 40.4379 114.587 39.706L114.83 38.7298C115.012 37.9979 114.568 37.2565 113.838 37.074Z"
                fill="white"/>
        </mask>
        <g mask="url(#mask0_10202_3283)">
            <path d="M109.692 35.141L108.315 40.6892L109.154 40.8988L110.531 35.3506L109.692 35.141Z" fill="#2C52A8"/>
            <path d="M111.779 35.6654L110.402 41.2136L111.241 41.4232L112.618 35.875L111.779 35.6654Z" fill="#2C52A8"/>
            <path d="M113.863 36.1857L112.486 41.7339L113.325 41.9435L114.702 36.3952L113.863 36.1857Z" fill="#2C52A8"/>
        </g>
        <path d="M100.883 4.51549L78.6899 127.655L85.506 128.891L107.699 5.75214L100.883 4.51549Z" fill="#27AAE1"/>
        <g style={{mixBlendMode:"soft-light"}} opacity="0.3">
            <path
                d="M81.1416 127.646L103.246 4.93747L100.873 4.5083L78.6855 127.646L85.5018 128.883L85.58 128.454L81.1416 127.646Z"
                fill="white"/>
        </g>
        <path
            d="M106.36 12.2242L99.953 10.9875C98.9917 10.8029 98.3662 9.87072 98.5502 8.91087C98.7341 7.9464 99.6632 7.3188 100.62 7.50339L107.027 8.74012C107.988 8.92471 108.614 9.85687 108.43 10.8167C108.246 11.7812 107.316 12.4088 106.36 12.2242Z"
            fill="#231F20"/>
        <path
            d="M105.693 15.7083L99.286 14.4716C98.3247 14.287 97.6992 13.3548 97.8832 12.395C98.0671 11.4305 98.9962 10.8029 99.9529 10.9875L106.36 12.2243C107.321 12.4088 107.947 13.341 107.763 14.3009C107.579 15.2653 106.649 15.8929 105.693 15.7083Z"
            fill="#231F20"/>
        <path
            d="M107.031 8.73543L100.624 7.4987C99.6631 7.31411 99.0376 6.38195 99.2215 5.4221C99.4055 4.45763 100.335 3.83003 101.291 4.01462L107.698 5.25135C108.659 5.43594 109.285 6.3681 109.101 7.32796C108.917 8.29242 107.988 8.92002 107.031 8.73543Z"
            fill="#231F20"/>
        <path
            d="M100.201 5.78207C100.22 5.42212 100.422 2.22415 102.303 0.738228C103.122 0.0921739 104.134 -0.133945 105.228 0.0783299C106.314 0.28599 107.183 0.872054 107.735 1.7673C109.014 3.83929 108.218 6.89882 108.121 7.2403L105.863 6.60809C106.029 5.99434 106.369 4.01465 105.739 3.00403C105.532 2.66716 105.228 2.47335 104.782 2.38567C104.226 2.27953 103.936 2.43643 103.747 2.5841C102.92 3.23477 102.575 5.09448 102.538 5.89282L100.197 5.78207H100.201Z"
            fill="#231F20"/>
        <path
            d="M133.855 49.2899L133.229 49.1605C130.216 48.5378 127.27 50.4835 126.65 53.5064L122.916 71.6894C122.296 74.7123 124.235 77.6677 127.248 78.2904L127.874 78.4198C130.887 79.0425 133.832 77.0968 134.453 74.0739L138.186 55.8909C138.807 52.868 136.868 49.9126 133.855 49.2899Z"
            fill="#EF4136"/>
        <path
            d="M134.278 56.5159C134.084 57.4619 133.165 58.0664 132.222 57.8726C131.279 57.6788 130.676 56.7559 130.87 55.8099C131.063 54.8639 131.983 54.2593 132.925 54.4531C133.868 54.647 134.471 55.5699 134.278 56.5159Z"
            fill="white"/>
        <g style={{mixBlendMode:"soft-light"}}>
            <path
                d="M125.277 71.8458L128.878 54.2916C129.453 51.4905 131.918 49.5616 134.664 49.5754C134.31 49.4231 133.933 49.3032 133.542 49.2247C130.354 48.5648 127.241 50.623 126.583 53.8209L122.982 71.3751C122.324 74.5731 124.375 77.6973 127.563 78.3571C127.953 78.4402 128.349 78.4771 128.735 78.4771C126.21 77.3973 124.702 74.6516 125.277 71.8505V71.8458Z"
                fill="white"/>
        </g>
        <path
            d="M112.474 68.3113L145.376 75.1118C149.106 75.8828 151.51 79.5457 150.741 83.2881L144.561 113.39L98.1397 103.795L104.32 73.6935C105.088 69.9511 108.739 67.5395 112.469 68.3104L112.474 68.3113Z"
            fill="#EF4136"/>
        <path
            d="M142.993 79.6491L112.881 73.4285C110.453 72.9255 108.135 74.2084 107.707 76.2942L105.798 85.5836L104.391 92.4502L102.261 102.815L141.167 110.854L143.297 100.489L144.704 93.6223L146.613 84.333C147.04 82.2472 145.417 80.1521 142.988 79.6491H142.993Z"
            fill="#BE1E2D"/>
        <path
            d="M141.507 111.366L101.755 103.152L107.284 76.2066C107.758 73.8901 110.31 72.4549 112.969 73.004L143.08 79.2246C144.354 79.4877 145.472 80.166 146.217 81.1305C146.981 82.118 147.27 83.2855 147.04 84.4208L141.512 111.366H141.507ZM102.776 102.473L140.836 110.337L146.19 84.2454C146.369 83.364 146.139 82.4457 145.532 81.6658C144.911 80.8628 143.977 80.2998 142.906 80.0783L112.794 73.8578C110.604 73.4055 108.512 74.5407 108.135 76.3866L102.781 102.478L102.776 102.473Z"
            fill="#F46460"/>
        <path
            d="M140.234 82.7457L114.202 77.3649C112.909 77.0973 111.676 77.7803 111.447 78.8924L107.445 98.394L106.364 103.659L137.079 110.005L138.159 104.739L142.161 85.2376C142.391 84.1301 141.526 83.0133 140.234 82.7457Z"
            fill="#EF4136"/>
        <path
            d="M112.995 64.0582L96.1169 67.5066C94.0883 67.9211 92.7787 69.9071 93.1918 71.9424L95.3338 82.4965C95.7469 84.5318 97.7263 85.8458 99.7549 85.4313L116.633 81.9829C118.662 81.5684 119.971 79.5825 119.558 77.5471L117.416 66.9931C117.003 64.9577 115.024 63.6437 112.995 64.0582Z"
            fill="#F2C60F"/>
        <path
            d="M116.391 81.928L99.9549 85.2862C97.8044 85.7256 96.416 87.831 96.854 89.9887L99.9247 105.119C100.363 107.277 102.461 108.67 104.612 108.23L121.048 104.872C123.199 104.433 124.587 102.327 124.149 100.17L121.078 85.0393C120.64 82.8816 118.542 81.4886 116.391 81.928Z"
            fill="#F2C60F"/>
        <path
            d="M109.05 64.869L92.1716 68.3174C90.143 68.7319 88.8334 70.7179 89.2465 72.7532L91.3885 83.3073C91.8016 85.3426 93.781 86.6566 95.8096 86.2421L112.688 82.7937C114.716 82.3792 116.026 80.3933 115.613 78.3579L113.471 67.8039C113.058 65.7685 111.078 64.4545 109.05 64.869Z"
            fill="#FFDE17"/>
        <path
            d="M96.0131 86.0867L112.445 82.7295C114.595 82.2903 116.694 83.6839 117.132 85.8408L120.202 100.966C120.639 103.123 119.251 105.23 117.101 105.669L100.669 109.026C98.519 109.465 96.4197 108.072 95.9819 105.915L92.9121 90.7892C92.4743 88.6323 93.8633 86.526 96.0131 86.0867Z"
            fill="#FFDE17"/>
        <path
            d="M94.7326 72.1826C94.8706 72.8563 94.4337 73.5162 93.7622 73.6547C93.0907 73.7931 92.433 73.3547 92.295 72.681C92.157 72.0072 92.5939 71.3473 93.2654 71.2089C93.937 71.0705 94.5947 71.5088 94.7326 72.1826Z"
            fill="#F2C60F"/>
        <path
            d="M111.461 67.4941C111.599 68.1679 111.162 68.8278 110.49 68.9662C109.819 69.1046 109.161 68.6662 109.023 67.9925C108.885 67.3188 109.322 66.6589 109.993 66.5204C110.665 66.382 111.323 66.8204 111.461 67.4941Z"
            fill="#F2C60F"/>
        <path
            d="M98.9411 71.3242C99.0791 71.9979 98.6422 72.6578 97.9707 72.7963C97.2992 72.9347 96.6415 72.4963 96.5035 71.8226C96.3655 71.1488 96.8024 70.4889 97.4739 70.3505C98.1454 70.2121 98.8031 70.6504 98.9411 71.3242Z"
            fill="#F2C60F"/>
        <path
            d="M97.9382 79.6677C98.0762 80.3414 97.6392 81.0013 96.9677 81.1398C96.2962 81.2782 95.6385 80.8398 95.5006 80.1661C95.3626 79.4923 95.7995 78.8324 96.471 78.694C97.1425 78.5556 97.8002 78.994 97.9382 79.6677Z"
            fill="#F2C60F"/>
        <path
            d="M107.5 74.0378C107.638 74.7116 107.201 75.3715 106.53 75.5099C105.858 75.6483 105.201 75.2099 105.063 74.5362C104.925 73.8625 105.362 73.2026 106.033 73.0641C106.705 72.9257 107.362 73.3641 107.5 74.0378Z"
            fill="#F2C60F"/>
        <path
            d="M104.617 95.9206C104.755 96.5944 104.318 97.2543 103.646 97.3927C102.975 97.5311 102.317 97.0928 102.179 96.419C102.041 95.7453 102.478 95.0854 103.15 94.9469C103.821 94.8085 104.479 95.2469 104.617 95.9206Z"
            fill="#F2C60F"/>
        <path
            d="M104.511 89.1509C104.649 89.8246 104.212 90.4845 103.54 90.6229C102.869 90.7614 102.211 90.323 102.073 89.6492C101.935 88.9755 102.372 88.3156 103.044 88.1772C103.715 88.0387 104.373 88.4771 104.511 89.1509Z"
            fill="#F2C60F"/>
        <path
            d="M115.581 94.0378C115.719 94.7116 115.282 95.3715 114.611 95.5099C113.939 95.6483 113.282 95.2099 113.144 94.5362C113.006 93.8625 113.443 93.2026 114.114 93.0641C114.786 92.9257 115.443 93.3641 115.581 94.0378Z"
            fill="#F2C60F"/>
        <path
            d="M109.552 104.388C109.69 105.062 109.253 105.722 108.582 105.86C107.91 105.999 107.252 105.561 107.114 104.887C106.976 104.213 107.413 103.553 108.085 103.415C108.756 103.276 109.414 103.715 109.552 104.388Z"
            fill="#F2C60F"/>
        <path
            d="M102.335 75.0945C102.547 76.1328 101.88 77.1434 100.845 77.3557C99.8102 77.568 98.803 76.8988 98.5914 75.8605C98.3798 74.8222 99.0467 73.8116 100.082 73.5993C101.116 73.3871 102.124 74.0562 102.335 75.0945Z"
            fill="#F2C60F"/>
        <path
            d="M110.987 78.2739C111.198 79.3122 110.531 80.3229 109.496 80.5351C108.462 80.7474 107.454 80.0783 107.243 79.04C107.031 78.0017 107.698 76.9911 108.733 76.7788C109.768 76.5665 110.775 77.2356 110.987 78.2739Z"
            fill="#F2C60F"/>
        <path
            d="M100.68 91.8919C100.891 92.9302 100.224 93.9408 99.1893 94.1531C98.1545 94.3653 97.1472 93.6962 96.9356 92.6579C96.7241 91.6196 97.391 90.609 98.4258 90.3967C99.4607 90.1844 100.468 90.8536 100.68 91.8919Z"
            fill="#F2C60F"/>
        <path
            d="M113.001 97.794C113.213 98.8323 112.546 99.8429 111.511 100.055C110.476 100.267 109.469 99.5983 109.257 98.56C109.046 97.5217 109.713 96.5111 110.748 96.2988C111.782 96.0865 112.79 96.7557 113.001 97.794Z"
            fill="#F2C60F"/>
        <path d="M50.9054 51.7508L47.8975 51.7629L47.9234 58.2557L50.9314 58.2436L50.9054 51.7508Z" fill="#1B75BC"/>
        <path
            d="M47.2377 50.0952L44.1838 50.1075C43.1881 50.1115 42.3841 50.9246 42.3881 51.9237L42.413 58.1396C42.417 59.1386 43.2274 59.9453 44.2231 59.9413L47.2771 59.929C48.2728 59.925 49.0768 59.1118 49.0728 58.1128L49.0479 51.8969C49.0439 50.8978 48.2334 50.0912 47.2377 50.0952Z"
            fill="#27AAE1"/>
        <path
            d="M86.2054 90.5674C86.3388 85.0021 76.8642 80.8489 76.8228 80.8305L76.763 65.2283L64.8875 65.2744L64.9473 80.8766C64.9473 80.8766 55.4589 85.1129 55.6383 90.6874L55.8498 144.73C55.8498 144.73 55.8498 144.744 55.8498 144.748C55.859 146.931 62.7074 148.671 71.1518 148.639C79.5962 148.606 86.4308 146.811 86.4216 144.628L86.21 90.5628L86.2054 90.5674Z"
            fill="#B9EAFC"/>
        <g style={{mixBlendMode:"soft-light"}}>
            <path
                d="M86.2055 90.5674C86.3159 86.0127 79.9872 82.4041 77.6646 81.2319C80.1988 82.9855 82.9354 85.579 82.8618 88.5877L82.871 90.5813L86.2055 90.5674Z"
                fill="#231F20"/>
            <path
                d="M83.0781 142.653C83.0873 144.836 76.2481 146.631 67.8083 146.663C63.048 146.682 58.7936 146.137 55.9834 145.265C56.9631 147.199 63.3791 148.675 71.152 148.643C79.5964 148.611 86.431 146.816 86.4218 144.633L86.2102 90.5674L82.8711 90.5812L83.0781 142.653Z"
                fill="#231F20"/>
            <path
                d="M73.4795 78.8508C73.4795 78.8508 75.0157 79.5245 76.8232 80.6736L76.7634 65.2283L73.4243 65.2421L73.4795 78.8508Z"
                fill="#231F20"/>
        </g>
        <path
            d="M78.3313 61.8548C78.3313 62.9808 74.9738 63.9083 70.8206 63.9268C66.6674 63.9453 63.2961 63.0408 63.2915 61.9148C63.2915 60.7888 66.649 59.8613 70.8022 59.8428C74.9554 59.8243 78.3267 60.7288 78.3313 61.8548Z"
            fill="#1B75BC"/>
        <path
            d="M63.2915 61.915L63.3237 69.7092C63.3237 70.8351 66.6996 71.735 70.8528 71.7212C75.006 71.7027 78.3681 70.7751 78.3635 69.6492L78.3313 61.855L63.2915 61.915Z"
            fill="#1B75BC"/>
        <g style={{mixBlendMode:"soft-light"}}>
            <path
                d="M75.9859 61.8642L76.0089 68.1448C76.0089 69.2708 72.6514 70.1983 68.4982 70.2168C66.4929 70.226 64.6715 70.0183 63.3193 69.6769V69.7092C63.3193 70.8351 66.6952 71.735 70.8484 71.7212C75.0016 71.7027 78.3638 70.7751 78.3592 69.6492L78.327 61.855L75.9813 61.8642H75.9859Z"
                fill="#231F20"/>
        </g>
        <path
            d="M82.179 47.8779L54.137 47.9908C52.3462 47.9981 50.9004 49.4605 50.9075 51.2572L50.9469 61.0818C50.954 62.8785 52.4116 64.3293 54.2024 64.3221L82.2443 64.2091C84.0351 64.2019 85.481 62.7395 85.4738 60.9427L85.4345 51.1182C85.4273 49.3214 83.9698 47.8707 82.179 47.8779Z"
            fill="#2C52A8"/>
        <path
            d="M53.2469 64.3236C52.8882 64.3236 52.603 64.619 52.603 64.9743L52.6444 75.5881L52.6812 84.6374C52.6812 85.1404 53.2239 85.4496 53.6563 85.1958C55.3212 84.2267 58.5362 81.5779 58.5132 75.565L58.4718 64.9512C58.4718 64.5913 58.1774 64.3052 57.8233 64.3052L53.2515 64.3236H53.2469Z"
            fill="#15267A"/>
        <mask id="mask1_10202_3283" style={{maskType:"luminance"}} maskUnits="userSpaceOnUse" x="42" y="50" width="8"
              height="10">
            <path
                d="M47.2377 50.0952L44.1838 50.1075C43.1881 50.1115 42.3841 50.9246 42.3881 51.9237L42.413 58.1396C42.417 59.1386 43.2274 59.9453 44.2231 59.9413L47.2771 59.929C48.2728 59.925 49.0768 59.1118 49.0728 58.1128L49.0479 51.8969C49.0439 50.8978 48.2334 50.0912 47.2377 50.0952Z"
                fill="white"/>
        </mask>
        <g mask="url(#mask1_10202_3283)">
            <path d="M49.7803 51.0263L41.6533 51.0591L41.6585 52.3604L49.7855 52.3277L49.7803 51.0263Z" fill="#2B3990"/>
            <path d="M49.7847 53.2502L41.6577 53.2815L41.6627 54.5828L49.7897 54.5515L49.7847 53.2502Z" fill="#2B3990"/>
            <path d="M49.793 55.4697L41.666 55.501L41.671 56.8023L49.798 56.771L49.793 55.4697Z" fill="#2B3990"/>
            <path d="M49.8013 57.6894L41.6743 57.7207L41.6793 59.022L49.8063 58.9907L49.8013 57.6894Z" fill="#2B3990"/>
        </g>
        <path
            d="M82.9031 47.877L80.2953 47.8862C81.6843 47.8816 82.8111 49.0076 82.8203 50.3966L82.8663 61.6841C82.8709 63.0777 81.7533 64.2083 80.3643 64.2175L53.4766 64.3237L82.9767 64.2083C84.3657 64.2037 85.4833 63.0685 85.4787 61.6748L85.4328 50.3874C85.4282 48.9937 84.2967 47.8724 82.9077 47.877H82.9031Z"
            fill="#13247A"/>
        <path
            d="M136.458 190.101H57.3952C53.7664 190.101 50.7768 187.249 50.5928 183.613L46.9548 111.647C46.757 107.743 49.8615 104.467 53.7572 104.467H140.091C143.987 104.467 147.092 107.743 146.894 111.647L143.256 183.613C143.072 187.249 140.082 190.101 136.453 190.101H136.458Z"
            fill="#86BD3D"/>
        <g style={{mixBlendMode:"soft-light"}}>
            <path
                d="M140.096 104.467H132.447C136.343 104.467 139.447 107.743 139.25 111.647L135.612 183.613C135.428 187.249 132.438 190.101 128.809 190.101H136.458C140.087 190.101 143.076 187.249 143.26 183.613L146.898 111.647C147.096 107.743 143.991 104.467 140.096 104.467Z"
                fill="#231F20"/>
        </g>
        <g style={{mixBlendMode:"soft-light"}}>
            <path
                d="M146.167 126.073L146.898 111.647C147.096 107.743 143.992 104.467 140.096 104.467H53.7572C49.8615 104.467 46.757 107.743 46.9548 111.647L47.686 126.073H146.172H146.167Z"
                fill="#262262"/>
        </g>
        <path
            d="M149.129 94.2224H44.7241C41.7267 94.2224 39.2969 96.6604 39.2969 99.6677V113.909C39.2969 116.916 41.7267 119.354 44.7241 119.354H149.129C152.126 119.354 154.556 116.916 154.556 113.909V99.6677C154.556 96.6604 152.126 94.2224 149.129 94.2224Z"
            fill="#86BD3D"/>
        <g style={{mixBlendMode:"soft-light"}}>
            <path
                d="M149.451 94.2224H141.577C144.396 94.2224 146.682 96.5159 146.682 99.3447V114.227C146.682 117.056 144.396 119.349 141.577 119.349H149.451C152.27 119.349 154.556 117.056 154.556 114.227V99.3447C154.556 96.5159 152.27 94.2224 149.451 94.2224Z"
                fill="#231F20"/>
        </g>
        <path
            d="M66.971 174.836H81.7946C83.8275 174.836 85.4741 176.488 85.4741 178.528V190.101H63.2915V178.528C63.2915 176.488 64.9381 174.836 66.971 174.836Z"
            fill="#608929"/>
        <path
            d="M112.9 174.836H127.723C129.756 174.836 131.403 176.488 131.403 178.528V190.101H109.22V178.528C109.22 176.488 110.867 174.836 112.9 174.836Z"
            fill="#608929"/>
        <path
            d="M79.0489 177.937H69.5605C67.381 177.937 65.6143 179.71 65.6143 181.896V195.007C65.6143 197.193 67.381 198.966 69.5605 198.966H79.0489C81.2284 198.966 82.9951 197.193 82.9951 195.007V181.896C82.9951 179.71 81.2284 177.937 79.0489 177.937Z"
            fill="#ABD179"/>
        <path
            d="M125.056 177.937H115.567C113.388 177.937 111.621 179.71 111.621 181.896V195.007C111.621 197.193 113.388 198.966 115.567 198.966H125.056C127.235 198.966 129.002 197.193 129.002 195.007V181.896C129.002 179.71 127.235 177.937 125.056 177.937Z"
            fill="#ABD179"/>
        <path
            d="M86.6687 106.323L84.3077 103.197C83.4226 102.025 81.758 101.795 80.5898 102.683L40.4222 133.218C39.254 134.106 39.0245 135.776 39.9096 136.948L42.2705 140.075C43.1556 141.247 44.8202 141.477 45.9884 140.589L86.1561 110.054C87.3243 109.166 87.5538 107.496 86.6687 106.323Z"
            fill="#ABD179"/>
        <path d="M56.134 138.374L53.1069 138.528L54.8084 172.227L57.8355 172.073L56.134 138.374Z" fill="white"/>
        <path
            d="M58.2275 175.699C58.2275 176.696 57.4227 177.503 56.4292 177.503C55.4357 177.503 54.6309 176.696 54.6309 175.699C54.6309 174.702 55.4357 173.895 56.4292 173.895C57.4227 173.895 58.2275 174.702 58.2275 175.699Z"
            fill="white"/>
        <path
            d="M46.5728 114.024C46.5728 115.021 45.7679 115.828 44.7744 115.828C43.781 115.828 42.9761 115.021 42.9761 114.024C42.9761 113.027 43.781 112.219 44.7744 112.219C45.7679 112.219 46.5728 113.027 46.5728 114.024Z"
            fill="white"/>
        <path d="M46.2922 98.8232H43.2612V111.024H46.2922V98.8232Z" fill="white"/>
        <path
            d="M122.995 92.3625C123.156 94.0053 122.816 95.6528 122.388 97.2494C120.075 105.948 115.19 114.033 114.625 123.018C114.569 123.927 114.758 125.113 115.646 125.293C116.501 125.464 117.131 124.531 117.462 123.724C118.171 121.998 118.681 120.189 118.985 118.348C118.713 121.449 119.123 124.614 119.955 127.609C120.25 128.675 120.958 129.944 122.048 129.783C122.834 129.663 123.285 128.837 123.566 128.089C124.357 125.99 124.766 123.747 124.78 121.5C124.784 120.632 124.729 119.769 124.619 118.906C124.531 121.883 125.093 124.864 126.04 127.688C126.27 128.38 126.629 129.146 127.332 129.331C128.009 129.506 128.717 129.008 129.02 128.375C129.324 127.743 129.324 127.014 129.319 126.308C129.296 123.83 129.273 121.352 129.255 118.874C129.237 121.73 130.198 124.342 131.766 126.728C132.304 127.545 133.141 128.389 134.094 128.177C135.184 127.932 135.496 126.53 135.492 125.408C135.478 122.898 134.986 120.41 134.788 117.909C134.59 115.404 134.705 112.787 135.827 110.54C136.09 112.21 136.448 114.093 136.711 115.764C136.826 116.506 136.954 117.277 137.396 117.882C137.833 118.491 138.684 118.869 139.351 118.532C139.856 118.278 140.109 117.706 140.303 117.171C142.064 112.302 141.83 106.742 139.673 102.035C140.068 99.4137 140.1 96.5895 139.985 93.9407C139.948 93.1285 139.764 92.1456 139.001 91.8733C133.831 90.0413 128.174 90.5535 122.991 92.3625H122.995Z"
            fill="#2C52A8"/>
        <g style={{mixBlendMode:"soft-light"}} opacity="0.5">
            <path
                d="M120.516 115.27C120.516 115.27 119.716 125.51 122.048 129.778C122.048 129.778 118.911 131.149 118.985 118.343C118.985 118.343 119.744 116.313 120.516 115.274V115.27Z"
                fill="#231F20"/>
        </g>
        <g style={{mixBlendMode:"soft-light"}} opacity="0.5">
            <path
                d="M124.771 122.132C124.771 122.132 125.612 129.681 127.802 129.349C127.802 129.349 125.249 122.363 126.288 114.139C126.288 114.139 124.38 117.102 124.775 122.132H124.771Z"
                fill="#231F20"/>
        </g>
        <g style={{mixBlendMode:"soft-light"}} opacity="0.5">
            <path
                d="M129.283 119.843C129.283 119.843 129.462 122.681 130.612 124.702C131.762 126.723 132.387 128.357 134.089 128.172C134.089 128.172 129.83 122.275 130.456 114.564C130.456 114.564 129.278 116.89 129.283 119.838V119.843Z"
                fill="#231F20"/>
        </g>
        <g style={{mixBlendMode:"soft-light"}} opacity="0.2">
            <path
                d="M139.985 93.9407C139.948 93.1285 139.764 92.1456 139.001 91.8733C133.831 90.0413 128.174 90.5535 122.991 92.3625C122.991 92.3625 136.481 91.1904 139.668 102.039C140.063 99.4183 140.096 96.5895 139.981 93.9453L139.985 93.9407Z"
                fill="white"/>
        </g>
        <g style={{mixBlendMode:"soft-light"}} opacity="0.5">
            <path d="M125.143 95.0759C125.143 95.0759 123.529 103.193 120.516 108.417" stroke="white" stroke-width="2"
                  stroke-miterlimit="10"/>
            <path
                d="M120.516 109.746C120.516 110.101 120.227 110.392 119.872 110.392C119.518 110.392 119.229 110.101 119.229 109.746C119.229 109.391 119.518 109.1 119.872 109.1C120.227 109.1 120.516 109.391 120.516 109.746Z"
                fill="white"/>
        </g>
        <g style={{mixBlendMode:"soft-light"}} opacity="0.5">
            <path
                d="M79.0488 177.937H76.3628C78.5429 177.937 80.309 179.709 80.309 181.896V195.007C80.309 197.194 78.5429 198.966 76.3628 198.966H79.0488C81.2289 198.966 82.995 197.194 82.995 195.007V181.896C82.995 179.709 81.2289 177.937 79.0488 177.937Z"
                fill="white"/>
        </g>
        <g style={{mixBlendMode:"soft-light"}} opacity="0.5">
            <path
                d="M125.051 177.937H122.365C124.545 177.937 126.311 179.709 126.311 181.896V195.007C126.311 197.194 124.545 198.966 122.365 198.966H125.051C127.231 198.966 128.997 197.194 128.997 195.007V181.896C128.997 179.709 127.231 177.937 125.051 177.937Z"
                fill="white"/>
        </g>
        <path
            d="M166.381 178.551V161.906C166.381 157.61 162.913 154.13 158.631 154.13H147.519C143.237 154.13 139.769 157.61 139.769 161.906V178.551C137.929 179.866 136.729 182.021 136.729 184.458V202.746C136.729 206.756 139.967 210.005 143.964 210.005H162.191C166.188 210.005 169.426 206.756 169.426 202.746V184.458C169.426 182.021 168.221 179.866 166.386 178.551H166.381Z"
            fill="#2C52A8"/>
        <path
            d="M143.964 205.468C142.469 205.468 141.25 204.245 141.25 202.746V184.458C141.25 183.572 141.669 182.764 142.395 182.243L144.29 180.886V161.906C144.29 160.12 145.739 158.662 147.519 158.662H158.631C160.411 158.662 161.864 160.115 161.864 161.906V180.886L163.759 182.243C164.49 182.764 164.904 183.572 164.904 184.458V202.746C164.904 204.245 163.686 205.468 162.191 205.468H143.964Z"
            fill="#4B76C4"/>
        <path
            d="M157.283 147.388H148.867C147.403 147.388 146.217 148.578 146.217 150.046V151.601C146.217 153.069 147.403 154.259 148.867 154.259H157.283C158.746 154.259 159.933 153.069 159.933 151.601V150.046C159.933 148.578 158.746 147.388 157.283 147.388Z"
            fill="#042066"/>
        <path
            d="M166.625 129.349C171.484 129.349 175.423 125.397 175.423 120.521C175.423 115.646 171.484 111.693 166.625 111.693C161.765 111.693 157.826 115.646 157.826 120.521C157.826 125.397 161.765 129.349 166.625 129.349Z"
            fill="#B9EAFC"/>
        <path
            d="M169.338 115.93C169.338 116.682 168.731 117.291 167.981 117.291C167.232 117.291 166.625 116.682 166.625 115.93C166.625 115.177 167.232 114.568 167.981 114.568C168.731 114.568 169.338 115.177 169.338 115.93Z"
            fill="white"/>
        <path
            d="M173.156 120.143C173.156 119.031 172.259 118.131 171.15 118.131C170.042 118.131 169.145 119.031 169.145 120.143C169.145 121.255 170.042 122.155 171.15 122.155C172.259 122.155 173.156 121.255 173.156 120.143Z"
            fill="white"/>
        <g style={{mixBlendMode:"soft-light"}}>
            <path
                d="M173.506 115.034C173.864 115.999 174.071 117.042 174.071 118.131C174.071 123.004 170.134 126.959 165.273 126.959C162.485 126.959 160.002 125.653 158.392 123.622C159.643 126.968 162.849 129.354 166.62 129.354C171.477 129.354 175.419 125.404 175.419 120.526C175.419 118.449 174.701 116.543 173.506 115.039V115.034Z"
                fill="#231F20"/>
        </g>
        <path
            d="M151.774 83.2116C157.372 83.2116 161.911 78.658 161.911 73.0409C161.911 67.4237 157.372 62.8701 151.774 62.8701C146.175 62.8701 141.637 67.4237 141.637 73.0409C141.637 78.658 146.175 83.2116 151.774 83.2116Z"
            fill="#B9EAFC"/>
        <path
            d="M155.655 74.2408C156.997 74.2408 158.084 73.1499 158.084 71.8042C158.084 70.4586 156.997 69.3677 155.655 69.3677C154.314 69.3677 153.227 70.4586 153.227 71.8042C153.227 73.1499 154.314 74.2408 155.655 74.2408Z"
            fill="white"/>
        <path
            d="M154.51 67.5956C154.51 68.094 154.106 68.5001 153.609 68.5001C153.112 68.5001 152.708 68.094 152.708 67.5956C152.708 67.0972 153.112 66.6912 153.609 66.6912C154.106 66.6912 154.51 67.0972 154.51 67.5956Z"
            fill="white"/>
        <g style={{mixBlendMode:"soft-light"}}>
            <path
                d="M153.475 63.0271C156.529 64.7807 158.594 68.0709 158.594 71.855C158.594 77.471 154.055 82.0257 148.457 82.0257C147.878 82.0257 147.312 81.9657 146.76 81.8734C148.241 82.7225 149.952 83.2117 151.778 83.2117C157.376 83.2117 161.915 78.657 161.915 73.0409C161.915 68.0063 158.263 63.8393 153.48 63.0271H153.475Z"
                fill="#231F20"/>
        </g>
        <path
            d="M173.871 51.8851C177.737 50.3773 179.653 46.0104 178.151 42.1312C176.648 38.252 172.296 36.3296 168.429 37.8374C164.563 39.3451 162.647 43.7121 164.15 47.5912C165.652 51.4704 170.005 53.3928 173.871 51.8851Z"
            fill="#B9EAFC"/>
        <path
            d="M169.288 44.9329C169.288 45.9158 168.492 46.7141 167.512 46.7141C166.532 46.7141 165.737 45.9158 165.737 44.9329C165.737 43.95 166.532 43.1516 167.512 43.1516C168.492 43.1516 169.288 43.95 169.288 44.9329Z"
            fill="white"/>
        <path
            d="M170.635 40.6645C170.635 41.2921 170.124 41.8043 169.499 41.8043C168.873 41.8043 168.363 41.2921 168.363 40.6645C168.363 40.0369 168.873 39.5247 169.499 39.5247C170.124 39.5247 170.635 40.0369 170.635 40.6645Z"
            fill="white"/>
        <g style={{mixBlendMode:"soft-light"}}>
            <path
                d="M175.713 38.8969C176.541 40.106 177.028 41.5735 177.028 43.1517C177.028 47.3141 173.666 50.6874 169.518 50.6874C167.802 50.6874 166.224 50.1014 164.955 49.1277C166.307 51.1074 168.575 52.4041 171.15 52.4041C175.299 52.4041 178.661 49.0308 178.661 44.8683C178.661 42.4318 177.502 40.2675 175.713 38.8923V38.8969Z"
                fill="#231F20"/>
        </g>
        <path
            d="M28.4005 36.8804C29.2235 33.4249 27.0988 29.9543 23.6548 29.1285C20.2108 28.3028 16.7517 30.4346 15.9287 33.89C15.1057 37.3455 17.2304 40.8161 20.6744 41.6419C24.1184 42.4677 27.5774 40.3359 28.4005 36.8804Z"
            fill="#B9EAFC"/>
        <path
            d="M19.699 33.1793C20.1994 33.1793 20.6051 32.7723 20.6051 32.2702C20.6051 31.7681 20.1994 31.3611 19.699 31.3611C19.1986 31.3611 18.793 31.7681 18.793 32.2702C18.793 32.7723 19.1986 33.1793 19.699 33.1793Z"
            fill="white"/>
        <path
            d="M20.6047 35.7682C20.6047 36.6126 19.924 37.3002 19.0778 37.3002C18.2315 37.3002 17.5508 36.6173 17.5508 35.7682C17.5508 34.9191 18.2315 34.2361 19.0778 34.2361C19.924 34.2361 20.6047 34.9191 20.6047 35.7682Z"
            fill="white"/>
        <g style={{mixBlendMode:"soft-light"}}>
            <path
                d="M23.9671 39.6123C20.4257 39.6123 17.5557 36.7327 17.5557 33.1794C17.5557 32.1457 17.804 31.1766 18.2364 30.3091C16.7278 31.4858 15.7573 33.3178 15.7573 35.3806C15.7573 38.9339 18.6273 41.8135 22.1688 41.8135C24.68 41.8135 26.8463 40.3598 27.8996 38.2509C26.8141 39.1 25.4527 39.6077 23.9717 39.6077L23.9671 39.6123Z"
                fill="#231F20"/>
        </g>
        <path
            d="M126.473 154.739L128.901 156.073L126.473 157.407L125.143 159.843L123.814 157.407L121.386 156.073L123.814 154.739L125.143 152.303L126.473 154.739Z"
            fill="white"/>
        <path
            d="M146.056 170.217L148.503 171.56L146.056 172.898L144.723 175.353L143.384 172.898L140.942 171.56L143.384 170.217L144.723 167.766L146.056 170.217Z"
            fill="white"/>
        <path d="M65.6143 203.475H18.7332L11.6318 128.759H62.4683L65.6143 203.475Z" fill="#D68112"/>
        <path d="M6.98179 128.759L0 210H7.97525L8.72034 204.799H41.4814L41.831 210H50.8365L57.8183 128.759H6.98179Z"
              fill="#FFDE17"/>
        <path d="M57.8182 128.759H62.4682L55.9831 210H50.8364L57.8182 128.759Z" fill="#EDA132"/>
        <path d="M57.8184 128.759L65.6142 203.475L69.4455 202.118L62.4683 128.759H57.8184Z" fill="#EDA132"/>
        <path d="M8.72019 204.799L7.9751 210H12.901L13.6323 204.799H8.72019Z" fill="#D68112"/>
        <g style={{mixBlendMode:"soft-light"}}>
            <path
                d="M1.67876 190.457L0 210H7.97525L8.72034 204.799H41.4814L41.831 210H50.8365L54.8103 163.775L1.67876 190.457Z"
                fill="#231F20"/>
        </g>
        <g style={{mixBlendMode:"soft-light"}}>
            <path d="M34.4903 128.759H6.98168L5.35352 147.711L34.4903 128.759Z" fill="white"/>
        </g>
        <path
            d="M48.2749 137.042L50.7033 138.371L48.2749 139.705L46.9456 142.141L45.6164 139.705L43.188 138.371L45.6164 137.042L46.9456 134.601L48.2749 137.042Z"
            fill="white"/>
        <path
            d="M17.0864 190.651L19.5148 191.984L17.0864 193.318L15.7572 195.755L14.428 193.318L11.9995 191.984L14.428 190.651L15.7572 188.214L17.0864 190.651Z"
            fill="white"/>
        <path
            d="M9.30464 181.186L11.7331 182.519L9.30464 183.853L7.97543 186.29L6.64622 183.853L4.21777 182.519L6.64622 181.186L7.97543 178.749L9.30464 181.186Z"
            fill="white"/>
        <path
            d="M38.1881 180.554H19.846C19.3308 180.554 18.8571 180.268 18.6225 179.806L10.3713 163.867C10.1552 163.447 10.1644 162.944 10.3989 162.538L19.57 146.599C19.8184 146.17 20.2737 145.907 20.7658 145.907H39.108C39.6001 145.907 40.0554 146.17 40.3038 146.599L49.4749 162.538C49.7324 162.986 49.7186 163.539 49.4427 163.973L39.3517 179.912C39.0988 180.314 38.6572 180.554 38.1881 180.554ZM20.683 177.785H37.4292L46.6647 163.193L38.3077 148.671H21.5569L13.1631 163.263L20.6784 177.78L20.683 177.785Z"
            fill="#00003D"/>
        <path d="M31.9284 166.114L33.2576 152.044H27.7108L26.3862 166.114H31.9284Z" fill="#00003D"/>
        <path d="M26.1977 168.113L25.6826 173.586H31.2248L31.7399 168.113H26.1977Z" fill="#00003D"/>
        <g style={{mixBlendMode:"soft-light"}}>
            <mask id="mask2_10202_3283" style={{maskType:"luminance"}} maskUnits="userSpaceOnUse" x="141" y="158"
                  width="24" height="48">
                <path
                    d="M143.964 205.468C142.469 205.468 141.25 204.245 141.25 202.746V184.458C141.25 183.572 141.669 182.764 142.395 182.243L144.29 180.886V161.906C144.29 160.12 145.739 158.662 147.519 158.662H158.631C160.411 158.662 161.864 160.115 161.864 161.906V180.886L163.759 182.243C164.49 182.764 164.904 183.572 164.904 184.458V202.746C164.904 204.245 163.686 205.468 162.191 205.468H143.964Z"
                    fill="white"/>
            </mask>
            <g mask="url(#mask2_10202_3283)">
                <path d="M164.873 166.587L132.007 186.574L137.395 195.493L170.261 175.506L164.873 166.587Z"
                      fill="white"/>
                <path d="M172.209 178.731L139.099 198.866L141.047 202.09L174.157 181.955L172.209 178.731Z"
                      fill="white"/>
            </g>
        </g>
        <path d="M10.804 131.966L9.12061 154.13" stroke="white" stroke-width="5" stroke-miterlimit="10"/>
        <path
            d="M9.96238 156.816C9.96238 157.48 9.42425 158.02 8.76195 158.02C8.09965 158.02 7.56152 157.48 7.56152 156.816C7.56152 156.151 8.09965 155.611 8.76195 155.611C9.42425 155.611 9.96238 156.151 9.96238 156.816Z"
            fill="white"/>
        <g opacity="0.8">
            <mask id="mask3_10202_3283" style={{maskType:"luminance"}} maskUnits="userSpaceOnUse" x="146" y="147"
                  width="14" height="8">
                <path
                    d="M157.283 147.388H148.867C147.403 147.388 146.217 148.578 146.217 150.046V151.601C146.217 153.069 147.403 154.259 148.867 154.259H157.283C158.746 154.259 159.933 153.069 159.933 151.601V150.046C159.933 148.578 158.746 147.388 157.283 147.388Z"
                    fill="white"/>
            </mask>
            <g mask="url(#mask3_10202_3283)">
                <path d="M148.167 145.907V156.816" stroke="#2C52A8" stroke-width="2" stroke-miterlimit="10"/>
                <path d="M151.438 145.907V156.816" stroke="#2C52A8" stroke-width="2" stroke-miterlimit="10"/>
                <path d="M154.712 145.907V156.816" stroke="#2C52A8" stroke-width="2" stroke-miterlimit="10"/>
                <path d="M157.987 145.907V156.816" stroke="#2C52A8" stroke-width="2" stroke-miterlimit="10"/>
            </g>
        </g>
    </svg>

)
