export const BoxCleaned = () => {

    return (
        <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3 23V16C3 14.6167 3.4875 13.4375 4.4625 12.4625C5.4375 11.4875 6.61667 11 8 11H9V3C9 2.45 9.19583 1.97917 9.5875 1.5875C9.97917 1.19583 10.45 1 11 1H13C13.55 1 14.0208 1.19583 14.4125 1.5875C14.8042 1.97917 15 2.45 15 3V11H16C17.3833 11 18.5625 11.4875 19.5375 12.4625C20.5125 13.4375 21 14.6167 21 16V23H3ZM5 21H7V18C7 17.7167 7.09583 17.4792 7.2875 17.2875C7.47917 17.0958 7.71667 17 8 17C8.28333 17 8.52083 17.0958 8.7125 17.2875C8.90417 17.4792 9 17.7167 9 18V21H11V18C11 17.7167 11.0958 17.4792 11.2875 17.2875C11.4792 17.0958 11.7167 17 12 17C12.2833 17 12.5208 17.0958 12.7125 17.2875C12.9042 17.4792 13 17.7167 13 18V21H15V18C15 17.7167 15.0958 17.4792 15.2875 17.2875C15.4792 17.0958 15.7167 17 16 17C16.2833 17 16.5208 17.0958 16.7125 17.2875C16.9042 17.4792 17 17.7167 17 18V21H19V16C19 15.1667 18.7083 14.4583 18.125 13.875C17.5417 13.2917 16.8333 13 16 13H8C7.16667 13 6.45833 13.2917 5.875 13.875C5.29167 14.4583 5 15.1667 5 16V21ZM13 11V3H11V11H13Z"
                fill="white" fill-opacity="0.56"/>
        </svg>
    )
}
