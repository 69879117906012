// DisposableDrawer.tsx
import React, {createContext, useCallback, useEffect, useState} from 'react';
import {Drawer, DrawerProps} from '@mui/material';
import {OpenDrawerConfig} from "@src/utils/hooks/contextMenu/useDrawer";

interface DisposableDrawerProps extends Omit<DrawerProps, 'open' | 'onClose'> {
    isOpen: boolean;
    onClose: () => void;
    config: OpenDrawerConfig
}

export const DisposableDrawerContext = createContext({} as DisposableDrawerProps);

/**
 * A drawer component that disposes itself after the close animation completes.
 */
export const DisposableDrawer: React.FC<DisposableDrawerProps> = ({
                                                                      isOpen,
                                                                      onClose,
                                                                      config,
                                                                      children,
                                                                      ...props
                                                                  }) => {

    // const [open, setOpen] = useState<boolean>(false);
    //
    // // Separate close handler to trigger the drawer close and dispose after a delay
    // const closeDrawer = useCallback(() => {
    //     setOpen(false);
    //     setTimeout(onClose, 200); // Delay to match drawer close animation duration
    // }, [onClose]);
    //
    // useEffect(() => {
    //     setOpen(true); // Open drawer on mount
    // }, []);


    useEffect(() => {
        // Only trigger onClose after the animation if `isOpen` is set to false
        // if (!isOpen) {
        //     const timer = setTimeout(onClose, 200); // Adjust for animation duration if needed
        //     return () => clearTimeout(timer);
        // }
    }, [isOpen, onClose]);

    return (
        <DisposableDrawerContext.Provider value={{isOpen, onClose, children, config, ...props}}>
            <Drawer open={isOpen} onClose={onClose} {...props}>
                {children}
            </Drawer>
        </DisposableDrawerContext.Provider>
    );
};

export default DisposableDrawer;
