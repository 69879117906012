export const ManualEnter = () => {

    return (
        <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2 14C1.45 14 0.979167 13.8042 0.5875 13.4125C0.195833 13.0208 0 12.55 0 12V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H18C18.55 0 19.0208 0.195833 19.4125 0.5875C19.8042 0.979167 20 1.45 20 2V12C20 12.55 19.8042 13.0208 19.4125 13.4125C19.0208 13.8042 18.55 14 18 14H2ZM2 12H18V2H2V12ZM6 11H14V9H6V11ZM3 8H5V6H3V8ZM6 8H8V6H6V8ZM9 8H11V6H9V8ZM12 8H14V6H12V8ZM15 8H17V6H15V8ZM3 5H5V3H3V5ZM6 5H8V3H6V5ZM9 5H11V3H9V5ZM12 5H14V3H12V5ZM15 5H17V3H15V5Z"
                fill="white" fill-opacity="0.56"/>
        </svg>

    )
}
