import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useHttpClient } from "@src/utils/hooks/xhr/useHttpClient";
import { XhrBase } from "@src/core/xhr";
import { useContext } from "react";
import { QueryDependencyContext } from "@src/components/context/QueryDeppendencyContext/QueryDeppendencyContext";
import { TBoxIssue } from "@src/components/shared/BoxIssues/BoxIssuesNames";

export type EventType =
    | "registered"
    | "checked_in"
    | "edited"
    | "repaired"
    | "relabeled"
    | "cleaned"
    | "dispatched"
    | "recycled"
    | "photo"
    | "reviewed";

type StatusType = "ok" | "repairing" | "cleaning";

export type BoxEvent = {
    date: string;
    box: {
        code: string;
        status: StatusType;
        issues: TBoxIssue[];
    };
    user: {
        login: string;
        role: "manager" | "staff" | "user";
    };
    type: EventType;
    data?: {
        status?: StatusType;
        [key: string]: any;
    };
};


export type TransformedGroup = {
    dispatched: BoxEvent;
    actions: BoxEvent[];
    reviewed: BoxEvent;
};

export type TransformedData = {
    registered: BoxEvent;
    groups: TransformedGroup[];
    currentActions: Record<string, BoxEvent>;
};

export function transformData(events: BoxEvent[]): TransformedData {
    if (events.length === 0) throw new Error("Events array cannot be empty.");

    const registeredEvent = events[0];
    if (registeredEvent.type !== "registered") {
        throw new Error("The first event must be of type 'registered'.");
    }

    const groups: TransformedGroup[] = [];
    let currentActions: BoxEvent[] = [];
    let dispatchedEvent: BoxEvent | null = null;
    let lastCheckedInIndex: number = -1;

    for (let i = 1; i < events.length; i++) {
        const event = events[i];

        if (event.type === "checked_in") {
            lastCheckedInIndex = i; // Update the last `checked_in` index
        }

        if (event.type === "dispatched") {
            if (dispatchedEvent) {
                // Close the current group
                groups.push({
                    dispatched: dispatchedEvent,
                    actions: currentActions.filter(action => action.type !== "dispatched" && action.type !== "reviewed"),
                    reviewed: currentActions.find(action => action.type === "reviewed") || dispatchedEvent, // Ensure reviewed is present
                });
            }

            // Reset for a new group
            dispatchedEvent = event;
            currentActions = [];
        } else if (dispatchedEvent) {
            // Collect intermediate actions within the group
            currentActions.push(event);
        }
    }

    // Handle any remaining actions after the last dispatched event
    if (dispatchedEvent) {
        groups.push({
            dispatched: dispatchedEvent,
            actions: currentActions.filter(action => action.type !== "dispatched" && action.type !== "reviewed"),
            reviewed: currentActions.find(action => action.type === "reviewed") || dispatchedEvent,
        });
    }

    // Collect actions after the last `checked_in` event
    const actionsAfterLastCheckedIn = lastCheckedInIndex > -1
        ? events.slice(lastCheckedInIndex + 1)
        : [];

    return {
        registered: registeredEvent,
        groups: groups.reverse(), // Reverse the groups to maintain chronological order
        currentActions: actionsAfterLastCheckedIn.reduce((acc, el) => ({ ...acc, [el.type]: el }), {}),
    };
}

export const useBoxHistory = ({ code, refetchOnMount }: { code: string; refetchOnMount?: boolean }) => {
    const { xhr } = useHttpClient();
    const { deppFor } = useContext(QueryDependencyContext);

    const depps = [deppFor("useBoxHistory"), code];

    const query = useQueryClient();

    const prevData = query.getQueryData(depps);

    return useQuery({
        queryKey: depps,
        queryFn: async () => {
            const { data } = await xhr.get({
                relativeUrl: XhrBase.BOX_HISTORY({ code }),
            });

            // Filter out unwanted "edited" events
            const filteredData = data.filter(({ type }: { type: EventType }) => type !== "edited");

            // Transform the data into the desired format
            const groupedData = transformData(filteredData);

            return groupedData;
        },
        refetchOnMount: refetchOnMount || !prevData,
    });
};

export const useBoxHistoryLastEvent = ({ code }: { code: string }) => {
    const { data } = useBoxHistory({ code });

    return { data: data?.groups[0] };
};

