import {Checkbox, FormControl, FormControlLabel, FormLabel, Radio as MuiRadio, RadioGroup} from "@mui/material";
import {useController, useFormContext} from "react-hook-form";

interface IRadioGroup {
    label: string;
    value: any;
}

interface RadioProps {
    path: string;
    radioGroup: IRadioGroup[];
}

export const Radio = ({radioGroup, path}: RadioProps) => {
    const {control} = useFormContext();
    const {field} = useController({name: path, control});


    return (
        <FormControl>
            {/*<FormLabel id="demo-radio-buttons-group-label">Gender</FormLabel>*/}
            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name={path}
                value={field.value}
                onChange={field.onChange}
            >
                {
                    radioGroup.map(({label, value}) => {

                        return (
                            <FormControlLabel
                                value={value}
                                control={<MuiRadio
                                    sx={{
                                        color: "white",
                                        '&.Mui-checked': {
                                            color: "#98C75B",
                                        },
                                    }}
                                />}
                                sx={{
                                    color: "white",
                                }}
                                label={label}
                            />
                        )
                    })
                }
            </RadioGroup>
        </FormControl>
    )
}
