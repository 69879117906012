import React, {useContext} from "react";

import {BoxBoxPendingTodoItem} from "@src/components/core/pages/BoxInfoSuperAdmin/components/BoxBoxPendingTodos/components/BoxBoxPendingTodoItem/BoxBoxPendingTodoItem";
import {QrCodeChanged} from "@src/components/icons/BoxActions/QrCodeChanged";
import {DialogWindowContextHolderContext} from "@src/components/context/DialogWindowContextHolder/DialogWindowContextHolder.context";
import {ApplicationMap} from "@src/core/applicationMap";
import {StartQrCodeChange} from "@src/components/shared/SaveBoxButton/components/ReportBox/components/ChangeQrCodeBoxButton/ChangeQrCodeBoxContent/components/StartQrCodeChange/StartQrCodeChange";
import {useTranslationCascade} from "@src/utils/hooks/i18n/useTranslationCascade";


export const RelabelBoxTodoButton = ({code}: {code: string}) => {
    const {drawerApi} = useContext(DialogWindowContextHolderContext);
    const {t} = useTranslationCascade();

    const onClick = () => {
        drawerApi.open({
            key: ApplicationMap.REPORT_BOX_Action,
            anchor: "right",
            children: (
                <StartQrCodeChange code={code} />
            )
        })
    }

    return (
        <BoxBoxPendingTodoItem
            onClick={onClick}
            code={code}
            label={ t('translations:boxStaff.reviewOptions.qrChange') }
            icon={<QrCodeChanged/>}
        />
    )
}
