import {
    BoxAction
} from "@src/components/shared/SaveBoxButton/components/ReportBox/components/BoxActionBase/BoxActionBase";
import {RepairBoxIIcon} from "@src/components/icons/BoxActions/RepairBoxIIcon";
import {useContext} from "react";
import {
    DialogWindowContextHolderContext
} from "@src/components/context/DialogWindowContextHolder/DialogWindowContextHolder.context";
import {ApplicationMap} from "@src/core/applicationMap";
import {
    RepairBoxContent
} from "@src/components/shared/SaveBoxButton/components/ReportBox/components/RepairBoxButton/components/RepairBoxContent/RepairBoxContent";
import {useBoxHistory} from "@src/services/box/useBoxHistory";
import {useTranslationCascade} from "@src/utils/hooks/i18n/useTranslationCascade";
import {useGetBoxInfoResult} from "@src/services/box/useGetBoxInfo";

export const RepairBoxButton = ({code}: {code: string}) => {
    const {drawerApi} = useContext(DialogWindowContextHolderContext);
    const {data: boxHistory} = useBoxHistory({code});
    const {t} = useTranslationCascade();

    const {data} = useGetBoxInfoResult({code, refetchOnMount: false});

    const boxTodoActions = data?.actions;

    const onClick = () => {
        drawerApi.open({
            key: ApplicationMap.REPORT_BOX_Action,
            anchor: "right",
            children: (
                <RepairBoxContent code={code} />
            )
        })
    }

    return (
        <BoxAction
            onClick={onClick}
            code={code}
            event={"registered"}
            label={ t('translations:boxStaff.reviewOptions.repair') }
            icon={<RepairBoxIIcon />}
            done={boxHistory?.currentActions?.['repaired']}
            requested={boxTodoActions['repair'] === "planned"}
        />
    )
}
