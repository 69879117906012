export const FullLogo = () => {

    return (
        <svg width="143" height="67" viewBox="0 0 143 67" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_10032_12081)">
                <path
                    d="M19.8248 41.1648V58.424C19.8248 64.2664 15.2338 67 10.4827 67C6.47894 67 3.3827 65.4992 1.88796 62.6048L5.25112 60.6752C6.05187 62.2296 7.38645 63.4624 10.5361 63.4624C13.8992 63.4624 15.9278 61.64 15.9278 58.4776V56.28C14.4865 58.3168 12.1376 59.496 9.62856 59.4424C4.50375 59.2816 0.393222 55.0472 0.499989 49.9016C0.606756 44.8632 4.61052 40.7896 9.62856 40.736C12.1376 40.6288 14.4865 41.8616 15.9278 43.8984V41.2184L19.8248 41.1648ZM15.9812 50.0624C16.0346 46.9536 13.5789 44.4344 10.4827 44.3808C10.3759 44.3808 10.2692 44.3808 10.2158 44.3808C7.06615 44.3272 4.50375 46.7928 4.45037 49.9552C4.3436 53.064 6.85262 55.6904 10.0022 55.744C10.0556 55.744 10.1624 55.744 10.2158 55.744C13.312 55.8512 15.8744 53.4392 15.9812 50.3304C15.9812 50.2232 15.9812 50.116 15.9812 50.0624Z"
                    fill="white"/>
                <path
                    d="M33.5444 40.8433V45.0777C30.982 44.9169 27.9391 46.0961 27.9391 50.1697V59.2817H24.0421V41.1649H27.9391V44.2201C29.0602 41.7545 31.2489 40.8433 33.5444 40.8433Z"
                    fill="white"/>
                <path
                    d="M45.0218 56.1728C46.8369 56.2264 48.5985 55.4224 49.7196 53.9216L52.976 55.7976C51.3211 58.2632 48.5985 59.764 45.0218 59.764C39.0429 59.764 35.1459 55.6904 35.1459 50.2232C35.1459 44.8632 39.0429 40.6824 44.7549 40.6824C50.1466 40.6824 53.8835 45.024 53.8835 50.2232C53.8835 50.7592 53.8301 51.2952 53.7233 51.8312H39.2564C39.8436 54.7256 42.1391 56.1728 45.0218 56.1728ZM49.8797 48.7224C49.3459 45.6136 47.0504 44.2736 44.7015 44.2736C41.7654 44.2736 39.7902 45.9888 39.203 48.7224H49.8797Z"
                    fill="white"/>
                <path
                    d="M56.7128 35.9655C56.7128 34.5719 57.8338 33.4463 59.2218 33.4463C60.6098 33.4463 61.7308 34.5719 61.7308 35.9655C61.7308 37.3591 60.6098 38.4847 59.2218 38.4847C57.8338 38.4311 56.7128 37.3591 56.7128 35.9655ZM57.3 41.1647H61.197V59.2815H57.3V41.1647Z"
                    fill="white"/>
                <path
                    d="M82.0699 48.1864V59.3352H78.1729V48.6152C78.1729 45.8816 76.5714 44.4344 74.1158 44.4344C71.5534 44.4344 69.5248 45.9352 69.5248 49.6336V59.3352H65.6278V41.1648H69.5248V43.4696C70.6992 41.5936 72.6744 40.6824 75.1301 40.6824C79.1872 40.6824 82.0699 43.4696 82.0699 48.1864Z"
                    fill="white"/>
                <path
                    d="M94.9887 56.1728C96.8038 56.2264 98.5654 55.4224 99.6865 53.9216L102.943 55.7976C101.288 58.2632 98.5654 59.764 94.9887 59.764C89.0098 59.764 85.1128 55.6904 85.1128 50.2232C85.1128 44.8632 89.0098 40.6824 94.7218 40.6824C100.114 40.6824 103.85 45.024 103.85 50.2232C103.85 50.7592 103.797 51.2952 103.69 51.8312H89.2233C89.8105 54.7256 92.106 56.1728 94.9887 56.1728ZM99.7932 48.7224C99.2594 45.6136 96.9639 44.2736 94.6151 44.2736C91.679 44.2736 89.7038 45.9888 89.1166 48.7224H99.7932Z"
                    fill="white"/>
                <path
                    d="M116.396 40.8433V45.0777C113.833 44.9169 110.79 46.0961 110.79 50.1697V59.2817H106.893V41.1649H110.79V44.2201C111.858 41.7545 114.1 40.8433 116.396 40.8433Z"
                    fill="white"/>
                <path
                    d="M142.5 17.2056L134.492 12.596L130.489 16.5624L137.001 20.3144V29.1048L129.368 33.5L121.734 29.1048V20.368L134.439 7.6648V0L116.289 18.1168V32.2672L129.368 39.7712L142.447 32.2672L142.5 17.2056Z"
                    fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0_10032_12081">
                    <rect width="142" height="67" fill="white" transform="translate(0.5)"/>
                </clipPath>
            </defs>
        </svg>
    )
}
