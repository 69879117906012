export const BoxWashedIcon = () => {

    return (
        <svg width="220" height="110" viewBox="0 0 220 110" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_10216_5934)">
                <path d="M212.391 27.0419H159.063V109.601H212.391V27.0419Z" fill="#608929"/>
                <path d="M212.343 3.82135H159.063V33.1386H212.343V3.82135Z" fill="#405C19"/>
                <path d="M16.8606 27.0419H7.97266V109.601H16.8606H159.064V27.0419H16.8606Z" fill="#86BD3D"/>
                <path d="M159.064 33.1386V3.82135H16.8606H7.97266V33.1386H16.8606H159.064Z" fill="#608929"/>
                <path d="M65.0864 48.2965V62.4677L212.127 62.4677V48.2965L65.0864 48.2965Z" fill="#639A2C"/>
                <path d="M7.97656 48.2965L7.97656 62.4677L159.06 62.4677V48.2965L7.97656 48.2965Z" fill="#ABD179"/>
                <path d="M219.5 0.77301H159.063V27.0419H219.5V0.77301Z" fill="#608929"/>
                <path d="M181.905 27.0419H196.143V0.772987H181.905V27.0419Z" fill="#639A2C"/>
                <path d="M159.064 27.0419V0.77301H9.82774H0.5V27.0419H9.82774H159.064Z" fill="#86BD3D"/>
                <path d="M106.028 27.0419H120.265V0.772987H106.028V27.0419Z" fill="#ABD179"/>
                <path d="M33.053 27.0419H47.2905V0.772987H33.053V27.0419Z" fill="#ABD179"/>
                <path d="M83.0823 76.3443H26.4922V99.1589H83.0823V76.3443Z" fill="white" fill-opacity="0.5"/>
                <path d="M32.9771 91.0089H67.1655V89.6001H32.9771V91.0089Z" fill="#998376"/>
                <path d="M41.5213 95.3466H50.2173V93.9378H41.5213V95.3466Z" fill="#6D5041"/>
                <path d="M41.5213 95.3466H50.2173V93.9378H41.5213V95.3466Z" fill="#7F6D62"/>
                <path d="M33.1371 82.4489H54.3594V81.0402H33.1371V82.4489Z" fill="#7F6D62"/>
                <path d="M41.5213 86.6712H50.2173V85.2624H41.5213V86.6712Z" fill="#7F6D62"/>
                <path d="M67.7371 82.2101H76.4331V80.8014H67.7371V82.2101Z" fill="#7F6D62"/>
                <path d="M34.0086 95.3466H37.9868V93.9378H34.0086V95.3466Z" fill="#7F6D62"/>
                <path d="M41.6013 93.9418H32.9053V95.3505H41.6013V93.9418Z" fill="#998376"/>
                <path d="M37.1032 85.6723H33.125V87.0811H37.1032V85.6723Z" fill="#7F6D62"/>
            </g>
            <defs>
                <clipPath id="clip0_10216_5934">
                    <rect width="219" height="108.828" fill="white" transform="translate(0.5 0.77301)"/>
                </clipPath>
            </defs>
        </svg>

    )
}
