import {useContext} from "react";
import {Typography} from "@mui/material";

import {BoxActionContainer} from "@src/components/shared/SaveBoxButton/components/BoxActionContainer/BoxActionContainer";
import {Button} from "@src/components/shared/form/Button/Button";
import {WashBoxIcon} from "@src/components/icons/BoxActions/WashBoxIcon";
import {Space} from "@src/components/shared/Space/Space";
import {DisposableDrawerContext} from "@src/utils/hooks/contextMenu/components/DisposableDrawer/DisposableDrawer";
import {ApplicationMap} from "@src/core/applicationMap";
import {DialogWindowContextHolderContext} from "@src/components/context/DialogWindowContextHolder/DialogWindowContextHolder.context";
import {WashComplete} from "@src/components/shared/SaveBoxButton/components/ReportBox/components/WashBoxButton/components/WashBoxContent/components/WashComplete/WashComplete";
import {useTranslationCascade} from "@src/utils/hooks/i18n/useTranslationCascade";
import {useTodoMutation} from "@src/services/box/useTodoMutation";


export const WashBoxContent = ({code}: {code: string}) => {
    const foundDrawerContext = useContext(DisposableDrawerContext);
    const {drawerApi} = useContext(DialogWindowContextHolderContext);
    const {t} = useTranslationCascade();
    const {mutateAsync, isPending} = useTodoMutation({code, action: 'clean'});

    const onClick = () => {
        drawerApi.open({
            key: ApplicationMap.REPORT_BOX_Action,
            anchor: "right",
            children: (
                <WashComplete code={code} />
            )
        })
    }

    const onRequestWash = async () => {
        await mutateAsync();
        foundDrawerContext.onClose();
    }

    return (
        <BoxActionContainer
            title={ t('translations:boxStaff.reviewOptions.cleanOption.title') }
            topBar={(
                <Space justify={"center"} direction={"vertical"} align={"center"} padding={0}>
                    <WashBoxIcon />
                    <Typography variant="h6" gutterBottom sx={{color: 'white', textAlign: 'center'}}>
                        {code}
                    </Typography>
                </Space>
            )}
            bottomBar={(
                <Space padding={0} direction={"vertical"}>
                    <Button onClick={onClick}>
                        { t('translations:boxStaff.reviewOptions.cleanOption.startToClean') }
                    </Button>
                    <Button variant={"outlined"} onClick={onRequestWash} loading={isPending}>
                        { t('translations:boxStaff.reviewOptions.cleanOption.requestCleaning') }
                    </Button>
                </Space>
            )}
        />
    )
}
