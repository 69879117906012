import React, { useState, useCallback, ReactElement } from 'react';
import { DialogProps } from '@mui/material';
import DisposableDialog from "@src/utils/hooks/contextMenu/components/DisposableModal/DisposableModal";

export interface OpenDialogConfig extends Omit<DialogProps, 'open' | 'onClose' | 'children'> {
    key?: string;
    children: ReactElement; // Enforce ReactElement
    onOpen?: () => void;
    onClose?: () => void;
}

interface DialogInstance {
    key: string;
    config: OpenDialogConfig;
    isOpen: boolean;
}

export interface DialogAPI {
    open: (config: OpenDialogConfig) => void;
    close: (key: string) => void;
    closeAll: () => void;
    closeMany: (keys: string[]) => void;
    getIsOpenOneOf: (keys: string[]) => boolean;
    leaveOnly: (key: string) => void;
}

export const useDialog = (): readonly [DialogAPI, React.ReactElement] => {
    const [dialogInstances, setDialogInstances] = useState<DialogInstance[]>([]);

    const open = useCallback((config: OpenDialogConfig) => {
        if (config.onOpen) config.onOpen();

        const key = config.key || `dialog_${Date.now()}`;
        const newInstance: DialogInstance = { key, config, isOpen: false };

        setDialogInstances((prev) => [...prev, newInstance]);

        // Delay opening for animation
        setTimeout(() => {
            setDialogInstances((prev) =>
                prev.map((instance) =>
                    instance.key === key ? { ...instance, isOpen: true } : instance
                )
            );
        }, 10);
    }, []);

    const close = useCallback((key: string) => {
        setDialogInstances((prev) =>
            prev.map((instance) =>
                instance.key === key ? { ...instance, isOpen: false } : instance
            )
        );

        const closingInstance = dialogInstances.find((instance) => instance.key === key);
        if (closingInstance?.config.onClose) {
            closingInstance.config.onClose();
        }

        // Remove after animation
        setTimeout(() => {
            setDialogInstances((prev) => prev.filter((instance) => instance.key !== key));
        }, 200);
    }, []);

    const closeMany = useCallback((keys: string[]) => {
        setDialogInstances((prev) =>
            prev.map((instance) =>
                keys.includes(instance.key) ? { ...instance, isOpen: false } : instance
            )
        );

        dialogInstances.forEach((instance) => {
            if (keys.includes(instance.key) && instance.config.onClose) {
                instance.config.onClose();
            }
        });

        // Remove after animation
        setTimeout(() => {
            setDialogInstances((prev) => prev.filter((instance) => !keys.includes(instance.key)));
        }, 200);
    }, [dialogInstances]);

    const closeAll = useCallback(() => {
        dialogInstances.forEach(({ config }) => {
            if (config.onClose) config.onClose();
        });

        setDialogInstances((prev) => prev.map((instance) => ({ ...instance, isOpen: false })));

        // Remove after animation
        setTimeout(() => {
            setDialogInstances([]);
        }, 200);
    }, [dialogInstances]);

    const getIsOpenOneOf = useCallback((keys: string[]) => {
        return dialogInstances.some(({ key, isOpen }) => keys.includes(key) && isOpen);
    }, [dialogInstances]);

    const leaveOnly = useCallback((key: string) => {
        setDialogInstances((prev) =>
            prev.map((instance) =>
                instance.key === key
                    ? { ...instance, isOpen: true }
                    : { ...instance, isOpen: false }
            )
        );

        dialogInstances.forEach((instance) => {
            if (instance.key !== key && instance.config.onClose) {
                instance.config.onClose();
            }
        });

        // Remove others after animation
        setTimeout(() => {
            setDialogInstances((prev) =>
                prev.filter((instance) => instance.key === key || instance.isOpen)
            );
        }, 200);
    }, [dialogInstances]);

    const contextHolder = (
        <>
            {dialogInstances.map(({ key, config, isOpen }) => (
                <DisposableDialog
                    key={key}
                    isOpen={isOpen}
                    onClose={() => close(key)}
                    {...config} // Spread other props
                >
                    {config.children}
                </DisposableDialog>
            ))}
        </>
    );

    const dialogApi: DialogAPI = {
        open,
        close,
        closeAll,
        closeMany,
        getIsOpenOneOf,
        leaveOnly,
    };

    return [dialogApi, contextHolder] as const;
};
