import {useMutation} from "@tanstack/react-query";
import {useHttpClient} from "@src/utils/hooks/xhr/useHttpClient";
import {BoxIssue, XhrBase} from "@src/core/xhr";

export const usePatchBoxIssues = ({code}: {code: string}) => {
    const {xhr} = useHttpClient();

    return useMutation({
        mutationFn: async ({issues}: {issues: BoxIssue[]}) => {

            xhr.patch({
                relativeUrl: XhrBase.BOX_GET_BY_CODE({code}),
                payload: XhrBase.BOX_SUBMIT_ISSUES_body({code, issues})
            });
        }
    })
}
