import {createContext} from "react";
import {HookAPI} from "@src/utils/hooks/contextMenu/useDrawer";
import {SnackbarHookAPI} from "@src/utils/hooks/contextMenu/useSnackbar";
import {DialogAPI} from "@src/utils/hooks/contextMenu/useModal";


interface IDialogWindowContextHolderProps {
    drawerApi: HookAPI
    snackbarApi: SnackbarHookAPI,
    dialogApi: DialogAPI,
}

export const DialogWindowContextHolderContext = createContext({} as IDialogWindowContextHolderProps);
