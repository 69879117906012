import {ScannerContainer} from "@src/components/core/pages/Scanner/components/ScannerContainer/ScannerContainer";
import {ApplicationMap} from "@src/core/applicationMap";
import {useContext} from "react";
import {
    DialogWindowContextHolderContext
} from "@src/components/context/DialogWindowContextHolder/DialogWindowContextHolder.context";
import {
    CompleteQrCodeRenew
} from "@src/components/shared/SaveBoxButton/components/ReportBox/components/ChangeQrCodeBoxButton/ChangeQrCodeBoxContent/components/StartQrCodeChange/components/CompleteQrCodeRenew/CompleteQrCodeRenew";
import {IDetectedBarcode} from "@yudiel/react-qr-scanner";
import {CloseIcon} from "@src/components/icons/core/CloseIcon";

import styles from "./StartQrCodeChange.module.scss"
import {useExternalScanner} from "@src/utils/hooks/scanner/useExternalScanner";


export const StartQrCodeChange = ({code}: {code: string}) => {
    const {drawerApi} = useContext(DialogWindowContextHolderContext);

    const onScanFromScanner = (foundCode: string) => {
        drawerApi.open({
            key: ApplicationMap.REPORT_BOX_Action,
            anchor: "right",
            children: (
                <CompleteQrCodeRenew code={code} nextCode={foundCode} />
            )
        })
    }

    useExternalScanner(onScanFromScanner, false);

    const onScan = (result: IDetectedBarcode[]) => {
        const foundCode = result[0].rawValue;


        drawerApi.open({
            key: ApplicationMap.REPORT_BOX_Action,
            anchor: "right",
            children: (
                <CompleteQrCodeRenew code={code} nextCode={foundCode} />
            )
        })
    }

    const closeScanner = () => {
        drawerApi.close(ApplicationMap.REPORT_BOX_Action)
    }

    return (
        <>
            <ScannerContainer onScan={onScan}/>
            <div className={styles.closeIcon}>
                <button className={styles.actionButton} onClick={closeScanner}>
                    <CloseIcon/>
                </button>
            </div>
        </>
)
}
