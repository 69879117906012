import { v4 as uuidv4 } from 'uuid';

// Define a custom type for the Blob with an ID property
export class PhotoReportImageBlob extends Blob {
    public id: string;

    constructor(data: BlobPart[], options?: BlobPropertyBag) {
        super(data, options);
        this.id = uuidv4(); // Assign a unique ID
    }
}

export function base64ToBinary(base64String: string): PhotoReportImageBlob {
    // Decode the base64 string to raw binary data
    const binaryString = atob(base64String.split(',')[1]); // Ignore the data URL header
    const binaryLength = binaryString.length;
    const bytes = new Uint8Array(binaryLength);

    // Populate the Uint8Array with the binary data
    for (let i = 0; i < binaryLength; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }

    // Convert to a Blob (or return Uint8Array if needed)
    const blob = new PhotoReportImageBlob([bytes], { type: 'image/jpeg' }); // Change MIME type as needed
    return blob;
}
