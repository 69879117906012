import React, {useContext} from "react";
import {useIsLoggedIn} from "@src/services/auth/useIsLoggedIn";
import {PageTemplate} from "@src/components/core/PageTemplate/PageTemplate";
import {LoginPage} from "@src/components/core/pages/LoggedInGuard/components/LoginPage/LoginPage";
import Home from "@src/components/core/pages/Home/Home";
import {FullPageLoader} from "@src/components/core/FullPageLoader/FullPageLoader";
import {useExternalScanner} from "@src/utils/hooks/scanner/useExternalScanner";
import {Alert} from "@mui/material";
import {
    DialogWindowContextHolderContext
} from "@src/components/context/DialogWindowContextHolder/DialogWindowContextHolder.context";
import {useGetBoxInfo} from "@src/services/box/useGetBoxInfo";
import {useOpenBox} from "@src/components/core/pages/Scanner/utils/useOpenBox";
import {ApplicationMap} from "@src/core/applicationMap";
import {useTranslationCascade} from "@src/utils/hooks/i18n/useTranslationCascade";


export const AppRouting = () => {
    const {snackbarApi, drawerApi} = useContext(DialogWindowContextHolderContext);
    const {t} = useTranslationCascade();

    const {error, isInitialLoading} = useIsLoggedIn();
    const {mutateAsync} = useGetBoxInfo();
    const {openBox} = useOpenBox();

    const onScan = async (code: string) => {
        snackbarApi.open({
            key: "box_check",
            children: (
                <Alert severity="info" icon sx={{ width: '100%' }}>
                    {t("translations:box.notifications.loading")}
                </Alert>
            )
        });

        const scanned = await mutateAsync({code});

        snackbarApi.close("box_check")

        openBox({code: scanned.code});
    }

    const isPaused= drawerApi.getIsOpenOneOf([ApplicationMap.SCANNER, ApplicationMap.BOX_INFO, ApplicationMap.BOX_INFO_ADMIN, ApplicationMap.MANUAL_ENTER]);

    useExternalScanner(onScan, isPaused);


    if (isInitialLoading) {
        return (
            <PageTemplate>
                <FullPageLoader />
            </PageTemplate>
        )
    }

    // @ts-ignore
    const status = error?.status;

    if (status === 401) {
        return (
            <PageTemplate>
                <LoginPage />
            </PageTemplate>
        )
    }

    return <Home />;
}
