import {useQuery, useQueryClient} from "@tanstack/react-query";
import {useHttpClient} from "@src/utils/hooks/xhr/useHttpClient";
import {XhrBase} from "@src/core/xhr";
import {useContext} from "react";
import {QueryDependencyContext} from "@src/components/context/QueryDeppendencyContext/QueryDeppendencyContext";

export const useIsLoggedIn = () => {
    const {xhr} = useHttpClient();
    const {deppFor}  = useContext(QueryDependencyContext);
    const query = useQueryClient();

    const depps = query.getQueryData([deppFor('useIsLoggedIn')]);

    return useQuery({
        queryKey: [deppFor('useIsLoggedIn')],
        queryFn: async () => {
            const {data} = await xhr.get({
                relativeUrl: XhrBase.ME
            });

            return data;
        },
        refetchOnMount: !depps,
    })
}
